import { takeLatest, call, put } from "redux-saga/effects";
import {
    FETCH_HCP_BASIC_DETAILS_REQUEST,
    fetchHcpBasicDetailsSuccess,
    fetchHcpBasicDetailsFailure,
} from "../actions/hcp-management.action";
import CommonService from "../../helpers/common-service";
import {ENV} from "../../constants";

function* fetchHcpBasicDetails(action:any) {
    try {
        //@ts-ignore
        const response = yield call(CommonService._api.get, `${ENV.API_URL}hcp/${action.payload}`);
        yield put(fetchHcpBasicDetailsSuccess(response.data));
    } catch (error) {
        yield put(fetchHcpBasicDetailsFailure(error));
    }
}

export function* hcpBasicDetailsSaga() {
    yield takeLatest(FETCH_HCP_BASIC_DETAILS_REQUEST, fetchHcpBasicDetails);
}
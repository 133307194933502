import "./HcpDetailsComponentOnMap.scss";
import {ENV, ImageConfig} from "../../../constants";
import {Checkbox, Tooltip} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import {DateRangeOutlined} from "@material-ui/icons";
import {CommonService} from "../../../helpers";
import {useLocalStorage} from "../../../components/useLocalStorage";
import moment from "moment";
import CircularProgress from '@material-ui/core/CircularProgress';
import {Box} from "@material-ui/core";

interface HcpDetailsComponentOnMapProps {
    setIsHcpDetailsCardOpen: any;
    isHcpDetailsCardOpen: any;
    hcpDetails: any;
    setSelectedHcpTypeForNearbyHcp: any;
    setSelectedHcpRegionForNearbyHcp: any;
    selectedHcpTypeForNearbyHcp: any;
    selectedHcpRegionForNearbyHcp: any;
    selectedHcp: any;
    setSelectedHcp: any;
    setFacilityList: any;
    getFacilityList: any;
    checkedFacility:any;
    setCheckedFacility:any;
}

const [firstDayOfMonth, lastDayOfMonth] = CommonService.getFirstDayAndLastDayUsingDate();

const HcpDetailsComponentOnMap = (props: HcpDetailsComponentOnMapProps) => {
    const {
        setIsHcpDetailsCardOpen,
        setSelectedHcpRegionForNearbyHcp,
        setSelectedHcpTypeForNearbyHcp,
        selectedHcpTypeForNearbyHcp,
        selectedHcpRegionForNearbyHcp,
        setSelectedHcp,
        setFacilityList,
        getFacilityList,
        checkedFacility,
        setCheckedFacility,
    } = props;
    const {hcpDetails} = props;
    const [dateRange, setDateRange] = useLocalStorage<any[]>("hcp-details-date-range-shift-stats",[firstDayOfMonth, lastDayOfMonth]);
    const [startDate, endDate] = dateRange;
    const [hcpStats, setHcpStats] = useLocalStorage<any>("hcpStats", []);
    const [isHcpStatsLoading, setIsHcpStatsLoading] = useState<any>(true);
   // const [checkedFacility, setCheckedFacility] = useLocalStorage<any>("checkedFacility", null);

    const getHcpStats = useCallback(() => {
        setIsHcpStatsLoading(true);
        const payload: any = {};
        if (dateRange[0] || dateRange[1]) {
            let startDate = moment(dateRange[0]).format("YYYY-MM-DD");
            let endDate = moment(dateRange[1]).format("YYYY-MM-DD");
            if (!dateRange[1]) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        }
        CommonService._api
            .get(ENV.API_URL + `hcp/${hcpDetails?.user_id}/shiftStats`, payload)
            .then((resp) => {
                console.log(resp);
                setIsHcpStatsLoading(false);
                setHcpStats(resp.data || []);
            })
            .catch((err) => {
                setIsHcpStatsLoading(true);
                console.log(err);
            });
        console.log(hcpStats);
        // eslint-disable-next-line
    }, [hcpDetails, dateRange]);

    const getFacilityHcpClosedTheShift = useCallback(() => {
        const payload: any = {};
        if (dateRange[0] || dateRange[1]) {
            let startDate = moment(dateRange[0]).format("YYYY-MM-DD");
            let endDate = moment(dateRange[1]).format("YYYY-MM-DD");
            if (!dateRange[1]) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        }
        CommonService._api
            .get(ENV.API_URL + `hcp/${hcpDetails?.user_id}/closedFacility`, payload)
            .then((resp) => {
                console.log(resp);
                setFacilityList(resp.data || []);
            })
            .catch((err) => {
                console.log(err);
            });
        // eslint-disable-next-line
    }, [hcpDetails, dateRange]);

    useEffect(() => {
        getHcpStats();
    }, [getHcpStats]);

useEffect(()=>{
    if(checkedFacility){
        getFacilityHcpClosedTheShift()
    }else{
        getFacilityList()
    }
},[checkedFacility,getFacilityHcpClosedTheShift,getFacilityList])
    return (
        <>
            <div className={'hcp-details-card-component'}>
                <div className={'hcp-details-header'}>
                    <div className={'header-text'}>Staff Details</div>
                    <div className={'header-icon'} onClick={() => {
                        setIsHcpDetailsCardOpen(false);
                        setSelectedHcp(null);
                        setSelectedHcpTypeForNearbyHcp(null);
                        setSelectedHcpRegionForNearbyHcp(null);
                        getFacilityList();
                    }}>
                        <img src={ImageConfig.CrossIcon} alt=""/>
                    </div>
                </div>
                <div className={'hcp-details-similar-selection'}>
                    <div className={'similar-hcp-checkbox'}>
                        <Checkbox
                            style={{
                                color: "#11C5D1",
                                transform: "scale(.8)",
                            }}
                            checked={selectedHcpRegionForNearbyHcp && selectedHcpTypeForNearbyHcp}
                            onChange={(e: any) => {
                                if (e.target.checked) {
                                    setSelectedHcpTypeForNearbyHcp({
                                        code: hcpDetails?.hcp_type,
                                        title: hcpDetails?.hcp_type
                                    });
                                    setSelectedHcpRegionForNearbyHcp({
                                        code: hcpDetails?.address?.region,
                                        title: hcpDetails?.address?.region
                                    });
                                } else {
                                    setSelectedHcpTypeForNearbyHcp(null);
                                    setSelectedHcpRegionForNearbyHcp(null);
                                }
                            }}
                            id={"select_similar_hcp_cb"}/>
                    </div>
                    <div className={'similar-hcp-text'}>
                        Show similar Staff type nearby
                    </div>
                </div>
                <div className={'hcp-status-wrapper'}>
                    {hcpDetails?.is_active ? <>
                        <div className={'active-icon active'}/>
                        <div className={'status-text'}>
                            Active
                        </div>
                    </> : <>
                        <div className={'active-icon inactive'}/>
                        <div className={'status-text'}>
                            Inactive
                        </div>
                    </>}

                </div>
                <div className={'hcp-name-rate-wrapper'}>
                    <div style={{display: "flex"}}>
                        <div
                            className={'hcp-name-text-symbol'}>{hcpDetails?.first_name?.charAt(0).toUpperCase()}{hcpDetails?.last_name?.charAt(0).toUpperCase()}
                        </div>
                        <div>
                            <div className={'hcp-name-text'}>{hcpDetails?.first_name}{" "}{hcpDetails?.last_name}</div>
                            <div className={'hcp-type-text'}>{hcpDetails?.hcp_type}</div>
                        </div>
                    </div>
                    <div>
                        <div className={'details-header-text'}>Current Rate</div>
                        <div className={'hcp-rate'}>{` $ ${hcpDetails?.contract_details?.rate_per_hour || "-"}`}</div>
                    </div>
                </div>
                <div className={'basic-details-wrapper'}>
                    <div style={{width: "50%"}}>
                        <div className={'details-header-text'}>
                            Location
                        </div>
                        <div className={'details-header-text-content'} style={{width: "140px", wordWrap: "break-word"}}>
                            {hcpDetails?.address?.city || "NA"}
                        </div>
                    </div>
                    <div style={{width: "50%"}}>
                        <div className={'details-header-text'}>
                            Email Address
                        </div>
                        <div className={'details-header-text-content'} style={{width: "140px", wordWrap: "break-word"}}>
                            {hcpDetails?.email || "NA"}
                        </div>
                    </div>
                </div>
                <div className={'basic-details-wrapper'}>
                    <div style={{flex: "1"}}>
                        <div className={'details-header-text'}>
                            Phone Number
                        </div>
                        <div className={'details-header-text-content'}>
                            {hcpDetails?.contact_number || "NA"}
                        </div>
                    </div>
                    <div style={{flex: "1"}}>
                        <div className={'details-header-text'}>
                            Mailing Address
                        </div>
                        <Tooltip
                            title={hcpDetails?.address?.street + " " + hcpDetails?.address?.city + " " + hcpDetails?.address?.region + " " + hcpDetails?.address?.state + " " + hcpDetails?.address?.zip_code}>
                            <div className={'details-header-text-content'} style={{
                                whiteSpace: "nowrap",
                                width: "130px",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                            }}>
                                {hcpDetails?.address?.street + " " + hcpDetails?.address?.city + " " + hcpDetails?.address?.region + " " + hcpDetails?.address?.state + " " + hcpDetails?.address?.zip_code}
                            </div>
                        </Tooltip>
                    </div>
                </div>
                <div className={'basic-details-wrapper'}>
                    <div style={{flex: "1"}}>
                        <div className={'details-header-text'}>
                            Longitude
                        </div>
                        <div className={'details-header-text-content'}>
                            {hcpDetails?.location?.coordinates[0]}
                        </div>
                    </div>
                    <div style={{flex: "1"}}>
                        <div className={'details-header-text'}>
                            Latitude

                        </div>
                        <div className={'details-header-text-content'}>
                            {hcpDetails?.location?.coordinates[1]}
                        </div>
                    </div>
                </div>
                <hr style={{borderTop: "1px solid #97979E", opacity: '0.5', marginBottom: "10px"}}/>
                <div className={'hcp-shift-details-wrapper'}>
                    <div style={{display: "flex", justifyContent: 'space-between', marginBottom: "10px"}}>
                        <div className={'header-text'}
                             style={{display: "flex", justifyContent: "center", alignItems: "center"}}>Shift Details
                        </div>
                        <div style={{width: "50%"}}>
                            <label className="label-wrapper">
                                <DatePicker
                                    dateFormat="MM/dd/yyyy"
                                    placeholderText="Select Date"
                                    className="custom-input"
                                    selectsRange={true}
                                    startDate={startDate && new Date(startDate)}
                                    endDate={endDate && new Date(endDate)}
                                    onChange={(update) => {
                                        if (!update[0] && !update[1]) {
                                            setDateRange([firstDayOfMonth, lastDayOfMonth]);
                                        } else {
                                            setDateRange(update);
                                        }
                                    }}
                                    isClearable={true}
                                />
                                {!dateRange[0] && !dateRange[1] && (
                                    <DateRangeOutlined className="date-icon" fontSize="medium" color="action"/>
                                )}
                            </label>
                        </div>
                    </div>
                    <div className={'basic-details-wrapper'}>
                        <div style={{flex: "1"}}>
                            <div className={'details-header-text'}>
                                Total Shifts Closed
                            </div>
                            <div className={'details-header-text-content'}>
                                {isHcpStatsLoading ? <Box sx={{display: 'flex'}}>
                                    <CircularProgress size={20}/>
                                </Box> : hcpStats?.closed}
                            </div>
                        </div>
                        <div style={{flex: "1"}}>
                            <div className={'details-header-text'}>
                                Total Shifts Completed
                            </div>
                            <div className={'details-header-text-content'}>
                                {isHcpStatsLoading ? <Box sx={{display: 'flex'}}>
                                    <CircularProgress size={20}/>
                                </Box> : hcpStats?.complete}
                            </div>
                        </div>
                    </div>
                    <div className={'basic-details-wrapper'}>
                        <div style={{flex: "1"}}>
                            <div className={'details-header-text'}>
                                Shifts Applied
                            </div>
                            <div className={'details-header-text-content'}>
                                {isHcpStatsLoading ? <Box sx={{display: 'flex'}}>
                                    <CircularProgress size={20}/>
                                </Box> : hcpStats?.applied_shifts}
                            </div>
                        </div>
                        <div style={{flex: "1"}}>
                            <div className={'details-header-text'}>
                                Shifts Approved
                            </div>
                            <div className={'details-header-text-content'}>
                                {isHcpStatsLoading ? <Box sx={{display: 'flex'}}>
                                    <CircularProgress size={20}/>
                                </Box> : hcpStats?.approved}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'hcp-details-similar-selection'}>
                    <div className={'similar-hcp-checkbox'}>
                        <Checkbox style={{
                            color: "#11C5D1",
                            transform: "scale(.8)",
                        }}
                                  checked={checkedFacility}
                                  onChange={(e: any) => {
                                      setCheckedFacility(e.target.checked);
                                      // if (e.target.checked) {
                                      //     getFacilityHcpClosedTheShift();
                                      // } else {
                                      //     getFacilityList();
                                      // }
                                  }}
                                  id={"show_facility_that_hcp_closed_shift_cb"}/>
                    </div>
                    <div className={'similar-hcp-text'}>
                        Show facilities the Staff has closed the shifts with.
                    </div>
                </div>
            </div>
        </>
    );
};

export default HcpDetailsComponentOnMap;

import "./GeneratedInvoiceComponent.scss";
import React, {useCallback, useEffect, useState} from "react";
import {useLocalStorage} from "../../../../components/useLocalStorage";
import GeneratedFilters from "./generated-filters/GeneratedFilters";
import GeneratedInvoiceListTable from "./generated-invoice-list-table/GeneratedInvoiceListTable";
import {ApiService} from "../../../../helpers";
import {ENV} from "../../../../constants";

interface GeneratedProps {
    value: any;
}

const GeneratedInvoiceComponent = (props: GeneratedProps) => {
    const [status, setStatus] = useLocalStorage<any>("generatedInvoiceStatus", "");
    const [dateRange, setDateRange] = useLocalStorage("generatedInvoiceDateRange", [null, null]);
    const [startDate, setStartDate] = useLocalStorage("generatedTabStartDate", null);
    const [endDate, setEndDate] = useLocalStorage("generatedTabEndDate", null);
    const [isFacilityListLoading, setIsFacilityListLoading] = useState<boolean>(false);
    const [selectedFacilities, setSelectedFacilities] = useLocalStorage<any[]>("selectedFacilitiesGenerated", []);
    const [facilityList, setFacilityList] = useState<any | null>(null);
    const value = props?.value;

    const getFacilityData = useCallback(() => {
        setIsFacilityListLoading(true);
        let payload: any = {};
        ApiService.post(ENV.API_URL + "facility/lite", payload)
            .then((res) => {
                setFacilityList(res?.data || []);
                setIsFacilityListLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsFacilityListLoading(false);
            });
    }, []);


    const clearFilterValues = () => {
        setStatus("");
        setSelectedFacilities([]);
        setStartDate(null);
        setEndDate(null);
    };

    const resetFilters = () => {
        clearFilterValues();
    };


    useEffect(() => {
        getFacilityData();
    }, [getFacilityData]);


    return (
        <>
            <div className={"generate-invoice-component"}>
                <div className={"filter-wrapper"}>
                    <GeneratedFilters isFacilityListLoading={isFacilityListLoading}
                                      setSelectedFacilities={setSelectedFacilities} facilityList={facilityList}
                                      selectedFacilities={selectedFacilities} status={status} setStatus={setStatus}
                                      dateRange={dateRange}
                                      startDate={startDate} setStartDate={setStartDate} endDate={endDate}
                                      setEndDate={setEndDate}
                                      setDateRange={setDateRange} resetFilters={resetFilters}/>
                </div>
                <div className={'mrg-top-20'}>
                    <GeneratedInvoiceListTable startDate={startDate} value={value} status={status} endDate={endDate}
                                               selectedFacilities={selectedFacilities}/>
                </div>
            </div>
        </>
    );
};
export default GeneratedInvoiceComponent;
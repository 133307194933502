import {
    FETCH_NURSE_CHAMPION_LIST_FAILURE,
    FETCH_NURSE_CHAMPION_LIST_REQUEST,
    FETCH_NURSE_CHAMPION_LIST_SUCCESS,
    FETCH_USER_BASIC_DETAILS_FAILURE,
    FETCH_USER_BASIC_DETAILS_REQUEST,
    FETCH_USER_BASIC_DETAILS_SUCCESS
} from "../actions/employee-management.action";

export interface  EmployeeManagementParams{
    ncList: any[];
    isLoading: boolean;
    error: string;
    userBasicDetails:any;
    isUserBasicDetailsLoading:boolean;
    UserBasicDetailsError:string;
}

const initialData: EmployeeManagementParams = {
    ncList: [],
    isLoading: false,
    error: "",
    userBasicDetails:[],
    isUserBasicDetailsLoading:false,
    UserBasicDetailsError:""
};

const EmployeeManagementReducer = (state = initialData, action: any): EmployeeManagementParams => {
    switch (action.type) {
        case FETCH_NURSE_CHAMPION_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case FETCH_NURSE_CHAMPION_LIST_SUCCESS:
            return {
                ...state,
                ncList: action.ncList,
                isLoading: false,
                error: "",
            };
        case FETCH_NURSE_CHAMPION_LIST_FAILURE:
            return {
                ...state,
                ncList: [],
                isLoading: false,
                error: action.error,
            };
        case FETCH_USER_BASIC_DETAILS_REQUEST:
            return {
                ...state,
                isUserBasicDetailsLoading: true,
            };
        case FETCH_USER_BASIC_DETAILS_SUCCESS:
            return {
                ...state,
                userBasicDetails: action.userData,
                isUserBasicDetailsLoading: false,
            };
        case FETCH_USER_BASIC_DETAILS_FAILURE:
            return {
                ...state,
                isLoading: false,
                UserBasicDetailsError: action.error,
            };

        default:
            return state;
    }
}

export default EmployeeManagementReducer;
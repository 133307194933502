import {CommonService} from "../../../helpers";
import moment from "moment/moment";
import {ImageConfig} from "../../../constants";
import React from "react";

interface ApprovedCancelledShiftDetailsScreenProps {
    basicDetails?: any;
    openEditShift?: any;
}


const ApprovedCancelledComponentDetails = (props: ApprovedCancelledShiftDetailsScreenProps) => {
    const {basicDetails, openEditShift} = props;
    const {start_time, end_time} = CommonService.getUtcTimeInAMPM(
        basicDetails?.expected?.shift_start_time,
        basicDetails?.expected?.shift_end_time
    );
    const shift_date = CommonService.getUtcDate(basicDetails?.shift_date);
    return (
        <>
            {basicDetails?.requirement?.type === "home" &&
                <div className="facility-details custom-border mrg-top-10">
                    <div className={'d-flex'}>
                        <h3 className={'mrg-right-5'}>{basicDetails?.requirement?.patient_details?.first_name}</h3>
                        <h3 className={'mrg-right-5'}>{basicDetails?.requirement?.patient_details?.middle_name}</h3>
                        <h3>{basicDetails?.requirement?.patient_details?.last_name}</h3>
                        {
                            <h3 className={"mrg-left-10"}>
                                ({CommonService.calculateAge(basicDetails?.requirement?.patient_details?.dob)}years, {basicDetails && basicDetails?.requirement?.patient_details?.gender})
                            </h3>
                        }
                    </div>
                    <p>
                        {basicDetails?.requirement?.patient_details?.address?.apt && basicDetails?.requirement?.patient_details?.address.apt + ","}&nbsp;
                        {basicDetails?.requirement?.patient_details?.address?.street && basicDetails?.requirement?.patient_details?.address?.street + ","}&nbsp;
                        {basicDetails?.requirement?.patient_details?.address?.city && basicDetails.requirement?.patient_details.address?.city + ","}&nbsp;
                        {basicDetails?.requirement?.patient_details?.address?.state && basicDetails.requirement?.patient_details?.address?.state + ","}&nbsp;
                        {basicDetails?.requirement?.patient_details?.address?.zip_code && basicDetails.requirement?.patient_details.address?.zip_code}&nbsp;
                    </p>
                    <p>
                        MRN no : {basicDetails && basicDetails?.requirement?.patient_details?.mrn}
                    </p>
                    <p>
                        Latitude
                        : {basicDetails?.requirement?.patient_details?.coordinates ? basicDetails?.requirement?.patient_details?.coordinates[0] : ""}
                    </p>
                    <p>
                        Longitude
                        : {basicDetails?.requirement?.patient_details?.coordinates ? basicDetails?.requirement?.patient_details?.coordinates[1] : ""}
                    </p>

                    <div className={'mrg-top-25 d-flex'} style={{
                        color: "#808080"
                    }}>
                        <div>
                            <span>Phone Number : {basicDetails?.requirement?.patient_details?.contact_number}</span>
                        </div>

                        <div className={'d-flex'}>
                            <span>&nbsp;| Email Address : {basicDetails?.requirement?.patient_details?.email}</span>
                        </div>
                    </div>
                </div>}
            <div className="facility-details mrg-top-10 custom-border">
                {basicDetails?.requirement?.type === "home" &&
                    <>
                        <div className="d-flex shift-name-requested">

                            <div className="shift-name-action-container d-flex">
                                <h2>Shift Details</h2>
                                {/*<h4 className="shift-detail-edit" onClick={openEditShift}>(Edit)</h4>*/}
                            </div>

                            <div className="d-flex requested-on-wrapper">
                                <h3>Created On:</h3>
                                <p className="mrg-left-10">{moment(basicDetails?.created_at).format("MM-DD-YYYY")}</p>
                            </div>
                        </div>
                        <p>{basicDetails?.title}</p>
                        <div className="d-flex shift-details">
                            <div className="flex-1">
                                <h3>Visit Patient By Date</h3>
                                <p>{shift_date}</p>
                            </div>
                            <div className="flex-1">
                                <h3>Shift Time</h3>
                                <p>
                                    {/*{start_time}*/}
                                    {/*&nbsp;-&nbsp;*/}
                                    {start_time} - {end_time}
                                </p>
                            </div>
                            <div className="flex-1">
                                <h3>Visit Type</h3>
                                <p>{basicDetails?.shift_type}</p>
                            </div>
                            <div className="flex-1">
                                <h3>Staff Type</h3>
                                <p>{basicDetails?.hcp_type}</p>
                            </div>
                        </div>
                        <div className="d-flex shift-details">
                            <div className="flex-1">
                                <h3>Salary Credit Day</h3>
                                <p>{basicDetails?.hasOwnProperty("hcp_details") ? (basicDetails?.hcp_details?.contract_details?.salary_credit ? basicDetails?.hcp_details?.contract_details?.salary_credit : "NA") : "NA"}</p>
                            </div>

                            <div className="flex-1">
                                <>
                                    <h3>Total Number of Visits</h3>
                                    <p>{basicDetails?.requirement?.home_requirements?.number_of_visits || "NA"}</p>
                                </>
                            </div>

                            <div className="flex-1">
                                <h3>Shift Rate</h3>
                                <p>$ {basicDetails?.requirement?.shift_rate || "NA"}</p>
                            </div>
                            <div className="flex-1">
                                <h3>Staff Speciality</h3>
                                <p>{basicDetails?.hasOwnProperty("hcp_speciality") ? (basicDetails?.hcp_speciality ? basicDetails?.hcp_speciality : "NA") : "NA"}</p>

                            </div>
                        </div>
                        <div className="d-flex shift-details">


                            <div className="flex-1">
                                <>
                                    <h3>Type</h3>
                                    <p>Per Job/Visit</p>
                                </>
                            </div>
                            <div className="flex-1">
                                <h3>Visit Patient By Time</h3>
                                <p>
                                    {/*{start_time} &nbsp;-&nbsp;*/}
                                    {end_time}
                                </p>
                            </div>
                            <div className="flex-1">
                                <>
                                    <h3>Gender Preference</h3>
                                    <p>{basicDetails?.requirement?.home_requirements?.gender_pref ? CommonService.capitalizeFirstLetter(basicDetails?.requirement?.home_requirements?.gender_pref) : "NA"}</p>
                                </>
                            </div>

                            <div className="flex-1">
                                <h3>Staff Pay Date</h3>
                                <p>{basicDetails?.hasOwnProperty("hcp_pay_date") ? (basicDetails?.hcp_pay_date ? moment(basicDetails?.hcp_pay_date).format('MM-DD-YYYY') : "NA") : "NA"}</p>
                            </div>
                        </div>

                        <div className="d-flex shift-details">
                            <div className="flex-1">
                                <>
                                    <h3>Language</h3>
                                    <p>{basicDetails?.requirement?.home_requirements?.languages ? basicDetails?.requirement?.home_requirements?.languages?.filter((language: any) => language !== null)
                                        .join(', ') : "NA"}</p>
                                </>
                            </div>


                            <div className="flex-1">
                                <h3>Staff Differential Rate</h3>
                                <p>$ {basicDetails?.payments?.differential ? basicDetails?.payments?.differential : "0"}</p>
                            </div>
                            <div className="flex-1">
                            </div>
                            <div className="flex-1"></div>
                        </div>
                        <div className="d-flex shift-details">
                            <div className="flex-1">
                                {(basicDetails?.status === "complete" || basicDetails?.status === "closed" || basicDetails?.status === "cancelled") &&
                                    <>
                                        <h3>Staff Sent Home</h3>
                                        <p>
                                            {basicDetails?.hasOwnProperty("is_sent_home")
                                                ? basicDetails?.is_sent_home === true
                                                    ? "Yes"
                                                    : "No"
                                                : "NA"}
                                        </p>
                                    </>

                                }
                            </div>
                        </div>

                        {/*<div className="shift-details">*/}
                        {/*    <div>*/}
                        {/*        <h3>Shift Requirement Details</h3>*/}
                        {/*        <p className="summary">{basicDetails?.shift_details}</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className={'d-flex mrg-top-10 mrg-bottom-20'}>
                            {basicDetails?.requirement?.documents && basicDetails?.requirement?.documents?.map((file: any, index: number) => {
                                return (
                                    <>
                                        <div
                                            className={'d-flex justify-content-space-between file-holder-wrapper mrg-right-10 align-items-center '}>
                                            <div className={'d-flex'}>
                                                <div className={'mrg-right-10'}>
                                                    <img src={ImageConfig.FileViewIcon} alt=""/>
                                                </div>
                                                <div className={"underline-text"} onClick={() => {
                                                    window.open(file);
                                                }}>
                                                    document {index + 1}
                                                </div>
                                            </div>
                                            {/*<div className={'cursor-pointer'} onClick={() => {*/}
                                            {/*    removeFile(index);*/}
                                            {/*}}>*/}
                                            {/*    <DeleteIcon/>*/}
                                            {/*</div>*/}
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                    </>
                }
                {basicDetails?.requirement?.type === "facility" &&
                    <>
                        <div className="mrg-top-10">
                            <div className="d-flex shift-name-requested pdd-top-10">
                                <div className="shift-name-action-container d-flex">
                                    <h2>Shift Details</h2>
                                    <h4 className="shift-detail-edit" onClick={openEditShift}>
                                        (Edit)
                                    </h4>
                                </div>
                                <div className="d-flex requested-on-wrapper">
                                    <h3>Created On:</h3>
                                    <p className="mrg-left-10">{moment(basicDetails?.created_at).format("MM-DD-YYYY")}</p>
                                </div>
                            </div>
                            <p>{basicDetails?.title}</p>
                            <div className="d-flex shift-details">
                                <div className="flex-1">
                                    <h3>Required On</h3>
                                    <p>{shift_date}</p>
                                </div>
                                <div className="flex-1">
                                    <h3>Time</h3>
                                    <p>
                                        {start_time} &nbsp;-&nbsp;{end_time}
                                    </p>
                                </div>
                                <div className="flex-1">
                                    <h3>Shift Type</h3>
                                    <p>{basicDetails?.shift_type}</p>
                                </div>
                                <div className="flex-1">
                                    <h3>Warning Zone</h3>
                                    <p>{basicDetails?.warning_type}</p>
                                </div>
                            </div>
                            <div className="d-flex shift-details">

                                <div className="flex-1">
                                    <h3>Rush Rate</h3>
                                    <p>$ {basicDetails?.requirement?.rush_rate ? basicDetails?.requirement?.rush_rate : "NA"}</p>
                                </div>
                                <div className="flex-1">
                                    <h3>Weekend Rate</h3>
                                    <p>$ {basicDetails?.requirement?.weekend_rate ? basicDetails?.requirement?.weekend_rate : "NA"}</p>
                                </div>
                                <div className="flex-1">
                                    <h3>NOC Diff</h3>
                                    <p>$ {basicDetails?.requirement?.noc_diff ? basicDetails?.requirement?.noc_diff : "NA"}</p>
                                </div>
                                <div className="flex-1">
                                    <h3>Staff Differential Rate</h3>
                                    <p>$ {basicDetails?.payments?.differential ? basicDetails?.payments?.differential : "NA"}</p>
                                </div>
                            </div>

                            <div className="d-flex shift-details">
                                <div className="flex-1">
                                    <h3>PM Diff</h3>
                                    <p>$ {basicDetails?.requirement?.pm_diff ? basicDetails?.requirement?.pm_diff : "NA"}</p>
                                </div>
                                <div className="flex-1">
                                    <h3>Show Shift Rate in Mobile ?</h3>
                                    <p>
                                        {basicDetails?.requirement?.is_shift_rate ? "Yes" : "No"}
                                    </p>
                                </div>
                                <div className="flex-1">
                                    <h3>Shift Rate</h3>
                                    <p>$ {basicDetails?.requirement?.shift_rate ? basicDetails?.requirement?.shift_rate : "NA"}</p>
                                </div>
                                <div className="flex-1">
                                    <h3>Hazard Rate</h3>
                                    <p>
                                        {basicDetails?.hasOwnProperty("hazard_rate")
                                            ? basicDetails?.hazard_rate
                                                ? `$ ${basicDetails?.hazard_rate}`
                                                : "NA"
                                            : "NA"}
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex shift-details">

                                <div className="flex-1">
                                    <h3>Staff Pay Date</h3>
                                    <p>{basicDetails?.hasOwnProperty("hcp_pay_date") ? (basicDetails?.hcp_pay_date ? moment(basicDetails?.hcp_pay_date).format('MM-DD-YYYY') : "NA") : "NA"}</p>
                                </div>
                                <div className="flex-1">
                                    <h3>Staff Speciality</h3>
                                    <p>{basicDetails?.hasOwnProperty("hcp_speciality") ? (basicDetails?.hcp_speciality ?? "NA") : "NA"}</p>
                                </div>
                                <div className="flex-1">
                                    <h3>Salary Credit Day</h3>
                                    <p>{basicDetails?.hasOwnProperty("hcp_details") ? (basicDetails?.hcp_details?.contract_details?.salary_credit ?? "NA") : "NA"}</p>
                                </div>
                                <div className="flex-1">
                                    {/*<h3>Warning Zone</h3>*/}
                                    {/*<p>{basicDetails?.hasOwnProperty("warning_type") ? basicDetails?.warning_type : "NA"}</p>*/}
                                </div>
                            </div>

                            <div className="d-flex shift-details">
                                <div className="flex-1">
                                    {(basicDetails?.status === "complete" || basicDetails?.status === "closed" || basicDetails?.status === "cancelled") &&
                                        <>
                                            <h3>Staff Sent Home</h3>
                                            <p>
                                                {basicDetails?.hasOwnProperty("is_sent_home")
                                                    ? basicDetails?.is_sent_home === true
                                                        ? "Yes"
                                                        : "No"
                                                    : "NA"}
                                            </p>
                                        </>

                                    }
                                </div>
                            </div>
                            <div className={'d-flex mrg-top-10 mrg-bottom-20'}>
                                {basicDetails?.documents && basicDetails?.documents?.map((file: any, index: number) => {
                                    return (
                                        <>
                                            <div
                                                className={'d-flex justify-content-space-between file-holder-wrapper mrg-right-10 align-items-center '}>
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-right-10'}>
                                                        <img src={ImageConfig.FileViewIcon} alt=""/>
                                                    </div>
                                                    <div className={"underline-text"} onClick={() => {
                                                        window.open(file);
                                                    }}>
                                                        document {index + 1}
                                                    </div>
                                                </div>
                                                {/*<div className={'cursor-pointer'} onClick={() => {*/}
                                                {/*    removeFile(index);*/}
                                                {/*}}>*/}
                                                {/*    <DeleteIcon/>*/}
                                                {/*</div>*/}
                                            </div>
                                        </>
                                    );
                                })}
                            </div>

                            <div className="shift-details">
                                <div>
                                    <h3>Shift Requirement Details</h3>
                                    <p className="summary">{basicDetails?.shift_details}</p>
                                </div>
                            </div>


                        </div>
                    </>
                }
                {basicDetails?.requirement?.type === "contract" &&
                    <>
                        <div className="d-flex shift-name-requested">

                            <div className="shift-name-action-container d-flex">
                                <h2>Shift Details</h2>
                                {/*<h4 className="shift-detail-edit" onClick={openEditShift}>(Edit)</h4>*/}
                            </div>
                        </div>
                        <p>{basicDetails?.title}</p>
                        <div className="d-flex shift-details">
                            <div className="flex-1">
                                <h3>Required On</h3>
                                <p>{CommonService.getUtcDate(basicDetails?.shift_date)}</p>
                            </div>
                            <div className="flex-1">
                                <h3>Shift Duration</h3>
                                <p>
                                    {basicDetails?.requirement?.contract_requirements?.shift_duration || "0"}h
                                </p>
                            </div>
                            <div className="flex-1">
                                <h3>Staff Type</h3>
                                <p>{basicDetails?.hcp_type || "-"}</p>
                            </div>
                            <div className="flex-1">
                                <h3>Speciality</h3>
                                <p>{basicDetails?.hcp_speciality || "-"}</p>
                            </div>
                        </div>
                        <div className="d-flex shift-details">
                            <div className="flex-1">
                                <h3>Shift Type</h3>
                                <p>{basicDetails?.shift_type || "NA"}</p>
                            </div>
                            <div className="flex-1">
                                <h3>Days Per Week</h3>
                                <p>{basicDetails?.requirement?.contract_requirements?.days_per_week || "NA"}</p>
                            </div>
                            <div className="flex-1">
                                <h3>Job Type</h3>
                                <p>{(basicDetails?.requirement?.contract_requirements?.contract_type || basicDetails?.requirement?.type) || "-"}</p>
                            </div>
                            <div className="flex-1">
                                <h3>Salary Credit Day</h3>
                                <p>{basicDetails?.hasOwnProperty("hcp_details") ? (basicDetails?.hcp_details?.contract_details?.salary_credit ? basicDetails?.hcp_details?.contract_details?.salary_credit : "NA") : "NA"}</p>
                            </div>
                        </div>
                        <div className="d-flex shift-details">
                            <div className="flex-1">
                                <h3>Shift Rate</h3>
                                <p>$ {basicDetails?.requirement?.shift_rate ? basicDetails?.requirement?.shift_rate : "NA"}</p>
                            </div>


                            <div className="flex-1">
                                <h3>Staff Pay Date</h3>
                                <p>{basicDetails?.hasOwnProperty("hcp_pay_date") ? (basicDetails?.hcp_pay_date ? moment(basicDetails?.hcp_pay_date).format('MM-DD-YYYY') : "NA") : "NA"}</p>
                            </div>
                            <div className="flex-1">
                                <h3>Number of weeks</h3>
                                <p> {basicDetails?.requirement?.contract_requirements?.no_of_weeks || "-"}</p>
                            </div>
                            <div className="flex-1">
                                <h3>Differential Rate</h3>
                                <p>$ {basicDetails?.payments?.differential ? basicDetails?.payments?.differential : "0"}</p>
                            </div>
                        </div>
                        <div className={'d-flex shift-details'}>

                            <div className="flex-1">
                                <div className="flex-1">
                                    {(basicDetails?.status === "complete" || basicDetails?.status === "closed" || basicDetails?.status === "cancelled") &&
                                        <>
                                            <h3>Staff Sent Home</h3>
                                            <p>
                                                {basicDetails?.hasOwnProperty("is_sent_home")
                                                    ? basicDetails?.is_sent_home === true
                                                        ? "Yes"
                                                        : "No"
                                                    : "NA"}
                                            </p>
                                        </>

                                    }
                                </div>
                            </div>
                        </div>

                        <div className=" shift-details">
                            <h3>Travel Diem</h3>
                            <div>
                                {
                                    basicDetails?.requirement?.contract_requirements?.travel_per_diems?.map((item: any, index: any) => {
                                        const [key, value]: any = Object.entries(item)[0];
                                        const entryTwo = Object.entries(item)[1]; // Check for the second entry
                                        const [key2, valuetwo] = entryTwo ? entryTwo : [null, null]; // Fallback to null if it doesn't exist

                                        return (
                                            <p className={'mrg-bottom-10'} key={index}>
                                                <div className={'d-flex'}>
                                                    <div className={'flex-1'}>
                                                        {key}
                                                    </div>
                                                    <div className={'flex-1'} onClick={() => {
                                                        if (key2) console.log(key2); // Only log if key2 exists
                                                    }}>
                                                        ${Number(value)} {`${index === 0 ? "/hr" : "/day"}`}
                                                    </div>
                                                    {key2 && (
                                                        <div className={'flex-1'}>
                                                            $ {Number(valuetwo)} /week
                                                        </div>
                                                    )}
                                                </div>
                                            </p>
                                        );
                                    })
                                }

                            </div>

                        </div>

                        <div className="d-flex shift-details">
                            <div className="flex-2">
                                <h3>Cancel Policy</h3>
                                <p
                                    dangerouslySetInnerHTML={{__html: basicDetails?.requirement?.contract_requirements?.cancel_policy?.replace(/\n/g, '<br/>')}}/>
                            </div>
                        </div>
                        <div className="d-flex shift-details">
                            <div className="flex-2">
                                <h3>Staff Requirements</h3>
                                <p
                                    dangerouslySetInnerHTML={{__html: basicDetails?.requirement?.contract_requirements?.staff_requirements?.replace(/\n/g, '<br/>')}}/>
                            </div>
                        </div>
                        <div className="d-flex shift-details">
                            <div className="flex-2">
                                <h3>Job Details</h3>
                                <p
                                    dangerouslySetInnerHTML={{__html: basicDetails?.requirement?.contract_requirements?.job_details?.replace(/\n/g, '<br/>')}}/>
                            </div>
                        </div>
                        <div className="d-flex shift-details">
                            <div className="flex-2">
                                <h3>Job Benefits</h3>
                                <p
                                    dangerouslySetInnerHTML={{__html: basicDetails?.requirement?.contract_requirements?.job_benefits?.replace(/\n/g, '<br/>')}}/>
                            </div>
                        </div>

                        {/*<div className="d-flex shift-details">*/}
                        {/*    <div className="flex-1">*/}
                        {/*        <h3>State License</h3>*/}
                        {/*        <p>{basicDetails?.requirement?.contract_requirements?.state_license || "-"}</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="d-flex shift-details">*/}
                        {/*    <div className="flex-1">*/}
                        {/*        <h3>Certifications</h3>*/}
                        {/*        <p>{basicDetails?.requirement?.contract_requirements?.certifications || "-"}</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="d-flex shift-details">*/}
                        {/*    <div className="flex-1">*/}
                        {/*        <h3>Speciality Experience</h3>*/}
                        {/*        <p>{basicDetails?.requirement?.contract_requirements?.speciality_experience || "-"}</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="d-flex shift-details">*/}
                        {/*    <div className="flex-1">*/}
                        {/*        <h3>Skills Assessment</h3>*/}
                        {/*        <p>{basicDetails?.requirement?.contract_requirements?.skills_assessment || "-"}</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="d-flex shift-details">*/}
                        {/*    <div className="flex-1">*/}
                        {/*        <h3>References</h3>*/}
                        {/*        <p>{basicDetails?.requirement?.contract_requirements?.references || "-"}</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="d-flex shift-details">*/}
                        {/*    <div className="flex-1">*/}
                        {/*        <h3>Additional Skill</h3>*/}
                        {/*        <p>{basicDetails?.requirement?.contract_requirements?.additional_skill || "-"}</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="d-flex shift-details">*/}
                        {/*    <div className="flex-1">*/}
                        {/*        <h3>Additional Confirmations</h3>*/}
                        {/*        <p>{basicDetails?.requirement?.contract_requirements?.additional_confirmations || "-"}</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<div className="d-flex shift-details">*/}
                        {/*    <div className="flex-1">*/}
                        {/*        <h3>Travel Contract Limitations</h3>*/}
                        {/*        <p>{basicDetails?.requirement?.contract_requirements?.travel_contract_limitations || "-"}</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </>
                }

            </div>

        </>
    )
}

export default ApprovedCancelledComponentDetails;

import {Chip, Paper} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {DateRangeOutlined} from "@material-ui/icons";
import CancelIcon from '@material-ui/icons/Cancel';
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, {PropsWithChildren, useCallback} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-multi-date-picker/styles/layouts/mobile.css";
import {localStore} from "../../../../../helpers";
import "./GeneratedFilters.scss";
import {useLocalStorage} from "../../../../../components/useLocalStorage";


export interface FacilityFiltersComponentProps {
    status: any;
    setStatus: any;
    dateRange: any;
    setDateRange: any;
    resetFilters: any;
    selectedFacilities: any;
    facilityList: any;
    setSelectedFacilities: any;
    isFacilityListLoading: any;

    startDate: any;
    setStartDate: any;
    endDate: any;
    setEndDate: any;
}

const GeneratedFilters = (props: PropsWithChildren<FacilityFiltersComponentProps>) => {

    const statusList = [
        {name: "Paid", code: "paid"},
        {name: "Unpaid", code: "unpaid"},
        {name: "Cancelled", code: "cancelled"}
    ];
    const setStatus = props?.setStatus;
    const status = props?.status;
    const {startDate, setStartDate, endDate, setEndDate} = props;
    // const dateRange = props?.dateRange;
    const resetFilters = props?.resetFilters;
    const isFacilityListLoading = props?.isFacilityListLoading;
    const selectedFacilities = props?.selectedFacilities;
    const setSelectedFacilities = props?.setSelectedFacilities;
    const [isAllFacilitiesSelected, setIsAllFacilitiesSelected] = useLocalStorage('isAllFacilitiesSelectedGenerated', false);


    const facilityList: any[] = props?.facilityList ? props?.facilityList?.map((item: any) => ({
        code: item?._id,
        name: item?.name
    })) : [];


    const handleDisableReset = (): boolean => {
        if ((status !== "" && status !== null) || startDate !== null || endDate !== null || selectedFacilities?.length > 0) {
            return false;
        } else {
            return true;
        }
    };


    const formatFilterMenuListToHandleObj = useCallback((options: any[], isAllMenuItemSelected = false): any[] => {
        let menuList = options;
        console.log(options);
        const allOptions = options.filter((item) => {
            return item.code !== "Select All" && item.code !== "Clear All";
        });

        if (isAllMenuItemSelected) {
            menuList = [{name: "Clear All", code: "Clear All"}, ...allOptions];
        }

        if (!isAllMenuItemSelected) {
            menuList = [{name: "Select All", code: "Select All"}, ...allOptions];
        }
        return menuList;
    }, []);


    const handleFilterValueSelectToHandleObj = useCallback(
        (newValues: any, options: any[], setSelectedFilterField: any, setIsAllMenuItemsSelected: any) => {
            const isSelectAll = newValues.some((f: any) => f.code === "Select All");
            const isClearAll = newValues.some((f: any) => f.code === "Clear All");

            if (newValues?.length === 0) {
                setIsAllMenuItemsSelected(false);
            }

            if (isSelectAll || isClearAll) {
                if (isSelectAll) {
                    setIsAllMenuItemsSelected(true);
                    const allOptions = options.filter((item) => {
                        return item.code !== "Select All" && item.code !== "Clear All";
                    });
                    setSelectedFilterField(allOptions);
                }

                if (isClearAll) {
                    setIsAllMenuItemsSelected(false);
                    setSelectedFilterField([]);
                }


                return;
            }

            //for single select
            setSelectedFilterField(newValues);
        },
        []
    );

    const handleFacilityDelete = (chip: any) => {
        let filterdChips = selectedFacilities?.filter((item: any) => item?.code !== chip);
        setSelectedFacilities(filterdChips);
    };

    const handleDateChange = (date: any) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date: any) => {
        setEndDate(date);
    };


    return (
        <div className="generated-tab-filters">
            <div className="filters-generated-wrapper">
                <div className="facility-filters mrg-right-100">
                    <Autocomplete
                        disableClearable
                        disabled={isFacilityListLoading}
                        PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                        multiple
                        getOptionLabel={(option: any) => option?.name}
                        getOptionSelected={(option: any, value: any) => option?.name === value?.name}
                        id="input_select_facility"
                        value={selectedFacilities}
                        options={formatFilterMenuListToHandleObj(facilityList, isAllFacilitiesSelected)}
                        onChange={(e, newValue) => {
                            if (newValue) {
                                handleFilterValueSelectToHandleObj(
                                    newValue,
                                    facilityList,
                                    setSelectedFacilities,
                                    setIsAllFacilitiesSelected
                                );
                            }
                        }}
                        renderTags={() => null}
                        renderInput={(params) => <TextField {...params} variant="outlined"
                                                            placeholder="Facilities"/>}
                        disableCloseOnSelect
                    />
                    {isFacilityListLoading && (
                        <div className="loading-text-wrapper">
                            <span className="loading-text">loading...</span>
                        </div>
                    )}
                </div>
                <div className={'filter-group'}>
                    <div className="status-filter">
                        <Autocomplete
                            PaperComponent={({children}) => <Paper
                                style={{color: "#1e1e1e"}}>{children}</Paper>}
                            value={status}
                            options={statusList}
                            getOptionLabel={(option: any) => option.name}
                            placeholder={"Select Status"}
                            id="input_select_status"
                            onChange={($event, value) => {
                                setStatus(value);
                                console.log(value);
                                // console.log(value);
                            }}
                            renderInput={(params) => <TextField {...params} id="select_status"
                                                                variant="outlined"
                                                                placeholder={"Status"}/>}
                        />
                    </div>
                    <div className="start-date-filter">
                        <DatePicker
                            dateFormat="MM/dd/yyyy"
                            placeholderText="Start Date"
                            className="custom-input"
                            selected={startDate && new Date(startDate)}
                            //  selectsRange={true}
                            // startDate={new Date()}
                            //endDate={endDate && new Date(endDate)}
                            onChange={handleDateChange}
                            // isClearable={true}
                        />

                        {startDate &&
                            <CancelIcon style={{cursor: "pointer"}} className={"date-icon"} onClick={() => {
                                setStartDate(null);
                            }}/>}
                        {!startDate &&
                            <DateRangeOutlined className="date-icon" fontSize="small" color="action"/>}
                    </div>

                    <div className="end-date-filter">
                        <DatePicker
                            dateFormat="MM/dd/yyyy"
                            placeholderText="End Date"
                            className="custom-input"
                            selected={endDate && new Date(endDate)}
                            onChange={handleEndDateChange}
                        />
                        {endDate &&
                            <CancelIcon style={{cursor: "pointer"}} className={"date-icon"} onClick={() => {
                                setEndDate(null);
                            }}/>}
                        {!endDate &&
                            <DateRangeOutlined className="date-icon" fontSize="small" color="action"/>}
                    </div>
                </div>
            </div>

            <div className="custom-chips-wrapper">
                {selectedFacilities && selectedFacilities.length > 0 && (
                    <p className="custom-chips">
                        {selectedFacilities.map((data: any) => (
                            <Chip key={data?.code} label={data?.name}
                                  onDelete={() => handleFacilityDelete(data?.code)}/>
                        ))}
                    </p>
                )}
            </div>
            <div className="facility-chips-wrapper">
                <span
                    onClick={() => {
                        resetFilters();
                    }}
                    style={{color: "#0C80E3FF"}}
                    color="secondary"
                    id="btn_reset_filter"
                    className={`clear-all-filters mrg-top-10  ${handleDisableReset() ? " hide-filter" : "show-filter"}`}
                >
          Clear All Filters
        </span>
            </div>
        </div>
    );
};

export const clearGeneratedFilters = () => {
    localStore.removeItem("generatedInvoiceStatus");
    localStore.removeItem("selectedFacilitiesGenerated");
    localStore.removeItem("generatedTabStartDate");
    localStore.removeItem("generatedTabEndDate");
};

export default GeneratedFilters;
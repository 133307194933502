import {call, put, takeLatest} from "redux-saga/effects";
import {
    FETCH_REGIONS_REQUEST,
    fetchRegionsSuccess,
    fetchRegionsFailure,
    fetchHcpTypeSuccess,
    fetchHcpTypeFailure,
    FETCH_HCP_TYPES_REQUEST,
    getSpecialitiesSuccess,
    getSpecialitiesFailure,
    GET_SPECIALITIES_REQUEST
} from "../actions/meta.action";
import CommonService from "../../helpers/common-service";
import {ENV} from "../../constants";

function fetchRegions(): any {
    return CommonService._api.get(ENV.API_URL + `regions`);
}

function* getRegionsList() {
    try {
        // @ts-ignore
        const response = yield call(fetchRegions);
        yield put(fetchRegionsSuccess(response.data || []));
    } catch (error) {
        yield put(fetchRegionsFailure(error));
    }
}

function fetchHcpTypes(): any {
    return CommonService._api.get(ENV.API_URL + `hcpTypes`);
}

function* getHcpTypeList() {
    try {
        // @ts-ignore
        const response = yield call(fetchHcpTypes);
        yield put(fetchHcpTypeSuccess(response?.data || []));
    } catch (error) {
        yield put(fetchHcpTypeFailure(error));
    }
}

export function* getSpecialitiesSaga() {
    try {
        //@ts-ignore
        const response = yield call(CommonService._api.get, ENV.API_URL + "hcpSpecialities");
        yield put(getSpecialitiesSuccess(response.data || []));
    } catch (error:any) {
        yield put(getSpecialitiesFailure(error.message));
    }
}


function* metaSaga() {
    yield takeLatest(FETCH_REGIONS_REQUEST, getRegionsList);
    yield takeLatest(FETCH_HCP_TYPES_REQUEST, getHcpTypeList);
    yield takeLatest(GET_SPECIALITIES_REQUEST, getSpecialitiesSaga);
}

export default metaSaga;
import {Button} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import React, {useEffect, useState} from "react";
import LoaderComponent from "../../../components/LoaderComponent";
import ENV from "../../../constants/ENV";
import {ApiService, CommonService, Communications} from "../../../helpers";
import "./NotificationComponent.scss";

const NotificationComponent = () => {
    // const [notificationId,setNotificationId]=useState('')
    const [isLoadingNotification, setIsLoadingNotifications] = useState(false);
    const [isSubmittingNotifications, setIsSubmittingNotifications] = useState(false);

    const [notifications, setNotifications] = useState({
        sms: false,
        email: false,
        push_notification: false,
    });

    const getNotifications = () => {
        setIsLoadingNotifications(true);
        ApiService.get(ENV.API_URL + "notification/preference")
            .then((res) => {
                const {sms, email, push_notification} = res?.data;
                setNotifications({
                    sms,
                    email,
                    push_notification,
                });

                setIsLoadingNotifications(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoadingNotifications(false);
            });
    };

    const editNotifications = () => {
        setIsSubmittingNotifications(true);
        const payload = {
            sms: notifications.sms,
            push_notification: notifications.push_notification,
            email: notifications.email
        }

        ApiService.put(ENV.API_URL + `notification/preference`, payload)
            .then((res) => {
                const {sms, email, push_notification} = notifications;
                setNotifications({
                    sms,
                    email,
                    push_notification,
                });

                CommonService.showToast(res?.msg || "Success", "success");

                setIsSubmittingNotifications(false);
            })
            .catch((err) => {
                console.log(err);
                CommonService.showToast(err?.msg || "Error", "error");
                setIsSubmittingNotifications(false);
            });
    };

    useEffect(() => {
        Communications.pageTitleSubject.next("Notifications");
        Communications.pageBackButtonSubject.next(null);
        getNotifications();
    }, []);

    const handleSubmit = () => {
        editNotifications()
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNotifications({
            ...notifications,
            [event.target.name]: event.target.checked,
        });
    };

    if (isLoadingNotification) {
        return <LoaderComponent/>;
    }

    return (
        <div className="notification-component screen">
            <div className="notification-header">Notifications</div>
            <div className="notification-preferences custom-border mrg-top-10">
                <div className="notification-group">
                    Notification Preferences for Staff
                </div>
                <div className="notification-preferences-container">
                    <div className="form-control">
                        <Checkbox
                            checked={notifications.sms}
                            onChange={handleChange}
                            name="sms"
                        />
                        <span className="form-label">SMS</span>
                    </div>

                    <div className="form-control">
                        <Checkbox
                            checked={notifications.email}
                            onChange={handleChange}
                            name="email"
                        />
                        <span className="form-label">Email</span>
                    </div>

                    <div className="form-control">
                        <Checkbox
                            checked={notifications.push_notification}
                            onChange={handleChange}
                            name="push_notification"
                        />
                        <span className="form-label">Push Notification</span>
                    </div>

                    <div className="flex-1">
                        <Button color={"primary"} disabled={isSubmittingNotifications} variant={"contained"}
                                className={isSubmittingNotifications ? "mrg-left-30 has-loading-spinner" : "mrg-left-30"}
                                onClick={handleSubmit}>
                            {isSubmittingNotifications ? "Saving" : "Save"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotificationComponent;

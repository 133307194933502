import {Button, Tooltip} from "@material-ui/core";
import {Field, Form, Formik, FormikHelpers} from "formik";
import React, {useCallback, useEffect, useState} from "react";
import "react-phone-number-input/style.css";
import {useHistory} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import {TsFileUploadConfig, TsFileUploadWrapperClass} from "../../../classes/ts-file-upload-wrapper.class";
import DialogComponent from "../../../components/DialogComponent";
import LoaderComponent from "../../../components/LoaderComponent";
import CustomPreviewFile from "../../../components/shared/CustomPreviewFile";
import LeavePageConfirmationComponent from "../../../components/shared/LeavePageConfirmationComponent";
import {ENV} from "../../../constants";
import {americanTimeZone, otHours} from "../../../constants/data";
import {ApiService, CommonService, Communications} from "../../../helpers";
import FacilityAddDetailsComponent from "./BasicDetails/FacilityAddDetailsComponent";
import {FacilityItemAddType} from "./FacilityInitialAndValidationsComponent";
import "./FacilityManagementAddScreen.scss";
import FacilityAddComponent from "./FacilityMemberAddComponent/FacilityMemberAddComponent";
import ShiftAddComponent from "./ShiftAddComponent/ShiftAddComponent";
import {useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";
import {memberFormValidation} from "./FacilityMemberAddComponent/FacilityMemberFormValidation";
import {TextField} from "formik-material-ui";


interface MemberAddType {
    name: string;
    email: string;
    contact_number: string;
    extension_number: string;
    designation: string;
}

const memberInitialState: MemberAddType = {
    name: "",
    contact_number: "",
    extension_number: "",
    designation: "FA",
    email: "",
};


const FacilityManagementAddScreen = () => {
    const history = useHistory();
    const [members, setMembers] = useState<any[]>([]);
    const [shiftTimings, setShiftTimings] = useState<any[]>([]);
    const {regions, isLoading} = useSelector((state: StateParams) => state.meta);
    const [isFacilitySubmitting, setIsFacilitySubmitting] = useState<boolean>(false);
    const [fileUpload, setFileUpload] = useState<{ wrapper: any } | null>(null);
    const [otherFileUpload, setOtherFileUpload] = useState<{ wrapper: any } | null>(null);
    const [contractFileUpload, setContractFileUpload] = useState<{ wrapper: any } | null>(null);
    const [previewFileData, setPreviewFile] = useState<any | null>(null);
    const [previewContractFileData, setPreviewContractFile] = useState<any | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [openContractFile, setOpenContractFile] = useState<boolean>(false);
    const [isImage, setIsImage] = useState<boolean>(false);
    const [isContractImage, setContractIsImage] = useState<boolean>(false);
    const [isAddOpen, setIsAddOpen] = useState<boolean>(false);
    const [scrollToError, setScrollToError] = useState<boolean>(false);
    const [otherDocuments, setOtherDocuments] = useState<any>([]);

    const previewFile = useCallback(
        (index: any) => {
            setPreviewFile(fileUpload?.wrapper[index]);
            setOpen(true);
        },
        [fileUpload]
    );
    const previewOtherFile = useCallback(
        (index: any, type: any) => {

            setPreviewFile(otherFileUpload?.wrapper[index]);
            setOpen(true);
        },
        [otherFileUpload, setPreviewFile, setOpen]
    );
    const previewContractFile = useCallback(
        (index: any) => {
            setPreviewContractFile(contractFileUpload?.wrapper[index]);
            setOpenContractFile(true);
        },
        [contractFileUpload]
    );
    const cancelPreviewFile = useCallback(() => {
        setOpen(false);
    }, []);
    const cancelContractPreviewFile = useCallback(() => {
        setOpenContractFile(false);
    }, []);
    const confirmPreviewFile = useCallback(() => {
        setOpen(false);
    }, []);

    const confirmContractPreviewFile = useCallback(() => {
        setOpenContractFile(false);
    }, []);

    useEffect(() => {
    }, [shiftTimings]);

    const OnFileSelected = (files: File[]) => {
        for (let file of files) {
            const uploadConfig: TsFileUploadConfig = {
                file: file,
                fileFieldName: "image",
                uploadUrl: ENV.API_URL + "facility/add",
                allowed_types: ["jpg", "png", "csv", "pdf", "jpeg"],
            };
            const uploadWrapper = new TsFileUploadWrapperClass(uploadConfig, CommonService._api, (state: {
                wrapper: TsFileUploadWrapperClass
            }) => {
                // console.log(state);
                setFileUpload((prevState) => {
                    if (prevState) {
                        const index = prevState?.wrapper.findIndex((value: any) => value.uploadId === state.wrapper.uploadId);
                        prevState.wrapper[index] = state.wrapper;
                        return {wrapper: prevState.wrapper};
                    }
                    return prevState;
                });
            });
            uploadWrapper.onError = (err, heading) => {
                // console.error(err, heading);
                if (heading) {
                    CommonService.showToast(err, "error");
                }
            };
            uploadWrapper.onSuccess = (resp) => {
                // console.log(resp);
                if (resp && resp.success) {
                    CommonService.showToast(resp.msg || resp.error, "success");
                }
            };
            uploadWrapper.onProgress = (progress) => {
                // console.log('progress', progress);
            };
            setFileUpload((prevState) => {
                let state: TsFileUploadWrapperClass[] = [];
                if (prevState) {
                    state = prevState?.wrapper;
                }
                const newState = [...state, uploadWrapper];
                return {wrapper: newState};
            });
            // uploadWrapper.startUpload();
        }
        setTimeout(() => setIsImage(!isImage), 1000);
    };

    const OnOtherFileSelected = (files: File[], index: any) => {

        setOtherDocuments((prevDocuments: any) => {
            const newDocuments = [...prevDocuments];
            for (let file of files) {
                newDocuments.push({name: file.name, index: otherFileUpload?.wrapper?.length || 0});
            }
            return newDocuments;
        });

        for (let file of files) {
            // console.log(file)
            const uploadConfig: TsFileUploadConfig = {
                file: file,
                fileFieldName: 'Data',
                uploadUrl: ENV.API_URL + 'facility/add',
                allowed_types: ['jpg', 'png', 'csv', 'pdf'],
                extraPayload: {expiry_date: '', file_type: "others"}
            };
            const uploadWrapper = new TsFileUploadWrapperClass(uploadConfig, CommonService._api, (state: {
                wrapper: TsFileUploadWrapperClass
            }) => {
                // console.log(state);
                setFileUpload((prevState: any) => {
                    if (prevState) {
                        const index = prevState?.wrapper.findIndex((value: any) => value.uploadId === state.wrapper.uploadId);
                        prevState.wrapper[index] = state.wrapper;
                        return {wrapper: prevState.wrapper};
                    }
                    return prevState;
                });
            });
            uploadWrapper.onError = (err, heading) => {
                // console.error(err, heading);
                if (heading) {
                    CommonService.showToast(err, 'error');
                }
            };
            uploadWrapper.onSuccess = (resp) => {
                console.log(resp);
                if (resp && resp.success) {
                    CommonService.showToast(resp.msg || resp.error, 'success');
                }
            };
            uploadWrapper.onProgress = (progress) => {
            };
            setOtherFileUpload((prevState: any) => {
                let state: TsFileUploadWrapperClass[] = [];
                if (prevState) {
                    state = prevState?.wrapper;
                }
                const newState = [...state, uploadWrapper];
                return {wrapper: newState};
            });
        }
    };


    useEffect(() => {
    }, [isImage]);

    const OnContractFileSelected = (files: File[]) => {
        for (let file of files) {
            const uploadConfig: TsFileUploadConfig = {
                file: file,
                fileFieldName: "image",
                uploadUrl: ENV.API_URL + "facility/add",
                allowed_types: ["jpg", "png", "csv", "pdf", "jpeg"],
            };
            const uploadWrapper = new TsFileUploadWrapperClass(uploadConfig, CommonService._api, (state: {
                wrapper: TsFileUploadWrapperClass
            }) => {
                // console.log(state);

                setContractFileUpload((prevState) => {
                    if (prevState) {
                        const index = prevState?.wrapper.findIndex((value: any) => value.uploadId === state.wrapper.uploadId);
                        prevState.wrapper[index] = state.wrapper;
                        return {wrapper: prevState.wrapper};
                    }
                    return prevState;
                });
            });
            uploadWrapper.onError = (err, heading) => {
                // console.error(err, heading);
                if (heading) {
                    CommonService.showToast(err, "error");
                }
            };
            uploadWrapper.onSuccess = (resp) => {
                // console.log(resp);
                if (resp && resp.success) {
                    CommonService.showToast(resp.msg || resp.error, "success");
                }
            };
            uploadWrapper.onProgress = (progress) => {
                // console.log('progress', progress);
            };
            setContractFileUpload((prevState) => {
                let state: TsFileUploadWrapperClass[] = [];
                if (prevState) {
                    state = prevState?.wrapper;
                }
                const newState = [...state, uploadWrapper];
                return {wrapper: newState};
            });
            // uploadWrapper.startUpload();
        }
        setTimeout(() => setContractIsImage(!isContractImage), 1000);
    };
    useEffect(() => {
    }, [isContractImage]);


    const handleGetUrlForUpload = useCallback(
        (id: any) => {
            return new Promise((resolve, reject) => {
                const file = fileUpload?.wrapper[0]?.file;
                console.log(fileUpload?.wrapper);
                const formData = new FormData();
                if (file) {
                    formData.append("image", file);
                }
                if (fileUpload?.wrapper.length > 0) {
                    CommonService._api.upload(ENV.API_URL + "facility/" + id + "/profile", formData)
                        .then((resp) => {
                            resolve(resp);
                        })
                        .catch((err) => {
                            CommonService.showToast(err || "Error", "error");
                        });
                } else {
                    resolve(null)
                }
            });
        },
        [fileUpload]
    );


    const handleGetUrlForContractUpload = useCallback(
        (id: any) => {
            return new Promise((resolve, reject) => {
                const file = contractFileUpload?.wrapper[0]?.file;
                // console.log(contractFileUpload?.wrapper);
                const formData = new FormData();
                if (file) {
                    formData.append("contract", file);
                }
                if (contractFileUpload?.wrapper.length > 0) {
                    CommonService._api
                        .upload(ENV.API_URL + "facility/" + id + "/contract", formData)
                        .then((resp) => {
                            resolve(resp);
                        })
                        .catch((err) => {
                            CommonService.showToast(err || "Error", "error");
                            reject(err);
                        });
                } else {
                    resolve(null)
                }

            });
        },
        [contractFileUpload?.wrapper]
    );

    const deleteFile = (temp: any) => {
        let data = fileUpload?.wrapper.filter((_: any, index: any) => index !== temp);
        setFileUpload((prevState) => {
            return {wrapper: [...data]};
        });
    };

    const deleteContractFile = (temp: any) => {
        let data = contractFileUpload?.wrapper.filter((_: any, index: any) => index !== temp);
        setContractFileUpload((prevState) => {
            return {wrapper: [...data]};
        });
    };

    const onAddShift = useCallback((shift: any, facilityId: string) => {
        return new Promise((resolve, reject) => {
            ApiService.post(ENV.API_URL + "facility/" + facilityId + "/shift", shift)
                .then((resp: any) => {
                    if (resp && resp.success) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                })
                .catch((err) => {
                    //console.log(err);
                    reject(err);
                });
        });
    }, []);

    const addShifts = useCallback(
        (facilityId: string) => {
            return (shiftTimings || []).map((value) => {
                return onAddShift(value, facilityId);
            });
        },
        [shiftTimings, onAddShift]
    );

    const onAddMember = useCallback((member: any, facilityId: string) => {
        return new Promise((resolve, reject) => {
            ApiService.post(ENV.API_URL + "facility/" + facilityId + "/member", member)
                .then((resp: any) => {
                    // console.log(resp);
                    if (resp && resp.success) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                })
                .catch((err) => {
                    // console.log(err);
                    reject(err);
                });
        });
    }, []);

    const addMembers = useCallback(
        (facilityId: string) => {
            return (members || []).map((value) => {
                return onAddMember(value, facilityId);
            });
        },
        [members, onAddMember]
    );

    const onHandleOtherAttachmentUpload = useCallback((value: any, index: any, facilityId: any, requiredAttachment: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                const file = value?.file;
                const formData = new FormData();
                if (file) {
                    formData.append("attachment", file);
                    formData.append("attachment_type", value?.extraPayload?.file_type);
                    //  formData.append("expiry_date",requiredAttachment?.isAdditionalAttachment === true ? "" : value?.extraPayload?.expiry_date)
                }
                // let payload = {
                //     file_name: value?.file?.name,
                //     file_type: value?.file?.type,
                //     attachment_type: value?.extraPayload?.file_type,
                //     expiry_date: requiredAttachment?.isAdditionalAttachment === true ? "" : value?.extraPayload?.expiry_date,
                // };
                CommonService._api.upload(ENV.API_URL + "facility/" + facilityId + "/attachments", formData).then((resp) => {
                    resolve(resp);
                })
                    .catch((err) => {
                        console.log(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }, []);

    const handleOtherAttachmentsUpload = useCallback((hcpId: any, hcpResp: any) => {
        let promArray: any = [];
        otherDocuments?.forEach((value: any, index: any) => {
            if (value?.index !== -1) {
                promArray.push(onHandleOtherAttachmentUpload(otherFileUpload?.wrapper[value?.index], index, hcpId, value));
            }
        });

        return promArray;
    }, [otherFileUpload?.wrapper, onHandleOtherAttachmentUpload, otherDocuments]);

    const onAdd = useCallback((facility: any, {
        setSubmitting,
        setErrors,
        resetForm
    }: FormikHelpers<FacilityItemAddType>) => {

        if (members?.some((value: any) => value?.designation === "FA")) {
            setIsFacilitySubmitting(true);
            facility.phone_number = facility?.phone_number?.toLowerCase();
            facility.email = facility?.email.toLowerCase();
            facility.coordinates = [Number(facility?.longitude), Number(facility?.latitude)];
            ApiService.post(ENV.API_URL + "facility", facility)
                .then((resp: any) => {
                    if (resp && resp.success) {
                        const facilityId = resp.data._id;

                        // Trigger all the asynchronous operations
                        const membersProm = addMembers(facilityId);
                        const shiftsProm = addShifts(facilityId);
                        const imageUploadProm = handleGetUrlForUpload(facilityId);
                        const contractImageUploadProm = handleGetUrlForContractUpload(facilityId);
                        const otherAttachmentsPromises = handleOtherAttachmentsUpload(facilityId, resp);

                        // Wait for all promises to resolve
                        Promise.all([membersProm, shiftsProm, imageUploadProm, contractImageUploadProm, otherAttachmentsPromises])
                            .then(() => {
                                CommonService.showToast("Success", "success");
                                setTimeout(() => history.push(`/facility/view/${facilityId}`), 600);
                            })
                            .catch((err) => {
                                CommonService.showToast(err.message || "Error", "error");
                            });
                    } else {
                        setSubmitting(false);
                        setIsFacilitySubmitting(false);
                    }
                })
                .catch((err: any) => {
                    CommonService.handleErrors(setErrors, err);
                    setSubmitting(false);
                    setIsFacilitySubmitting(false);
                    CommonService.showToast(err.message || "Error", "error");
                    setScrollToError((prevState) => !prevState);
                });
        } else {
            CommonService.showToast("Please add the Facility Admin", "info");
        }
    }, [members, addMembers, addShifts, handleGetUrlForContractUpload, handleGetUrlForUpload, handleOtherAttachmentsUpload, history]);

    // const getRegions = useCallback(() => {
    //   CommonService._api
    //     .get(ENV.API_URL + "meta/hcp-regions")
    //     .then((resp) => {
    //       setRegions(resp.data || []);
    //       setRegIsLoading(false);
    //     })
    //     .catch((err) => {
    //       //console.log(err);
    //       setRegIsLoading(false);
    //     });
    // }, []);

    const openAdd = useCallback(() => {
        setIsAddOpen(true);
        console.log(fileUpload);
    }, [fileUpload]);

    const cancelAdd = useCallback(() => {
        setIsAddOpen(false);
    }, []);

    const confirmAdd = useCallback(() => {
        history.push("/facility/list");
    }, [history]);

    useEffect(() => {
        Communications.pageTitleSubject.next("Add Facility");
        Communications.pageBackButtonSubject.next(null);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [scrollToError]);

    const onFacilityAdminAdd = (member: MemberAddType, {
        setSubmitting,
        setErrors,
        resetForm
    }: FormikHelpers<MemberAddType>) => {
        const newMembers = [...members, member];
        setMembers(newMembers)
        resetForm();
    };

    if (isLoading) {
        return <LoaderComponent/>;
    }
    return (
        !isLoading && (
            <div className="facility-main  screen">
                <DialogComponent open={open} cancel={cancelPreviewFile} class="preview-content">
                    <CustomPreviewFile cancel={cancelPreviewFile} confirm={confirmPreviewFile}
                                       previewData={previewFileData}/>
                </DialogComponent>
                <DialogComponent open={openContractFile} cancel={cancelContractPreviewFile} class="preview-content">
                    <CustomPreviewFile cancel={cancelContractPreviewFile} confirm={confirmContractPreviewFile}
                                       previewData={previewContractFileData}/>
                </DialogComponent>
                <DialogComponent open={isAddOpen} cancel={cancelAdd}>
                    <LeavePageConfirmationComponent cancel={cancelAdd} confirm={confirmAdd} confirmationText={""}
                                                    notext={"Cancel"} yestext={"Leave"}/>
                </DialogComponent>
                <div className="form-container mrg-top-30">
                    <FacilityAddDetailsComponent OnOtherFileSelected={OnOtherFileSelected}
                                                 otherFileUpload={otherFileUpload}
                                                 setOtherFileUpload={setOtherFileUpload} onAdd={onAdd} regions={regions}
                                                 americanTimeZone={americanTimeZone}
                                                 fileUpload={fileUpload} contractFileUpload={contractFileUpload}
                                                 previewFile={previewFile} previewContractFile={previewContractFile}
                                                 previewOtherFile={previewOtherFile}
                                                 deleteFile={deleteFile} deleteContractFile={deleteContractFile}
                                                 OnFileSelected={OnFileSelected}
                                                 OnContractFileSelected={OnContractFileSelected} otHours={otHours}
                                                 otherDocuments={otherDocuments} setOtherDocuments={setOtherDocuments}/>

                    <div className="facility-members mrg-top-10  custom-border">
                        <p className="card-header">Facility Admin *</p>
                        <div className="facility-add-component-container">
                            <div>
                                <Formik initialValues={memberInitialState}
                                        validateOnChange={true}
                                        validationSchema={memberFormValidation}
                                        onSubmit={onFacilityAdminAdd}
                                >
                                    {({isSubmitting, handleSubmit, isValid, resetForm}) => (
                                        <Form className={"form-holder"}>
                                            <div className="facility-add-input">
                                                <Field variant="outlined" name="name" type={"text"}
                                                       component={TextField}
                                                       label="Name*" fullWidth autoComplete="off"
                                                       id="input_facility_member_add_name"/>
                                            </div>
                                            <div className="facility-add-input">
                                                <div className="number-container">
                                                    <Field
                                                        inputProps={{maxLength: 10}}
                                                        className="phone_number"
                                                        variant="outlined"
                                                        name="contact_number"
                                                        type={"text"}
                                                        component={TextField}
                                                        label="Contact Number*"
                                                        fullWidth
                                                        autoComplete="off"
                                                        id="input_facility_member_add_phone_number"
                                                    />

                                                    <Field
                                                        className="extension_number"
                                                        inputProps={{maxLength: 10}}
                                                        variant="outlined"
                                                        name="extension_number"
                                                        type={"text"}
                                                        component={TextField}
                                                        label="Extension No.*"
                                                        autoComplete="off"
                                                        id="input_facility_member_add_extension_number"
                                                    />
                                                </div>

                                                <Field variant="outlined" name="email" type={"email"}
                                                       component={TextField}
                                                       label="Email" fullWidth autoComplete="off"
                                                       id="input_facility_member_add_email"/>
                                            </div>
                                            <div className="facility-add-btn-grp mrg-top-20">
                                                <Button type="submit" variant="contained" color="primary"
                                                        id="btn_facility_member_add_submit">
                                                    Save
                                                </Button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>

                    <div className="facility-members mrg-top-10  custom-border">
                        <p className="card-header">Facility Members</p>
                        <div className="facility-add-component-container">
                            <FacilityAddComponent members={members} setMembers={setMembers}/>
                        </div>
                    </div>


                    <div className="facility-shift-timings mrg-top-10  custom-border">
                        <p className="card-header">Shift Timings</p>
                        <ShiftAddComponent setShiftTimings={setShiftTimings} shiftTimings={shiftTimings}/>
                    </div>
                </div>

                <div className="facility-actions mrg-top-60">
                    <Tooltip title={"Cancel"}>
                        <Button type="reset" size="large" variant={"outlined"} className={"normal"} color="primary"
                                onClick={openAdd} id="btn_facility_add_cancel">
                            Cancel
                        </Button>
                    </Tooltip>
                    <Tooltip title={`${members?.length === 0 ? "Please Add one Facility Admin" : "Save Changes"}`}>
                        <Button
                            disabled={isFacilitySubmitting}
                            form="facility-add-form"
                            type="submit"
                            size="large"
                            variant={"contained"}
                            className={isFacilitySubmitting ? "pdd-left-30 pdd-right-30 has-loading-spinner" : "pdd-left-30 pdd-right-30"}
                            color={"primary"}
                            id="btn_facility_add_submit"
                        >
                            {isFacilitySubmitting ? "Saving" : "Save"}
                        </Button>
                    </Tooltip>
                </div>
                <ScrollToTop smooth color="white"/>
            </div>
        )
    );
};

export default FacilityManagementAddScreen;

import "./PendingConcernConfirmationBox.scss"
import DialogActions from "@material-ui/core/DialogActions";
import {Button} from "@material-ui/core";
import React from "react";

interface PendingConcernProps{
    cancelMarkingPendingConcern:any;
    confirmMarkingPendingConcern:any;
    selectedShifts:any;
}

const PendingConcernConfirmationBox=(props:PendingConcernProps)=>{
    const {cancelMarkingPendingConcern,confirmMarkingPendingConcern}=props
    return(
        <>
            <div className={'pending-concern-component'}>
                <div className={'pending-concern-text'}>
                    Are you sure you want to move the shift as pending concern?
                </div>
                <DialogActions className={'pdd-20'}>
                    <Button onClick={cancelMarkingPendingConcern} variant={"outlined"} color={'primary'}>
                        {'No, Cancel'}
                    </Button>
                    <Button onClick={confirmMarkingPendingConcern} variant={"outlined"} color={'primary'}>
                        {'Yes, Confirm'}
                    </Button>
                </DialogActions>
            </div>
        </>
    )
}

export default PendingConcernConfirmationBox
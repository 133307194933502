import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { TsDataListOptions, TsDataListState, TsDataListWrapperClass } from '../../../../classes/ts-data-list-wrapper.class';
import { ENV } from '../../../../constants';
import { ApiService, CommonService, Communications } from '../../../../helpers';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button } from "@material-ui/core";
import NoDataCardComponent from '../../../../components/NoDataCardComponent';
import LoaderComponent from '../../../../components/LoaderComponent';
import Checkbox from '@material-ui/core/Checkbox';
import "./RemoveHcpsScreen.scss";

const RemoveHcpsScreen = () => {
    const [list, setList] = useState<TsDataListState | null>(null);
    const params = useParams<{ id: string }>();
    const { id } = params;
    const history = useHistory();
    const [selectedHcps, setSelectedHcps] = useState<any>([])
    const [isAllselected, setAllSelected] = useState<boolean>(false);
    const [groupDetails, setGroupDetails] = useState<any>(null);
    const [isRemoveMembers, setIsRemoveMembers] = useState<boolean>(false);
    const [selectedCount, setSelectedCount] = useState<any>(-1);

    const init = useCallback(() => {
        if (!list) {
            const options = new TsDataListOptions({
                webMatColumns: ['Staff Name', 'Staff Type', 'Actions'],
                mobileMatColumns: ['Staff Name', 'Staff Type', 'Actions'],
            }, ENV.API_URL + 'group/' + id + '/member', setList, ApiService, 'get');

            let tableWrapperObj = new TsDataListWrapperClass(options)
            setList({ table: tableWrapperObj });
        }
    }, [list, id]);

    const RemoveHcpsToGroup = useCallback((hcp: any) => {
        return new Promise((resolve, reject) => {
            setIsRemoveMembers(true)
            ApiService.delete(ENV.API_URL + 'group/' + id + '/member/' + hcp).then((resp: any) => {
                if (resp && resp.success) {
                    resolve(null);
                    history.push('/group/view/' + id);
                    setIsRemoveMembers(false)
                } else {
                    reject(resp);
                    setIsRemoveMembers(false)
                }
            }).catch((err) => {
                reject(err);
                setIsRemoveMembers(false)
            })
        })
    }, [history, id])

    const getDetails = useCallback(() => {
        CommonService._api.get(ENV.API_URL + 'group/' + id).then((resp) => {
            setGroupDetails(resp.data);
        }).catch((err) => {
            console.log(err)
        })
    }, [id])

    const handleSelectHcp = useCallback((event: any, _id: any) => {
        if (event.target.checked === true) {
            setSelectedHcps([...selectedHcps, _id]);
            setSelectedCount(1);
        } else {
            let tempSelectedShifts = selectedHcps?.filter((item: any) => item !== _id)
            setSelectedHcps([...tempSelectedShifts]);
            if (tempSelectedShifts?.length === 0) {
                setSelectedCount(-1)
            }
        }
    }, [selectedHcps])

    useEffect(() => {
        setAllSelected(false)
    }, [list?.table?.data])

    const handleSelectAll = useCallback((event: any) => {
        if (event.target.checked === true) {
            let temp: any[] = []
            list?.table?.data?.forEach((item: any) => {
                let index = selectedHcps?.indexOf(item?._id);
                if (index === -1) {
                    temp.push(item._id);
                }
            })
            setSelectedHcps([...temp])
            setSelectedCount(1)
        } else {
            setSelectedHcps([])
            setSelectedCount(-1)
        }
        setAllSelected(event.target.checked)
    }, [list?.table?.data,selectedHcps])

    useEffect(() => {
        let count = 0;
        list?.table?.data?.forEach((item: any) => {
            if (selectedHcps?.indexOf(item?._id) !== -1) {
                count++
            }
        })
        if (list?.table?.data?.length !== 0 && count === list?.table?.data?.length) {
            setAllSelected(true);
        } else {
            setAllSelected(false);
        }
    }, [list?.table?.data, selectedHcps]);


    const isSelected = useCallback((_id: any) => {
        if (selectedHcps?.indexOf(_id) !== -1) {
            return true;
        }
        else {
            return false;
        }

    }, [selectedHcps])

    const handleRemoveMembers = useCallback(() => {
        setIsRemoveMembers(true);
        (selectedHcps || []).forEach((value: any) => {
            RemoveHcpsToGroup(value)
        })
    }, [selectedHcps, RemoveHcpsToGroup])

    useEffect(() => {
        init();
        Communications.pageTitleSubject.next('Remove HCP');
        Communications.pageBackButtonSubject.next('/group/view/'+id);
    }, [init,id])

    useEffect(() => {
        getDetails()
    }, [getDetails])

    return (
        <>
            <div className={'remove-members-list screen crud-layout pdd-30'}>
                {list && list.table?._isDataLoading && <div className="table-loading-indicator">
                    <LoaderComponent />
                </div>}
                <div>
                    <div className="header mrg-bottom-0">
                        <div className="filter">
                            <div>
                                <h2>{groupDetails?.title}</h2>
                                <p>Total Members:{list?.table?.data?.length}</p>
                            </div>
                        </div>
                        <div className="actions">
                            <div className="">

                            </div>
                        </div>
                    </div>
                </div>
                {list && list.table && <>
                    <TableContainer component={Paper} className={'table-responsive'}>
                        <Table stickyHeader className="mat-table table remove-hcps-list-table">
                            <TableHead className={"mat-thead"}>
                            <TableRow className={"mat-tr"}>
                                    <TableCell padding="checkbox" className="mat-th pdd-left-15">
                                        <Checkbox onChange={(event) => handleSelectAll(event)} checked={isAllselected} id="cb_select_all_hcps" />
                                    </TableCell>
                                    {list?.table.matColumns.map((column: any, columnIndex: any) => (
                                        <TableCell className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}
                                            key={'header-col-' + columnIndex}
                                        >
                                            {column}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {list.table.canShowNoData() &&
                                    <NoDataCardComponent tableCellCount={list.table.matColumns.length} />
                                }
                                {list?.table.data.map((row: any, rowIndex: any) => {
                                    const isItemSelected = isSelected(row["_id"]);
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={'row-' + rowIndex}>
                                            <TableCell className="mat-td mat-td-checkbox">
                                                <Checkbox checked={isItemSelected} onChange={(event) => handleSelectHcp(event, row["_id"])} id={"cb_" + rowIndex} />
                                            </TableCell>
                                            <TableCell className="mat-td mat-td-hcp-name">
                                                {row['hcp_name']}
                                            </TableCell>
                                            <TableCell className="mat-td mat-td-hcp-type">
                                                {row['hcp_type']}
                                            </TableCell>
                                            <TableCell className="mat-td mat-td-sticky mat-td-actions">
                                                <Link to={'/hcp/view/' + row.id} className="info-link" id={"link_hcp_details" + rowIndex} >
                                                    {('View Details')}
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>}
                <div className="mrg-top-20 remove-members-wrapper">
                    <div className="d-flex">
                        <Button
                            size="large"
                            onClick={() => history.push('/group/view/' + id)}
                            variant={"outlined"}
                            color="secondary"
                            className="cancel"
                            id="btn_hcp_edit_cancel">Cancel</Button>
                        <Button variant={"contained"} disabled={selectedCount === -1 || isRemoveMembers} className={isRemoveMembers ? "actions has-loading-spinner" : "actions"} onClick={handleRemoveMembers} color={"primary"}>{isRemoveMembers ? "Removing Members" : "Remove Members"}</Button>
                    </div>
                </div>
            </div>
        </>
    )
}


export default RemoveHcpsScreen;

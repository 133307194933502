import "./ApprovedShiftCreationComponent.scss"

import {

    FormControlLabel,
    Radio,
    RadioGroup as NormalRadioGroup,
} from "@material-ui/core";
import React, {useCallback} from "react";
import PerDiemApprovedShiftCreationComponent from "./per-diem/PerDiemApprovedShiftCreationComponent";
import HomeHealthApprovedShiftCreationComponent from "./home-health/HomeHealthApprovedShiftCreationComponent";
import ApprovedContractTravelShiftCreation from "./contract-travel/ApprovedContractTravelShiftCreation";

interface ApprovedShiftProps {
    shiftTimings: any
    facilityId?: any
    setSelectedApprovedTypeValue?: any
    selectedApprovedTypeValue?: any
}

const ApprovedShiftCreationComponent = (props: ApprovedShiftProps) => {
    const {shiftTimings, facilityId, setSelectedApprovedTypeValue, selectedApprovedTypeValue} = props;

    const handleChange = useCallback((event: any) => {
        console.log(event.target.value);
        setSelectedApprovedTypeValue(event.target.value);
    }, [setSelectedApprovedTypeValue]);


    return (
        <>
            <NormalRadioGroup aria-label="gender" defaultValue="open_shift"
                              name="radio-buttons-group"
                              className="mrg-top-10">
                <div className={'d-flex mrg-bottom-20'}>
                    {[{
                        name: "Per Diem",
                        code: "open_shift"
                    }, {
                        name: "Per Job / Visit",
                        code: "home_health"
                    }, {
                        name: "Contract / Travel",
                        code: "contract-travel"
                    }]?.map((item: any) => {
                        return (
                            <FormControlLabel value={item?.code}
                                              control={<Radio/>}
                                              onChange={(event) => handleChange(event)}
                                              label={item?.name}/>
                        );
                    })}
                </div>
            </NormalRadioGroup>
            <div className={'approved-shift-creation-component'}>
                {(selectedApprovedTypeValue === 'open_shift' && facilityId) &&
                    <>
                        <PerDiemApprovedShiftCreationComponent shiftTimings={shiftTimings} facilityId={facilityId}/>
                    </>}
                {(selectedApprovedTypeValue === 'home_health' && facilityId) &&
                    <>
                        <HomeHealthApprovedShiftCreationComponent shiftTimings={shiftTimings} facilityId={facilityId}/>
                    </>}
                {(selectedApprovedTypeValue === 'contract-travel' && facilityId) &&
                    <>
                        <ApprovedContractTravelShiftCreation facilityId={facilityId}/>
                    </>}
            </div>
        </>
    )
}

export default ApprovedShiftCreationComponent;

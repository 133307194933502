import {TableCell, TableRow} from "@material-ui/core";
import React from "react";
// import Lottie from "react-lottie";
// import DataLoading from "../assets/animations/DataLoading.json";

export interface DataCardComponentProps {
    tableCellCount?: number;
    width?: number;
    height?: number;
    speed?: number;
    isNotTable?: boolean;
    message?: any;
}

const TableNotificationAlert = (props: DataCardComponentProps) => {
    const tableCellCount = props.tableCellCount || 10;
    const message = props.message;

    // const defaultOptions = {
    //     animationData: DataLoading
    // };
    return props?.isNotTable ? (
        <div>
            <div className={"mrg-top-20"}>
                {/*<Lottie width={props?.width || 700} height={props?.height || 350} speed={props?.speed || 1} options={defaultOptions} />*/}
                <div style={{color: "#bfbfbf", fontWeight: 600, fontSize: "16px"}}>{message}</div>
            </div>
        </div>
    ) : (
        <TableRow>
            <TableCell style={{borderBottom: "none",paddingLeft:"200px",paddingTop:"150px"}} colSpan={tableCellCount}>
                <div className={"mrg-top-20"} style={{textAlign: "center"}}>
                    {/*<Lottie width={props?.width || 700} height={props?.height || 300} speed={props?.speed || 1} options={defaultOptions} />*/}
                    <div style={{color: "#bfbfbf", fontWeight: 600, fontSize: "16px"}}>{props?.message}</div>
                </div>
            </TableCell>
        </TableRow>
    );
};

export default TableNotificationAlert;

import React, {useCallback, useState} from 'react';
import {IconButton} from '@material-ui/core';
import CustomPreviewFile from "../../../../../components/shared/CustomPreviewFile";
import DialogComponent from "../../../../../components/DialogComponent";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
// import NormalTextField from "@material-ui/core/TextField";
import GetAppIcon from '@material-ui/icons/GetApp';

const TableViewCustomFile = (props: any) => {
    const [open, setOpen] = useState(false);
    const handleDownload = props?.handleDownload;
    const isDownload = props?.isDownload;

    const previewFile = useCallback(() => {
        setOpen(true);
    }, []);
    const cancelPreviewFile = useCallback(() => {
        setOpen(false);
    }, []);
    const confirmPreviewFile = useCallback(() => {
        setOpen(false);
    }, []);


    return <>
        <DialogComponent open={open} cancel={cancelPreviewFile} class="preview-content">
            <CustomPreviewFile cancel={cancelPreviewFile} confirm={confirmPreviewFile} previewData={props?.data}/>
        </DialogComponent>
        <TableRow className="mat-tr" role="checkbox" tabIndex={-1}
                  key={`row-${props?.data?.attachment_type}`}>
            <TableCell
                className="mat-td mat-td-hcp-name">
                <p style={{
                    // cursor: 'pointer',
                    //  textUnderlinePosition: "under"
                }}
                   onClick={previewFile}
                   className="delete-image"> {props?.data?.file_name}</p>
            </TableCell>
            <TableCell className="mat-td mat-td-sticky mat-td-actions">
                <div>
                    <IconButton onClick={(e) => handleDownload(props?.data?.file_key)}>
                        <GetAppIcon aria-disabled={isDownload} className="download-icon"/>
                    </IconButton>
                </div>
            </TableCell>
        </TableRow>
    </>;
};

export default TableViewCustomFile;
import "./GenerateTimesheetList.scss";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TableBody from "@material-ui/core/TableBody";
import NoDataCardComponent from "../../../../../components/NoDataCardComponent";
import {ApiService, CommonService} from "../../../../../helpers";
import React, {useCallback, useEffect, useState} from "react";
import {ENV, ImageConfig} from "../../../../../constants";
import moment from "moment";
import Button from "@material-ui/core/Button";
import DialogComponent from "../../../../../components/DialogComponent";
//import RemoveHCPComponent from "../../../../employeeManagement/view/nc/removeHcp/RemoveHCPComponent";
import {useHistory} from "react-router-dom";
import TableNotificationalert from "../../../../../components/TableNotificationalert";
import ConfirmationDialogAction from "../../../../../components/confirmation-component/ConfirmationDialogAction";
import TablePagination from "@material-ui/core/TablePagination";
import {
    TsDataListOptions,
    TsDataListState,
    TsDataListWrapperClass
} from "../../../../../classes/ts-data-list-wrapper.class";
import {useLocalStorage} from "../../../../../components/useLocalStorage";
import {Field, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";
import {DatePicker} from "formik-material-ui-pickers";
import {Tooltip} from "@material-ui/core";
import * as Yup from "yup";

interface GenerateTimesheetProps {
    shiftTimeShiftList: any;
    isGenerateInvoiceListLoading: any;
    selectedFacility: any;
    dateRange: any;
    timeshiftList: any;
    setIsGenerateInvoiceListLoading: any;
    setShiftTimeShiftList: any;
    setPageSizeIndex: any;
    pageSizeIndex: any;
    selectedStatusTypes: any;
    isPreviewingTheTimesheet: any;
    setIsPreviewingTheTimesheet: any;
}


const matColumns: any = ["S.No", "Shift Date", "Staff Type", "Shift", "Shift Hours", "Worker Name", "Clock In", "Clock Out", "Hourly Rate", "Late Request Premium", "Shift Differential", "Hazard Pay", "OT/Holiday Hours", "Break", "Total Hourly Rate", "Regular Pay", "OT Pay", "DT Pay", "Total Amount", "Shift Status"];
const GenerateTimeSheetList = (props: GenerateTimesheetProps) => {
    const shiftTimeShiftList = props?.shiftTimeShiftList;
    const [isAllselected, setAllSelected] = useState<boolean>(false);
    const [selectedShifts, setSelectedShifts] = useState<any>([]);
    const selectedFacility = props?.selectedFacility;
    const dateRange = props?.dateRange;
    const selectedStatusTypes = props?.selectedStatusTypes;
    const history = useHistory();
    const {isPreviewingTheTimesheet, setIsPreviewingTheTimesheet} = props;
    const setPageSizeIndex = props?.setPageSizeIndex;
    const [openGeneratePopup, setGeneratePoopupOpen] = useState<boolean>(false);
    const [isConfirmMarking, setIsConfirmMarking] = useState<boolean>(false);
    //const [isPreviewingTheTimesheet, setIsPreviewingTheTimesheet] = useState<any>(false);
    const [pageKaIndex, setPageKaIndex] = useLocalStorage<any>("GeneratePageSizeIndexPage", 100);
    const [shiftList, setShiftList] = useState<TsDataListState | null>(null);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [generateTimesheetMode, setGenerateTimesheetMode] = useState<"edit" | "default">("default");
    const [addFinanceChargePopupOpen, setAddFinanceChargePopupOpen] = useState(false);

    const cancelOpenGeneratePopup = useCallback(() => {
        setGeneratePoopupOpen(false);
    }, []);

    const cancelOpenAddFinancePopup = useCallback(() => {
        setAddFinanceChargePopupOpen(false);
    }, []);

    const handleSelectAll = (event: any) => {
        if (event.target.checked === true) {
            let temp: any[] = [];
            shiftTimeShiftList?.table?.data?.forEach((item: any) => {
                let index = selectedShifts?.indexOf(item?._id);
                if (index === -1) {
                    temp.push(item._id);
                }
            });
            setSelectedShifts([...selectedShifts, ...temp]);
            // setSelectedCount(1);
        } else {
            shiftTimeShiftList?.table?.data?.forEach((item: any) => {
                let index = selectedShifts?.indexOf(item?._id);
                selectedShifts.splice(index, 1);
            });
            setSelectedShifts([...selectedShifts]);
            //   setSelectedCount(selectedShifts?.length === 0 ? -1 : selectedShifts?.length);
        }
        setAllSelected(event.target.checked);
    };

    const isSelected = useCallback(
        (_id: any) => {
            if (selectedShifts?.indexOf(_id) !== -1) {
                return true;
            } else {
                return false;
            }
        },
        [selectedShifts]
    );

    const handleSelectShifts = useCallback(
        (event: any, _id: any) => {
            if (event.target.checked === true) {
                setSelectedShifts([...selectedShifts, _id]);
                // setSelectedCount(1);
            } else {
                let tempSelectedShifts = selectedShifts?.filter((item: any) => item !== _id);
                setSelectedShifts([...tempSelectedShifts]);
                if (tempSelectedShifts?.length === 0) {
                    //      setSelectedCount(-1);
                }
            }
        },
        [selectedShifts]
    );

    useEffect(() => {
        let count = 0;
        shiftTimeShiftList?.table?.data?.forEach((item: any) => {
            if (selectedShifts?.indexOf(item?._id) !== -1) {
                count++;
            }
        });
        if (shiftTimeShiftList?.table?.data?.length !== 0 && count === shiftTimeShiftList?.table?.data?.length) {
            setAllSelected(true);
        } else {
            setAllSelected(false);
        }
    }, [shiftTimeShiftList?.table?.data, selectedShifts]);

    useEffect(() => {
        setAllSelected(false);
    }, [shiftTimeShiftList?.table?.data]);


    const generateInvoice = useCallback(() => {
        let payload: any = {
            is_invoice_generated: true,
        };
        if (dateRange[0] || dateRange[1]) {
            let startDate = moment(dateRange[0]).format("YYYY-MM-DD");
            let endDate = moment(dateRange[1]).format("YYYY-MM-DD");

            if (!dateRange[1]) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        }
        if (selectedShifts) {
            payload.shift_ids = selectedShifts;
        }
        if (selectedStatusTypes.length > 0) {
            payload.status = selectedStatusTypes.map((item: any) => item?.code);
        }
        CommonService._api.post(ENV.API_URL + `facility/${selectedFacility?._id}/invoice`, payload).then((resp) => {
            if (generateTimesheetMode === "edit") {
                history.push(`/EditGeneratedInvoiceViewDetails/${resp?.data[0]?.invoice_id}`);
            } else {
                history.push(`/generatedInvoiceViewDetails/${resp?.data[0]?.invoice_id}`);
            }
            setIsConfirmMarking(false);
            setGeneratePoopupOpen(false);
            CommonService.showToast("success", "success");
        }).catch((err) => {
            setGeneratePoopupOpen(false);
            setIsConfirmMarking(false);
        });
    }, [selectedFacility, dateRange, selectedShifts, history, selectedStatusTypes, generateTimesheetMode]);

    useEffect(() => {
        if (generateTimesheetMode === "edit") {
            setGeneratePoopupOpen(true);
        }
    }, [generateTimesheetMode]);

    const generateInvoicePreview = useCallback(() => {
        setIsPreviewingTheTimesheet(true);
        let payload: any = {};
        let url = "invoice/generatePreview";
        if (selectedShifts) {
            payload.shift_ids = selectedShifts;
        }
        if (selectedStatusTypes.length > 0) {
            payload.status = selectedStatusTypes.map((item: any) => item?.code);
        }
        const options = new TsDataListOptions(
            {
                // @ts-ignore
                pagination: {
                    ...(shiftList?.table?.pagination || {}),
                    pageSize: pageKaIndex,
                },
                extraPayload: payload,
                // webMatColumns: role === "super_admin" ? ["Created On", "Facility Name", "Region", "Contact Number", "Active / Inactive", "Actions"] : ["Created On", "Facility Name", "Region", "Contact Number", "Status", "Actions"],
                // mobileMatColumns: role === "super_admin" ? ["Created On", "Facility Name", "Region", "Contact Number", "Active / Inactive", "Actions"] : ["Created On", "Facility Name", "Region", "Contact Number", "Status", "Actions"],
            },
            ENV.API_URL + url,
            setShiftList,
            ApiService,
            "post"
        );
        let tableWrapperObj = new TsDataListWrapperClass(options);
        setShiftList({table: tableWrapperObj});
        // eslint-disable-next-line
    }, [selectedShifts, pageKaIndex, selectedStatusTypes]);


    useEffect(() => {
        if (selectedFacility || dateRange[0] || dateRange[1]) {
            setIsPreviewingTheTimesheet(false);
            setSelectedShifts([]);
        }
        // eslint-disable-next-line
    }, [selectedFacility, dateRange]);

    const handleInvoiceDownload = useCallback(() => {
        let payload: any = {};
        if (selectedShifts) {
            payload.shift_ids = selectedShifts;
        }
        CommonService._api.post(ENV.API_URL + `invoice/previewDownload`, payload).then((res) => {
            if (res?.data) {
                const link = document.createElement("a");
                link?.setAttribute("href", res?.data);
                document.body.appendChild(link);
                link.click();
                setIsDownloading(false);
            } else {
                CommonService.showToast(res?.msg || "No Data to Download", "info");
                setIsDownloading(false);
            }
        })
            .catch((err) => {
                setIsDownloading(false);
                CommonService.showToast(err?.msg || "Error", "error");
            });
    }, [selectedShifts]);

    const InvoiceGeneratedInitialState = {

            finance_charge: {
                details: "",
                date: null,
                amount: "",
                //     Number(invoiceDetails?.finance_charge?.amount)?.toLocaleString('en-US', {
                //     style: 'currency',
                //     currency: 'USD',
                //     minimumFractionDigits: 2,
                //     maximumFractionDigits: 2,
                // }),
            },
        }
    ;

    const editFinanceChargeFormValidation = Yup.object({
        // "details": "finance charge",
        // "date": "2023-07-12",
        // "amount": 10
        finance_charge: Yup.object({
            details: Yup.string().typeError(" must be a text").required("Invoice Details is required"),
            date: Yup.string().nullable().typeError(" must be a date").required("Date is required"),
            amount: Yup.string().nullable().required("Amount is required"),
        }),
    });

    const onEditFinanceCharge = useCallback((item: any, {setSubmitting, setErrors, resetForm}) => {
        console.log(selectedFacility);
        let payload: any = {
            ...item,
            finance_charge: {
                details: item?.finance_charge?.details,
                date: item?.finance_charge?.date,
                amount: parseFloat(item?.finance_charge?.amount.replace('$', ''))
            }
        };
        if (dateRange[0] || dateRange[1]) {
            let startDate = moment(dateRange[0]).format("YYYY-MM-DD");
            let endDate = moment(dateRange[1]).format("YYYY-MM-DD");

            if (!dateRange[1]) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        }

        ApiService.post(ENV.API_URL + "facility/" + selectedFacility?._id + "/financeInvoice", payload)
            .then((resp: any) => {
                // console.log(resp);
                if (resp && resp.success) {
                    setAddFinanceChargePopupOpen(false);
                    history.push(`/EditGeneratedInvoiceViewDetails/${resp?.data?._id}`);
                    CommonService.showToast(resp.msg || "Success", "success");
                } else {
                    setAddFinanceChargePopupOpen(false);
                    setSubmitting(false);
                }
            })
            .catch((err) => {
                CommonService.handleErrors(setErrors, err);
                setAddFinanceChargePopupOpen(false);
                setSubmitting(false);
                CommonService.showToast(err.msg || "Error", "error");
            });

    }, [selectedFacility, history, dateRange]);


    // @ts-ignore
    // @ts-ignore
    return (
        <>
            <>
                <DialogComponent isEditableDialog={true} cancel={cancelOpenGeneratePopup} open={openGeneratePopup}>
                    <ConfirmationDialogAction headerText={"GENERATE INVOICE"} cancel={cancelOpenGeneratePopup}
                                              confirm={generateInvoice} confirmationText="Are you sure you want to generate invoices for
the selected Timesheets?" notext={"NO"} yestext={"YES"} isConfirm={isConfirmMarking}/>
                </DialogComponent>
                <DialogComponent isEditableDialog={true} cancel={cancelOpenAddFinancePopup}
                                 open={addFinanceChargePopupOpen}>
                    <Formik
                        initialValues={InvoiceGeneratedInitialState}
                        validateOnChange={true}
                        validationSchema={editFinanceChargeFormValidation}
                        onSubmit={onEditFinanceCharge}
                    >
                        {({isSubmitting, isValid}) => (
                            <Form className="">
                                <div className={"mrg-10"} style={{fontWeight: 600, fontSize: "16px"}}>Add Any Other
                                    Charge
                                </div>
                                <div style={{maxWidth: "580px"}}>
                                    <Field variant="outlined" name="finance_charge.details"
                                           className={"mrg-10"}
                                           type={"text"}
                                        // label={'Invoice Details'}
                                           placeholder={'Enter Invoice Details'}
                                           component={TextField}
                                           fullWidth autoComplete="off"
                                           id="input_facility_edit_facility_name"/>
                                    <Field
                                        variant="inline"
                                        className={"mrg-10"}
                                        orientation="landscape"
                                        openTo="date"
                                        format="MM/dd/yyyy"
                                        views={["year", "month", "date"]}
                                        inputVariant="outlined"
                                        component={DatePicker}
                                        placeholder="MM/DD/YYYY"
                                        fullWidth
                                        autoComplete="off"
                                        InputLabelProps={{shrink: true}}
                                        label="Date"
                                        name="finance_charge.date"
                                    />

                                    <Field variant="outlined" name="finance_charge.amount" type={"text"}
                                           component={TextField}
                                           className={"mrg-10"}
                                           fullWidth autoComplete="off"
                                        //  placeholder={'Amount'}
                                           placeholder={"Enter Amount"}
                                           id="input_facility_edit_facility_name"/>


                                    <div className={"mrg-bottom-20"} style={{display: "flex", marginLeft: "20%"}}>
                                        <Button onClick={() => {
                                            setAddFinanceChargePopupOpen(false);
                                        }}
                                                color={"primary"}
                                                style={{width: "150px"}}
                                                variant={"outlined"} id="sms_blast_button"
                                                className={"mrg-right-10"} size={"small"} fullWidth={true}>
                                            Cancel
                                        </Button>
                                        <Tooltip title={isSubmitting ? "Saving Finance Charge" : "Save"}>
                                            <Button disabled={isSubmitting || !isValid}
                                                    color={"primary"}
                                                    style={{width: "150px"}}
                                                    variant={"contained"} id="sms_blast_button"
                                                    className={isSubmitting ? "has-loading-spinner" : ""}
                                                    type="submit" size={"small"} fullWidth={true}>
                                                {isSubmitting ? "Saving" : "Save"}
                                            </Button>
                                        </Tooltip>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </DialogComponent>
                <div className="generate-component custom-border pdd-10">
                    <div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "black"
                        }}>
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                {isPreviewingTheTimesheet &&
                                    <img onClick={() => {
                                        setIsPreviewingTheTimesheet(false);
                                    }} style={{cursor: "pointer"}} className={"mrg-right-10"} src={ImageConfig.BackIcon}
                                         alt={''}/>}
                                <h5>
                                    {`Timesheet `} <span
                                    className={"selected-text"}>({selectedShifts?.length} Selected)</span></h5>
                            </div>
                            {!isPreviewingTheTimesheet && <>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    {selectedFacility?._id && selectedShifts?.length === 0 &&
                                        <Button
                                            color={"primary"}
                                            variant={"outlined"}
                                            onClick={() => {
                                                setAddFinanceChargePopupOpen(true);
                                            }}
                                            className={'login-button mrg-right-10'}
                                        >
                                            Generate Without Shifts
                                        </Button>}
                                    <Button
                                        disabled={selectedShifts?.length === 0}
                                        color={"primary"}
                                        variant={"contained"}
                                        onClick={() => {
                                            //   setGeneratePoopupOpen(true);
                                            generateInvoicePreview();
                                            //  generateInvoice();
                                        }}
                                        className={'login-button'}
                                    >
                                        {/*{isSubmitting ? "Logging in" : "Login"}*/}
                                        CALCULATE
                                    </Button>
                                </div>
                            </>
                            }
                            {isPreviewingTheTimesheet && <>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <Button
                                        disabled={selectedShifts?.length === 0}
                                        color={"primary"}
                                        variant={"outlined"}
                                        onClick={() => {
                                            setGenerateTimesheetMode("edit");
                                            //  generateInvoice();
                                        }}
                                        className={'login-button'}
                                    >
                                        {/*{isSubmitting ? "Logging in" : "Login"}*/}
                                        Edit Details
                                    </Button>
                                    <Button
                                        disabled={selectedShifts?.length === 0}
                                        color={"primary"}
                                        variant={"outlined"}
                                        onClick={() => {
                                            setGeneratePoopupOpen(true);
                                            //  generateInvoice();
                                        }}
                                        className={'login-button mrg-left-10'}
                                    >
                                        {/*{isSubmitting ? "Logging in" : "Login"}*/}
                                        Generate Invoice
                                    </Button>
                                    <Button
                                        disabled={selectedShifts?.length === 0}
                                        color={"primary"}
                                        variant={"contained"}
                                        onClick={() => {
                                            handleInvoiceDownload();
                                        }}
                                        className={'login-button mrg-left-10'}
                                    >
                                        {isDownloading ? "Downloading Timesheet" : " Download Timesheet"}
                                    </Button>
                                </div>
                            </>
                            }
                        </div>
                    </div>
                    <TableContainer component={Paper} className={"table-responsive"}>
                        <Table stickyHeader className="mat-table table open-shifts-list-table">
                            <TableHead className={"mat-thead"}>
                                <TableRow className={"mat-tr"}>
                                    {!isPreviewingTheTimesheet &&
                                        <TableCell padding="checkbox" className="mat-th">
                                            <Checkbox
                                                // disabled={checkForDisablingSelectAllCheckbox()}
                                                onChange={(event) => handleSelectAll(event)}
                                                checked={isAllselected}
                                                id={"select-all-cb"}
                                            />
                                        </TableCell>}
                                    {matColumns.map((column: any, columnIndex: any) => (
                                        <TableCell
                                            className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}
                                            key={"header-col-" + columnIndex}>
                                            {column}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            {/*{shiftTimeShiftList && (*/}
                            <TableBody className={"mat-tbody"}>
                                {(!selectedFacility || !dateRange[0]) &&
                                    <>
                                        {/*<div style={{border:"1px solid red", marginTop: "50%"}}>*/}
                                        <TableNotificationalert tableCellCount={10}
                                                                message={"Please Select facility and Date Range to view Timesheets"}/>
                                        {/*</div>*/}
                                    </>
                                }

                                {!isPreviewingTheTimesheet && <>
                                    {(selectedFacility && dateRange[0]) && shiftTimeShiftList?.table?.data?.length === 0 &&
                                        <NoDataCardComponent tableCellCount={10}/>}
                                    {(selectedFacility && dateRange[0]) && shiftTimeShiftList?.table?.data?.length > 0 &&
                                        shiftTimeShiftList?.table?.data?.map((row: any, rowIndex: any) => {
                                            const shift_date = CommonService.getUtcDate(row["shift_date"]);
                                            const isItemSelected = isSelected(row["_id"]);
                                            const otHrs = !isNaN(Number(row["ot_hrs"])) ? Number(row["ot_hrs"]) : 0;
                                            const dtHrs = !isNaN(Number(row["dt_hrs"])) ? Number(row["dt_hrs"]) : 0;

                                            return (
                                                <TableRow role="checkbox" tabIndex={-1} key={"row-" + rowIndex}
                                                          className={"mat-tr"}>
                                                    <TableCell className="mat-td mat-td-checkbox">
                                                        <Checkbox id={"cb_" + rowIndex}
                                                            //   disabled={row["status"] === "cancelled"}
                                                                  checked={isItemSelected}
                                                                  onChange={(event) => handleSelectShifts(event, row["_id"])}/>
                                                    </TableCell>
                                                    <TableCell
                                                        className="mat-td mat-td-title">{rowIndex + 1}</TableCell>
                                                    <TableCell
                                                        className="mat-td mat-td-facility-name"
                                                        style={{minWidth: "150px"}}>
                                                    {/*    {new Date(row?.shift_date).toLocaleDateString("en-US", {*/}
                                                    {/*    month: "2-digit",*/}
                                                    {/*    day: "2-digit",*/}
                                                    {/*    year: "numeric",*/}
                                                    {/*})}*/}
                                                        {shift_date}
                                                    </TableCell>
                                                    <TableCell
                                                        className="mat-td mat-td-shift-date">{row["hcp"]?.hcp_type}</TableCell>
                                                    <TableCell
                                                        className="mat-td mat-td-hcp-type"
                                                        style={{minWidth: "100px"}}>{row["shift_type"]}</TableCell>
                                                    <TableCell
                                                        className="mat-td mat-td-hcp-count">{row["shift_hours"]}</TableCell>
                                                    <TableCell className="mat-td mat-td-shift-timings">
                                                        {row["hcp"]?.first_name + " " + row["hcp"]?.last_name}
                                                    </TableCell>
                                                    <TableCell style={{minWidth: "150px"}}
                                                               className="mat-td mat-td-shift-type">{row?.time_breakup?.check_in_time ?
                                                        CommonService.startTime(row?.time_breakup?.check_in_time)
                                                        :
                                                        <div style={{color: "red"}}>
                                                            {CommonService.startTime(row?.expected?.shift_start_time)}
                                                        </div>}
                                                    </TableCell>
                                                    <TableCell style={{minWidth: "150px"}}
                                                               className="mat-td mat-td-shift-type">{row["time_breakup"]?.check_out_time ? CommonService.endTime(row["time_breakup"]?.check_out_time) :
                                                        <div style={{color: "red"}}>
                                                            {CommonService.endTime(row?.expected?.shift_end_time)}
                                                        </div>}</TableCell>
                                                    <TableCell
                                                        className={` mat-td mat-td-status`}>{Number(row["hourly_rate"])?.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }) || (0)?.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })}</TableCell>
                                                    <TableCell className="mat-td mat-td-is-facility-paid ">
                                                        {Number(row["rush_rate"])?.toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: 'USD',
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        }) || (0)?.toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: 'USD',
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        })}
                                                    </TableCell>
                                                    <TableCell className="mat-td mat-td-sticky mat-td-actions">
                                                        {Number(row["shift_diff"])?.toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: 'USD',
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        })}
                                                    </TableCell>
                                                    <TableCell>{Number(row["hazard_rate"])?.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }) || 0}</TableCell>
                                                    <TableCell>{(otHrs + dtHrs).toFixed(2)}</TableCell>
                                                    <TableCell style={{minWidth: "200px"}}>
                                                        {row["time_breakup"].break_timings?.length > 0 ? row["time_breakup"].break_timings?.map((breaks: any, index: any) => {
                                                            const {
                                                                start_time,
                                                                end_time
                                                            } = CommonService.getUtcTimeInAMPM(breaks?.break_in_time, breaks?.break_out_time);
                                                            return (
                                                                <>
                                                                    <div style={{
                                                                        marginBottom: "10px",
                                                                        color: "red"
                                                                    }}>
                                                                        {start_time + "-" + end_time}
                                                                    </div>
                                                                </>
                                                            );
                                                        }) : <>
                                                            {row?.time_breakup?.check_in_time ? <>No Break</> : <>30
                                                                Minutes</>}
                                                        </>
                                                        }
                                                    </TableCell>
                                                    <TableCell>{Number(row["total_hourly_rate"])?.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }) || (0)?.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })}</TableCell>
                                                    <TableCell
                                                        style={isPreviewingTheTimesheet ? {color: "#0C80E3"} : {}}>$0.00</TableCell>
                                                    <TableCell
                                                        style={isPreviewingTheTimesheet ? {color: "#0C80E3"} : {}}>$0.00</TableCell>
                                                    <TableCell
                                                        style={isPreviewingTheTimesheet ? {color: "#0C80E3"} : {}}>$0.00</TableCell>
                                                    <TableCell
                                                        style={isPreviewingTheTimesheet ? {color: "#0C80E3"} : {}}>$0.00</TableCell>
                                                    <TableCell
                                                        className={`${row["status"]}`}>{row["status"] === "pending" ? "Approved" : row["status"]?.charAt(0)?.toUpperCase() + row["status"]?.slice(1)}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </>}
                                {isPreviewingTheTimesheet && <>
                                    {(selectedFacility && dateRange[0]) && shiftList && shiftList?.table?.data?.length > 0 &&
                                        shiftList?.table?.data?.map((row: any, rowIndex: any) => {
                                            // const isItemSelected = isSelected(row["_id"]);
                                            const shift_date = CommonService.getUtcDate(row["shift_date"]);
                                            const otHrs = !isNaN(Number(row["ot_hrs"])) ? Number(row["ot_hrs"]) : 0;
                                            const dtHrs = !isNaN(Number(row["dt_hrs"])) ? Number(row["dt_hrs"]) : 0;
                                            return (
                                                <TableRow role="checkbox" tabIndex={-1} key={"row-" + rowIndex}
                                                          className={"mat-tr"}>
                                                    {/*<TableCell className="mat-td mat-td-checkbox">*/}
                                                    {/*    /!*<Checkbox id={"cb_" + rowIndex}*!/*/}
                                                    {/*    /!*    //   disabled={row["status"] === "cancelled"}*!/*/}
                                                    {/*    /!*          checked={isItemSelected}*!/*/}
                                                    {/*    /!*          onChange={(event) => handleSelectShifts(event, row["_id"])}/>*!/*/}
                                                    {/*</TableCell>*/}
                                                    <TableCell
                                                        className="mat-td mat-td-title">{rowIndex + 1}</TableCell>
                                                    <TableCell
                                                        className="mat-td mat-td-facility-name"
                                                        style={{minWidth: "150px"}}>{shift_date}</TableCell>
                                                    <TableCell
                                                        className="mat-td mat-td-shift-date">{row["hcp"]?.hcp_type}</TableCell>
                                                    <TableCell
                                                        className="mat-td mat-td-hcp-type"
                                                        style={{minWidth: "100px"}}>{row["shift_type"]}</TableCell>
                                                    <TableCell
                                                        className="mat-td mat-td-hcp-count">{row["shift_hours"]}</TableCell>
                                                    <TableCell className="mat-td mat-td-shift-timings">
                                                        {row["hcp"]?.first_name + " " + row["hcp"]?.last_name}
                                                    </TableCell>
                                                    <TableCell style={{minWidth: "150px"}}
                                                               className="mat-td mat-td-shift-type">{row?.time_breakup?.check_in_time ?
                                                        CommonService.startTime(row?.time_breakup?.check_in_time)
                                                        :
                                                        <div style={{color: "red"}}>
                                                            {CommonService.startTime(row?.expected?.shift_start_time)}
                                                        </div>}
                                                    </TableCell>
                                                    <TableCell style={{minWidth: "150px"}}
                                                               className="mat-td mat-td-shift-type">{row["time_breakup"]?.check_out_time ? CommonService.endTime(row["time_breakup"]?.check_out_time) :
                                                        <div style={{color: "red"}}>
                                                            {CommonService.endTime(row?.expected?.shift_end_time)}
                                                        </div>}</TableCell>
                                                    <TableCell
                                                        className={` mat-td mat-td-status`}>{Number(row["hourly_rate"])?.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }) || 0}</TableCell>
                                                    <TableCell className="mat-td mat-td-is-facility-paid ">
                                                        {Number(row["rush_rate"])?.toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: 'USD',
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        }) || 0}
                                                    </TableCell>
                                                    <TableCell className="mat-td mat-td-sticky mat-td-actions">
                                                        {Number(row["shift_diff"])?.toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: 'USD',
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        })}
                                                    </TableCell>
                                                    <TableCell>{Number(row["hazard_rate"])?.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }) || 0}</TableCell>
                                                    <TableCell>{(otHrs + dtHrs).toFixed(2)}</TableCell>
                                                    <TableCell style={{minWidth: "200px"}}>
                                                        {row["time_breakup"].break_timings?.length > 0 ? row["time_breakup"].break_timings?.map((breaks: any, index: any) => {
                                                            const {
                                                                start_time,
                                                                end_time
                                                            } = CommonService.getUtcTimeInAMPM(breaks?.break_in_time, breaks?.break_out_time);
                                                            return (
                                                                <>
                                                                    <div style={{
                                                                        marginBottom: "10px",
                                                                        color: "red"
                                                                    }}>
                                                                        {start_time + "-" + end_time}
                                                                    </div>
                                                                </>
                                                            );
                                                        }) : <>
                                                            {row?.time_breakup?.check_in_time ? <>No Break</> : <>30
                                                                Minutes</>}
                                                        </>
                                                        }
                                                    </TableCell>
                                                    <TableCell>{Number(row["total_hourly_rate"])?.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }) || 0}</TableCell>
                                                    <TableCell
                                                        style={isPreviewingTheTimesheet ? {color: "#0C80E3"} : {}}>{Number(row["reg_amount"])?.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }) || Number(row["regular_pay"])?.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }) || (0)?.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })}</TableCell>
                                                    <TableCell
                                                        style={isPreviewingTheTimesheet ? {color: "#0C80E3"} : {}}>{Number(row["ot_amount"])?.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }) || Number(row["ot_pay"])?.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }) || (0)?.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })}</TableCell>
                                                    <TableCell
                                                        style={isPreviewingTheTimesheet ? {color: "#0C80E3"} : {}}>{Number(row["dt_amount"])?.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }) || Number(row["dt_pay"])?.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }) || (0)?.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })}</TableCell>
                                                    <TableCell
                                                        style={isPreviewingTheTimesheet ? {color: "#0C80E3"} : {}}>{Number(row["total_amount"])?.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }) || (0)?.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })}</TableCell>
                                                    <TableCell
                                                        className={`${row["status"]}`}>{row["status"] === "pending" ? "Approved" : row["status"]?.charAt(0)?.toUpperCase() + row["status"]?.slice(1)}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </>}
                            </TableBody>
                            {/*)}*/}
                        </Table>
                    </TableContainer>
                    {!isPreviewingTheTimesheet &&
                        <>
                            <div className={"thhh"}>
                                {shiftTimeShiftList?.table && selectedFacility && dateRange[0] && <>
                                    <TablePagination
                                        rowsPerPageOptions={[100, 200, 300, 400, 500]}
                                        component="div"
                                        count={shiftTimeShiftList?.table?.pagination?.totalItems}
                                        rowsPerPage={shiftTimeShiftList?.table?.pagination.pageSize}
                                        page={shiftTimeShiftList?.table?.pagination.pageIndex}
                                        onPageChange={(event, page) => shiftTimeShiftList?.table.pageEvent(page)}
                                        onRowsPerPageChange={(event) => {
                                            setPageSizeIndex(event.target.value);
                                            shiftTimeShiftList.table?.pageEvent(0, +event.target.value);
                                        }}
                                    />
                                </>}
                            </div>
                        </>
                    }
                    {isPreviewingTheTimesheet &&
                        <>

                            {shiftList?.table && <>

                                <TablePagination
                                    rowsPerPageOptions={[100, 200, 300, 400, 500]}
                                    component="div"
                                    count={shiftList?.table?.data?.length}
                                    rowsPerPage={shiftList?.table?.pagination.pageSize}
                                    page={shiftList?.table?.pagination.pageIndex}
                                    onPageChange={(event, page) => shiftList?.table.pageEvent(page)}
                                    onRowsPerPageChange={(event) => {
                                        setPageKaIndex(event.target.value);
                                        shiftList?.table?.pageEvent(0, +event.target.value);
                                    }}
                                />
                            </>}
                        </>
                    }
                </div>
            </>
        </>
    )
        ;
};
export default GenerateTimeSheetList;

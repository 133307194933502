import React, {useEffect, useState} from "react";
import {Document, Font, Image, Page, PDFDownloadLink, StyleSheet, Text, View} from "@react-pdf/renderer";
import Logo from "../../../../assets/images/DownloadInvoiceLogo.png";

import {CommonService} from "../../../../helpers";
import _ from "lodash";


Font.register({
    family: 'AntonFamily',
    src: ""
});

interface PdfProps {
    invoiceDetails: any,
    sortedTimesheets: any,
    totalHrs: any,
    TotalAmount: any,
}

const DownloadPdfHeader = ["", "SHIFT DATE", "Staff TYPE", "SHIFT", "SHIFT HOURS", "WORKER NAME", "CLOCK IN", "CLOCK OUT", "HOURLY RATE", "LATE REQUEST PREMIUM", "SHIFT DIFFERENTIAL", "HAZARD PAY", "OT/HOLIDAY HOURS", "BREAK", "TOTAL HOURLY RATE", "REGULAR PAY", "OT PAY", "DT PAY", "TOTAL AMOUNT"];
const ReferenceDetailsHeader = ["Reference - Agreement#", "START DATE", "END DATE", "ADDL.", "INVOICE DESCRIPTION", "DOC. DATE"];
const ItemDetailsHeader = ["ITEM", "ITEM DETAILS", "TOTAL HOURS", "CURR. ($)", "TOTAL AMOUNT"];
const styles = StyleSheet.create({
    body: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingHorizontal: 20,
    },
    flexItem: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: "space-between",
        fontSize: "10px",
        fontWeight: 400
    },
    logo: {

        width: 100,
        height: 18,
        marginBottom: "20px"
    },
    title: {
        fontSize: 24,
        textAlign: "center",
        fontFamily: "AntonFamily",
    },
    addressToBox: {
        border: "1px solid black",
        width: "255px",
        height: "45px",
        padding: "5px",
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: "justify",
        fontFamily: "AntonFamily",

    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100,
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: "center",
        color: "grey",
        fontFamily: "AntonFamily",
    },
    pageNumber: {
        position: "absolute",
        fontSize: 12,
        top: 10,
        right: 10,
        textAlign: "center",
        color: "#000205",
        fontFamily: "AntonFamily",
    },
    container: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        backgroundColor: "#D9D9D9"
    },
    columnContainer: {
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    columnHeader: {
        width: '7%', // Adjust as needed
        fontSize: "7.5px",
        fontWeight: 700,
        textAlign: "center",
        paddingVertical: '2px',
        // paddingHorizontal: '2px',
        //   border: "1px solid red"
    },
    columnContent: {
        width: '7%',
        fontSize: "9px",
        fontWeight: 400,
        textAlign: "center",
        //    border: "1px solid red"// Adjust as needed
    },
    widerColumnHeader: {
        width: '12%',
        fontSize: "7.5px",
        paddingVertical: '2px',
        // paddingHorizontal: '2px',
        // fontWeight: 700,
        textAlign: "center",
        //  border: "1px solid red"// Adjust as needed
    },
    widerColumn: {
        width: '12%',
        fontSize: "9px",
        fontWeight: 700,
        textAlign: "center",
        //  border: "1px solid red"// Adjust as needed
    },
    fontStyle: {
        fontSize: "10px",
        fontWeight: 700,
        color: "#414040"
    },
    headerRow: {
        flexDirection: 'row', // Add flexDirection to arrange headers horizontally
        justifyContent: 'space-between',
        border: 1
    },
    doubleWidth: {
        flex: 2,
        padding: 5,
        borderRight: 1
    },
    column: {
        flex: 1,
        padding: 5,
        borderRight: 1
    },
    referenceHeader: {
        flex: 1,
        padding: 5,
        fontWeight: 'bold',
        borderRight: 1
        // Add bold font style
    },
    referenceContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        border: 1
    },
    dataGrid: {
        flexDirection: "column",
        fontSize: "10px",

    },
    row: {
        flexDirection: "row"
    },
    col: {
        //background-color: #f2f2f2;
        padding: "10px",
        border: 0.5,
    },

});

// interface DocsProps {
//     invoiceDetails: any;
// }

const ITEMS_PER_PAGE = 16;

const MyDocument = ({sortedTimesheets, invoiceDetails, totalHrs, TotalAmount, add30Days, timesheetData}: any) => (
        <Document>
            {timesheetData?.map((timesheets: any, pageIndex: any) => {
                return (
                    <Page size={'A3'} orientation={'landscape'}  style={{...styles.body, backgroundColor: "white", fontSize: "16px"}}>
                        <View fixed style={{marginBottom: "20px"}}>
                            <View style={styles.logo}>
                                <Image src={Logo}/>
                            </View>
                            <View style={styles.flexItem}>
                                <View style={styles.addressToBox}>
                                    <Text style={{fontWeight: 'bold'}}>TO</Text>
                                    <Text>{invoiceDetails?.billing_to?.name}</Text>
                                    <Text>{invoiceDetails?.billing_to?.street}, {invoiceDetails?.billing_to?.city}, {invoiceDetails?.billing_to?.state}, {invoiceDetails?.billing_to?.zip_code}</Text>
                                </View>
                                <View style={styles.addressToBox}>
                                    <Text style={{textAlign: "center"}}>PERIOD COVERED</Text>
                                    <View style={{}}>
                                        <View style={styles.flexItem}>
                                            <Text>Start Date:</Text>
                                            <Text>
                                                {CommonService.getUtcDate(invoiceDetails?.start_date)}
                                            </Text>
                                        </View>
                                        <View style={styles.flexItem}>
                                            <Text>End Date:</Text>
                                            <Text>{CommonService.getUtcDate(invoiceDetails?.end_date)}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View fixed style={{width: "100%", alignItems: "center", display: 'flex'}}>
                                <Text style={{fontSize: "15px", color: "#000205"}}>TIMESHEET</Text>
                            </View>
                        </View>


                        <View>
                            <View style={styles.container} fixed>
                                {DownloadPdfHeader?.map((columnHeader, index) => (
                                    <>
                                        {index === 0 && <>
                                            <View style={{width: "2%", fontSize: "7.5px", alignItems: "center", fontWeight: 700}}
                                                  key={index}>
                                                <Text>{columnHeader}</Text>
                                            </View>
                                        </>}
                                        {index !== 0 &&
                                            <View key={index}
                                                  style={index === 5 || index === 13 ? styles.widerColumnHeader : styles.columnHeader}>
                                                <Text>{columnHeader}</Text>
                                            </View>}

                                    </>
                                ))}
                            </View>
                            {timesheets?.map((rowData: any, rowIndex: any) => (
                                // const otHrs = !isNaN(Number(row["ot_hrs"])) ? Number(row["ot_hrs"]) : 0;
                                // const dtHrs = !isNaN(Number(row["dt_hrs"])) ? Number(row["dt_hrs"]) : 0;
                                <>
                                    <View key={rowIndex} style={{...styles.columnContainer, marginTop: "3px", marginBottom: "2px"}}>
                                        <View style={{
                                            width: "2%",
                                            marginRight: "3px",
                                            fontSize: "9px",
                                            alignItems: "center",
                                            fontWeight: 400
                                        }}>
                                            <Text>
                                                { (pageIndex * ITEMS_PER_PAGE) + rowIndex + 1}
                                            </Text>
                                        </View>
                                        <View style={styles.columnContent}>
                                            <Text>
                                                {CommonService.getUtcDate(rowData?.shift_date)}
                                            </Text>
                                        </View>
                                        <View style={styles.columnContent}>
                                            <Text>{rowData?.hcp?.hcp_type}</Text>
                                        </View>
                                        <View style={styles.columnContent}>
                                            <Text style={{fontSize: '8px'}}>{rowData?.shift_type}</Text>
                                        </View>
                                        <View style={styles.columnContent}>
                                            <Text>{rowData?.shift_hours}</Text>
                                        </View>
                                        <View style={{width: "12%", fontWeight: 400, fontSize: "9px", textAlign: "center"}}>
                                            <Text>{rowData?.hcp?.first_name + " " + rowData?.hcp?.last_name}</Text>
                                        </View>
                                        <View style={styles.columnContent}>
                                            <Text>{rowData?.shift_timings?.start_time ?
                                                CommonService.startTime(rowData?.shift_timings?.start_time)
                                                :
                                                <Text style={{color: "red"}}>
                                                    {CommonService.startTime(rowData?.shift_timings?.start_time)}
                                                </Text>}</Text>
                                        </View>
                                        <View style={styles.columnContent}>
                                            <Text>{rowData?.shift_timings?.end_time ?
                                                CommonService.startTime(rowData?.shift_timings?.end_time)
                                                :
                                                <Text style={{color: "red"}}>
                                                    {CommonService.startTime(rowData?.shift_timings?.end_time)}
                                                </Text>}</Text>
                                        </View>
                                        <View style={styles.columnContent}>
                                            <Text>{rowData?.hourly_rate?.toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }) || (0)?.toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}</Text>
                                        </View>
                                        <View style={styles.columnContent}>
                                            <Text>   {rowData?.rush_rate ? Number(rowData?.rush_rate)?.toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }) : "$ 0.00"}</Text>
                                        </View>
                                        <View style={styles.columnContent}>
                                            <Text>{rowData?.shift_diff ? Number(rowData?.shift_diff)?.toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }) : "$ 0.00"}</Text>
                                        </View>
                                        <View style={styles.columnContent}>
                                            <Text>{rowData?.hazard_rate ? Number(rowData?.hazard_rate)?.toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }) : "$ 0.00"}</Text>
                                        </View>
                                        <View style={styles.columnContent}>
                                            <Text>{Number(rowData?.ot_hrs) + Number(rowData?.dt_hrs)}</Text>
                                        </View>
                                        <View style={{width: "12%", fontWeight: 400, fontSize: "9px", textAlign: "center"}}>
                                            <Text>
                                                {rowData?.shift_timings?.break_timings?.length > 0 ? rowData?.shift_timings?.break_timings?.map((breaks: any, index: any) => {
                                                    const {
                                                        start_time,
                                                        end_time
                                                    } = CommonService.getUtcTimeInAMPM(breaks?.break_in_time, breaks?.break_out_time);
                                                    return (
                                                        <>
                                                            <div>
                                                                {start_time + "-" + end_time}{'\n'}
                                                            </div>
                                                            <br/>
                                                        </>
                                                    )
                                                        ;
                                                }) : <>
                                                    {rowData?.break_duration === 0 ? <>No Break</> : <>
                                                        {`${Number(rowData?.break_duration)} Minutes`}</>}
                                                </>
                                                }
                                            </Text>
                                        </View>
                                        <View style={styles.columnContent}>
                                            <Text>{rowData?.total_hourly_rate?.toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }) || (0)?.toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}</Text>
                                        </View>
                                        <View style={styles.columnContent}>
                                            <Text>{Number(rowData?.reg_amount)?.toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }) || "$ 0.00"}</Text>
                                        </View>
                                        <View style={styles.columnContent}>
                                            <Text>{Number(rowData?.ot_amount)?.toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }) || "$ 0.00"}</Text>
                                        </View>
                                        <View style={styles.columnContent}>
                                            <Text>{Number(rowData?.dt_amount)?.toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }) || "$ 0.00"}</Text>
                                        </View>
                                        <View style={styles.columnContent}>
                                            <Text>{Number(rowData?.total_amount)?.toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }) || "$ 0.00"}</Text>
                                        </View>
                                    </View>
                                </>
                            ))}
                        </View>


                        <Text
                            style={styles.pageNumber}
                            render={({pageNumber}) =>
                                `${pageIndex+1}`
                            }
                            fixed
                        />
                    </Page>
                )
                }
            )}
            <Page size={'A3'} style={{...styles.body, backgroundColor: "white", fontSize: "16px"}}>
                <View style={{marginTop: "20px"}} fixed>
                    <View style={{flexDirection: "row", justifyContent: "space-between"}}>
                        <View style={styles.logo}>
                            <Image src={Logo}/>
                        </View>
                        <View>
                            <View style={{flexDirection: "row"}}>
                                <Text style={{...styles.fontStyle}}>CUSTOMER NO: </Text>
                                <Text style={{...styles.fontStyle}}>{invoiceDetails?.facility?.uid}</Text>
                            </View>
                            <View style={{flexDirection: "row"}}>
                                <Text style={{...styles.fontStyle}}>INVOICE NO: </Text>
                                <Text style={{...styles.fontStyle}}>{invoiceDetails?.invoice_number}</Text>
                            </View>
                            <View style={{flexDirection: "row"}}>
                                <Text style={{...styles.fontStyle}}>INV. DATE: </Text>
                                <Text style={{...styles.fontStyle}}> {invoiceDetails?.invoice_generated_date &&
                                    <>
                                        {CommonService.getUtcDate(invoiceDetails?.invoice_generated_date)}
                                    </>}
                                    {!invoiceDetails?.invoice_generated_date &&
                                        <>
                                            {CommonService.getUtcDate(invoiceDetails?.created_at)}
                                        </>}</Text>
                            </View>
                        </View>
                    </View>

                    <View>
                        <View style={{...styles.fontStyle}}><Text>{invoiceDetails?.billing_from?.name}</Text></View>
                        <View style={{...styles.fontStyle}}><Text>{invoiceDetails?.billing_from?.street}</Text></View>
                        <View style={{...styles.fontStyle}}><Text>
                            <span>{invoiceDetails?.billing_from?.city}, </span>{invoiceDetails?.billing_from?.state}, {invoiceDetails?.billing_from?.zip_code}
                        </Text></View>
                        <Text style={{fontSize: "8px"}}>
                            <span>EIN: </span>{invoiceDetails?.billing_from?.ein}
                        </Text>
                    </View>

                    <View style={{width: "100%", alignItems: "center", display: 'flex'}}>
                        <Text style={{fontSize: "15px", color: "#000205"}}>INVOICE SUMMARY</Text>
                    </View>

                    <View style={{width: "100%", border: "1px solid black"}}>
                        <View style={{...styles.fontStyle}}>
                            <Text><Text style={{marginRight: "5px"}}>&nbsp;Bill
                                To: </Text>{invoiceDetails?.billing_to?.name}
                            </Text>
                            <Text style={{marginLeft: "28px"}}> <span>{invoiceDetails?.billing_to?.street}, </span>
                                <span>{invoiceDetails?.billing_to?.city}, </span>{invoiceDetails?.billing_to?.state}, {invoiceDetails?.billing_to?.zip_code}
                            </Text>
                        </View>
                        <View style={{...styles.fontStyle}}>
                            <Text>&nbsp;Attn:&nbsp;&nbsp;&nbsp;&nbsp;{invoiceDetails?.billing_to?.attn}</Text>
                        </View>
                        <View style={{...styles.fontStyle, width: "100%", borderTop: "1px solid black"}}>
                            <Text
                                style={{marginLeft: "5px"}}>{invoiceDetails?.comment || "The enclosed health/individual’s information was reproduced by Vitawerks, Inc.. The confidentiality of this information is protected by federal and other\n" +
                                "law. These reproductions are intended exclusively for the requested, authorized, purpose and cannot be redistributed for other purposes without the written consent of the person to whom they pertain.\n" +
                                "If you have questions regarding these records or any other information provided or requested from Vitawerks, Inc. Call 309 472 3148"}</Text>
                        </View>
                    </View>

                    <View style={{...styles.headerRow, ...styles.fontStyle, color: "black", marginTop: "10px"}}>
                        {ReferenceDetailsHeader?.map((columnHeader, index) => (
                            <>
                                <View
                                    style={index === 0 || index === 4 || index === 5 ? styles.doubleWidth : styles.referenceHeader}>
                                    <Text>{columnHeader}</Text>
                                </View>
                            </>
                        ))}
                    </View>

                    <View style={{...styles.referenceContainer, ...styles.fontStyle}}>
                        <View style={styles.doubleWidth}>
                            <Text>{invoiceDetails?.reference}</Text>
                        </View>
                        <View style={styles.column}>
                            <Text>
                                {CommonService.getUtcDate(invoiceDetails?.start_date)}
                            </Text>
                        </View>
                        <View style={styles.column}>
                            <Text>
                                {CommonService.getUtcDate(invoiceDetails?.end_date)}
                            </Text>
                        </View>
                        <View style={styles.column}>
                            <Text>{invoiceDetails?.addl}</Text>
                        </View>
                        <View style={styles.doubleWidth}>
                            <Text>{invoiceDetails?.reference + " " + invoiceDetails?.addl}</Text>
                        </View>
                        <View style={styles.doubleWidth}>
                            <Text>
                                {invoiceDetails?.invoice_generated_date &&
                                    <>
                                        {CommonService.getUtcDate(invoiceDetails?.invoice_generated_date)}
                                    </>}
                                {!invoiceDetails?.invoice_generated_date &&
                                    <>
                                        {CommonService.getUtcDate(invoiceDetails?.created_at)}
                                    </>}
                            </Text>
                        </View>
                    </View>

                    <View style={{...styles.headerRow, ...styles.fontStyle, color: "black", marginTop: "10px"}}>
                        {ItemDetailsHeader?.map((columnHeader, index) => (
                            <>
                                <View
                                    style={index === 1 || index === 4 ? styles.doubleWidth : styles.referenceHeader}>
                                    <Text>{columnHeader}</Text>
                                </View>
                            </>
                        ))}
                    </View>

                    <View style={{...styles.referenceContainer, ...styles.fontStyle}}>
                        <View style={styles.column}>
                            <Text>1</Text>
                        </View>
                        <View style={styles.doubleWidth}>
                            <Text>{invoiceDetails?.reference}</Text>
                        </View>
                        <View style={styles.column}>
                            <Text>{totalHrs ? totalHrs.toFixed(2) : ""}</Text>
                        </View>
                        <View style={styles.column}>
                            <Text>USD</Text>
                        </View>
                        <View style={styles.doubleWidth}>
                            <Text>{(Number(TotalAmount) + Number(invoiceDetails?.finance_charge?.amount ? invoiceDetails?.finance_charge?.amount : 0))?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}</Text>
                        </View>
                    </View>

                    <View>
                        <Text style={{color: "green", fontSize: "12px"}}>PAYMENT
                            TERMS: {`Within 30 days Due NET (Subject to Late Payment Penalties) - Payment Due ${add30Days(invoiceDetails?.created_at, invoiceDetails?.invoice_generated_date)}`}</Text>
                    </View>

                    <View style={{width: "100%", border: 1, flexDirection: "row", marginTop: "20px"}}>
                        <View style={{width: "50%", fontSize: "10px", borderRight: 1}}>
                            <Text>&nbsp;Checks payable / Remits to:</Text>
                            <Text>&nbsp;{invoiceDetails?.checks_payable?.name}</Text>
                            <Text>&nbsp;{invoiceDetails?.checks_payable?.street}</Text>
                            <Text>&nbsp;
                                <div style={{fontWeight: 600}}>
                                        <span
                                        >{invoiceDetails?.checks_payable?.city},</span>{invoiceDetails?.checks_payable?.state},{invoiceDetails?.checks_payable?.zip_code}
                                </div>
                            </Text>
                            <Text
                                style={{marginTop: "20px"}}>&nbsp;ATTN: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{invoiceDetails?.checks_payable?.attn}</Text>
                            <Text>&nbsp;CONTACT: &nbsp;{invoiceDetails?.checks_payable?.contact}</Text>
                        </View>
                        <View style={{width: "50%", fontSize: "10px"}}>
                            <Text>&nbsp;Please SEND your remittance to :</Text>
                            <Text>&nbsp;{invoiceDetails?.send_remittance?.name}</Text>
                            <Text>&nbsp;Account# {invoiceDetails?.send_remittance?.account}</Text>
                            <Text>&nbsp;Electronic(ACH etc): {invoiceDetails?.send_remittance?.electronic}</Text>

                        </View>
                    </View>

                </View>

                <Text
                    style={styles.pageNumber}
                    render={({pageNumber, totalPages}) =>
                        `${pageNumber}`
                    }
                    fixed
                />
            </Page>
            <Page size={'A3'} style={{...styles.body, backgroundColor: "white", fontSize: "18px"}}>
                <View>
                    <View fixed style={{flexDirection: "row", justifyContent: "space-between", marginTop: "20px"}}>
                        <View fixed style={styles.logo}>
                            <Image src={Logo}/>
                        </View>
                        <View fixed>
                            <View style={{flexDirection: "row"}}>
                                <Text style={{...styles.fontStyle}}>CUSTOMER NO: </Text>
                                <Text style={{...styles.fontStyle}}>{invoiceDetails?.facility?.uid}</Text>
                            </View>
                            <View style={{flexDirection: "row"}}>
                                <Text style={{...styles.fontStyle}}>INVOICE NO: </Text>
                                <Text style={{...styles.fontStyle}}>{invoiceDetails?.invoice_number}</Text>
                            </View>
                            <View style={{flexDirection: "row"}}>
                                <Text style={{...styles.fontStyle}}>INV. DATE: </Text>
                                <Text style={{...styles.fontStyle}}> {invoiceDetails?.invoice_generated_date &&
                                    <>
                                        {CommonService.getUtcDate(invoiceDetails?.invoice_generated_date)}
                                    </>}
                                    {!invoiceDetails?.invoice_generated_date &&
                                        <>
                                            {CommonService.getUtcDate(invoiceDetails?.created_at)}
                                        </>}</Text>
                            </View>
                        </View>
                    </View>

                    <View fixed>
                        <View style={{...styles.fontStyle}}><Text>{invoiceDetails?.billing_from?.name}</Text></View>
                        <View style={{...styles.fontStyle}}><Text>{invoiceDetails?.billing_from?.street}</Text></View>
                        <View style={{...styles.fontStyle}}><Text>
                            <span>{invoiceDetails?.billing_from?.city}, </span>{invoiceDetails?.billing_from?.state}, {invoiceDetails?.billing_from?.zip_code}
                        </Text></View>
                        <Text style={{fontSize: "10px"}}>
                            <span>EIN: </span>{invoiceDetails?.billing_from?.ein}
                        </Text>
                    </View>

                    <View fixed style={{width: "100%", alignItems: "center", display: 'flex'}}>
                        <Text style={{fontSize: "15px", color: "#000205"}}>INVOICE DETAILS</Text>
                    </View>

                    <View fixed style={{width: "100%", border: "1px solid black"}}>
                        <View style={{...styles.fontStyle}}>
                            <Text>&nbsp;<Text style={{marginRight: "5px"}}>&nbsp;Bill
                                To: </Text>{invoiceDetails?.billing_to?.name}
                            </Text>
                            <Text style={{marginLeft: "28px"}}> <span>{invoiceDetails?.billing_to?.street}, </span>
                                <span>{invoiceDetails?.billing_to?.city}, </span>{invoiceDetails?.billing_to?.state}, {invoiceDetails?.billing_to?.zip_code}
                            </Text>
                        </View>
                        <View style={{...styles.fontStyle}}>
                            <Text>&nbsp;Attn:&nbsp;&nbsp;&nbsp;&nbsp;{invoiceDetails?.billing_to?.attn}</Text>
                        </View>
                        <View style={{...styles.fontStyle, width: "100%", borderTop: "1px solid black"}}>
                            <Text
                                style={{marginLeft: "5px"}}>{invoiceDetails?.comment || "The enclosed health/individual’s information was reproduced by Vitawerks, Inc.. The confidentiality of this information is protected by federal and other\n" +
                                "law. These reproductions are intended exclusively for the requested, authorized, purpose and cannot be redistributed for other purposes without the written consent of the person to whom they pertain.\n" +
                                "If you have questions regarding these records or any other information provided or requested from Vitawerks, Inc. Call 309 472 3148"}</Text>
                        </View>
                    </View>

                    <View fixed style={{...styles.headerRow, ...styles.fontStyle, color: "black", marginTop: "10px"}}>
                        {ReferenceDetailsHeader?.map((columnHeader, index) => (
                            <>
                                <View
                                    style={index === 0 || index === 4 || index === 5 ? styles.doubleWidth : styles.referenceHeader}>
                                    <Text>{columnHeader}</Text>
                                </View>
                            </>
                        ))}
                    </View>

                    <View fixed style={{...styles.referenceContainer, ...styles.fontStyle, marginBottom: "3px"}}>
                        <View style={styles.doubleWidth}>
                            <Text>{invoiceDetails?.reference}</Text>
                        </View>
                        <View style={styles.column}>
                            <Text>
                                {CommonService.getUtcDate(invoiceDetails?.start_date)}
                            </Text>
                        </View>
                        <View style={styles.column}>
                            <Text>
                                {CommonService.getUtcDate(invoiceDetails?.end_date)}
                            </Text>
                        </View>
                        <View style={styles.column}>
                            <Text>{invoiceDetails?.addl}</Text>
                        </View>
                        <View style={styles.doubleWidth}>
                            <Text>{invoiceDetails?.reference + " " + invoiceDetails?.addl}</Text>
                        </View>
                        <View style={styles.doubleWidth}>
                            <Text>
                                {invoiceDetails?.invoice_generated_date &&
                                    <>
                                        {CommonService.getUtcDate(invoiceDetails?.invoice_generated_date)}
                                    </>}
                                {!invoiceDetails?.invoice_generated_date &&
                                    <>  {CommonService.getUtcDate(invoiceDetails?.created_at)}
                                    </>}
                            </Text>
                        </View>
                    </View>

                    <View style={{...styles.dataGrid, marginTop: "10px"}}>
                        <View style={styles.row} fixed>
                            <Text style={{...styles.col, width: "7%"}}>ITEM</Text>
                            <Text style={{...styles.col, width: "40%"}}>INVOICE DETAILS</Text>
                            <Text style={{...styles.col, width: "13%"}}>DATE</Text>
                            <Text style={{...styles.col, width: "7%"}}>CURR. ($)</Text>
                            <Text style={{...styles.col, width: "7%"}}>REG AMT</Text>
                            <Text style={{...styles.col, width: "7%"}}>OT AMT</Text>
                            <Text style={{...styles.col, width: "7%"}}>DT AMT</Text>
                            <Text style={{...styles.col, width: "12%"}}>TOTAL AMOUNT</Text>
                        </View>
                        {sortedTimesheets?.map((timesheet: any, index: any) => (
                            <>
                                <>
                                    <View style={styles.row}>
                                        <Text style={{...styles.col, width: "7%"}}>{index + 1}</Text>
                                        <View style={{...styles.col, width: "40%"}}>
                                            <Text>
                                                {timesheet?.hcp?.hcp_type} Staffing {timesheet?.shift_type} Shift
                                            </Text>
                                            <Text>
                                                {timesheet?.hcp?.first_name} {timesheet?.hcp?.last_name}
                                            </Text>
                                            <Text>
                                                {
                                                    timesheet?.reg_hrs > 0 && <>
                                                        {`${timesheet?.reg_hrs}H REG ${(timesheet?.total_hourly_rate ? timesheet?.total_hourly_rate : 0)?.toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: 'USD',
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        })}`}
                                                    </>
                                                }
                                                {
                                                    timesheet?.ot_hrs && <>
                                                        {timesheet?.reg_hrs > 0 && <> | </>}
                                                        {`${timesheet?.ot_hrs}H OT ${(timesheet?.total_hourly_rate ? timesheet?.total_hourly_rate * 1.5 : 0)?.toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: 'USD',
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        })}`}
                                                    </>
                                                }
                                                {
                                                    timesheet?.dt_hrs > 0 && <>
                                                        {timesheet?.ot_hrs > 0 && <> | </>}
                                                        {`${timesheet?.dt_hrs}H DT ${(timesheet?.total_hourly_rate ? timesheet?.total_hourly_rate * 2 : 0)?.toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: 'USD',
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        })}`}
                                                    </>
                                                }
                                            </Text>
                                        </View>
                                        <Text style={{
                                            ...styles.col,
                                            width: "13%"
                                        }}>{CommonService?.getUtcDate(timesheet?.shift_date) || "NA"}</Text>
                                        <Text style={{...styles.col, width: "7%"}}>USD
                                        </Text>
                                        <Text style={{
                                            ...styles.col,
                                            width: "7%"
                                        }}>{timesheet?.reg_amount ? timesheet?.reg_amount?.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }) : "$0"}
                                        </Text>
                                        <Text
                                            style={{
                                                ...styles.col,
                                                width: "7%"
                                            }}>{timesheet?.ot_amount ? timesheet?.ot_amount?.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }) : "$0"}</Text>
                                        <Text
                                            style={{
                                                ...styles.col,
                                                width: "7%"
                                            }}>{timesheet?.dt_amount ? timesheet?.dt_amount?.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }) : "$0"}</Text>
                                        <Text style={{
                                            ...styles.col,
                                            width: "12%"
                                        }}>{timesheet?.total_amount ? timesheet?.total_amount?.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }) : "$0"}
                                        </Text>
                                    </View>
                                </>
                            </>

                        ))}

                        {invoiceDetails?.finance_charge?.amount &&
                            <View style={styles.row}>
                                <Text style={{...styles.col, width: "7%"}}>{sortedTimesheets?.length + 1}</Text>
                                <View style={{...styles.col, width: "40%"}}>
                                    <Text>
                                        {invoiceDetails?.finance_charge?.details}
                                    </Text>
                                </View>
                                <Text style={{
                                    ...styles.col,
                                    width: "13%"
                                }}>{invoiceDetails?.finance_charge?.date ? CommonService.getUtcDate(invoiceDetails?.finance_charge?.date) : null}</Text>
                                <Text style={{...styles.col, width: "7%"}}>USD
                                </Text>
                                <Text style={{
                                    ...styles.col,
                                    width: "7%"
                                }}>-
                                </Text>
                                <Text
                                    style={{
                                        ...styles.col,
                                        width: "7%"
                                    }}>-</Text>
                                <Text
                                    style={{
                                        ...styles.col,
                                        width: "7%"
                                    }}>-</Text>
                                <Text style={{
                                    ...styles.col,
                                    width: "12%"
                                }}>
                                    {
                                        Number(invoiceDetails?.finance_charge?.amount)?.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                </Text>
                            </View>
                        }

                    </View>
                    {/*{invoiceDetails?.finance_charge?.amount &&*/}
                    {/*<View style={{...styles.dataGrid, marginTop: "10px"}}>*/}
                    {/*    <View style={styles.row} fixed>*/}
                    {/*        <Text style={{...styles.col, width: "20%"}}>ITEM</Text>*/}
                    {/*        <Text style={{...styles.col, width: "40%"}}>INVOICE DETAILS</Text>*/}
                    {/*        <Text style={{...styles.col, width: "20%"}}>DATE</Text>*/}
                    {/*        <Text style={{...styles.col, width: "20%"}}> AMOUNT</Text>*/}
                    {/*    </View>*/}
                    {/*    <View style={styles.row}>*/}
                    {/*        <Text style={{...styles.col, width: "20%"}}>1</Text>*/}
                    {/*        <View style={{...styles.col, width: "40%"}}>*/}
                    {/*            <Text>*/}
                    {/*                {invoiceDetails?.finance_charge?.details}*/}
                    {/*            </Text>*/}
                    {/*        </View>*/}
                    {/*        <Text style={{*/}
                    {/*            ...styles.col,*/}
                    {/*            width: "20%"*/}
                    {/*        }}>{invoiceDetails?.finance_charge?.date ? CommonService.getUtcDate(invoiceDetails?.finance_charge?.date) : null}</Text>*/}
                    {/*        <Text style={{*/}
                    {/*            ...styles.col,*/}
                    {/*            width: "20%"*/}
                    {/*        }}>{*/}
                    {/*            Number(invoiceDetails?.finance_charge?.amount)?.toLocaleString('en-US', {*/}
                    {/*                style: 'currency',*/}
                    {/*                currency: 'USD',*/}
                    {/*                minimumFractionDigits: 2,*/}
                    {/*                maximumFractionDigits: 2,*/}
                    {/*            })}*/}
                    {/*        </Text>*/}
                    {/*    </View>*/}
                    {/*</View>*/}
                    {/*}*/}
                </View>

                <Text
                    style={styles.pageNumber}
                    render={({pageNumber, totalPages}) =>
                        `${pageNumber}`
                    }
                    fixed
                />
            </Page>
        </Document>
    )
;

const PDFFileForTimesheet = (props: PdfProps) => {
    const {invoiceDetails, sortedTimesheets, totalHrs, TotalAmount} = props;
    const [timesheetData, setTimesheetData] = useState<any>([]);
    useEffect(() => {
        const newArray = _.chunk(sortedTimesheets, ITEMS_PER_PAGE);
        setTimesheetData(newArray);
    }, [sortedTimesheets]);
    const add30Days = (dateString: string, editTimesheetDate: string) => {
        if (editTimesheetDate) {
            const dateObj = new Date(editTimesheetDate);
            dateObj.setDate(dateObj.getDate() + 30);
            return dateObj.toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
            });
        } else {
            const dateObj = new Date(dateString);
            dateObj.setDate(dateObj.getDate() + 30);
            return dateObj.toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
            });
        }
    };
    return (
        <>
            <PDFDownloadLink
                style={{textDecoration: "none", color: "#10C4D2"}}
                document={<MyDocument add30Days={add30Days} TotalAmount={TotalAmount} totalHrs={totalHrs}
                                      invoiceDetails={invoiceDetails} timesheetData={timesheetData}
                                      sortedTimesheets={sortedTimesheets}/>}
                fileName={`${invoiceDetails?.invoice_number}`}>
                {({loading}) => (loading ? "Loading Document..." : "Download Invoice")}
            </PDFDownloadLink>
            {/*<MyDocument/>*/}
        </>
    );
};

export default PDFFileForTimesheet;

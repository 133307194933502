import { Button } from "@material-ui/core";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { ENV } from "../../../constants";
import { ApiService, CommonService } from "../../../helpers";
import "./HcpSentHomeDialogComponent.scss";

interface HcpSentHomeDialogComponentProps {
  cancel: () => void;
  confirm: () => void;
  selectedShifts: any[];
}

const hcpSentHomeFieldsValidation = Yup.object({
  actual_hours: Yup.number()
  .typeError(" must be valid")
  .positive().min(0,'min 0')
  .max(9999999, "max 9999999")
  .nullable(),
});

const hcpSentHomeInitialState = {
  actual_hours:"",
};

const HcpSentHomeDialogComponent = (props: HcpSentHomeDialogComponentProps) => {
  const { selectedShifts, confirm, cancel } = props;

  const onAdd = (data: any, { setSubmitting, setErrors, resetForm, setFieldValue }: FormikHelpers<any>) => {
    const payload = {
        sent_home_hours: data?.actual_hours,
        shift_ids: selectedShifts
    };

    console.log(payload)

    ApiService.patch(ENV.API_URL + "shift/sentHome", payload)
      .then((res: any) => {
        CommonService.showToast(res?.msg || "Success", "success");
        setSubmitting(false);
        confirm();
      })
      .catch((err) => {
        CommonService.showToast(err?.msg || err?.error || "Error", "error");
        setSubmitting(false);
      });
  };
  return (
    <div className="pdd-40 hcp-sent-home-dialog-component">
      <h2 className="hcp-sent-home-dialog-heading">Enter the Actual Hours</h2>
      <div className="pdd-20">
        <Formik initialValues={hcpSentHomeInitialState} validateOnChange={true}  validateOnMount={true} validateOnBlur={true} validationSchema={hcpSentHomeFieldsValidation} onSubmit={onAdd}>
          {({ isSubmitting, handleSubmit, isValid, values, resetForm, setFieldValue }) => (
            <Form id="add-hcp-sent-home-form" className={"hcp-sent-home form-holder"}>
              <div className="add-input">
                <div className="hcp-sent-home-details-container">
                    <Field
                      type="number"
                      size='medium'
                      variant='outlined'
                      id="input_actual_hours"
                      component={TextField}
                      placeholder="Enter Actual Hours "
                      fullWidth
                      autoComplete="off"
                      label="Actual Hours"
                      name="actual_hours"
                    />
                  
                </div>
              </div>

              <div className="mrg-top-40 text-align">
                <Button variant={"outlined"} id="cancel-employee-btn" size={"medium"} color={"primary"} onClick={() => cancel()}>
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  variant={"contained"}
                  type={"submit"}
                  size={"medium"}
                  color={"primary"}
                  className={isSubmitting ? "has-loading-spinner mrg-left-20 pdd-left-30 pdd-right-30" : "mrg-left-20 pdd-left-30 pdd-right-30"}
                >
                  {isSubmitting ? "Saving" : "Save"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default HcpSentHomeDialogComponent;

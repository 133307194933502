import {Button, Tooltip} from "@material-ui/core";
import {FormikHelpers} from "formik";
import React, {useCallback, useEffect, useState} from "react";
import "react-phone-number-input/style.css";
import {useHistory, useParams} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import {TsFileUploadConfig, TsFileUploadWrapperClass} from "../../../classes/ts-file-upload-wrapper.class";
import DialogComponent from "../../../components/DialogComponent";
import LoaderComponent from "../../../components/LoaderComponent";
import CustomPreviewFile from "../../../components/shared/CustomPreviewFile";
import LeavePageConfirmationComponent from "../../../components/shared/LeavePageConfirmationComponent";
import {ENV} from "../../../constants";
import {ApiService, CommonService, Communications} from "../../../helpers";
import FacilityEditDetailsComponent from "./BasicDetails/FacilityEditDetailsComponent";
import {FacilityItemEditType} from "./FacilityInitialAndValidationsComponent";
import "./FacilityManagementEditScreen.scss";
import FacilityAddComponent from "./FacilityMemberEditComponent/FacilityMemberEditComponent";
import ShiftAddComponent from "./ShiftEditComponent/ShiftEditComponent";
import {useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";
import VitawerksConfirmComponent from "../../../components/VitawerksConfirmComponent";

const FacilityManagementEditScreen = () => {
    const history = useHistory();
    const [timezone, setTimeZone] = useState(0);
    // const [members, setMembers] = useState<any[]>([]);
    // const [shiftTimings, setShiftTimings] = useState<any[]>([]);
    const params = useParams<{ id: string }>();
    const {id} = params;
    const [facilityDetails, setFacilityDetails] = useState<any | null>(null);
    const [isFacilityDetailsLoading, setIsFacilityDetailsLoading] = useState<boolean>(true);
    // const [regions, setRegions] = useState<any>([]);
    // const [regIsLoading, setRegIsLoading] = useState<boolean>(true);
    const {regions, isLoading} = useSelector((state: StateParams) => state.meta)
    const [facilitySubmitting, setIsFacilitySubmitting] = useState<boolean>(false);
    const [fileUpload, setFileUpload] = useState<{ wrapper: any } | null>(null);
    const [contractFileUpload, setContractFileUpload] = useState<{ wrapper: any } | null>(null);
    const [isImageRemoved, setIsImageRemoved] = useState<boolean>(false);
    const [isContractImageRemoved, setIsContractImageRemoved] = useState<boolean>(false);
    const [previewFileData, setPreviewFile] = useState<any | null>(null);
    const [previewContractFileData, setPreviewContractFile] = useState<any | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [openContractFile, setOpenContractFile] = useState<boolean>(false);
    const [isImage, setIsImage] = useState<boolean>(false);
    const [isContractImage, setContractIsImage] = useState<boolean>(false);
    const [isAddOpen, setIsAddOpen] = useState<boolean>(false);
    const [scrollToError, setScrollToError] = useState<boolean>(false);
    const [facilityContractData] = useState<any>([])
    const [otherDocuments, setOtherDocuments] = useState<any>([]);
    const [deleteAttachmentDetails, setDeleteAttachmentDetails] = useState<any>(null);
    const [isDeleteAttachmentOpen, setIsDeleteAttachmentOpen] = useState<boolean>(false);
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [isDeleteAttachment, setIsDeleteAttachment] = useState<boolean>(false);
    const [otherFileUpload, setOtherFileUpload] = useState<{ wrapper: any } | null>(null);
    // const handleFacilityImageUpload = useCallback(
    //   async (link: any) => {
    //     const file = fileUpload?.wrapper[0]?.file;
    //     delete file.base64;
    //     CommonService._api.upload(link, file, { "Content-Type": file?.type }).then((resp) => {
    //      // console.log(resp);
    //     })
    //       .catch((err) => {
    //        // console.log(err);
    //       });
    //   }, [fileUpload?.wrapper]);

    // const handleFacilityContractImageUpload = useCallback(
    //     async (link: any) => {
    //       const file = contractFileUpload?.wrapper[0]?.file;
    //       delete file.base64;
    //       CommonService._api.upload(link, file, { "Content-Type": file?.type }).then((resp) => {
    //        // console.log(resp);
    //       })
    //           .catch((err) => {
    //           //  console.log(err);
    //           });
    //     }, [contractFileUpload?.wrapper]);

    const onHandleOtherAttachmentUpload = useCallback((value: any, index: any, facilityId: any, requiredAttachment: any) => {
        // return new Promise(async (resolve, reject) => {
        //     try {
        //         let payload = {
        //             file_name: value?.file?.name,
        //             file_type: value?.file?.type,
        //             attachment_type: value?.extraPayload?.file_type,
        //             expiry_date: "",
        //         };
        //         CommonService._api.post(ENV.API_URL + "hcp/" + hcpId + "/attachment", payload).then((resp) => {
        //             if (value) {
        //                 const file = value?.file;
        //                 delete file.base64;
        //                 CommonService._api.upload(resp.data, file, {"Content-Type": value?.file?.type}).then((resp) => {
        //                     resolve(resp);
        //                 })
        //                     .catch((err) => {
        //                         console.log(err);
        //                     });
        //             }
        //         })
        //             .catch((err) => {
        //                 console.log(err);
        //             });
        //     } catch (error) {
        //         reject(error);
        //     }
        // });
        return new Promise(async (resolve, reject) => {
            try {
                const file = value?.file;
                const formData = new FormData();
                if (file) {
                    formData.append("attachment", file);
                    formData.append("attachment_type", value?.extraPayload?.file_type);
                    //  formData.append("expiry_date",requiredAttachment?.isAdditionalAttachment === true ? "" : value?.extraPayload?.expiry_date)
                }
                // let payload = {
                //     file_name: value?.file?.name,
                //     file_type: value?.file?.type,
                //     attachment_type: value?.extraPayload?.file_type,
                //     expiry_date: requiredAttachment?.isAdditionalAttachment === true ? "" : value?.extraPayload?.expiry_date,
                // };
                CommonService._api.upload(ENV.API_URL + "facility/" + facilityId + "/attachments", formData).then((resp) => {
                    resolve(resp);
                })
                    .catch((err) => {
                        console.log(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }, []);

    const handleOtherAttachmentsUpload = useCallback((hcpId: any) => {
        console.log(isDeleted);
        let promArray: any = [];
        otherDocuments?.forEach((value: any, index: any) => {
            if (value?.index !== -1) {
                promArray.push(onHandleOtherAttachmentUpload(otherFileUpload?.wrapper[value?.index], index, hcpId, value));
            }
        });

        return promArray;
    }, [otherFileUpload?.wrapper, onHandleOtherAttachmentUpload, otherDocuments, isDeleted]);

    const handleGetUrlForUpload = useCallback(() => {
        const file = fileUpload?.wrapper[0]?.file;
        const formData = new FormData();
        if (file) {
            formData.append("image", file);
        }
        CommonService._api.upload(ENV.API_URL + "facility/" + id + "/profile", formData)
            .then((resp) => {
                CommonService.showToast("Profile Uploaded Successfully", "success")
            })
            .catch((err) => {
                CommonService.showToast(err || "Error", "error");
            });
    }, [fileUpload?.wrapper, id]);

    const handleGetUrlForContractUpload = useCallback(() => {
        const file = contractFileUpload?.wrapper[0]?.file;
        const formData = new FormData();
        if (file) {
            formData.append("contract", file);
        }
        CommonService._api.upload(ENV.API_URL + "facility/" + id + "/contract", formData).then((resp) => {
            CommonService.showToast("Contract File Uploaded Successfully", "success")
        })
            .catch((err) => {
                // console.log(err);
                CommonService.showToast(err || "Error", "error");
            });
    }, [contractFileUpload?.wrapper, id]);

    const init = useCallback(() => {
        CommonService._api.get(ENV.API_URL + "facility/" + id).then((resp) => {
            setFacilityDetails(resp.data);
            setTimeZone(resp?.data?.timezone);
            setIsFacilityDetailsLoading(false);
        })
            .catch((err) => {
                // console.log(err);
            });
    }, [id]);

    const deleteFacilityImage = useCallback(() => {
        setIsImageRemoved(true);
        CommonService._api.delete(ENV.API_URL + "facility/" + id + "/profile").then((resp) => {
            init();
        })
            .catch((err) => {
                // console.log(err);
            });
    }, [init, id]);

    const deleteFacilityContractImage = useCallback(() => {
        setIsContractImageRemoved(true);
        console.log(facilityDetails?.contract_details?.file_key);
        const payload = {
            file_key: facilityDetails?.contract_details?.file_key
        }
        CommonService._api.delete(ENV.API_URL + "deleteAttachment", payload).then((resp) => {
            init();
        })
            .catch((err) => {
                //  console.log(err);
            });
    }, [facilityDetails, init]);

    // const getRegions = useCallback(() => {
    //   CommonService._api.get(ENV.API_URL + "meta/hcp-regions").then((resp) => {
    //     setRegions(resp.data || []);
    //     setRegIsLoading(false);
    //   })
    //     .catch((err) => {
    //     //  console.log(err);
    //       setRegIsLoading(false);
    //     });
    // }, []);

    // const getShiftDetails = useCallback(() => {
    //   CommonService._api.get(ENV.API_URL + "facility/" + id + "/shift").then((resp) => {
    //     setShiftTimings(resp.data || []);
    //   })
    //     .catch((err) => {
    //       //console.log(err);
    //       setShiftTimings([]);
    //     });
    // }, [id]);

    // const getFacilityMembers = useCallback(() => {
    //   CommonService._api.get(ENV.API_URL + "facility/" + id + "/member").then((resp) => {
    //     setMembers(resp.data || []);
    //   })
    //     .catch((err) => {
    //      // console.log(err);
    //       setMembers([]);
    //     });
    // }, [id]);

    const openAdd = useCallback(() => {
        setIsAddOpen(true);
    }, []);

    const cancelAdd = useCallback(() => {
        setIsAddOpen(false);
    }, []);

    const confirmAdd = useCallback(() => {
        history.push(`/facility/view/${id}`);
    }, [id, history]);


    const OnOtherFileSelected = (files: File[], index: any) => {
        setOtherDocuments((prevDocuments: any) => {
            const newDocuments = [...prevDocuments];
            for (let file of files) {
                newDocuments.push({name: file.name, index: otherFileUpload?.wrapper?.length || 0});
            }
            return newDocuments;
        });

        for (let file of files) {
            // console.log(file)
            const uploadConfig: TsFileUploadConfig = {
                file: file,
                fileFieldName: 'Data',
                uploadUrl: ENV.API_URL + 'facility/add',
                allowed_types: ['jpg', 'png', 'csv', 'pdf'],
                extraPayload: {expiry_date: '', file_type: "others"}
            };
            const uploadWrapper = new TsFileUploadWrapperClass(uploadConfig, CommonService._api, (state: {
                wrapper: TsFileUploadWrapperClass
            }) => {
                // console.log(state);
                setOtherFileUpload((prevState: any) => {
                    if (prevState) {
                        const index = prevState?.wrapper.findIndex((value: any) => value.uploadId === state.wrapper.uploadId);
                        prevState.wrapper[index] = state.wrapper;
                        return {wrapper: prevState.wrapper};
                    }
                    return prevState;
                });
            });
            uploadWrapper.onError = (err, heading) => {
                // console.error(err, heading);
                if (heading) {
                    CommonService.showToast(err, 'error');
                }
            };
            uploadWrapper.onSuccess = (resp) => {
                console.log(resp);
                if (resp && resp.success) {
                    CommonService.showToast(resp.msg || resp.error, 'success');
                }
            };
            uploadWrapper.onProgress = (progress) => {
            };
            setOtherFileUpload((prevState: any) => {
                let state: TsFileUploadWrapperClass[] = [];
                if (prevState) {
                    state = prevState?.wrapper;
                }
                const newState = [...state, uploadWrapper];
                return {wrapper: newState};
            });
        }
    };

    useEffect(() => {
        init();
        // getFacilityMembers();
        // getShiftDetails()
    }, [init]);


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scrollToError])

    const deleteFile = (temp: any) => {
        let data = fileUpload?.wrapper.filter((_: any, index: any) => index !== temp);
        setFileUpload((prevState) => {
            return {wrapper: [...data]};
        });
    };

    const deleteContractFile = (temp: any) => {
        let data = contractFileUpload?.wrapper.filter((_: any, index: any) => index !== temp);
        setContractFileUpload((prevState) => {
            return {wrapper: [...data]};
        });
    };

    const onAddShift = useCallback(
        (shift: any, facilityId: string) => {
            return new Promise((resolve, reject) => {
                ApiService.post(ENV.API_URL + "facility/" + id + "/shift", shift)
                    .then((resp: any) => {
                        // console.log(resp);
                        if (resp && resp.success) {
                            CommonService.showToast("Facility Shift Timing added", "info");
                            resolve(null);
                        } else {
                            reject(resp);
                        }
                    })
                    .catch((err) => {
                        // console.log(err);
                        reject(err);
                    });
            });
        },
        [id]
    );

    const onAddMember = useCallback(
        (member: any) => {
            return new Promise((resolve, reject) => {
                ApiService.post(ENV.API_URL + "facility/" + id + "/member", member)
                    .then((resp: any) => {
                        //  console.log(resp);
                        if (resp && resp.success) {
                            CommonService.showToast(resp?.msg || "Facility Member added", "info");
                            resolve(null);
                        } else {
                            reject(resp);
                        }
                    })
                    .catch((err) => {
                        //console.log(err);
                        reject(err);
                    });
            });
        },
        [id]
    );

    const onAdd = (facility: FacilityItemEditType, {
        setSubmitting,
        setErrors,
        resetForm
    }: FormikHelpers<FacilityItemEditType>) => {
        setIsFacilitySubmitting(true);
        let regionName = regions.find((item: any) => item.title === facility?.address?.region);
        // facility.coordinates = [Number(facility?.location?.coordinates?.longitude), Number(facility?.location?.coordinates?.latitude)];
        let payload = {
            ...facility,
            email: facility?.email?.toLowerCase(),
            address: {
                ...facility.address,
                region: regionName.code,
            },
            location: {
                coordinates: [Number(facility?.location?.coordinates[0]), Number(facility?.location?.coordinates[1])],
                type: "Point"
                // longitude: facilityDetails?.location?.coordinates[0],
                // latitude: facilityDetails?.location?.coordinates[1],
            },
        };

        if (isImageRemoved && fileUpload?.wrapper?.length > 0) {
            handleGetUrlForUpload();
        } else if (fileUpload?.wrapper?.length > 0) {
            handleGetUrlForUpload();
        }

        if (isContractImageRemoved && contractFileUpload?.wrapper?.length > 0) {
            handleGetUrlForContractUpload();
        } else if (contractFileUpload?.wrapper?.length > 0) {
            handleGetUrlForContractUpload();
        }

        ApiService.put(ENV.API_URL + "facility/" + id, payload)
            .then((resp: any) => {
                handleOtherAttachmentsUpload(id);
                // console.log(resp);
                if (resp && resp.success) {
                    CommonService.showToast(resp.msg || "Success", "success");
                    history.push("/facility/view/" + id);
                } else {
                    setSubmitting(false);
                    setIsFacilitySubmitting(false);
                }
            })
            .catch((err) => {
                //console.log(err);
                CommonService.handleErrors(setErrors, err);
                setSubmitting(false);
                setIsFacilitySubmitting(false);
                CommonService.showToast(err.msg || "Error", "error");
                setScrollToError(prevState => !prevState)
            });
    };

    const previewFile = useCallback(
        (index: any) => {
            setPreviewFile(fileUpload?.wrapper[index]);
            setOpen(true);
        },
        [fileUpload]
    );
    const previewContractFile = useCallback(
        (index: any) => {
            setPreviewContractFile(contractFileUpload?.wrapper[index]);
            setOpenContractFile(true);
        },
        [contractFileUpload]
    );

    const cancelPreviewFile = useCallback(() => {
        setOpen(false);
    }, []);

    const cancelContractPreviewFile = useCallback(() => {
        setOpenContractFile(false);
    }, []);


    const confirmPreviewFile = useCallback(() => {
        setOpen(false);
    }, []);

    const confirmContractPreviewFile = useCallback(() => {
        setOpenContractFile(false);
    }, []);


    useEffect(() => {
        Communications.pageTitleSubject.next("Edit Facility");
        Communications.pageBackButtonSubject.next(null);
    }, []);

    const onFileSelected = (files: File[]) => {
        for (let file of files) {
            // console.log(file)
            const uploadConfig: TsFileUploadConfig = {
                file: file,
                fileFieldName: "Data",
                uploadUrl: ENV.API_URL + "facility/add",
                allowed_types: ["jpg", "png", "csv", "pdf", "jpeg"],
                extraPayload: {expiry_date: ""},
            };
            const uploadWrapper = new TsFileUploadWrapperClass(uploadConfig, CommonService._api, (state: {
                wrapper: TsFileUploadWrapperClass
            }) => {
                // console.log(state);
                setFileUpload((prevState) => {
                    if (prevState) {
                        const index = prevState?.wrapper.findIndex((value: any) => value.uploadId === state.wrapper.uploadId);
                        prevState.wrapper[index] = state.wrapper;
                        return {wrapper: prevState.wrapper};
                    }
                    return prevState;
                });
            });
            uploadWrapper.onError = (err, heading) => {
                // console.error(err, heading);
                if (heading) {
                    CommonService.showToast(err, "error");
                }
            };
            uploadWrapper.onSuccess = (resp) => {
                // console.log(resp, "contract");
                if (resp && resp.success) {
                    CommonService.showToast(resp.msg || resp.error, "success");
                }
            };
            uploadWrapper.onProgress = (progress) => {
                // console.log('progress', progress);
            };
            setFileUpload((prevState) => {
                let state: TsFileUploadWrapperClass[] = [];
                if (prevState) {
                    state = prevState?.wrapper;
                }
                const newState = [...state, uploadWrapper];
                return {wrapper: newState};
            });
            // uploadWrapper.startUpload();
        }
        setTimeout(() => setIsImage(!isImage), 1000);
    };

    useEffect(() => {
    }, [isImage]);


    const OnContractFileSelected = (files: File[]) => {
        for (let file of files) {
            // console.log(file)
            const uploadConfig: TsFileUploadConfig = {
                file: file,
                fileFieldName: "Data",
                uploadUrl: ENV.API_URL + "facility/add",
                allowed_types: ["jpg", "png", "csv", "pdf", "jpeg"],
                extraPayload: {expiry_date: ""},
            };
            const uploadWrapper = new TsFileUploadWrapperClass(uploadConfig, CommonService._api, (state: {
                wrapper: TsFileUploadWrapperClass
            }) => {
                setContractFileUpload((prevState) => {
                    if (prevState) {
                        const index = prevState?.wrapper.findIndex((value: any) => value.uploadId === state.wrapper.uploadId);
                        prevState.wrapper[index] = state.wrapper;
                        return {wrapper: prevState.wrapper};
                    }
                    return prevState;
                });
            });
            uploadWrapper.onError = (err, heading) => {
                // console.error(err, heading);
                if (heading) {
                    CommonService.showToast(err, "error");
                }
            };
            uploadWrapper.onSuccess = (resp) => {
                // console.log(resp, "contract");
                if (resp && resp.success) {
                    CommonService.showToast(resp.msg || resp.error, "success");
                }
            };
            uploadWrapper.onProgress = (progress) => {
                // console.log('progress', progress);
            };
            setContractFileUpload((prevState) => {
                let state: TsFileUploadWrapperClass[] = [];
                if (prevState) {
                    state = prevState?.wrapper;
                }
                const newState = [...state, uploadWrapper];
                return {wrapper: newState};
            });
            // uploadWrapper.startUpload();
        }
        setTimeout(() => setContractIsImage(!isContractImage), 1000);
    };

    useEffect(() => {
    }, [isContractImage]);

    const openDeleteAttachment = useCallback((e, file: any) => {
        e.preventDefault();
        setDeleteAttachmentDetails(file);
        setIsDeleteAttachmentOpen(true);
    }, []);

    const deleteAttachment = useCallback(() => {
        console.log(deleteAttachmentDetails);
        setIsDeleteAttachment(true);
        let payload = {
            file_key: deleteAttachmentDetails?.file_key,
        };

        CommonService._api
            .delete(ENV.API_URL + "deleteAttachment", payload)
            .then((resp) => {
                setIsDeleteAttachment(false);
                CommonService.showToast(resp?.msg || "Facility Attachment Deleted", "info");
                setIsDeleted(false);
                init();
                setIsDeleteAttachmentOpen(false);
            })
            .catch((err) => {
                console.log(err);
                setIsDeleted(false);
                setIsDeleteAttachment(false);
            });
    }, [init, deleteAttachmentDetails]);

    const confirmDeleteAttachment = useCallback((file: any) => {
        setIsDeleted(true);
        deleteAttachment();
    }, [deleteAttachment]);

    const cancelDeleteAttachment = useCallback(() => {
        setIsDeleteAttachmentOpen(false);
    }, []);


    if (isLoading || isFacilityDetailsLoading) {
        return <LoaderComponent/>;
    }

    return !isFacilityDetailsLoading && !isLoading ? (
        <div className="facility-main  screen">
            <DialogComponent open={isDeleteAttachmentOpen} cancel={cancelDeleteAttachment}>
                <VitawerksConfirmComponent
                    isConfirm={isDeleteAttachment}
                    cancel={cancelDeleteAttachment}
                    confirm={confirmDeleteAttachment}
                    text1="Want to delete"
                    hcpname={`${deleteAttachmentDetails?.file_name}`}
                    groupname={""}
                    confirmationText={""}
                    notext={"Back"}
                    yestext={"Delete"}
                />
            </DialogComponent>
            <DialogComponent open={open} cancel={cancelPreviewFile} class="preview-content">
                <CustomPreviewFile cancel={cancelPreviewFile} confirm={confirmPreviewFile}
                                   previewData={previewFileData}/>
            </DialogComponent>
            <DialogComponent open={openContractFile} cancel={cancelContractPreviewFile} class="preview-content">
                <CustomPreviewFile cancel={cancelContractPreviewFile} confirm={confirmContractPreviewFile}
                                   previewData={previewContractFileData}/>
            </DialogComponent>
            <DialogComponent open={isAddOpen} cancel={cancelAdd}>
                <LeavePageConfirmationComponent cancel={cancelAdd} confirm={confirmAdd} confirmationText={""}
                                                notext={"Cancel"} yestext={"Leave"}/>
            </DialogComponent>
            <FacilityEditDetailsComponent
                OnOtherFileSelected={OnOtherFileSelected}
                openDeleteAttachment={openDeleteAttachment}
                otherDocuments={otherDocuments} setOtherDocuments={setOtherDocuments}
                onAdd={onAdd}
                regions={regions}
                isImageRemoved={isImageRemoved}
                isContractImageRemoved={isContractImageRemoved}
                facilityDetails={facilityDetails}
                deleteFacilityImage={deleteFacilityImage}
                deleteFacilityContractImage={deleteFacilityContractImage}
                previewFile={previewFile}
                previewContractFile={previewContractFile}
                fileUpload={fileUpload}
                contractFileUpload={contractFileUpload}
                OnFileSelected={onFileSelected}
                OnContractFileSelected={OnContractFileSelected}
                deleteFile={deleteFile}
                deleteContractFile={deleteContractFile}
                facilityContractData={facilityContractData}
                otherFileUpload={otherFileUpload}
            />

            <div className="facility-members mrg-top-10  custom-border">
                <p className="card-header">Facility Members</p>
                <div className="facility-add-component-container">
                    <FacilityAddComponent init={init} onAddMember={onAddMember} hcpId={id}
                                          members={facilityDetails?.members
                                          }/>
                </div>
            </div>

            <div className="facility-shift-timings mrg-top-10  custom-border">
                <p className="card-header">Shift Timings</p>
                <ShiftAddComponent init={init} timezone={timezone} onAddShift={onAddShift} facilityId={id}
                                   shiftTimings={facilityDetails?.shifts}/>
            </div>

            <div className="facility-actions mrg-top-60">
                <Tooltip title={"Cancel"}>
                    <Button size="large" variant={"outlined"} className={"normal"} onClick={openAdd} color="primary"
                            id="btn_facility_edit_submit">
                        Cancel
                    </Button>
                </Tooltip>
                <Tooltip title={"Save Changes"}>
                    <Button
                        disabled={facilitySubmitting}
                        form="facility-edit-form"
                        type="submit"
                        size="large"
                        variant={"contained"}
                        color={"primary"}
                        className={facilitySubmitting ? "has-loading-spinner pdd-left-30 pdd-right-30" : "pdd-left-30 pdd-right-30"}
                        id="btn_facility_edit_submit"
                    >
                        {facilitySubmitting ? "Saving" : "Save"}
                    </Button>
                </Tooltip>
            </div>
            <ScrollToTop smooth color="white"/>
        </div>
    ) : (
        <></>
    );
};

export default FacilityManagementEditScreen;

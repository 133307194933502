import "./FacilityDetailsComponent.scss";
import {ENV, ImageConfig} from "../../../constants";
import React, {useCallback, useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import {DateRangeOutlined} from "@material-ui/icons";
import {Box, LinearProgress, Paper, Tooltip} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {CommonService} from "../../../helpers";
import moment from "moment";
import {useLocalStorage} from "../../../components/useLocalStorage";
import {BackIcon, RightArrow} from "../../../constants/ImageConfig";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import ShiftCardComponent from "./ShiftCardComponent";

interface FacilityDetailsProps {
    selectedFacility: any;
    setSelectedFacility: any;
    setIsFacilityDetailsCardOpen: any;
    facilityNavigation: any;
    setFacilityNavigation: any;
    hcpTypes: any;
    hcpList: any;
}

const [firstDayOfMonth, lastDayOfMonth, date] = CommonService.getFirstDayAndLastDayUsingDate();

const FacilityDetailsComponent = (props: FacilityDetailsProps) => {
    const {
        selectedFacility,
        setSelectedFacility,
        setIsFacilityDetailsCardOpen,
        facilityNavigation,
        setFacilityNavigation, hcpTypes, hcpList
    } = props;
    const [dateRange, setDateRange] = useLocalStorage<any[]>("date-range-for-shift-stats", [firstDayOfMonth, lastDayOfMonth]);
    const [dateRangeForOpenShifts, setDateRangeForOpenShifts] = useLocalStorage<any[]>("dateRangeForOpenShifts", [date, lastDayOfMonth]);
    const [startDate, endDate] = dateRange;
    const [startDateForOpenShift, endDateForOpenShift] = dateRangeForOpenShifts;
    const [facilityStats, setFacilityStats] = useLocalStorage<any>("facilityStats", []);
    const [facilityOpenShiftList, setFacilityOpenShiftList] = useState<any>([]);
    const [facilityStatsForOpenShift, setFacilityStatsForOpenShift] = useState<any>([]);
    const [isFacilityStatsLoading, setIsFacilityStatsLoading] = useState<any>(true);
    const [isFacilityOpenShiftStatsLoading, setIsFacilityOpenShiftStatsLoading] = useState<any>(true);
    const [isFacilityOpenShiftListLoading, setIsFacilityOpenShiftListLoading] = useLocalStorage<any>("isFacilityOpenShiftStatsLoading", true);
    const [selectedHcpType, setSelectedHcpType] = useState<any>(null);
    const [facilityAscendingSortedOpenShiftList, setFacilityAscendingSortedOpenShiftList] = useState<any>([]);

    const getFacilityStats = useCallback(() => {
        setIsFacilityStatsLoading(true);
        const payload: any = {};
        if (dateRange[0] || dateRange[1]) {
            let startDate = moment(dateRange[0]).format("YYYY-MM-DD");
            let endDate = moment(dateRange[1]).format("YYYY-MM-DD");
            if (!dateRange[1]) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        }
        CommonService._api
            .get(ENV.API_URL + `facility/${selectedFacility?._id}/shiftStats`, payload)
            .then((resp) => {
                console.log(resp);
                setIsFacilityStatsLoading(false);
                setFacilityStats(resp.data || []);
            })
            .catch((err) => {
                setIsFacilityStatsLoading(true);
                console.log(err);
            });
        // eslint-disable-next-line
    }, [selectedFacility, dateRange]);

    const getFacilityOpenShiftStats = useCallback(() => {
        setIsFacilityOpenShiftStatsLoading(true);
        const payload: any = {};
        if (dateRangeForOpenShifts[0] || dateRangeForOpenShifts[1]) {
            let startDate = moment(dateRangeForOpenShifts[0]).format("YYYY-MM-DD");
            let endDate = moment(dateRangeForOpenShifts[1]).format("YYYY-MM-DD");
            if (!dateRangeForOpenShifts[1]) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        }
        CommonService._api
            .get(ENV.API_URL + `facility/${selectedFacility?._id}/openShiftStats`, payload)
            .then((resp) => {
                console.log(resp);
                setIsFacilityOpenShiftStatsLoading(false);
                setFacilityStatsForOpenShift(resp.data || []);
            })
            .catch((err) => {
                setIsFacilityOpenShiftStatsLoading(true);
                console.log(err);
            });
        // eslint-disable-next-line
    }, [selectedFacility, dateRangeForOpenShifts]);


    const getFacilityOpenShiftList = useCallback(() => {
        setIsFacilityOpenShiftListLoading(true);
        // setSelectedHcpType(hcpType);
        const payload: any = {};
        // payload.new_shifts=true;
        //payload.hcp_type="RN"
        if (selectedHcpType) {
            payload.hcp_type = selectedHcpType?.title;
        }
        if (dateRangeForOpenShifts[0] || dateRangeForOpenShifts[1]) {
            let startDate = moment(dateRangeForOpenShifts[0]).format("YYYY-MM-DD");
            let endDate = moment(dateRangeForOpenShifts[1]).format("YYYY-MM-DD");
            if (!dateRangeForOpenShifts[1]) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        }
        CommonService._api
            .get(ENV.API_URL + `facility/${selectedFacility?._id}/openShift`, payload)
            .then((resp) => {
                console.log(resp);
                setIsFacilityOpenShiftListLoading(false);
                setFacilityOpenShiftList(resp.data || []);
            })
            .catch((err) => {
                setIsFacilityOpenShiftListLoading(true);
                console.log(err);
            });
        // eslint-disable-next-line
    }, [selectedFacility, dateRangeForOpenShifts, selectedHcpType]);


    useEffect(() => {
        getFacilityStats();
    }, [getFacilityStats]);

    useEffect(() => {
        getFacilityOpenShiftStats();
    }, [getFacilityOpenShiftStats]);

    useEffect(() => {
        getFacilityOpenShiftList();
    }, [getFacilityOpenShiftList]);


    useEffect(() => {
        if (facilityNavigation === "facility-details") {
            setSelectedHcpType(null);
        }
    }, [facilityNavigation]);

    useEffect(() => {
        const facilitySortedList = facilityOpenShiftList.sort((a: any, b: any) => {
            const dateA = new Date(moment.utc(a?.shift_date).format('MM/DD/YYYY')).getTime();
            const dateB = new Date(moment.utc(b?.shift_date).format('MM/DD/YYYY')).getTime();
            return dateA - dateB;
        });
        setFacilityAscendingSortedOpenShiftList(facilitySortedList);
    }, [facilityOpenShiftList]);

    return (
        <>
            <div className={'facility-details-card-component'}>
                {facilityNavigation === "facility-details" && <>
                    <div className={'facility-details-header'}>
                        <div className={'header-text'}>Facility Details</div>
                        <div className={'header-icon'} onClick={() => {
                            setIsFacilityDetailsCardOpen(false);
                            setSelectedFacility(null);
                        }}>
                            <img src={ImageConfig.CrossIcon} alt=""/>
                        </div>
                    </div>
                    <div className={'facility-status-wrapper'}>
                        {selectedFacility?.is_active ? <>
                            <div className={'active-icon active'}/>
                            <div className={'status-text'}>
                                Active
                            </div>
                        </> : <>
                            <div className={'active-icon inactive'}/>
                            <div className={'status-text'}>
                                Inactive
                            </div>
                        </>}

                    </div>
                    <div className={'facility-name-wrapper'}>
                        {/*//  <div style={{display: "flex"}}>*/}
                        <div
                            className={'facility-name-text-symbol'}>{selectedFacility?.name?.charAt(0).toUpperCase()}
                        </div>
                        <Tooltip title={`${selectedFacility?.name}`}>
                            <div>
                                <div
                                    className={'facility-name-text'}
                                >{selectedFacility?.name}</div>
                            </div>
                        </Tooltip>
                        {/*</div>*/}
                    </div>
                    <div className={'basic-details-wrapper'}>
                        <div style={{width: "50%"}}>
                            <div className={'details-header-text'}>
                                Location
                            </div>
                            <div className={'details-header-text-content'}
                                 style={{width: "140px", wordWrap: "break-word"}}>
                                {selectedFacility?.address?.city || "NA"}
                            </div>
                        </div>
                    </div>
                    <div className={'basic-details-wrapper'}>
                        <div style={{flex: "1"}}>
                            <div className={'details-header-text'}>
                                Longitude
                            </div>
                            <div className={'details-header-text-content'}>
                                {selectedFacility?.location?.coordinates[0]}
                            </div>
                        </div>
                        <div style={{flex: "1"}}>
                            <div className={'details-header-text'}>
                                Latitude

                            </div>
                            <div className={'details-header-text-content'}>
                                {selectedFacility?.location?.coordinates[1]}
                            </div>
                        </div>
                    </div>
                    <hr style={{borderTop: "1px solid #97979E", opacity: '0.5', marginBottom: "10px"}}/>
                    <div className={'facility-shift-details-wrapper'}>
                        <div style={{display: "flex", justifyContent: 'space-between', marginBottom: "10px"}}>
                            <div className={'header-text'}
                                 style={{display: "flex", justifyContent: "center", alignItems: "center"}}>Shift Details
                            </div>
                            <div style={{width: "50%"}}>
                                <label className="label-wrapper">
                                    <DatePicker
                                        dateFormat="MM/dd/yyyy"
                                        placeholderText="Select Date"
                                        className="custom-input"
                                        selectsRange={true}
                                        startDate={startDate && new Date(startDate)}
                                        endDate={endDate && new Date(endDate)}
                                        onChange={(update) => {
                                            if (!update[0] && !update[1]) {
                                                setDateRange([firstDayOfMonth, lastDayOfMonth]);
                                            } else {
                                                setDateRange(update);
                                            }
                                        }}
                                        isClearable={true}
                                    />
                                    {!dateRange[0] && !dateRange[1] && (
                                        <DateRangeOutlined className="date-icon" fontSize="medium" color="action"/>
                                    )}
                                </label>
                            </div>
                        </div>
                        <div className={'basic-details-wrapper'}>
                            <div style={{flex: "1"}}>
                                <div className={'details-header-text'}>
                                    Open Shifts
                                </div>
                                <div className={'details-header-text-content'}>
                                    {isFacilityStatsLoading ? <Box sx={{display: 'flex'}}>
                                        <CircularProgress size={20}/>
                                    </Box> : facilityStats?.open}
                                </div>
                            </div>
                            <div style={{flex: "1"}}>
                                <div className={'details-header-text'}>
                                    Approved Shifts
                                </div>
                                <div className={'details-header-text-content'}>
                                    {isFacilityStatsLoading ? <Box sx={{display: 'flex'}}>
                                        <CircularProgress size={20}/>
                                    </Box> : facilityStats?.approved}
                                </div>
                            </div>
                        </div>
                        <div className={'basic-details-wrapper'}>
                            <div style={{flex: "1"}}>
                                <div className={'details-header-text'}>
                                    Pending Shifts
                                </div>
                                <div className={'details-header-text-content'}>
                                    {isFacilityStatsLoading ? <Box sx={{display: 'flex'}}>
                                        <CircularProgress size={20}/>
                                    </Box> : facilityStats?.pending}
                                </div>
                            </div>
                            <div style={{flex: "1"}}>
                                <div className={'details-header-text'}>
                                    Closed Shifts
                                </div>
                                <div className={'details-header-text-content'}>
                                    {isFacilityStatsLoading ? <Box sx={{display: 'flex'}}>
                                        <CircularProgress size={20}/>
                                    </Box> : facilityStats?.closed}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style={{borderTop: "1px solid #97979E", opacity: '0.5', marginBottom: "10px"}}/>
                    <div className={'facility-shift-details-wrapper'}>
                        <div style={{display: "flex", justifyContent: 'space-between', marginBottom: "10px"}}>
                            <div className={'header-text'}
                                 style={{display: "flex", justifyContent: "center", alignItems: "center"}}>Open
                                Shift &nbsp;<>
                                    <div>
                                        {isFacilityOpenShiftStatsLoading ? <>
                                            <Box sx={{display: 'flex'}}>
                                                <CircularProgress size={15}/>
                                            </Box>
                                        </> : <>({facilityStatsForOpenShift?.open_shifts_count})</>}
                                    </div>
                                </>
                            </div>
                            <div style={{width: "50%"}}>
                                <label className="label-wrapper">
                                    <DatePicker
                                        dateFormat="MM/dd/yyyy"
                                        placeholderText="Select Date"
                                        className="custom-input"
                                        selectsRange={true}
                                        startDate={startDateForOpenShift && new Date(startDateForOpenShift)}
                                        endDate={endDateForOpenShift && new Date(endDateForOpenShift)}
                                        onChange={(update) => {
                                            console.log(new Date(startDateForOpenShift));
                                            if (!update[0] && !update[1]) {
                                                setDateRangeForOpenShifts([date, lastDayOfMonth]);
                                            } else {
                                                setDateRangeForOpenShifts(update);
                                            }
                                        }}
                                        isClearable={true}
                                    />
                                    {!dateRangeForOpenShifts[0] && !dateRangeForOpenShifts[1] && (
                                        <DateRangeOutlined className="date-icon" fontSize="medium" color="action"/>
                                    )}
                                </label>
                            </div>
                        </div>
                    </div>
                    {!isFacilityOpenShiftStatsLoading && !isFacilityStatsLoading &&
                        <div className={'open-shift-wrapper'} onClick={() => {
                            setFacilityNavigation("open-shift-card");
                            getFacilityOpenShiftList();
                        }
                        }>
                            <div className={'header-sub-text'}>
                                Show all open shifts
                            </div>
                            <div>
                                <img src={RightArrow} alt=""/>
                            </div>
                        </div>
                    }
                </>}

                {
                    facilityNavigation === "open-shift-card" && <>
                        <div style={{display: "flex"}}>
                            <div style={{marginRight: "15px", cursor: "pointer"}} onClick={() => {
                                setFacilityNavigation('facility-details');
                            }
                            }>
                                <img src={BackIcon} alt=""/>
                            </div>
                            <div className={'header-text'}>Open Shifts</div>
                        </div>

                        <div className={'facility-name-wrapper'}>
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <div
                                    className={'facility-name-text-symbol'}>{selectedFacility?.name?.charAt(0).toUpperCase()}
                                </div>
                                <Tooltip title={`${selectedFacility?.name}`}>
                                    <div>
                                        <div
                                            className={'facility-name-text'}
                                            style={{
                                                whiteSpace: "nowrap",
                                                width: "130px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        >{selectedFacility?.name}</div>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        <div className={`form-field-left`} style={{display: "flex", justifyContent: "right"}}>
                            <div className="form-field-left-items" style={{width: "50%"}}>
                                <div className="form-field-item">
                                    <Autocomplete
                                        PaperComponent={({children}) => <Paper
                                            style={{color: "#1e1e1e"}}>{children}</Paper>}
                                        value={selectedHcpType}
                                        options={hcpTypes}
                                        getOptionLabel={(option: any) => option.title}
                                        placeholder={"Select Status"}
                                        id="input_select_status"
                                        className="mrg-top-10"
                                        onChange={($event, value) => {
                                            setSelectedHcpType(value);
                                            // getFacilityOpenShiftList()
                                        }}
                                        renderInput={(params) => <TextField {...params} id="select_status"
                                                                            variant="outlined" value={[]}
                                                                            placeholder={"Staff Types"} size={"small"}
                                                                            fullWidth/>}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{marginTop: "10px", maxHeight: "400px", overflowY: "auto",}}>
                            {!isFacilityOpenShiftListLoading && facilityAscendingSortedOpenShiftList && facilityAscendingSortedOpenShiftList?.map((shiftDetails: any, index: any) => {
                                return (
                                    <>
                                        <div key={index}>
                                            <ShiftCardComponent selectedFacility={selectedFacility}
                                                                shiftDetails={shiftDetails} hcpList={hcpList}
                                                                selectedHcpType={selectedHcpType}/>
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                        {
                            isFacilityOpenShiftListLoading && <>
                                <div style={{width: "100%"}}>
                                    <LinearProgress color="primary" {...props} />
                                </div>

                            </>
                        }
                        {
                            !isFacilityOpenShiftListLoading && facilityOpenShiftList?.length === 0 && <>
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    No Shift to show
                                </div>

                            </>
                        }
                    </>
                }
            </div>
        </>
    );
};
export default FacilityDetailsComponent;

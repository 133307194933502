import React, {PropsWithChildren} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import {TransitionProps} from '@material-ui/core/transitions';
import {ClearRounded} from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export interface DialogComponentProps {
    cancel: () => void,
    open: boolean,
    class?: string,
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    headerLabel?: any;
    isEditableDialog?:any;
}

const DialogComponent = (props: PropsWithChildren<DialogComponentProps>) => {

    const maxWidth = props.maxWidth || 'sm';
    const {headerLabel,isEditableDialog} = props;
    return (
        <Dialog
            maxWidth={maxWidth}
            open={props.open}
            TransitionComponent={Transition}
            className={'dialog-main-holder ' + (props.class || '')}
            onClose={props.cancel}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div className={"pdd-left-25 pdd-top-25 pdd-right-25 dialog-header-wrapper"}>
                    {headerLabel}
                </div>
                {!isEditableDialog &&
                    <div className={'cross-icon-dialog'}>
                        <div
                            onClick={props.cancel} className={''}>
                            <ClearRounded style={{fontSize: 30}} color={"primary"}/>
                        </div>
                    </div>}
            </div>
            {props.children}
            {/*{Component && <>*/}
            {/*    {*/}
            {/*        // @ts-ignore*/}
            {/*        ({component: Component}) => {*/}
            {/*            return (<Component confirm={confirm} cancel={cancel}/>)*/}
            {/*        }*/}
            {/*    }*/}
            {/*</>*/}
            {/*}*/}


        </Dialog>
    );
};

export default DialogComponent;

import React, {useCallback, useEffect, useState} from "react";
import {ApiService, CommonService} from "../../../../../helpers";
import {ENV} from "../../../../../constants";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import NoDataCardComponent from "../../../../../components/NoDataCardComponent";
import MenuComponent from "./MenuComponent";
import {
    TsDataListOptions,
    TsDataListState,
    TsDataListWrapperClass
} from "../../../../../classes/ts-data-list-wrapper.class";
import {useLocalStorage} from "../../../../../components/useLocalStorage";
import TablePagination from "@material-ui/core/TablePagination";
import ViewStatusComponent from "../../../../../components/status-view-component/ViewStatusComponent";

const matColumns = ["Facility", "Customer No.", "Invoice Number", "Invoice Date", "Start Date", "End Date", "Total Amount", "Status", "Action"];

interface GeneratedInvoiceListTableProps {
    status: any,
    value: any,
    selectedFacilities: any;
    startDate: any;
    endDate: any;
}

const GeneratedInvoiceListTable = (props: GeneratedInvoiceListTableProps) => {
    const [generatedInvoiceList, setGeneratedInvoiceList] = useState<TsDataListState | null>(null);
    const [isGeneratedInvoiceListLoading, setIsGeneratedInvoiceListLoading] = useState<any>(false);
    // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    // const open = Boolean(anchorEl);
    const status = props?.status;
    const {endDate} = props;
    const {startDate} = props;
    const selectedFacilities = props?.selectedFacilities;
    const [pageSizeIndex, setPageSizeIndex] = useLocalStorage<any>("GeneratedPageSizeIndex", 100);


    const invoiceList = useCallback(() => {
        console.log(isGeneratedInvoiceListLoading);
        setIsGeneratedInvoiceListLoading(true);
        let url = "invoice/list";
        let payload: any = {};
        if (status) {
            console.log(status);
            payload.status = status?.code;
        }
        if (selectedFacilities.length > 0) {
            payload.facilities = selectedFacilities.map((item: any) => item?.code);
        }
        // if (dateRange[0] || dateRange[1]) {
        //     let startDate = moment(dateRange[0]).format("YYYY-MM-DD");
        //     let endDate = moment(dateRange[1]).format("YYYY-MM-DD");
        //
        //     if (!dateRange[1]) {
        //         payload.start_date = startDate;
        //         payload.end_date = startDate;
        //     } else {
        //         payload.start_date = startDate;
        //         payload.end_date = endDate;
        //     }
        // }
        if (startDate) {
            payload.start_date = CommonService.convertToDateFromTimestamp(startDate);
        }

        if (endDate) {
            payload.end_date = CommonService.convertToDateFromTimestamp(endDate);
        }

        const options = new TsDataListOptions(
            {
                // @ts-ignore
                pagination: {
                    ...(generatedInvoiceList?.table?.pagination || {}),
                    pageSize: pageSizeIndex,
                },
                extraPayload: payload,
                // webMatColumns: role === "super_admin" ? ["Created On", "Facility Name", "Region", "Contact Number", "Active / Inactive", "Actions"] : ["Created On", "Facility Name", "Region", "Contact Number", "Status", "Actions"],
                // mobileMatColumns: role === "super_admin" ? ["Created On", "Facility Name", "Region", "Contact Number", "Active / Inactive", "Actions"] : ["Created On", "Facility Name", "Region", "Contact Number", "Status", "Actions"],
            },
            ENV.API_URL + url,
            setGeneratedInvoiceList,
            ApiService,
            "post"
        );
        let tableWrapperObj = new TsDataListWrapperClass(options);
        setGeneratedInvoiceList({table: tableWrapperObj});
        // eslint-disable-next-line
    }, [status, startDate, endDate, pageSizeIndex, selectedFacilities]);


    useEffect(() => {
        invoiceList();
    }, [invoiceList]);

    return (
        <>
            <div className="custom-border pdd-10  pdd-bottom-0">
                <h3>Invoices</h3>
                {generatedInvoiceList && generatedInvoiceList?.table && (
                    <>
                        <TableContainer component={Paper} className={"table-responsive"}>
                            <Table stickyHeader className="mat-table table open-shifts-list-table">
                                <TableHead className={"mat-thead"}>
                                    <TableRow className={"mat-tr"}>
                                        {matColumns.map((column: any, columnIndex: any) => (
                                            <TableCell
                                                className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}
                                                key={"header-col-" + columnIndex}>
                                                {column}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody className={"mat-tbody"}>
                                    {generatedInvoiceList.table.canShowNoData() &&
                                        <NoDataCardComponent tableCellCount={matColumns.length}/>}
                                    {generatedInvoiceList?.table.data.map((row: any, rowIndex: any) => {
                                        // const startDate = moment(row["start_date"]);
                                        // const endDate = moment(row["end_date"]);
                                        const start_date = CommonService.getUtcDate(row["start_date"]);
                                        const end_date = CommonService.getUtcDate(row["end_date"]);
                                        const totalAmount = Number(row["total_amount"] || 0) + (Number(row["finance_charge"]?.amount) ? Number(row["finance_charge"]?.amount) : 0);
                                        return (
                                            <TableRow role="checkbox" tabIndex={-1} key={"row-" + rowIndex}
                                                      className={"mat-tr"}>
                                                <TableCell
                                                    className="mat-td mat-td-title">{row["facility"]?.name}</TableCell>
                                                <TableCell
                                                    className="mat-td mat-td-facility-name">{row["facility"]?.uid}</TableCell>
                                                <TableCell
                                                    className="mat-td mat-td-hcp-type">{row["invoice_number"]}</TableCell>
                                                <TableCell
                                                    className="mat-td mat-td-hcp-count">
                                                    {row["invoice_generated_date"] &&
                                                        <>
                                                            {CommonService.getUtcDate(row["invoice_generated_date"])}
                                                        </>}
                                                    {!row["invoice_generated_date"] &&
                                                        <>
                                                            {CommonService.getUtcDate(row["created_at"])}
                                                        </>}

                                                </TableCell>
                                                <TableCell style={{minWidth: "150px"}}
                                                           className="mat-td mat-td-hcp-count">
                                                    {start_date}
                                                </TableCell>
                                                <TableCell
                                                    className="at-td mat-td-hcp-count">
                                                    {end_date}
                                                </TableCell>
                                                {row["type"] === "finance_invoice" && <TableCell
                                                    className="mat-td mat-td-shift-type">{row["finance_charge"]?.amount ? Number(row["finance_charge"]?.amount)?.toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: 'USD',
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }) : "_"}</TableCell>}
                                                {row["type"] !== "finance_invoice" &&
                                                    <TableCell
                                                        className="mat-td mat-td-shift-type">{totalAmount ? totalAmount?.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }) : "$0.00"}</TableCell>}
                                                <TableCell
                                                    className={`${row["status"]} mat-td mat-td-status`}>
                                                    {row["status"] === "paid" &&
                                                        <ViewStatusComponent show={true} backgroundColor={"#BDE8CA"}
                                                                             textColor={"#00534F"}>  {row["status"]}</ViewStatusComponent>}
                                                    {row["status"] === "unpaid" &&
                                                        <ViewStatusComponent show={true} backgroundColor={"#FFF7EA"}
                                                                             textColor={"#DE561C"}>  {row["status"]}</ViewStatusComponent>}
                                                    {row["status"] === "cancelled" &&
                                                        <ViewStatusComponent show={true} backgroundColor={"#FFE2E9"}
                                                                             textColor={"#DE1C4E"}>  {row["status"]}</ViewStatusComponent>}
                                                </TableCell>
                                                <TableCell className="mat-td mat-td-sticky mat-td-actions">
                                                    <MenuComponent rowStatus={row["status"]} rowId={row["_id"]}
                                                                   invoiceList={invoiceList}/>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[100, 200, 300, 400, 500]}
                            component="div"
                            count={generatedInvoiceList?.table.pagination.totalItems}
                            rowsPerPage={generatedInvoiceList?.table.pagination.pageSize}
                            page={generatedInvoiceList?.table.pagination.pageIndex}
                            onPageChange={(event, page) => generatedInvoiceList?.table.pageEvent(page)}
                            onRowsPerPageChange={(event) => {
                                setPageSizeIndex(event.target.value);
                                generatedInvoiceList.table?.pageEvent(0, +event.target.value);
                            }}
                        />
                    </>
                )}
            </div>
        </>
    );
};
export default GeneratedInvoiceListTable;
import React, {useCallback, useEffect, useState} from "react";
import DialogComponent from "../../../../components/DialogComponent";
import NoDataToShowCardComponent from "../../../../components/NoDataToShowCardComponent";
import CustomPreviewFile from "../../../../components/shared/CustomPreviewFile";
import {americanTimeZone} from "../../../../constants/data";
import {Button, Paper} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import {Link, useParams} from "react-router-dom";
import AccessControlComponent from "../../../../components/AccessControl";
import {ACCOUNTMANAGER, ADMIN, FINANCE, HUMANRESOURCE, NURSECHAMPION} from "../../../../helpers/common-service";
import {PdfIcon} from "../../../../constants/ImageConfig";
import {CommonService} from "../../../../helpers";
import {ENV} from "../../../../constants";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableViewCustomFile from "./table-view-for-uploaded-file/TableViewCustomFile";


const webMatColumns = ["Document Name", "Download"];
const FacilityBasicDetailsComponent = (props: any) => {
    const facilityDetails = props?.facilityDetails;
    const init = props?.init;
    const [timeZone, setTimeZone] = useState<any>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [openContract, setOpenContract] = useState<boolean>(false);
    const params = useParams<{ id: string }>();
    const {id} = params;
    const [isDownload, setIsDownloading] = useState<boolean>(false);
    // const [facilityContractData, setFacilityContractData] = useState<any>([]);

    const previewFile = useCallback((index: any) => {
        setOpen(true);
    }, []);

    const cancelPreviewFile = useCallback(() => {
        setOpen(false);
    }, []);
    const confirmPreviewFile = useCallback(() => {
        setOpen(false);
    }, []);

    const previewContractFile = useCallback((index: any) => {
        setOpenContract(true);
    }, []);

    const cancelContractPreviewFile = useCallback(() => {
        setOpenContract(false);
    }, []);
    const confirmContractPreviewFile = useCallback(() => {
        setOpenContract(false);
    }, []);

    useEffect(() => {
        for (let i = 0; i < americanTimeZone?.length; i++) {
            if (americanTimeZone[i]?.value === facilityDetails?.timezone) {
                setTimeZone(americanTimeZone[i]?.label);
            }
        }
    }, [facilityDetails]);

    // const getFacilityContract = useCallback(() => {
    //     CommonService._api.get(ENV.API_URL + `facility/${id}/contract?is_preview=true`).then((resp) => {
    //         setFacilityContractData(resp?.data[0]);
    //         //    console.log(resp);
    //     })
    //         .catch((err) => {
    //             //   console.log(err);
    //         });
    // }, [id]);

    // useEffect(() => {
    //     getFacilityContract();
    // }, [getFacilityContract]);
    const handleDownload = useCallback((file_key: any) => {
        setIsDownloading(true);
        let payload = {
            file_key: file_key,
        };
        CommonService._api.post(ENV.API_URL + "downloadAttachment", payload).then((resp) => {
            const link = document.createElement('a');
            link?.setAttribute('href', resp?.data);
            document.body.appendChild(link);
            link.click();
            setIsDownloading(false);
        }).catch((err) => {
            console.log(err);
            setIsDownloading(false);
        });
    }, []);


    useEffect(() => {
        init();
    }, [init]);

    // const StyledLoader = () => {
    //     return (
    //         <div className="pdd-20" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
    //             <LoaderComponent position="block"/>
    //         </div>
    //     );
    // };

    return (
        <>
            <DialogComponent open={open} cancel={cancelPreviewFile} class="preview-content">
                <CustomPreviewFile cancel={cancelPreviewFile} confirm={confirmPreviewFile}
                                   previewData={facilityDetails?.profile_url}/>
            </DialogComponent>
            <DialogComponent open={openContract} cancel={cancelContractPreviewFile} class="preview-content">
                <CustomPreviewFile cancel={cancelContractPreviewFile} confirm={confirmContractPreviewFile}
                                   previewData={facilityDetails?.contract_details}/>
            </DialogComponent>
            <div className="fac-edit-btn">
                <AccessControlComponent role={[ADMIN, NURSECHAMPION, HUMANRESOURCE, ACCOUNTMANAGER, FINANCE]}>
                    <Tooltip title={`Edit ${facilityDetails?.name}`}>
                        <Button variant={"contained"} color={"primary"} component={Link} to={`/facility/edit/` + id}>
                            Edit Facility
                        </Button>
                    </Tooltip>
                </AccessControlComponent>
            </div>
            <div className="custom-border">
                <div className="basic_details">
                    <div className="d-flex title-rating-container edit-facility-wrapper">
                        <h3>Basic Details</h3>
                        <div className="rating-container">
                            <p className="rating-title">Average Rating</p>

                            <p className="rating-content">{facilityDetails?.rating ? `${Math.round(facilityDetails?.rating * 100) / 100}/5` : "N/A"}</p>
                        </div>
                    </div>
                    <div className="d-flex ">
                        <div className="flex-1 ">
                            <h4>Facility Name</h4>
                            <p>{facilityDetails?.name}</p>
                        </div>
                        <div className="flex-1">
                            <h4>Business Name</h4>
                            <p>{facilityDetails?.business_name}</p>
                        </div>
                        <div className="flex-1">
                            <h4>Facility Unique Id</h4>
                            <p>{facilityDetails?.uid}</p>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="flex-1">
                            <h4>Facility Short Name</h4>
                            <p>{facilityDetails?.short_name}</p>
                        </div>
                        <div className="flex-1">
                            <h4>Region Name</h4>
                            <p>{facilityDetails?.address?.region}</p>
                        </div>
                        <div className="flex-1">
                            <h4>Email</h4>
                            <p>{facilityDetails?.email}</p>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="flex-1">
                            <h4>Contact Number</h4>
                            <p>{facilityDetails?.contact_number}</p>
                        </div>
                        <div className="flex-1">
                            <h4>Extension Number</h4>
                            <p>{facilityDetails?.extension_number ? facilityDetails?.extension_number : "N/A"}</p>
                        </div>
                        <div className="flex-1">
                            <h4>Website</h4>
                            <p>{facilityDetails?.website_url}</p>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="flex-1">
                            <h4>Street</h4>
                            <p>{facilityDetails?.address?.street}</p>
                        </div>
                        <div className="flex-1">
                            <h4>City</h4>
                            <p>{facilityDetails?.address?.city}</p>
                        </div>
                        <div className="flex-1">
                            <h4>State</h4>
                            <p>{facilityDetails?.address?.state}</p>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="flex-1">
                            <h4>Country</h4>
                            <p>{facilityDetails?.address?.country}</p>
                        </div>
                        <div className="flex-1">
                            <h4>Zipcode</h4>
                            <p>{facilityDetails?.address?.zip_code}</p>
                        </div>
                        <div className="flex-1">
                            <h4>TimeZone</h4>
                            <p>{timeZone}</p>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="flex-1">
                            <h4>Latitude</h4>
                            <p>{facilityDetails?.location?.coordinates[1]}</p>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="flex-1">
                            <h4>Longitude</h4>
                            <p>{facilityDetails?.location?.coordinates[0]}</p>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="flex-1">
                            <h4>SNF Parent Company</h4>
                            <p>{facilityDetails?.parent_company}</p>
                        </div>
                    </div>
                    <div>
                        <h4>About</h4>
                        <p className="summary">{facilityDetails?.about}</p>
                    </div>
                </div>
            </div>
            {facilityDetails?.attachments ? (
                <div className="custom-border mrg-top-20 pdd-20">
                    <h3>Attachments/Documents</h3>
                    {facilityDetails?.attachments?.length === 0 && (
                        <p>
                            <NoDataToShowCardComponent/>
                        </p>
                    )}
                    {facilityDetails?.attachments?.length !== 0 &&
                        <TableContainer component={Paper} className={"doc-table-responsive"}>
                            <Table size={"small"} stickyHeader
                                   className="mat-table table shifts-requirment-pending-list-table">
                                <TableHead className={"mat-thead"}>
                                    <TableRow className={"mat-tr"}>
                                        {webMatColumns.map((column: any, columnIndex: any) => (
                                            <TableCell
                                                className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}
                                                key={"header-col-" + columnIndex}>
                                                {column}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody className={"mat-tbody"}>
                                    {facilityDetails?.attachments?.map((item: any, index: any) => {
                                        return (<>
                                                <TableViewCustomFile data={item} handleDownload={handleDownload}
                                                                     isDownload={isDownload}/>
                                            </>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                </div>
            ) : (
                <div className="custom-border mrg-top-10 pdd-20 pdd-left-40 pdd-right-40">
                    <h3>Attachments/Documents</h3>
                    <NoDataToShowCardComponent/>
                </div>
            )}
            <div className="custom-border mrg-top-10 pdd-top-10">
                <h3 className="card-header">Facility Image</h3>
                <div className="d-flex" style={{gap: "50px"}}>
                    {facilityDetails?.profile_url ? (
                        <div className="attachments" style={{cursor: "pointer"}}>
                            <Tooltip title="Preview Facility Icon">
                                <>
                                    <img onClick={previewFile} src={facilityDetails?.profile_url} alt=""
                                         style={{height: "100px", width: "100px"}}/>
                                    <p onClick={() => handleDownload(facilityDetails?.profile_key)}
                                       className={"download-link"} style={{
                                        color: "#10C4D3FF",
                                        cursor: 'pointer',
                                        marginTop: "5px",
                                        marginLeft: "5px"
                                    }}>Download</p>
                                </>
                            </Tooltip>
                        </div>
                    ) : (
                        <div style={{width: "100%"}}>
                            <NoDataToShowCardComponent/>
                        </div>
                    )}
                </div>
            </div>
            <div className="custom-border mrg-top-10 pdd-top-10">
                <h3 className="card-header">Facility Contract</h3>
                <div className="d-flex" style={{gap: "50px"}}>
                    {facilityDetails?.contract_details?.url ? (
                        <div className="attachments" style={{cursor: "pointer"}}>
                            <Tooltip title="Preview Facility Icon">
                                {facilityDetails?.contract_details?.ContentType === "image/png" || facilityDetails?.contract_details?.ContentType === "image/jpg" || facilityDetails?.contract_details?.ContentType === "image/jpeg" ? <>
                                    <img src={facilityDetails?.contract_details?.url} alt=""
                                         onClick={previewContractFile}
                                         style={{height: "100px", width: "100px"}}
                                    />
                                    <p onClick={() => handleDownload(facilityDetails?.contract_details?.file_key)}
                                       className={"download-link"} style={{
                                        color: "#10C4D3FF",
                                        cursor: 'pointer',
                                        marginTop: "5px",
                                        marginLeft: "5px"
                                    }}>Download</p>

                                </> : <>
                                    <img src={PdfIcon} alt="" onClick={previewContractFile}
                                         style={{height: "100px", width: "100px"}}/>
                                    <p onClick={() => handleDownload(facilityDetails?.contract_details?.file_key)}
                                       className={"download-link"} style={{
                                        color: "#10C4D3FF",
                                        cursor: 'pointer',
                                        marginTop: "5px",
                                        marginLeft: "5px"
                                    }}>Download</p>
                                </>}
                            </Tooltip>
                        </div>
                    ) : (
                        <div style={{width: "100%"}}>
                            <NoDataToShowCardComponent/>
                        </div>
                    )}
                </div>
            </div>
            <div className="basic_details custom-border mrg-top-10">
                <h3>Other Details</h3>
                <div className="d-flex">
                    <div className="flex-1">
                        <h4>SLP Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.slp ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Scrub Tech Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.scrub_tech ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>CNA Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.cna ?? "N/A"}</p>
                    </div>
                </div>

                <div className="d-flex">
                    <div className="flex-1">
                        <h4>Clinical Social Worker Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.clinical_social_worker
                            ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Marriage Family Therapist Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.marriage_family_therapist ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Medical Assistants Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.medical_assistant ?? "N/A"}</p>
                    </div>
                </div>

                <div className="d-flex">
                    <div className="flex-1">
                        <h4>Pharmacist Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.pharmacist ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Pharmacy Technician Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.pharmacy_technician ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Psychologist Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.psychologist ?? "N/A"}</p>
                    </div>
                </div>


                <div className="d-flex">
                    <div className="flex-1">
                        <h4>LVN Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.lvn ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>RN Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.rn ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Care Giver Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.care_giver ?? "N/A"}</p>
                    </div>
                </div>


                <div className="d-flex">

                    <div className="flex-1">
                        <h4>Med Tech Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.med_tech ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>SPD Tech Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.spd_tech ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>DSP Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.dsp ?? "N/A"}</p>
                    </div>
                </div>

                <div className="d-flex">

                    <div className="flex-1">
                        <h4>PSS Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.pss ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>CLS Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.cls ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>CRMA Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.crma ?? "N/A"}</p>
                    </div>

                </div>

                <div className="d-flex">

                    <div className="flex-1">
                        <h4>RN Supervisor Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.rn_supervisor ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Care Giver DSP Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.caregiver_dsp ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Care Giver PSS Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.caregiver_pss ?? "N/A"}</p>
                    </div>

                </div>

                <div className="d-flex">

                    <div className="flex-1">
                        <h4>Care Giver CRMA Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.caregiver_crma ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>RN Speciality 1 Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.rn_speciality1 ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>RN Speciality 2 Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.rn_speciality2 ?? "N/A"}</p>
                    </div>

                </div>


                <div className="d-flex">

                    <div className="flex-1">
                        <h4>RN Travel Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.rn_travel ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>EMT Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.emt ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Monitor Tech Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.monitor_tech ?? "N/A"}</p>
                    </div>

                </div>

                <div className="d-flex">

                    <div className="flex-1">
                        <h4>ORST Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.orst ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Radiology Tech Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.radiology_tech ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>LPN Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.lpn ?? "N/A"}</p>
                    </div>

                </div>

                <div className="d-flex">

                    <div className="flex-1">
                        <h4>NOC Diff Rate ($/hr)</h4>
                        <p>{facilityDetails?.diff_rates?.noc ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>PM Rate ($ / hr)</h4>
                        <p>{facilityDetails?.diff_rates?.pm ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Weekend Rate ($/hr)</h4>
                        <p>{facilityDetails?.diff_rates?.weekend ?? "N/A"}</p>
                    </div>

                </div>


                <div className="d-flex">

                    <div className="flex-1">
                        <h4>Holiday Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.holiday ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>OT Hours (hr / day)</h4>
                        <p>{facilityDetails?.conditional_rates?.overtime?.hours ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>OT Rate ($/hr)</h4>
                        <p>{facilityDetails?.conditional_rates?.overtime?.rate ?? "N/A"}</p>
                    </div>

                </div>

                <div className="d-flex">

                    <div className="flex-1">
                        <h4>Hazard Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.hazard ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Rush Hours</h4>
                        <p>{facilityDetails?.conditional_rates?.rush?.hours ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Rush Rate ($/hr)</h4>
                        <p>{facilityDetails?.conditional_rates?.rush?.rate ?? "N/A"}</p>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="flex-1">
                        <h4>Cancellation Before Hours</h4>
                        <p>{facilityDetails?.conditional_rates?.cancellation_before?.hours ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Cancellation Before Rate ($/hr)</h4>
                        <p>{facilityDetails?.conditional_rates?.cancellation_before?.rate ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Shift Early Completion Hours</h4>
                        <p>{facilityDetails?.conditional_rates?.shift_early_completion?.hours ?? "N/A"}</p>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="flex-1">
                        <h4>Shift Early Completion Rate ($/hr)</h4>
                        <p>{facilityDetails?.conditional_rates?.shift_early_completion?.rate ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Physical Therapist Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.physical_therapist ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Occupational Therapist Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.occupational_therapist ?? "N/A"}</p>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="flex-1">
                        <h4>CHHA Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.chha ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>CNA/CHHA Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.cna_chha ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Evaluation (RN/OT/PT/ST) Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.evaluation ?? "N/A"}</p>
                    </div>
                </div>


                <div className="d-flex">
                    <div className="flex-1">
                        <h4>Start of Care (RN/OT/PT/ST) Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.start_of_care ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Routine visit (RN) Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.rn_routine_vist ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Routine visit (LVN) Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.lvn_routine_vist ?? "N/A"}</p>
                    </div>
                </div>
                <div className="d-flex">

                    <div className="flex-1">
                        <h4>Routine visit (OT/PT/ST) Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.ot_pt_st_routine_vist ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Routine visit (OTA/PTA/STA) Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.ota_pta_sta_routine_vist ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Discharge Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.discharge ?? "N/A"}</p>
                    </div>
                </div>

                <div className="d-flex">

                    <div className="flex-1">
                        <h4>Recertification Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.recertification ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Resumption of Care Rate ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.resumption_of_care ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Routine visit (CNA)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.cna_routine_vist ?? "N/A"}</p>
                    </div>

                </div>
                <div className="d-flex">
                    <div className="flex-1">
                        <h4>Routine visit (CareGiver/CHHA)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.caregiver_chha_routine_vist ?? "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>BSW ($/hr)</h4>
                        <p>{facilityDetails?.hourly_base_rates?.bsw ?? "N/A"}</p>
                    </div>
                </div>

            </div>
        </>
    );
};

export default FacilityBasicDetailsComponent;

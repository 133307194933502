import {Button, Checkbox, Tooltip} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import {SearchRounded} from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import moment from "moment";
import React, {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {
    TsDataListOptions,
    TsDataListState,
    TsDataListWrapperClass
} from "../../../../classes/ts-data-list-wrapper.class";
import DialogComponent from "../../../../components/DialogComponent";
import RejectShiftComponent from "../../rejectShift/RejectShiftComponent";
import LoaderComponent from "../../../../components/LoaderComponent";
import NoDataCardComponent from "../../../../components/NoDataCardComponent";
import {useLocalStorage} from "../../../../components/useLocalStorage";
import VitawerksConfirmComponent from "../../../../components/VitawerksConfirmComponent";
import {ENV} from "../../../../constants";
import {ApiService, CommonService, Communications} from "../../../../helpers";
import ShiftFilter from "../../filters/ShiftFilter";
import "./CompletedShiftsListScreen.scss";
import {CssTextField} from "../../../../constants/data/styles";
import PendingConcernConfirmationBox from "./pending-concern-confirm/PendingConcernConfirmationBox";
import WarningIcon from "@material-ui/icons/Warning";
import {useSelector} from "react-redux";
import {StateParams} from "../../../../store/reducers";


const CompletedShiftsListScreen = () => {
    const [list, setList] = useState<TsDataListState | null>(null);
    // const [hcpTypes, setHcpTypes] = useState<any | null>(null);
    const {hcpTypes} = useSelector((state: StateParams) => state.meta)
    const [facilityList, setFacilityList] = useState<any | null>(null);
    // const [regions, setRegions] = useState<any>([]);
    const {regions} = useSelector((state: StateParams) => state.meta)
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [selectedRegion, setSelectedRegion] = useLocalStorage<any[]>("selectedRegion", []);
    const [selectedHcps, setSelectedHcps] = useLocalStorage<any[]>("selectedHcps", []);
    const [selectedFacilities, setSelectedFacilities] = useLocalStorage<any[]>("selectedFacilities", []);
    const [selectedSalaryCreditTypes, setSelectedSalaryCreditTypes] = useLocalStorage<any[]>("selectedSalaryCreditTypes", []);
    const [selectedTimeTypes, setSelectedTimeTypes] = useLocalStorage<any[]>("selectedTimeTypes", []);
    const [selectedApprovedHcps, setSelectedApprovedHcps] = useLocalStorage<any[]>("selectedApprovedHcps", []);
    const [dateRange, setDateRange] = useLocalStorage<any[]>("dateRange", [null, null]);
    const [isTimeSheetUploaded, setIsTimeSheetUploaded] = useLocalStorage<any>("isTimeSheetUploaded", "");
    const [isFacilityApproved, setIsFacilityApproved] = useLocalStorage<any>("isFacilityApproved", "");
    const [isPendingConcern, setIsPendingConcern] = useLocalStorage<any>("isPendingConcernForCompletedShift", "");
    const [hcpPaymentModeValue, setHcpPaymentModeValue] = useLocalStorage<any>("hcpPaymentModeValue", '');
    const [isHcpSentHome, setIsHcpSentHome] = useLocalStorage<any>("isHcpSentHome", "");
    const [isFacilityListLoading, setIsFacilityListLoading] = useState<boolean>(false);
    const [pageSizeIndex, setPageSizeIndex] = useLocalStorage<any>("shiftCompletedPageSizeIndex", 10);
    const [isAddOpen, setIsAddOpen] = useState<boolean>(false);
    const [isConfirm, setIsConfirm] = useState<boolean>(false);
    const [shiftId, setShiftId] = useState<string>("");
    const [selectedShifts, setSelectedShifts] = useState<any>([]);
    const [selectedCompletedShiftWithFacilityAndTimeline, setSelectedCompletedShiftWithFacilityAndTimeline] = useState<any>([]);
    const [isAllselected, setAllSelected] = useState<boolean>(false);
    const [selectedCount, setSelectedCount] = useState<any>(-1);
    const [isRejectShiftOpen, setRejectShiftOpen] = useState<boolean>(false);
    const [isPendingConcernOpen, setIsPendingConcernOpen] = useState<boolean>(false);
    const [shiftSearch, setShiftSearch] = useState<string>("");
    const [shiftRequirementType, setRequirementType] = useLocalStorage<any>("completed-shift-requirement-type", "facility");
    const handleDownload = useCallback(() => {
        setIsDownloading(true);
        let payload: any = {};
        if (selectedRegion.length > 0) {
            payload.regions = selectedRegion;
        }
        if (selectedFacilities.length > 0) {
            payload.facilities = selectedFacilities.map((item: any) => item?.code);
        }
        if (selectedHcps.length > 0) {
            payload.hcp_types = selectedHcps;
        }
        if (selectedApprovedHcps.length > 0) {
            payload.hcp_user_ids = selectedApprovedHcps.map((item: any) => item?.code);
        }

        payload.status = ['complete'];

        if (isTimeSheetUploaded.value !== "") {
            payload.is_timesheet_uploaded = isTimeSheetUploaded?.value;
        }

        if (isFacilityApproved.value !== "") {
            payload.is_facility_approved = isFacilityApproved?.value;
        }

        if (isPendingConcern.value !== "") {
            payload.is_pending_concern = isPendingConcern?.value;
        }

        if (hcpPaymentModeValue.value !== "") {
            payload.gusto_type = hcpPaymentModeValue?.value;
        }

        if (shiftRequirementType?.value !== '') {
            payload.type = shiftRequirementType?.value;
        }

        if (selectedSalaryCreditTypes.length > 0) {
            payload.salary_credit = selectedSalaryCreditTypes.map(item => item.code);
        }

        if (shiftSearch !== "") {
            payload.search = shiftSearch;
        }

        if (dateRange[0] || dateRange[1]) {
            let startDate = moment(dateRange[0]).format("YYYY-MM-DD");
            let endDate = moment(dateRange[1]).format("YYYY-MM-DD");
            if (!dateRange[1]) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        }
        if (selectedTimeTypes.length > 0) {
            payload.shift_types = selectedTimeTypes;
        }


        if (isHcpSentHome.value !== "") {
            payload.is_sent_home = isHcpSentHome?.value;
        }


        ApiService.post(ENV.API_URL + "shift/download", payload)
            .then((res) => {
                if (res?.data) {
                    const link = document.createElement("a");
                    link?.setAttribute("href", res?.data);
                    document.body.appendChild(link);
                    link.click();
                    setIsDownloading(false);
                } else {
                    CommonService.showToast(res?.msg || "No Data to Download", "info");
                    setIsDownloading(false);
                }
            })
            .catch((err) => {
                setIsDownloading(false);
                CommonService.showToast(err?.msg || "Error", "error");
            });
    }, [selectedRegion, dateRange, selectedFacilities, selectedHcps, selectedSalaryCreditTypes, selectedApprovedHcps, isHcpSentHome, selectedTimeTypes, isFacilityApproved, isTimeSheetUploaded, shiftSearch, isPendingConcern, hcpPaymentModeValue, shiftRequirementType]);

    const isSelected = useCallback((_id: any) => {
        if (selectedShifts?.indexOf(_id) !== -1) {
            return true;
        } else {
            return false;
        }

    }, [selectedShifts]);

    const handleSelectAll = (event: any) => {
        if (event.target.checked === true) {
            let temp: any[] = [];
            let temp2: any[] = [];
            list?.table?.data?.forEach((item: any) => {
                let index = selectedShifts?.indexOf(item?._id);
                if (index === -1) {
                    temp.push(item._id);
                }
                if (item?.is_timesheet_uploaded && item?.is_facility_approved && !item?.is_pending_concern) {
                    let index1 = selectedCompletedShiftWithFacilityAndTimeline?.indexOf(item?._id);
                    if (index1 === -1) {
                        temp2.push(item?._id);
                    }
                }
            });
            setSelectedShifts([...selectedShifts, ...temp]);
            setSelectedCompletedShiftWithFacilityAndTimeline([...selectedCompletedShiftWithFacilityAndTimeline, ...temp2]);
            setSelectedCount(1);
        } else {
            list?.table?.data?.forEach((item: any) => {
                let index = selectedShifts?.indexOf(item?._id);
                selectedShifts.splice(index, 1);
                if (item?.is_timesheet_uploaded && item?.is_facility_approved) {
                    let index1 = selectedCompletedShiftWithFacilityAndTimeline?.indexOf(item?._id);
                    selectedCompletedShiftWithFacilityAndTimeline.splice(index1, 1);
                }
            });
            setSelectedShifts([...selectedShifts]);
            setSelectedCompletedShiftWithFacilityAndTimeline([...selectedCompletedShiftWithFacilityAndTimeline]);
            setSelectedCount(selectedShifts?.length === 0 ? -1 : selectedShifts?.length);
        }
        setAllSelected(event.target.checked);
    };

    const handleSelectShifts = useCallback((event: any, _id: any, item: any) => {
        if (event.target.checked === true) {
            if (item?.is_timesheet_uploaded && item?.is_facility_approved && !item?.is_pending_concern) {
                setSelectedCompletedShiftWithFacilityAndTimeline([...selectedCompletedShiftWithFacilityAndTimeline, _id]);
            }
            setSelectedShifts([...selectedShifts, _id]);
            setSelectedCount(1);
        } else {
            let tempSelectedShifts = selectedShifts?.filter((item: any) => item !== _id);
            setSelectedShifts([...tempSelectedShifts]);
            if (tempSelectedShifts?.length === 0) {
                setSelectedCount(-1);
            }
            if (item?.is_timesheet_uploaded && item?.is_facility_approved) {
                let tempSelectedShiftsFacilityAndTimelineApprovedShifts = selectedCompletedShiftWithFacilityAndTimeline?.filter((item: any) => item !== _id);
                setSelectedCompletedShiftWithFacilityAndTimeline([...tempSelectedShiftsFacilityAndTimelineApprovedShifts]);
            }
        }
    }, [selectedShifts, selectedCompletedShiftWithFacilityAndTimeline]);

    // const getHcpTypes = useCallback(() => {
    //     CommonService._api
    //         .get(ENV.API_URL + "meta/hcp-types")
    //         .then((resp) => {
    //             setHcpTypes(resp.data || []);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }, []);

    // const getRegions = useCallback(() => {
    //     CommonService._api
    //         .get(ENV.API_URL + "meta/hcp-regions")
    //         .then((resp) => {
    //             setRegions(resp.data || []);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }, []);

    const getFacilityData = useCallback(() => {
        setIsFacilityListLoading(true);
        let payload: any = {};
        if (selectedRegion.length > 0) {
            const selectedRegions = selectedRegion;
            payload.regions = selectedRegions;
        }
        ApiService.post(ENV.API_URL + "facility/lite", payload)
            .then((res) => {
                setFacilityList(res?.data || []);
                setIsFacilityListLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsFacilityListLoading(false);
            });
    }, [selectedRegion]);

    useEffect(() => getFacilityData(), [selectedRegion, getFacilityData]);

    const handleCloseShift = useCallback(
        (shiftId: string) => {
            setIsConfirm(true);
            ApiService.patch(ENV.API_URL + "shift/" + shiftId + "/close")
                .then((resp: any) => {
                    CommonService.showToast(resp?.msg || "Shift has been moved from complete to closed", "success");
                    setIsConfirm(false);
                    setIsAddOpen(false);
                    list?.table.reload();
                })
                .catch((err) => {
                    console.log(err);
                    CommonService.showToast(err.error || "Someting went wrong", "error");
                    setIsConfirm(false);
                    setIsAddOpen(false);
                });
        },
        [list?.table]
    );

    const handleCloseShiftTimelineAndFacilityApproved = useCallback(
        () => {
            console.log(selectedCompletedShiftWithFacilityAndTimeline);
            setIsConfirm(true);
            const payloadFacilityAndTimelineApproved = {
                shift_ids: selectedCompletedShiftWithFacilityAndTimeline
            };
            ApiService.patch(ENV.API_URL + "shift/close", payloadFacilityAndTimelineApproved)
                .then((resp: any) => {
                    CommonService.showToast(resp?.msg || "Shift has been moved from complete to closed", "success");
                    setIsConfirm(false);
                    setIsAddOpen(false);
                    setSelectedCompletedShiftWithFacilityAndTimeline([]);
                    list?.table.reload();
                })
                .catch((err) => {
                    console.log(err);
                    CommonService.showToast(err.error || "Someting went wrong", "error");
                    setIsConfirm(false);
                    setIsAddOpen(false);
                });
        },
        [list?.table, selectedCompletedShiftWithFacilityAndTimeline]
    );

    const init = useCallback(() => {
        let url = "shift/complete";
        let payload: any = {};

        payload.status = "complete";

        if (selectedRegion.length > 0) {
            payload.regions = selectedRegion;
        }

        if (selectedFacilities.length > 0) {
            payload.facilities = selectedFacilities.map((item: any) => item?.code);
        }

        if (selectedApprovedHcps.length > 0) {
            payload.hcp_user_ids = selectedApprovedHcps.map((item: any) => item?.code);
        }

        if (selectedHcps.length > 0) {
            payload.hcp_types = selectedHcps;
        }
        if (isTimeSheetUploaded?.value !== "") {
            payload.is_timesheet_uploaded = isTimeSheetUploaded?.value;
        }

        if (isFacilityApproved.value !== "") {
            payload.is_facility_approved = isFacilityApproved?.value;
        }

        if (isPendingConcern.value !== "") {
            payload.is_pending_concern = isPendingConcern?.value;
        }

        if (hcpPaymentModeValue.value !== "") {
            payload.gusto_type = hcpPaymentModeValue?.value;
        }

        if (isHcpSentHome.value !== "") {
            payload.is_sent_home = isHcpSentHome?.value;
        }

        if (shiftRequirementType?.value !== '') {
            payload.type = shiftRequirementType?.value;
        }

        if (dateRange[0] || dateRange[1]) {
            let startDate = moment(dateRange[0]).format("YYYY-MM-DD");
            let endDate = moment(dateRange[1]).format("YYYY-MM-DD");

            if (!dateRange[1]) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        }

        if (selectedTimeTypes.length > 0) {
            payload.shift_types = selectedTimeTypes;
        }


        if (selectedSalaryCreditTypes.length > 0) {

            payload.salary_credit = selectedSalaryCreditTypes.map(item => item.code);


        }


        const options = new TsDataListOptions(
            {
                // @ts-ignore
                pagination: {
                    ...list?.table?.pagination,
                    pageSize: pageSizeIndex,
                },
                extraPayload: payload,
                webMatColumns: ["Title", "Facility Name", "Staff Name", "Type of hcp", "Shift Type", "Shift Date", "Staff Sent Home", "Timesheet", "Facility Confirmation", "Close Shift", "Actions"],
                mobileMatColumns: ["Title", "Facility Name", "Staff Name", "Type of hcp", "Shift Type", "Shift Date", "Staff Sent Home", "Timesheet", "Facility Confirmation", "Close Shift", "Actions"],
            },
            ENV.API_URL + url,
            setList,
            ApiService,
            "post"
        );

        let tableWrapperObj = new TsDataListWrapperClass(options);
        setList({table: tableWrapperObj});
        // eslint-disable-next-line
    }, [shiftRequirementType]);

    const getList = useCallback(() => {
        if (!list) {
            init();
            return;
        }
        let payload: any = {};

        payload.status = "complete";

        if (selectedRegion.length > 0) {
            payload.regions = selectedRegion;
        }

        if (selectedFacilities.length > 0) {
            payload.facilities = selectedFacilities.map((item: any) => item?.code);
        }

        if (selectedApprovedHcps.length > 0) {
            payload.hcp_user_ids = selectedApprovedHcps.map((item: any) => item?.code);
        }

        if (selectedHcps.length > 0) {
            payload.hcp_types = selectedHcps;
        }

        if (isTimeSheetUploaded?.value !== "") {
            payload.is_timesheet_uploaded = isTimeSheetUploaded?.value;
        }

        if (shiftRequirementType?.value !== '') {
            payload.type = shiftRequirementType?.value;
        }
        if (isFacilityApproved.value !== "") {
            payload.is_facility_approved = isFacilityApproved?.value;
        }

        if (isPendingConcern.value !== "") {
            payload.is_pending_concern = isPendingConcern?.value;
        }

        if (hcpPaymentModeValue.value !== "") {
            payload.gusto_type = hcpPaymentModeValue?.value;
        }

        if (isHcpSentHome.value !== "") {
            payload.is_sent_home = isHcpSentHome?.value;
        }

        if (dateRange[0] || dateRange[1]) {
            let startDate = moment(dateRange[0]).format("YYYY-MM-DD");
            let endDate = moment(dateRange[1]).format("YYYY-MM-DD");

            if (!dateRange[1]) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        }

        if (selectedTimeTypes.length > 0) {
            payload.shift_types = selectedTimeTypes;
        }


        if (selectedSalaryCreditTypes.length > 0) {

            payload.salary_credit = selectedSalaryCreditTypes.map(item => item.code);


        }

        list?.table?.setExtraPayload(payload);
        list?.table?.getList(1);
        // eslint-disable-next-line
    }, [selectedRegion, dateRange, selectedTimeTypes, selectedSalaryCreditTypes, selectedFacilities, selectedApprovedHcps, selectedHcps, isFacilityApproved, isHcpSentHome, isTimeSheetUploaded, isPendingConcern, hcpPaymentModeValue, shiftRequirementType]);

    const openRejectShift = useCallback(() => {
        setRejectShiftOpen(true);
    }, []);

    const openPendingConcern = useCallback(() => {
        setIsPendingConcernOpen(true);
    }, []);

    const cancelRejectShift = useCallback(() => {
        setRejectShiftOpen(false);
        setSelectedCount(-1);
        setSelectedShifts([]);
    }, []);

    const cancelMarkingPendingConcern = useCallback(() => {
        setIsPendingConcernOpen(false);
        setSelectedCount(-1);
        setSelectedShifts([]);
    }, []);

    const confirmRejectShift = useCallback(() => {
        setRejectShiftOpen(false);
        list?.table.reload();
        setSelectedCount(-1);
        setSelectedShifts([]);
    }, [list?.table, setSelectedCount, setSelectedShifts]);

    const confirmMarkingPendingConcern = useCallback(() => {
        setIsPendingConcernOpen(false);
        setSelectedCount(-1);
        return new Promise((resolve, reject) => {
            ApiService.patch(ENV.API_URL + "shift/markConcern", {
                shift_ids: selectedShifts
            })
                .then((res: any) => {
                    resolve(res);
                    CommonService.showToast(`${res?.msg}` || "success", "success");
                    setSelectedShifts([]);
                    getList();
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }, [selectedShifts, getList]);

    const clearFilterValues = () => {
        setSelectedTimeTypes([]);
        setSelectedSalaryCreditTypes([]);
        setSelectedFacilities([]);
        setSelectedApprovedHcps([]);
        setSelectedHcps([]);
        setDateRange([null, null]);
        setSelectedRegion([]);
        setIsTimeSheetUploaded("");
        setIsFacilityApproved("");
        setIsPendingConcern("");
        setHcpPaymentModeValue("");
        setIsHcpSentHome("");
        setRequirementType("");
    };

    const resetFilters = () => {
        clearFilterValues();
    };

    useEffect(() => {
        Communications.pageTitleSubject.next("Shifts Completed");
        Communications.pageBackButtonSubject.next(null);
    }, []);

    useEffect(() => {
        getList();
    }, [getList]);

    useEffect(() => {
        let count = 0;
        list?.table?.data?.forEach((item: any) => {
            if (selectedShifts?.indexOf(item?._id) !== -1) {
                count++;
            }
        });
        if (list?.table?.data?.length !== 0 && count === list?.table?.data?.length) {
            setAllSelected(true);
        } else {
            setAllSelected(false);
        }
    }, [list?.table?.data, selectedShifts]);


    const openAdd = useCallback((id: any) => {
        setShiftId(id);
        setIsAddOpen(true);
    }, []);

    const cancelAdd = useCallback(() => {
        setIsAddOpen(false);
    }, []);

    const confirmAdd = useCallback(() => {
        handleCloseShift(shiftId);
    }, [handleCloseShift, shiftId]);


    return (
        <div className="completed-shifts screen crud-layout pdd-30">
            <DialogComponent open={isRejectShiftOpen} cancel={cancelRejectShift}>
                <RejectShiftComponent isMultipleReasons={true} cancel={cancelRejectShift} confirm={confirmRejectShift}
                                      selectedShifts={selectedShifts}/>
            </DialogComponent>
            <DialogComponent open={isPendingConcernOpen} cancel={cancelMarkingPendingConcern}>
                <PendingConcernConfirmationBox selectedShifts={selectedShifts}
                                               cancelMarkingPendingConcern={cancelMarkingPendingConcern}
                                               confirmMarkingPendingConcern={confirmMarkingPendingConcern}/>
            </DialogComponent>

            {list && list.table?._isDataLoading && (
                <div className="table-loading-indicator">
                    <LoaderComponent/>
                </div>
            )}
            <DialogComponent open={isAddOpen} cancel={cancelAdd}>
                <VitawerksConfirmComponent
                    isShiftType={true}
                    isConfirm={isConfirm}
                    cancel={cancelAdd}
                    confirm={confirmAdd}
                    text1="Do you want to move the shift from completed to closed?"
                    hcpname={""}
                    groupname={""}
                    confirmationText={""}
                    notext={"No"}
                    yestext={"Yes"}
                />
            </DialogComponent>
            <ShiftFilter
                hcpPaymentModeValue={hcpPaymentModeValue}
                setHcpPaymentModeValue={setHcpPaymentModeValue}
                isPendingConcern={isPendingConcern}
                setIsPendingConcern={setIsPendingConcern}
                isFacilityListLoading={isFacilityListLoading}
                dateRange={dateRange}
                setDateRange={setDateRange}
                selectedRegion={selectedRegion}
                setSelectedRegion={setSelectedRegion}
                regions={regions}
                shiftRequirementType={shiftRequirementType}
                setRequirementType={setRequirementType}
                selectedHcps={selectedHcps}
                setSelectedHcps={setSelectedHcps}
                selectedTimeTypes={selectedTimeTypes}
                setSelectedTimeTypes={setSelectedTimeTypes}
                selectedFacilities={selectedFacilities}
                setSelectedFacilities={setSelectedFacilities}
                setIsTimeSheetUploaded={setIsTimeSheetUploaded}
                setIsFacilityApproved={setIsFacilityApproved}
                setIsHcpSentHome={setIsHcpSentHome}
                isTimeSheetUploaded={isTimeSheetUploaded}
                isFacilityApproved={isFacilityApproved}
                isHcpSentHome={isHcpSentHome}
                noStatus={true}
                resetFilters={resetFilters}
                facilityList={facilityList}
                hcpTypes={hcpTypes}
                isCompleted={true}
                selectedApprovedHcps={selectedApprovedHcps}
                setSelectedApprovedHcps={setSelectedApprovedHcps}
                selectedSalaryCreditTypes={selectedSalaryCreditTypes}
                setSelectedSalaryCreditTypes={setSelectedSalaryCreditTypes}
            />
            <div className="custom-border pdd-10 pdd-top-0 pdd-bottom-20 mrg-top-0">
                <div className="header">
                    <div className="mrg-left-5 filter">
                        <div>
                            <div className="d-flex">
                                <div className="d-flex position-relative">
                                    {!list?.table.filter.search ? (
                                        <div className={"search_icon"}>
                                            <SearchRounded/>
                                        </div>
                                    ) : (
                                        <div className={"search_icon"}>
                                            <ClearIcon
                                                onClick={(event) => {
                                                    if (list && list.table) {
                                                        list.table.filter.search = "";
                                                        setShiftSearch("");
                                                        list.table.reload();
                                                        // list?.table.pageEvent(0)
                                                    }
                                                }}
                                                id="clear_shift_search"
                                            />
                                        </div>
                                    )}
                                    <div>
                                        <CssTextField
                                            defaultValue={""}
                                            className="search-cursor searchField"
                                            id="input_search_shift"
                                            onChange={(event) => {
                                                if (list && list.table) {
                                                    list.table.filter.search = event.target.value;
                                                    setShiftSearch(event.target.value);
                                                    list.table.reload();
                                                    // list?.table.pageEvent(0)
                                                }
                                            }}
                                            value={list?.table.filter.search}
                                            variant={"outlined"}
                                            size={"small"}
                                            type={"text"}
                                            placeholder={"Search Shift"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="actions d-flex">
                        <div>
                            <Tooltip title={"Pending Concern"}>
                                <Button variant={"contained"} color={"primary"}
                                        disabled={!(isPendingConcern?.label === "No") || (selectedCount === -1)}
                                        onClick={openPendingConcern}>
                                    &nbsp;&nbsp;Pending Concern&nbsp;&nbsp;
                                </Button>
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip title={"Cancel Shift Inprogress"}>
                                <Button variant={"contained"} color={"primary"} disabled={selectedCount === -1}
                                        onClick={openRejectShift}>
                                    &nbsp;&nbsp;Cancel Shift&nbsp;&nbsp;
                                </Button>
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip title={"Close Shift Inprogress"}>
                                <Button variant={"contained"} color={"primary"}
                                        disabled={selectedCompletedShiftWithFacilityAndTimeline.length === 0}
                                        onClick={handleCloseShiftTimelineAndFacilityApproved}>
                                    &nbsp;&nbsp; Close &nbsp;&nbsp;
                                </Button>
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip title="Download Shifts List">
                                <Button variant={"contained"} color="primary" onClick={handleDownload}
                                        className={!isDownloading ? "" : "has-loading-spinner"}
                                        disabled={isDownloading}>
                                    &nbsp;Download
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                {list && list.table && (
                    <>
                        <TableContainer component={Paper} className={"table-responsive"}>
                            <Table stickyHeader className="mat-table table shifts-completed-list-table">
                                <TableHead className={"mat-thead"}>
                                    <TableRow className={"mat-tr"}>
                                        <TableCell padding="checkbox" className="mat-th">
                                            <Checkbox onChange={(event) => handleSelectAll(event)}
                                                      checked={isAllselected} id={"select-all-cb"}/>
                                        </TableCell>
                                        {list?.table.matColumns.map((column: any, columnIndex: any) => (
                                            <TableCell key={"header-col-" + columnIndex}
                                                       className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}>
                                                {column}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody className={"mat-tbody"}>
                                    {!list.table._isDataLoading && list.table?.data.length === 0 &&
                                        <NoDataCardComponent tableCellCount={list.table.matColumns.length}/>}
                                    {list?.table.data.map((row: any, rowIndex: any) => {
                                        const shift_date = CommonService.getUtcDate(row["shift_date"]);
                                        const isItemSelected = isSelected(row["_id"]);
                                        const isFacilityApproved = row?.hasOwnProperty('is_facility_approved') && row["is_facility_approved"];
                                        const isTimeSheetUploaded = row?.hasOwnProperty('is_timesheet_uploaded') && row["is_timesheet_uploaded"];
                                        const isPendingConcern = row?.hasOwnProperty('is_pending_concern') && row["is_pending_concern"];
                                        const isHcpSentHome = row?.hasOwnProperty('is_sent_home') ? (row["is_sent_home"] === true ? "Yes" : "No") : "No";
                                        return (
                                            <TableRow role="checkbox" tabIndex={-1} key={"row-" + rowIndex}
                                                      className="mat-tr">
                                                <TableCell className="mat-td mat-td-checkbox">
                                                    <Checkbox id={"cb_" + rowIndex} checked={isItemSelected}
                                                              onChange={(event) => handleSelectShifts(event, row['_id'], row)}/>
                                                </TableCell>
                                                <TableCell className="mat-td mat-td-title " style={{minWidth: "350px"}}>
                                                    <div className={'d-flex'}>
                                                        {isPendingConcern &&
                                                            <div className={'mrg-right-10'}>
                                                                <WarningIcon className={'mrg-top-5 mrg-right-5'}
                                                                             color={'error'}/>
                                                            </div>
                                                        }
                                                        <div style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center"
                                                        }}>
                                                            {shift_date + "_" + row["shift_type"] + "_" + row["hcp_details"]?.first_name}&nbsp;{row["hcp_details"]?.last_name + "_" + row["facility"]?.name}
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className="mat-td mat-td-facility-name">{row["facility"]?.name}</TableCell>
                                                <TableCell className="mat-td mat-td-hcp-name">
                                                    {row["hcp_details"]?.first_name}&nbsp;{row["hcp_details"]?.last_name}
                                                </TableCell>
                                                <TableCell
                                                    className="mat-td mat-td-hcp-type">{row["hcp_type"]}</TableCell>
                                                <TableCell
                                                    className="mat-td mat-td-shift-type">{row["shift_type"]}</TableCell>
                                                <TableCell
                                                    className="mat-td mat-td-completed-date">{shift_date}</TableCell>
                                                <TableCell className="mat-td mat-td-is-hcp-sent-home"><span
                                                    className={`${isHcpSentHome === 'Yes' ? 'success' : 'danger'}`}>{isHcpSentHome}</span></TableCell>


                                                <TableCell className="mat-td mat-td-timesheet">{isTimeSheetUploaded ?
                                                    <Checkbox checked={isTimeSheetUploaded}/> :
                                                    <Checkbox checked={Boolean(isTimeSheetUploaded)}/>}</TableCell>
                                                <TableCell
                                                    className="mat-td mat-td-is-facility-approved">{isFacilityApproved ?
                                                    <Checkbox checked={isFacilityApproved}/> :
                                                    <Checkbox checked={Boolean(isFacilityApproved)}/>}</TableCell>
                                                <TableCell className="mat-td mat-td-sticky mat-td-close-btn">
                                                    <Tooltip title={` close shift`}>
                                                        <Button variant="outlined" style={{borderRadius: "2rem"}}
                                                                disabled={!(isTimeSheetUploaded && isFacilityApproved && !isPendingConcern)}
                                                                color="primary" onClick={() => openAdd(row["_id"])}
                                                                className={`close-shift-btn ${!(isTimeSheetUploaded && isFacilityApproved && !isPendingConcern) ? 'btn-disabled' : ""}`}>
                                                            Close
                                                        </Button>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell className="mat-td mat-td-sticky mat-td-actions">
                                                    <Tooltip title={`${row["title"]} shift view details`}>
                                                        <Link to={"/completedShifts/view/" + row["_id"]}
                                                              className="info-link"
                                                              id={"link_hospital_details" + rowIndex}>
                                                            {"View Details"}
                                                        </Link>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={list.table.pagination.pageSizeOptions}
                            component="div"
                            count={list?.table.pagination.totalItems}
                            rowsPerPage={list?.table.pagination.pageSize}
                            page={list?.table.pagination.pageIndex}
                            onPageChange={(event, page) => list.table.pageEvent(page)}
                            onRowsPerPageChange={(event) => {
                                setPageSizeIndex(event.target.value);
                                list.table?.pageEvent(0, +event.target.value);
                            }}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default CompletedShiftsListScreen;

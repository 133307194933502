import {Button, Checkbox, Tooltip} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import ClearIcon from "@material-ui/icons/Clear";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {
    TsDataListOptions,
    TsDataListState,
    TsDataListWrapperClass
} from "../../../../classes/ts-data-list-wrapper.class";
import DialogComponent from "../../../../components/DialogComponent";
import LoaderComponent from "../../../../components/LoaderComponent";
import NoDataCardComponent from "../../../../components/NoDataCardComponent";
import {useLocalStorage} from "../../../../components/useLocalStorage";
import {ENV} from "../../../../constants";
import {ApiService, CommonService, Communications} from "../../../../helpers";
import {StateParams} from "../../../../store/reducers";
import ShiftFilter from "../../filters/ShiftFilter";
import AddShiftsComponent from "../addShiftsComponent/AddShiftsComponent";
import RejectShiftComponent from "../rejectApplication/RejectApplicationComponent";
import PendingShiftsTakeoverComponent from "../takeover/PendingShiftsTakeoverComponent";
import PendingSihftsViewComponent from "../view/PendingShiftsViewComponent";
import "./PendingShiftsListScreen.scss";
import {SearchRounded} from "@material-ui/icons";
import {pendingShiftsStatusList} from "../../../../constants/data";
import {CssTextField} from "../../../../constants/data/styles";

const AwaitingShiftsListScreen = () => {
    const [list, setList] = useState<TsDataListState | null>(null);
    const [hcpId, setHcpId] = useState<string>("");
    const [applicationId, setApplicationId] = useState<string>("");
    const [requirementId, setRequirementId] = useState<string>("");
    const [isViewOpen, setIsViewOpen] = useState<boolean>(false);
    const [isTakeOverOpen, setTakeOverOpen] = useState<boolean>(false);
    const {user} = useSelector((state: StateParams) => state.auth);
    const [facilityList, setFacilityList] = useState<any | null>(null);
    // const [hcpTypes, setHcpTypes] = useState<any | null>(null);
    const {hcpTypes} = useSelector((state: StateParams) => state.meta)
    // const [regions, setRegions] = useState<any>([]);
    const {regions} = useSelector((state: StateParams) => state.meta)
    const [selectedRegion, setSelectedRegion] = useLocalStorage<any[]>("selectedRegion", []);
    const [selectedHcps, setSelectedHcps] = useLocalStorage<any[]>("selectedHcps", []);
    const [selectedFacilities, setSelectedFacilities] = useLocalStorage<any[]>("selectedFacilities", []);
    const [selectedTimeTypes, setSelectedTimeTypes] = useLocalStorage<any[]>("selectedTimeTypes", []);
    const [dateRange, setDateRange] = useLocalStorage<any[]>("dateRange", [null, null]);
    const [selectedStatusTypes, setSelectedStatusTypes] = useLocalStorage<any[]>("selectedStatusTypesInAwaiting", ["awaiting"]);
    const [selectedApprovedHcps, setSelectedApprovedHcps] = useLocalStorage<any[]>("selectedApprovedHcps", []);


    const [isFacilityListLoading, setIsFacilityListLoading] = useState<boolean>(false);
    // const [isApprovalSubmitting, setIsApprovalSubmitting] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);

    const [pageSizeIndex, setPageSizeIndex] = useLocalStorage<any>("shiftAwaitingPageSizeIndex", 10);

    const [isAddShiftsOpen, setIsAddShiftsOpen] = useState<boolean>(false);
    const [rejectShiftOpen, setRejectShiftOpen] = useState<boolean>(false);

    const [selectedShifts, setSelectedShifts] = useState<any>([]);

    const [selectedCount, setSelectedCount] = useState<any>(-1);
    const [isAllselected, setIsAllSelected] = useState<boolean>(false);
    const [shiftSearch, setShiftSearch] = useState<string>("");

    // eslint-disable-next-line
    const [isRejectOpen, setIsRejectOpen] = useState<boolean>(false);
    const [shiftRequirementType, setRequirementType] = useLocalStorage<any>("awaiting-shift-requirement-type", "facility");

    const handleDownload = useCallback(() => {
        setIsDownloading(true);
        let payload: any = {};

        if (selectedRegion.length > 0) {
            payload.regions = selectedRegion;
        }

        if (selectedFacilities.length > 0) {
            payload.facilities = selectedFacilities.map((item: any) => item?.code)
        }

        if (selectedApprovedHcps.length > 0) {
            payload.hcp_user_ids = selectedApprovedHcps.map((item: any) => item?.code);
        }

        if (selectedHcps.length > 0) {
            payload.hcp_types = selectedHcps;
        }

        //send all status shifts for download when not selected
        if (selectedStatusTypes.length === 0) {
            payload.status = pendingShiftsStatusList.map(item => item.code);
        }
        if (shiftSearch !== "") {
            payload.search = shiftSearch
        }


        if (shiftRequirementType?.value !== '') {
            payload.type = shiftRequirementType?.value;
        }


        if (selectedStatusTypes.length > 0) {
            payload.status = selectedStatusTypes;
        }

        if (dateRange[0] || dateRange[1]) {
            let startDate = moment(dateRange[0]).format("YYYY-MM-DD");
            let endDate = moment(dateRange[1]).format("YYYY-MM-DD");

            if (!dateRange[1]) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        }

        if (selectedTimeTypes.length > 0) {
            payload.shift_types = selectedTimeTypes;
        }

        ApiService.post(ENV.API_URL + "application/download", payload)
            .then((res) => {
                if (res?.data) {
                    const link = document.createElement("a");
                    link?.setAttribute("href", res?.data);
                    document.body.appendChild(link);
                    link.click();
                    setIsDownloading(false);
                } else {
                    CommonService.showToast(res?.msg || "No Data to Download", "info");
                    setIsDownloading(false);
                }
            })
            .catch((err) => {
                setIsDownloading(false);
                CommonService.showToast(err?.error || "Error", "error");
            });
    }, [selectedRegion, dateRange, selectedFacilities, selectedApprovedHcps, selectedHcps, selectedStatusTypes, selectedTimeTypes, shiftSearch, shiftRequirementType]);

    const init = useCallback(() => {
        let today = moment(new Date()).format("YYYY-MM-DD");
        let url = "application";
        let payload: any = {};
        payload.user_id = user?._id;
        payload.new_shifts = today;

        if (selectedRegion.length > 0) {
            payload.regions = selectedRegion;
        }

        if (selectedFacilities.length > 0) {
            payload.facilities = selectedFacilities.map((item: any) => item?.code)
        }

        if (selectedApprovedHcps.length > 0) {
            payload.hcp_user_ids = selectedApprovedHcps.map((item: any) => item?.code);
        }

        if (selectedHcps.length > 0) {
            payload.hcp_types = selectedHcps;
        }

        if (selectedStatusTypes.length > 0) {
            payload.status = selectedStatusTypes;
        }


        if (shiftRequirementType?.value !== '') {
            payload.type = shiftRequirementType?.value;
        }

        if (dateRange[0] || dateRange[1]) {
            let startDate = moment(dateRange[0]).format("YYYY-MM-DD");
            let endDate = moment(dateRange[1]).format("YYYY-MM-DD");

            if (!dateRange[1]) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        }

        if (selectedTimeTypes.length > 0) {
            payload.shift_types = selectedTimeTypes;
        }

        const options = new TsDataListOptions(
            {
                // @ts-ignore
                pagination: {
                    ...list?.table?.pagination,
                    pageSize: pageSizeIndex,
                },
                extraPayload: payload,
                webMatColumns: ["Applied On", "HCP Name", "Facility Name", "Shift Date", "HCP Type", "Shift Type", "Status", "Actions"],
                mobileMatColumns: ["Applied On", "HCP Name", "Facility Name", "Shift Date", "HCP Type", "Shift Type", "Status", "Actions"],
            },
            ENV.API_URL + url,
            setList,
            ApiService,
            "post"
        );

        let tableWrapperObj = new TsDataListWrapperClass(options);
        setList({table: tableWrapperObj});
        // eslint-disable-next-line
    }, [shiftRequirementType]);

    const cancelView = useCallback(() => {
        if (isViewOpen === true) {
            setIsViewOpen(false);
        } else {
            setTakeOverOpen(false);
        }
    }, [isViewOpen]);

    const openViewShift = useCallback(() => {
        setIsViewOpen(true);
        setTakeOverOpen(false);
    }, []);

    const openView = useCallback((id: any, hcp_user_id: any, application_id: any) => {
        setIsViewOpen(true);
        setRequirementId(id);
        setHcpId(hcp_user_id);
        setApplicationId(application_id);
    }, []);

    const confirmView = useCallback(() => {
        setIsViewOpen(false);
        init();
    }, [init]);

    const cancelTakeOver = useCallback(() => {
        setTakeOverOpen(false);
    }, []);

    const openTakeOver = useCallback((hcp_user_id: any, application_id: any, requirement_id: any, status: any) => {
        setTakeOverOpen(true);
        setRequirementId(requirement_id);
        setHcpId(hcp_user_id);
        setApplicationId(application_id);
        // setShiftStatus(status);
    }, []);

    const confirmTakeOver = useCallback(() => {
        setTakeOverOpen(false);
        list?.table?.reload();
    }, [list?.table]);

    const handleSelectAll = (event: any) => {
        if (event.target.checked === true) {
            let temp: any[] = [];
            list?.table?.data?.forEach((item: any) => {
                let index = selectedShifts?.indexOf(item?._id);
                if (index === -1) {
                    temp.push(item?._id);
                }
            });
            setSelectedShifts([...selectedShifts, ...temp]);
            setSelectedCount(1);
        } else {
            list?.table?.data?.forEach((item: any) => {
                let index = selectedShifts?.indexOf(item?._id);
                selectedShifts.splice(index, 1);
            });
            setSelectedShifts([...selectedShifts]);
            setSelectedCount(selectedShifts?.length === 0 ? -1 : selectedShifts?.length);
        }
        setIsAllSelected(event.target.checked);
        console.log(selectedCount);
    };

    const handleSelectShifts = useCallback(
        (event: any, _id: any) => {
            if (event.target.checked === true) {
                setSelectedShifts([...selectedShifts, _id]);
                setSelectedCount(1);
            } else {
                let tempSelectedShifts = selectedShifts?.filter((item: any) => item !== _id);
                setSelectedShifts([...tempSelectedShifts]);
                if (tempSelectedShifts?.length === 0) {
                    setSelectedCount(-1);
                }
            }
        },
        [selectedShifts]
    );

    const clearFilterValues = () => {
        setSelectedTimeTypes([]);
        setSelectedFacilities([]);
        setSelectedApprovedHcps([]);
        setSelectedHcps([]);
        setSelectedStatusTypes([]);
        setRequirementType("");
        setSelectedRegion([]);
        setDateRange([null, null]);
    };

    const resetFilters = () => {
        clearFilterValues();
    };

    const getList = useCallback(() => {
        if (!list) {
            init();
            return;
        }

        let today = moment(new Date()).format("YYYY-MM-DD");
        let url = "application";
        let payload: any = {};
        payload.user_id = user?._id;
        payload.new_shifts = today;

        if (selectedRegion.length > 0) {
            payload.regions = selectedRegion;
        }

        if (selectedFacilities.length > 0) {
            payload.facilities = selectedFacilities.map((item: any) => item?.code)
        }

        if (selectedApprovedHcps.length > 0) {
            payload.hcp_user_ids = selectedApprovedHcps.map((item: any) => item?.code);
        }

        if (selectedHcps.length > 0) {
            payload.hcp_types = selectedHcps;
        }

        if (selectedStatusTypes.length > 0) {
            payload.status = selectedStatusTypes;
        }

        if (shiftRequirementType?.value !== '') {
            payload.type = shiftRequirementType?.value;
        }

        if (dateRange[0] || dateRange[1]) {
            let startDate = moment(dateRange[0]).format("YYYY-MM-DD");
            let endDate = moment(dateRange[1]).format("YYYY-MM-DD");

            if (!dateRange[1]) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        }

        if (selectedTimeTypes.length > 0) {
            payload.shift_types = selectedTimeTypes;
        }

        const options = new TsDataListOptions(
            {
                // @ts-ignore
                pagination: {
                    ...list?.table?.pagination,
                    pageSize: pageSizeIndex,
                },
                extraPayload: payload,
                webMatColumns: ["Applied On", "HCP Name", "Facility Name", "Shift Date", "Type of hcp", "Shift Type", "Status", "Actions"],
                mobileMatColumns: ["Applied On", "HCP Name", "Facility Name", "Shift Date", "Type of hcp", "Shift Type", "Status", "Actions"],
            },
            ENV.API_URL + url,
            setList,
            ApiService,
            "post"
        );

        let tableWrapperObj = new TsDataListWrapperClass(options);
        setList({table: tableWrapperObj});
        // eslint-disable-next-line

        list?.table?.setExtraPayload(payload);
        list?.table?.getList(1);
        // eslint-disable-next-line
    }, [selectedRegion, dateRange, selectedStatusTypes, selectedTimeTypes, selectedHcps, selectedFacilities, selectedApprovedHcps, shiftRequirementType]);

    const isSelected = useCallback(
        (_id: any) => {
            if (selectedShifts?.indexOf(_id) !== -1) {
                return true;
            } else {
                return false;
            }
        },
        [selectedShifts]
    );

    // const getRegions = useCallback(() => {
    //   CommonService._api
    //     .get(ENV.API_URL + "meta/hcp-regions")
    //     .then((resp) => {
    //       setRegions(resp.data || []);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }, []);

    // const getHcpTypes = useCallback(() => {
    //   CommonService._api
    //     .get(ENV.API_URL + "meta/hcp-types")
    //     .then((resp) => {
    //       setHcpTypes(resp.data || []);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }, []);

    const getFacilityData = useCallback(() => {
        setIsFacilityListLoading(true);
        let payload: any = {};
        if (selectedRegion.length > 0) {
            const selectedRegions = selectedRegion
            payload.regions = selectedRegions;
        }
        ApiService.post(ENV.API_URL + "facility/lite", payload)
            .then((res) => {
                setFacilityList(res?.data || []);
                setIsFacilityListLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsFacilityListLoading(false);
            });
    }, [selectedRegion]);

    // const handleFacilityApproval = useCallback(
    //     (requirementId, applicationId) => {
    //         setIsApprovalSubmitting(true);
    //         let payload = {
    //             awaited_by: user?._id,
    //         };
    //         CommonService._api
    //             .patch(ENV.API_URL + `application/${applicationId}/await`, payload)
    //             .then((resp) => {
    //                 CommonService.showToast(resp?.msg || "Success", "success");
    //                 list?.table?.reload();
    //                 setIsApprovalSubmitting(false);
    //             })
    //             .catch((err) => {
    //                 console.log(err);
    //                 CommonService.showToast(err?.msg || "Error", "error");
    //                 list?.table?.reload();
    //                 setIsApprovalSubmitting(false);
    //             });
    //     },
    //     [user?._id, list?.table]
    // );

    const openRejectShift = useCallback(() => {
        setRejectShiftOpen(true);
    }, []);

    const cancelRejectShift = useCallback(() => {
        setRejectShiftOpen(false);
        setSelectedShifts([]);
    }, []);

    const cancelOpenAddShifts = useCallback(() => {
        setIsAddShiftsOpen(false);
        setSelectedShifts([]);
    }, []);

    const onAddShiftsConfirm = useCallback(() => {
        setIsAddShiftsOpen(false);
        setSelectedShifts([]);
        list?.table?.reload();
    }, [list?.table]);

    const onRejectShiftConfirm = useCallback(() => {
        setRejectShiftOpen(false);
        setSelectedShifts([]);

        list?.table?.reload();
    }, [list?.table]);

    const openAddShifts = useCallback(() => {
        setIsAddShiftsOpen(true);
    }, []);

    const handleApproveButtonDisable = () => {
        if (selectedShifts?.length > 0) {
            if (selectedStatusTypes.length === 0 || selectedStatusTypes.includes("pending")) {
                return true;
            }
            return false;
        } else {
            return true;
        }
    };

    const handleRejectButtonDisable = () => {
        if (selectedShifts?.length === 0 || selectedStatusTypes.length === 0 || selectedStatusTypes.includes("cancelled") || selectedStatusTypes.includes("rejected") || list?.table?.data.length === 0) {
            return true;
        } else {
        }
    };

    const handleGlobalCheckboxDisable = () => {
        if (selectedStatusTypes.length === 0 || selectedStatusTypes.includes("cancelled") || selectedStatusTypes.includes("rejected") || selectedStatusTypes.includes("approved") || selectedStatusTypes.includes("awaiting")) {
            return true;
        } else {
            return false;
        }
    };

    const handleIndividualCheckBoxDisable = (row: any) => {
        if (row["status"] === "rejected" || row["status"] === "approved" || row["status"] === "cancelled" || row["can_take_over"]) {
            return true;
        } else if (selectedStatusTypes.length === 0) {
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        getFacilityData();
    }, [selectedRegion, getFacilityData]);

    useEffect(() => {
        Communications.pageTitleSubject.next("Awaiting Shifts");
        Communications.pageBackButtonSubject.next(null);
    }, [init]);

    useEffect(() => {
        getList();
    }, [getList]);

    useEffect(() => {
        let count = 0;
        list?.table?.data?.forEach((item: any) => {
            if (selectedShifts?.indexOf(item?._id) !== -1) {
                count++;
            }
        });
        if (list?.table?.data?.length !== 0 && count === list?.table?.data?.length) {
            setIsAllSelected(true);
        } else {
            setIsAllSelected(false);
        }
    }, [list?.table?.data, selectedShifts]);

    useEffect(() => {
        setIsAllSelected(false);
    }, [list?.table?.data]);

    return (
        <div className="pending-list-screen screen crud-layout pdd-30">
            <DialogComponent open={rejectShiftOpen} cancel={cancelRejectShift}>
                <RejectShiftComponent cancel={cancelRejectShift} confirm={onRejectShiftConfirm}
                                      selectedShifts={selectedShifts}/>
            </DialogComponent>
            <DialogComponent open={isViewOpen} cancel={cancelView} maxWidth="lg">
                <PendingSihftsViewComponent cancel={cancelView} requirementId={requirementId} confirm={confirmView}
                                            hcpId={hcpId} applicationId={applicationId}/>
            </DialogComponent>
            <DialogComponent open={isTakeOverOpen} cancel={cancelTakeOver} maxWidth="xs">
                <PendingShiftsTakeoverComponent cancel={cancelTakeOver} confirm={confirmTakeOver}
                                                openViewShift={openViewShift} applicationId={applicationId}
                                                requirementId={requirementId} hcpId={hcpId}/>
            </DialogComponent>
            <DialogComponent open={isAddShiftsOpen} cancel={cancelOpenAddShifts} maxWidth="md">
                <AddShiftsComponent cancel={cancelOpenAddShifts} selectedShiftIds={selectedShifts}
                                    confirm={onAddShiftsConfirm}/>
            </DialogComponent>
            {list && list.table?._isDataLoading && (
                <div className="table-loading-indicator">
                    <LoaderComponent/>
                </div>
            )}

            {/*{isApprovalSubmitting && (*/}
            {/*    <div className="table-loading-indicator">*/}
            {/*        <LoaderComponent/>*/}
            {/*    </div>*/}
            {/*)}*/}

            {list && list.table?._isDataLoading && (
                <div className="table-loading-indicator">
                    <LoaderComponent/>
                </div>
            )}

            <ShiftFilter
                isFacilityListLoading={isFacilityListLoading}
                dateRange={dateRange}
                setDateRange={setDateRange}
                selectedRegion={selectedRegion}
                setSelectedRegion={setSelectedRegion}
                regions={regions}
                selectedHcps={selectedHcps}
                setSelectedHcps={setSelectedHcps}
                selectedTimeTypes={selectedTimeTypes}
                setSelectedTimeTypes={setSelectedTimeTypes}
                selectedFacilities={selectedFacilities}
                setSelectedFacilities={setSelectedFacilities}
                selectedStatusTypes={selectedStatusTypes}
                setSelectedStatusTypes={setSelectedStatusTypes}
                shiftRequirementType={shiftRequirementType}
                setRequirementType={setRequirementType}
                setSelectedShifts={setSelectedShifts}
                isPending={true}
                isAwaiting={true}
                noStatus={false}
                resetFilters={resetFilters}
                facilityList={facilityList}
                hcpTypes={hcpTypes}
                selectedApprovedHcps={selectedApprovedHcps}
                setSelectedApprovedHcps={setSelectedApprovedHcps}
            />

            <div className="custom-border pdd-10 pdd-top-0 pdd-bottom-20 mrg-bottom-10">

                <div className="pending-shift-header">
                    <div className="d-flex">
                        <div className="d-flex position-relative">
                            {!list?.table.filter.search ? (
                                <div className={"search_icon"}>
                                    <SearchRounded/>
                                </div>
                            ) : (
                                <div className={"search_icon"}>
                                    <ClearIcon
                                        onClick={(event) => {
                                            if (list && list.table) {
                                                list.table.filter.search = "";
                                                setShiftSearch("")
                                                list.table.reload();
                                                // list?.table.pageEvent(0)
                                            }
                                        }}
                                        id="clear_pending_search"
                                    />
                                </div>
                            )}
                            <div>
                                <CssTextField
                                    defaultValue={""}
                                    className="search-cursor searchField"
                                    id="input_search_pending"
                                    onChange={(event) => {
                                        if (list && list.table) {
                                            list.table.filter.search = event.target.value;
                                            setShiftSearch(event.target.value)
                                            list.table.reload();
                                            // list?.table.pageEvent(0)
                                        }
                                    }}
                                    value={list?.table.filter.search}
                                    variant={"outlined"}
                                    size={"small"}
                                    type={"text"}
                                    placeholder={"Search Staff Name"}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="actions d-flex mrg-right-5 mrg-bottom-5">
                        <div className="actions pdd-right-5">
                            <Tooltip title="Download Shifts List">
                                <Button variant={"contained"} color="primary" onClick={handleDownload}
                                        className={!isDownloading ? "" : "has-loading-spinner"}
                                        disabled={isDownloading}>
                                    &nbsp;Download
                                </Button>
                            </Tooltip>
                        </div>
                        <Tooltip title={"Reject Multiple Shifts"}>
            <span>
              <Button disabled={handleRejectButtonDisable()}
                      className={handleRejectButtonDisable() ? "btn-disabled" : ""} variant={"outlined"}
                      color={"primary"} onClick={openRejectShift}>
                Reject
              </Button>
            </span>
                        </Tooltip>

                        <Tooltip title={"Approve Multiple Shifts"}>
            <span>
              <Button disabled={handleApproveButtonDisable()}
                      className={handleApproveButtonDisable() ? "btn-disabled" : ""} variant={"outlined"}
                      color={"primary"} onClick={openAddShifts}>
                Approve
              </Button>
            </span>
                        </Tooltip>
                    </div>

                </div>

                {list && list.table && (
                    <>
                        <TableContainer component={Paper} className={"table-responsive"}>
                            <Table stickyHeader className="mat-table table shift-pending-list-table">
                                <TableHead className="mat-thead">
                                    <TableRow className="mat-tr">
                                        <TableCell padding="checkbox" className="mat-th">
                                            <Checkbox disabled={handleGlobalCheckboxDisable()}
                                                      onChange={(event) => handleSelectAll(event)}
                                                      checked={isAllselected} id={"select-all-cb"}/>
                                        </TableCell>
                                        {list?.table.matColumns.map((column: any, columnIndex: any) => (
                                            <TableCell key={"header-col-" + columnIndex}
                                                       className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}>
                                                {column}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody className="mat-tbody">
                                    {!list.table._isDataLoading && list.table?.data.length === 0 &&
                                        <NoDataCardComponent tableCellCount={list.table.matColumns.length}/>}

                                    {list?.table.data.map((row: any, rowIndex: any) => {
                                        const shift_date = CommonService.getUtcDate(row["shift_date"]);
                                        const isItemSelected = isSelected(row["_id"]);

                                        return (
                                            <TableRow role="checkbox" tabIndex={-1} key={"row-" + rowIndex}
                                                      className="mat-tr">
                                                <TableCell className="mat-td mat-td-checkbox">
                                                    <Checkbox id={"cb_" + rowIndex}
                                                              disabled={handleIndividualCheckBoxDisable(row)}
                                                              checked={isItemSelected}
                                                              onChange={(event) => handleSelectShifts(event, row["_id"])}/>
                                                </TableCell>
                                                <TableCell
                                                    className="mat-td mat-td-applied-on">{moment(row["created_at"]).format("MM-DD-YYYY")}</TableCell>
                                                <TableCell className="mat-td mat-td-hcp-name">
                                                    {row["hcp_data"]?.first_name}&nbsp;{row["hcp_data"]?.last_name}
                                                </TableCell>
                                                <TableCell
                                                    className="mat-td mat-td-facility-name">{row["facility"]?.name}</TableCell>
                                                <TableCell className="mat-td mat-td-shift-date">{shift_date}</TableCell>
                                                <TableCell
                                                    className="mat-td mat-td-hcp-type">{row["hcp_data"]?.hcp_type}</TableCell>
                                                <TableCell
                                                    className="mat-td mat-td-shift-type">{row["shift_type"]}</TableCell>
                                                <TableCell
                                                    className={`mat-td mat-td-status captalize ${row["status"]}`}>{row["status"]}</TableCell>
                                                {/*                    <TableCell className={`mat-td mat-td-approval`}>*/}
                                                {/*                        <Tooltip*/}
                                                {/*                            title={`Send ${row["hcp_data"]?.first_name} ${row["hcp_data"]?.last_name} for Facility Approval`}>*/}
                                                {/*<span>*/}
                                                {/*  <IconButton disabled={row["status"] !== "pending"} color="primary"*/}
                                                {/*              onClick={() => handleFacilityApproval(row["requirement_id"], row["_id"])}*/}
                                                {/*              className={row["status"] !== "pending" ? "btn-disabled" : ""}>*/}
                                                {/*    <SendIcon fontSize="large"/>*/}
                                                {/*  </IconButton>*/}
                                                {/*</span>*/}
                                                {/*                        </Tooltip>*/}
                                                {/*                    </TableCell>*/}

                                                <TableCell
                                                    className="mat-td mat-td-sticky mat-td-actions action-wrapper">
                                                    <div className="actions-table">
                                                        <div>
                                                            <Tooltip
                                                                title={`Approve ${row["hcp_data"]?.first_name} ${row["hcp_data"]?.last_name} Shift Requirement Application`}>
                                                                <Button
                                                                    style={{borderRadius: "2rem"}}
                                                                    variant="outlined"
                                                                    onClick={() => openTakeOver(row["hcp_user_id"], row["_id"], row["requirement_id"], "approve")}
                                                                    disabled={row["status"] !== "awaiting" || row["can_take_over"] === false}
                                                                    className={row["status"] !== "awaiting" || row["can_take_over"] === false ? "btn-disabled" : ""}
                                                                >
                                                                    TAKEOVER
                                                                </Button>
                                                            </Tooltip>
                                                        </div>
                                                        <div className="mrg-left-5">
                                                            <Tooltip
                                                                title={`${row["hcp_data"]?.first_name} ${row["hcp_data"]?.last_name} shift Requirement view details`}>
                                                                <p onClick={() => openView(row["requirement_id"], row["hcp_user_id"], row["_id"])}
                                                                   className="view-details-link">
                                                                    View Details
                                                                </p>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={list.table.pagination.pageSizeOptions}
                            component="div"
                            count={list?.table.pagination.totalItems}
                            rowsPerPage={list?.table.pagination.pageSize}
                            page={list?.table.pagination.pageIndex}
                            onPageChange={(event, page) => list.table.pageEvent(page)}
                            onRowsPerPageChange={(event) => {
                                setPageSizeIndex(event.target.value);
                                list.table?.pageEvent(0, +event.target.value);
                            }}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default AwaitingShiftsListScreen;

import {Button, InputAdornment, MenuItem, Table, TableBody, TableHead, TableRow, Tooltip} from "@material-ui/core";
import BackspaceIcon from "@material-ui/icons/Backspace";
import {Field, Form, Formik, FormikHelpers} from "formik";
import {TextField} from "formik-material-ui";
import React, {useCallback, useState} from "react";
import DialogComponent from "../../../../components/DialogComponent";
import VitawerksConfirmComponent from "../../../../components/VitawerksConfirmComponent";
import {ENV} from "../../../../constants";
import {designationNames} from "../../../../constants/data";
import {ApiService, CommonService} from "../../../../helpers";
import {memberFormValidation} from "../../add/FacilityMemberAddComponent/FacilityMemberFormValidation";
import "./FacilityMemberEditComponent.scss";
import ReadOnlyRow from "./ReadOnlyRow";

type FacilityMemberEditComponentProps = {
    onAddMember: any;
    hcpId: string;
    members: any;
    init: any;
    // setMembers: any;
    // getFacilityMembers: () => void;
};

interface MemberType {
    name: string;
    email: string;
    contact_number: string;
    extension_number: string;
    designation: string;
}

const memberInitialState: MemberType = {
    name: "",
    contact_number: "",
    extension_number: "",
    designation: "",
    email: "",
};

const FacilityMemberEditComponent = ({init, onAddMember, hcpId, members}: FacilityMemberEditComponentProps) => {
    const [isMembers, setIsMembers] = useState<boolean>(false);
    const [fieldType, setFieldType] = useState<boolean>(false);
    const [isAddOpen, setIsAddOpen] = useState<boolean>(false);
    const [facilityId, setFacilityId] = useState<any>(null);
    const [isConfirm, setIsConfirm] = useState<boolean>(false);
    const [facilityMember, setFacilityMember] = useState<any>(null);

    const onAdd = (member: MemberType, {setSubmitting, setErrors, resetForm}: FormikHelpers<MemberType>) => {
        const newMember = {
            name: member.name,
            email: member.email,
            contact_number: member.contact_number,
            extension_number: member.extension_number ? member.extension_number : "",
            designation: member.designation,
        };

        onAddMember(newMember).then(() => {
            // getFacilityMembers();
            init();
            setIsMembers(false);
            resetForm();
        })
            .catch((err: any) => {
                //   console.log(err);
            });
    };

    const handleDeleteClick = useCallback((memberId: number) => {
            setIsConfirm(true);
            ApiService.delete(ENV.API_URL + "facility/" + hcpId + "/member/" + memberId).then((resp: any) => {
                CommonService.showToast(resp?.msg || "Facility Member Deleted", "success");
                //getFacilityMembers();
                init();
                setIsAddOpen(false);
                setIsConfirm(false);
            })
                .catch((err) => {
                    // console.log(err);
                    setIsConfirm(false);
                });
        },
        [hcpId,init]
    );

    const showDropDownBelowField = {
        MenuProps: {
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
            },
            getContentAnchorEl: null,
        },
    };

    const openAdd = useCallback((id: any, memberName: any) => {
        setFacilityMember(memberName);
        setFacilityId(id);
        setIsAddOpen(true);
    }, []);

    const cancelAdd = useCallback(() => {
        setIsAddOpen(false);
    }, []);

    const confirmAdd = useCallback(() => {
        handleDeleteClick(facilityId);
    }, [facilityId, handleDeleteClick]);

    return (
        <div className="facility-add-container">
            <DialogComponent open={isAddOpen} cancel={cancelAdd}>
                <VitawerksConfirmComponent cancel={cancelAdd} confirm={confirmAdd} isConfirm={isConfirm}
                                           text1="Want to delete" hcpname={facilityMember} groupname={""}
                                           confirmationText={""} notext={"Back"} yestext={"Delete"}/>
            </DialogComponent>
            {members.length > 0 && (
                <Table className="mrg-top-50">
                    <TableHead className={"mat-thead"}>
                        <TableRow className={"mat-tr"}>
                            <th>Name</th>
                            <th>Phone Number</th>
                            <th>Extension Number</th>
                            <th>Email</th>
                            <th>Designation</th>
                            <th>Actions</th>
                        </TableRow>
                    </TableHead>
                    <TableBody className={"mat-tbody"}>
                        {members.map((member: any) => (
                            <ReadOnlyRow key={member?._id} member={member} openAdd={openAdd}/>
                        ))}
                    </TableBody>
                </Table>
            )}

            {isMembers ? (
                <div>
                    <Formik initialValues={memberInitialState} validateOnChange={true}
                            validationSchema={memberFormValidation} onSubmit={onAdd}>
                        {({isSubmitting, handleSubmit, isValid, resetForm}) => (
                            <Form className={"form-holder"}>
                                <div className="facility-add-input">
                                    <Field variant="outlined" name="name" type={"text"} component={TextField}
                                           label="Name" fullWidth autoComplete="off"
                                           id="input_facility_member_add_name"/>

                                    <Field
                                        SelectProps={showDropDownBelowField}
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{fieldType &&
                                                <BackspaceIcon style={{cursor: "pointer"}}
                                                               onClick={() => setFieldType((prevState) => !prevState)}/>}</InputAdornment>,
                                        }}
                                        select={fieldType ? false : true}
                                        name="designation"
                                        type={"text"}
                                        component={TextField}
                                        label="Designation"
                                        fullWidth
                                        autoComplete="off"
                                        id="input_facility_member_add_designation"
                                    >
                                        {!fieldType &&
                                            designationNames &&
                                            designationNames.map((item: any, index) => (
                                                <MenuItem value={item.value} key={index}>
                                                    {item.label}
                                                </MenuItem>
                                            ))}
                                        <MenuItem
                                            onClick={() => setFieldType((prevState) => !prevState)}>Other</MenuItem>
                                    </Field>
                                </div>
                                <div className="facility-add-input">
                                    <div className="number-container">
                                        <Field
                                            inputProps={{maxLength: 10}}
                                            className="phone_number"
                                            variant="outlined"
                                            name="contact_number"
                                            type={"text"}
                                            component={TextField}
                                            label="Contact Number"
                                            fullWidth
                                            autoComplete="off"
                                            id="input_facility_member_add_phone_number"
                                        />

                                        <Field
                                            inputProps={{maxLength: 10}}
                                            className="extension_number"
                                            variant="outlined"
                                            name="extension_number"
                                            type={"text"}
                                            component={TextField}
                                            label="Extension No."
                                            autoComplete="off"
                                            id="input_facility_member_add_extension_number"
                                        />
                                    </div>
                                    <Field variant="outlined" name="email" type={"email"} component={TextField}
                                           label="Email" fullWidth autoComplete="off"
                                           id="input_facility_member_add_email"/>
                                </div>
                                <div className="facility-add-btn-grp">
                                    <Button
                                        variant="outlined"
                                        id="btn_facility_member_add_cancel"
                                        type="reset"
                                        onClick={() => {
                                            resetForm();
                                            setIsMembers(false);
                                        }}
                                    >
                                        Delete
                                    </Button>
                                    <Button disabled={isSubmitting} id="btn_facility_member_add_submit" color="primary"
                                            type="submit" variant="contained"
                                            className={isSubmitting ? "has-loading-spinner" : ""}>
                                        {isSubmitting ? "Saving" : "Save"}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            ) : (
                <>
                    <div className="facility-add-action">
                        <Tooltip title={"Add New Facility Member"}>
                            <p id="btn_facility_member_add_open" onClick={() => setIsMembers(true)}
                               className="generic-add-multiple">
                                + Add a Facility Member
                            </p>
                        </Tooltip>
                    </div>
                </>
            )}
        </div>
    );
};

export default FacilityMemberEditComponent;

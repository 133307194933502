import {combineReducers} from "redux";
import authReducer, {AuthParams} from "./auth.reducer";
import othersReducer, {OtherParams} from "./others.reducer";
import domainsReducer, {DomainParams} from "./domains.reducer";
import pollingReducer, {PollingParams} from "./polling.reducer";
import EmployeeManagementReducer,{EmployeeManagementParams} from "./employee-management.reducer";
import MetaReducer, {MetaParams} from "./meta.reducer";
import HcpManagementReducer, {HcpManagementParams} from "./hcp-management.reducer";

export interface StateParams {
    auth: AuthParams,
    other: OtherParams,
    domains: DomainParams,
    polling: PollingParams,
    employeeManagement:EmployeeManagementParams,
    meta:MetaParams,
    hcpManagement:HcpManagementParams
}

const rootReducer = combineReducers({
    auth: authReducer,
    other: othersReducer,
    domains: domainsReducer,
    polling: pollingReducer,
    employeeManagement:EmployeeManagementReducer,
    meta:MetaReducer,
    hcpManagement:HcpManagementReducer
});

export default rootReducer;

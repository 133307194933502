import {Chip, Paper} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {DateRangeOutlined} from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, {PropsWithChildren, useCallback, useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-multi-date-picker/styles/layouts/mobile.css";
import {localStore} from "../../../helpers";
import "./FacilityFiltersComponent.scss";

export interface FacilityFiltersComponentProps {
    status: any;
    setStatus: any;
    regionList: any;
    dateRange: any;
    setDateRange: any;
    selectedRegions?: any;
    setSelectedRegions?: any;
    resetFilters: any;
    selectedRegionGroups: any;
    setSelectedRegionGroups: any;
}

const region_groups = [
    {
        name: "REG1",
        code: "reg1",
        regions: [
            {
                "title": "US - Los Angeles",
                "code": "US - Los Angeles"
            },
            {
                "title": "US - Lancaster",
                "code": "US - Lancaster"
            },
            {
                "title": "US - Palm Springs",
                "code": "US - Palm Springs"
            },
        ]
    },
    {
        name: "REG2",
        code: "reg2",
        regions: [
            {
                "title": "US - San Francisco",
                "code": "US - San Francisco",
            },
            {
                "title": "US - Sacramento",
                "code": "US - Sacramento"
            },
            {
                "title": "US - San Diego",
                "code": "US - San Diego"
            },
            {
                "title": "US - Central Valley",
                "code": "US - Central Valley"
            },
        ]
    },
    {
        name: "REG3",
        code: "reg3",
        regions: [
            {
                "title": "US - Pennsylvania",
                "code": "US - Pennsylvania",
            },
            {
                "title": "US - New Jersey",
                "code": "US - New Jersey"
            },
            {
                "title": "US - Maine",
                "code": "US - Maine"
            },
            {
                "title": "US - New Hampshire",
                "code": "US - New Hampshire"
            },
        ]
    }
];

const FacilityFiltersComponent = (props: PropsWithChildren<FacilityFiltersComponentProps>) => {

    const statusList = [
        {name: "Active", code: true},
        {name: "Inactive", code: false},
    ];
    const selectedRegionGroups = props?.selectedRegionGroups;
    const setSelectedRegionGroups = props?.setSelectedRegionGroups;
    const setStatus = props?.setStatus;
    const status = props?.status;

    const dateRange = props?.dateRange;
    const setDateRange = props?.setDateRange;
    const [startDate, endDate] = dateRange;

    const regionList: any[] = props.regionList ? props.regionList : [];
    const selectedRegions = props?.selectedRegions;
    const setSelectedRegions = props?.setSelectedRegions;
    const resetFilters = props?.resetFilters;

    const [regionGroups, setRegionGroups] = useState<any>([]);
    // const [selectedRegionGroups, setSelectedRegionGroups] = useState<any>([]);

    const handleDelete = useCallback((chip: any) => {
        let filterdChips = selectedRegions.filter((item: any) => item?.title !== chip?.title);
        setSelectedRegions(filterdChips);
        selectedRegionGroups.forEach((rgroup: any) => {
            rgroup.regions.forEach((item: any) => {
                if (item.code === chip.code) {
                    const filtered=selectedRegionGroups.filter((reg:any)=>reg.code!==rgroup.code)
                    setSelectedRegionGroups(filtered)
                }
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRegionGroups, selectedRegions]);


    const handleGroupRegionDelete = useCallback((chip: any) => {
        let filteredChips = selectedRegionGroups.filter((item: any) => item?.name !== chip?.name);
        setSelectedRegionGroups(filteredChips);
       // console.log(filteredChips);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRegionGroups]);

    const handleDisableReset = (): boolean => {
        if (selectedRegions?.length !== 0 || (status !== "" && status !== null) || dateRange[0] !== null || dateRange[1] !== null || selectedRegionGroups?.length !== 0) {
            return false;
        } else {
            return true;
        }
    };

    useEffect(() => {
        const regionsList: any = [];
        region_groups.forEach((region_group) => {
            regionsList.push(region_group);
        });
        setRegionGroups(regionsList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRegionGroupSelection = useCallback((rgroups: any) => {
        const newlySelectedGroups = [];
     //   const newlyDeSelectedGroups = [];
        setSelectedRegionGroups((oldState: any) => {
            rgroups.forEach((rgroup: any) => {
                const index = oldState.findIndex((item: any) => item.code === rgroup.code);
                if (index > -1) {

                } else {
                    newlySelectedGroups.push(rgroup);
                }
            });
            return rgroups;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
    //    console.log(selectedRegionGroups);
        const region_list:any=[]
        selectedRegionGroups.forEach((region_group: any) => {
            region_group.regions.forEach((item: any) => {
               region_list.push(item)
            });
        });
        setSelectedRegions((prv:any)=>[...region_list])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRegionGroups]);


    const handleRegionSelection = useCallback((regions: any, event: any) => {
       // setSelectedRegions(regions);
        if(regions.length===0){
            setSelectedRegionGroups([])
        }
        selectedRegionGroups.forEach((rgroup: any) => {
            rgroup.regions.forEach((region: any) => {
                if (event.target.ariaSelected && region.title === event.target.innerHTML) {
                    let filteredChips = selectedRegionGroups.filter((item: any) => item.name !== rgroup.name);
                    setSelectedRegionGroups(filteredChips);
                }
            });
        });
        setSelectedRegions(regions)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regionGroups, selectedRegionGroups]);


    return (
        <div className="facility-filters mrg-bottom-20">
            <div className="form-field-wrapper">
                <div className="form-field-left">
                    <div className="form-field-left-items">
                        <div className="form-field-item">
                            <Autocomplete
                                PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                                multiple
                                options={regionList}
                                value={selectedRegions}
                                getOptionLabel={(option: any) => option.title}
                                getOptionSelected={(option, value) => {
                                    return option.title === value?.title;
                                }}
                                id="input_select_region"
                                className="mrg-top-10 "
                                onChange={(event, value) => {
                                    handleRegionSelection(value, event);
                                }}
                                renderTags={() => null}
                                renderInput={(params) => <TextField {...params} id="select_region" variant="outlined"
                                                                    placeholder={"Multiple Regions"}/>}
                            />
                        </div>
                        <div className="form-field-item">
                            <Autocomplete
                                PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                                multiple
                                options={regionGroups}
                                value={selectedRegionGroups}
                                getOptionLabel={(option: any) => option.name}
                                getOptionSelected={(option, value) => {
                                    return option.name === value?.name;
                                }}
                                id="input_select_region"
                                className="mrg-top-10 "
                                onChange={($event, value) => {
                                    handleRegionGroupSelection(value);
                                }}
                                renderTags={() => null}
                                renderInput={(params) => <TextField {...params} id="select_region" variant="outlined"
                                                                    placeholder={"Group Region"}/>}
                            />
                        </div>

                        <div className="form-field-item">
                            <Autocomplete
                                PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                                value={status}
                                options={statusList}
                                getOptionLabel={(option: any) => option.name}
                                placeholder={"Select Status"}
                                id="input_select_status"
                                className="mrg-top-10"
                                onChange={($event, value) => {
                                    setStatus(value);
                                   // console.log(value);
                                }}
                                renderInput={(params) => <TextField {...params} id="select_status" variant="outlined"
                                                                    placeholder={"Status"}/>}
                            />
                        </div>
                    </div>
                </div>

                <div className="form-field-right">
                    <label>
                        <DatePicker
                            dateFormat="MM/dd/yyyy"
                            placeholderText="Select Date"
                            className="custom-input"
                            selectsRange={true}
                            startDate={startDate && new Date(startDate)}
                            endDate={endDate && new Date(endDate)}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            isClearable={true}
                        />
                        {!dateRange[0] && !dateRange[1] &&
                            <DateRangeOutlined className="date-icon" fontSize="medium" color="action"/>}
                    </label>
                </div>
            </div>
            <div className="facility-chips-wrapper">
                {selectedRegions && selectedRegions.length > 0 && (
                    <p className="hcp-chips">
                        {selectedRegions.map((data: any) => (
                            <Chip key={data?.title} label={data?.title} onDelete={() => {
                                handleDelete(data);
                            }}/>
                        ))}
                    </p>
                )}
                {selectedRegionGroups && selectedRegionGroups.length > 0 && (
                    <p className="hcp-chips">
                        {selectedRegionGroups.map((data: any) => (
                            <Chip key={data?.name} label={data?.name} onDelete={() => handleGroupRegionDelete(data)}/>
                        ))}
                    </p>
                )}
                <span
                    onClick={() => {
                        resetFilters();
                    }}
                    color="secondary"
                    id="btn_reset_filter"
                    className={`clear-all-filters mrg-top-10  ${handleDisableReset() ? " hide-filter" : "show-filter"}`}
                >
          Clear All Filters
        </span>
            </div>
        </div>
    );
};

export const clearFacilityFilterValues = () => {
    localStore.removeItem("facilityRegions");
    localStore.removeItem("facilityStatus");
    localStore.removeItem("facilityDateRange");
};

export default FacilityFiltersComponent;
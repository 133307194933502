import "./LegendCardComponent.scss";
import React, {useCallback} from "react";
import {ImageConfig} from "../../../constants";
import {Tooltip} from "@material-ui/core";

interface LegendCardComponentProps {
    setIsLegendCardOpen: any;
    setSelectedLegend: any;
    selectedLegend: any;
}


const LegendCardComponent = (props: LegendCardComponentProps) => {
    const {setIsLegendCardOpen, setSelectedLegend, selectedLegend} = props;

    const LegendSwitcher = useCallback((legendLabel: any) => {
        if (!selectedLegend.includes(legendLabel)) {
            setSelectedLegend([...selectedLegend, legendLabel]);
        } else {
            const filteredSelectedLegend = selectedLegend.filter((item: any) => item !== legendLabel);
            setSelectedLegend(filteredSelectedLegend);
        }
        // eslint-disable-next-line
    }, [selectedLegend]);

    return (
        <>
            <div className={"legend-card-component component"}>
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <div onClick={() => {
                        LegendSwitcher("Open Shift");
                    }} style={{display: "flex", marginRight: "10px", cursor: "pointer"}}>
                        {!selectedLegend.includes('Open Shift') ? (
                            <Tooltip title={"Enable Open Shift"}>
                                <div style={{marginTop: "5px"}}>
                                    <img style={{width: "30", height: "30px"}}
                                         src={ImageConfig.InactiveFacilityLegendIcon} alt=""/>
                                </div>
                            </Tooltip>
                        ) : (
                            <>
                                <div className={'selected-legend'}>
                                    <img src={ImageConfig.FacilityLegendIcon} alt=""/>
                                    <div>Open Shifts</div>
                                </div>
                            </>
                        )}
                    </div>
                    <div onClick={() => {
                        LegendSwitcher('Active Facility');
                    }} style={{display: "flex", marginRight: "10px", cursor: "pointer"}}>
                        {!selectedLegend.includes('Active Facility') ? (
                            <Tooltip title={"Enable Active Facility"}>
                                <div style={{marginTop: "5px"}}>
                                    <img style={{width: "30px", height: "30px"}}
                                         src={ImageConfig.InactiveFacilityLegendIcon} alt=""/>
                                </div>
                            </Tooltip>
                        ) : (
                            <>
                                <div className={'selected-legend'}>
                                    <img src={ImageConfig.OpenShiftLegendIconForMap} alt=""/>
                                    <div>Active Facility</div>
                                </div>
                            </>
                        )}
                    </div>
                    <div onClick={() => {
                        LegendSwitcher("Active Hcp");
                    }} style={{display: "flex", marginRight: "10px", cursor: "pointer"}}>
                        {!selectedLegend.includes('Active Hcp') ? (
                            <Tooltip title={"Enable Active Staff"}>
                                <div style={{marginTop: "5px"}}>
                                    <img style={{width: "30", height: "30px"}} src={ImageConfig.InactiveHcpLegendIcon}
                                         alt=""/>
                                </div>
                            </Tooltip>
                        ) : (
                            <>
                                <div className={'selected-legend'}>
                                    <img src={ImageConfig.HcpLegendIcon} alt=""/>
                                    <div>Active Staff</div>
                                </div>
                            </>
                        )}
                    </div>
                    <div style={{background: "#E8E8E8", height: "31px", width: "1.5px", marginRight: "10px"}}/>
                    <div style={{cursor: "pointer"}} onClick={() => {
                        setIsLegendCardOpen(false);
                    }}>
                        <img src={ImageConfig.RightArrow} alt={ImageConfig.RightArrow}/>
                    </div>
                </div>

            </div>

        </>
    );
};

export default LegendCardComponent;

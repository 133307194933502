export const FETCH_NURSE_CHAMPION_LIST_REQUEST = "FETCH_NURSE_CHAMPION_LIST_REQUEST";
export const FETCH_NURSE_CHAMPION_LIST_SUCCESS = "FETCH_NURSE_CHAMPION_LIST_SUCCESS";
export const FETCH_NURSE_CHAMPION_LIST_FAILURE = "FETCH_NURSE_CHAMPION_LIST_FAILURE";
export const FETCH_USER_BASIC_DETAILS_REQUEST = "FETCH_USER_BASIC_DETAILS_REQUEST";
export const FETCH_USER_BASIC_DETAILS_SUCCESS = "FETCH_USER_BASIC_DETAILS_SUCCESS";
export const FETCH_USER_BASIC_DETAILS_FAILURE = "FETCH_USER_BASIC_DETAILS_FAILURE";

export const fetchNurseChampionListRequest = (searchNc: string) => ({
    type: FETCH_NURSE_CHAMPION_LIST_REQUEST,
    searchNc
});

export const fetchNurseChampionListSuccess = (ncList: any[]) => ({
    type: FETCH_NURSE_CHAMPION_LIST_SUCCESS,
    ncList,
});

export const fetchNurseChampionListFailure = (error: any) => ({
    type: FETCH_NURSE_CHAMPION_LIST_FAILURE,
    error,
});

export const fetchUserBasicDetailsRequest = (userId:any) => ({
    type: FETCH_USER_BASIC_DETAILS_REQUEST,
    userId
});

export const fetchUserBasicDetailsSuccess = (userData: any) => ({
    type: FETCH_USER_BASIC_DETAILS_SUCCESS,
   userData
});

export const fetchUserBasicDetailsFailure = (error: any) => ({
    type: FETCH_USER_BASIC_DETAILS_FAILURE,
    error,
});
import "./GenerateFilters.scss";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Chip, Paper} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import DatePicker from "react-datepicker";
import {DateRangeOutlined} from "@material-ui/icons";
import React, {useCallback, useEffect, useState} from "react";
import {CommonService} from "../../../../../helpers";
import {ENV} from "../../../../../constants";
import {makeStyles} from "@material-ui/core/styles";
import {timeTypesForFilters, AllShiftStatusList} from "../../../../../constants/data";
import {useLocalStorage} from "../../../../../components/useLocalStorage";
import {useSelector} from "react-redux";
import {StateParams} from "../../../../../store/reducers";

const [firstDayOfMonth, lastDayOfMonth] = CommonService.getFirstDayAndLastDayUsingDate();
const useStyles = makeStyles({
    autocomplete: {
        '& .MuiInputBase-input': {
            height: '14px', // Adjust the height value as needed
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            paddingTop: '4px', // Adjust the padding value as needed
            paddingBottom: '4px', // Adjust the padding value as needed
        },
    },
});

interface GenerateFiltersProps {
    selectedFacility: any;
    setSelectedFacility: any;
    dateRange: any;
    setDateRange: any;
    timeshiftList: any;
    selectedHcps: any;
    setSelectedHcps: any;
    setSelectedTimeTypes: any;
    selectedTimeTypes: any;
    selectedStatusTypes: any;
    setSelectedStatusTypes: any;
    isAllStatusTypesSelected: any;
    setIsAllStatusTypesSelected: any;
    isPreviewingTheTimesheet: any;
}

const GenerateFilters = (props: GenerateFiltersProps) => {
    const classes = useStyles();
    const [facilityList, setFacilityList] = useState<any>(null);
    const selectedFacility = props?.selectedFacility;
    const setSelectedFacility = props?.setSelectedFacility;
    const {
        setSelectedStatusTypes,
        isPreviewingTheTimesheet,
        selectedStatusTypes,
        isAllStatusTypesSelected,
        setIsAllStatusTypesSelected
    } = props;
    const dateRange = props?.dateRange;
    const setDateRange = props?.setDateRange;
    const [startDate, endDate] = dateRange;
    const {selectedHcps, setSelectedHcps, selectedTimeTypes, setSelectedTimeTypes} = props;
    //  const timeshiftList = props?.timeshiftList;
    const [isAllTimeTypesSelected, setisAllTimeTypesSelected] = useLocalStorage('isAllTimeTypesSelectedinGenerate', false);
    const {hcpTypes} = useSelector((state: StateParams) => state.meta);
    const [isAllHcpTypeSelected, setIsAllHcpTypeSelected] = useLocalStorage('isAllHcpTypeSelectedGenerate', false);

    const facilityListInit = useCallback(() => {
        let facilityUrl = "facility/lite";
        let facilityPayload: any = {};
        CommonService._api.post(ENV.API_URL + facilityUrl, facilityPayload).then((resp) => {
            setFacilityList(resp?.data);
        }).catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const clearFilterValues = () => {
        setDateRange([firstDayOfMonth, lastDayOfMonth]);
        setSelectedFacility(null);
        setSelectedHcps([]);
        setSelectedTimeTypes([]);
        setSelectedStatusTypes([]);
    };

    const resetFilters = () => {
        clearFilterValues();
    };

    const handleDisableReset = (): boolean => {
        if ((selectedFacility !== null) || (selectedHcps?.length > 0 || selectedHcps !== null) || (setSelectedTimeTypes.length > 0 || true) || dateRange[0] !== null || dateRange[1] !== null) {
            return false;
        } else {
            return true;
        }
    };

    useEffect(() => {
        facilityListInit();
    }, [facilityListInit]);
    const handleHcpDelete = (chip: any) => {
        // if (props?.resetSelectedCount) {
        //     props?.resetSelectedCount();
        // }
        let filterdChips = selectedHcps?.filter((item: any) => item !== chip);
        setSelectedHcps(filterdChips);
    };

    const handleTimeTypeDelete = (chip: any) => {
        let filterdChips = selectedTimeTypes?.filter((item: any) => item !== chip);
        setSelectedTimeTypes(filterdChips);
    };


    const handleFilterValueSelect = useCallback(
        (newValues: any, options: any[], setSelectedFilterField: any, setIsAllMenuItemsSelected: any, event: any) => {

            if (newValues.length === 0) {
                setIsAllMenuItemsSelected(false);
            }

            if (newValues.includes("Select All") || newValues.includes("Clear All")) {


                if (newValues.includes("Select All")) {
                    setIsAllMenuItemsSelected(true);
                    const allOptions = options.map((item: any) => item.code);
                    setSelectedFilterField(allOptions);
                }

                if (newValues.includes("Clear All")) {
                    setIsAllMenuItemsSelected(false);
                    setSelectedFilterField([]);
                }


                return;
            }


            //for single select
            setSelectedFilterField(newValues);
        },
        []
    );

    const formatFilterMenuListToHandleObj = useCallback((options: any[], isAllMenuItemSelected = false): any[] => {
        let menuList = options;
        //  console.log(options);
        const allOptions = options.filter((item) => {
            return item.code !== "Select All" && item.code !== "Clear All";
        });

        if (isAllMenuItemSelected) {
            menuList = [{name: "Clear All", code: "Clear All"}, ...allOptions];
        }

        if (!isAllMenuItemSelected) {
            menuList = [{name: "Select All", code: "Select All"}, ...allOptions];
        }

        return menuList;
    }, []);

    const handleFilterValueSelectToHandleObj = useCallback(
        (newValues: any, options: any[], setSelectedFilterField: any, setIsAllMenuItemsSelected: any) => {
            const isSelectAll = newValues.some((f: any) => f.code === "Select All");
            const isClearAll = newValues.some((f: any) => f.code === "Clear All");

            console.log(newValues);
            if (newValues.length === 0) {
                setIsAllMenuItemsSelected(false);
            }

            if (isSelectAll || isClearAll) {
                if (isSelectAll) {
                    setIsAllMenuItemsSelected(true);
                    const allOptions = options.filter((item) => {
                        return item.code !== "Select All" && item.code !== "Clear All";
                    });
                    setSelectedFilterField(allOptions);
                }

                if (isClearAll) {
                    setIsAllMenuItemsSelected(false);
                    setSelectedFilterField([]);
                }


                return;
            }

            //for single select
            setSelectedFilterField(newValues);
        },
        []
    );


    const handleStatusDelete = (chip: any) => {
        let filterdChips = selectedStatusTypes?.filter((item: any) => item.code !== chip);
        setSelectedStatusTypes(filterdChips);
    };

    const formatFilterMenuList = useCallback((options: any[], isAllMenuItemSelected = false): any[] => {
        let menuList = options?.map((option: any) => option?.code);

        if (isAllMenuItemSelected) {
            menuList = ["Clear All", ...menuList];
        }
        if (!isAllMenuItemSelected) {
            menuList = ["Select All", ...menuList];
        }
        return menuList;
    }, []);
    // const handleDisableReset = (): boolean => {
    //     if ((status !== "" && status !== null) || dateRange[0] !== null || dateRange[1] !== null) {
    //         return false;
    //     } else {
    //         return true;
    //     }
    // };
    return (
        <>
            <div className={'generate-filter'}>
                <div className="form-field-wrapper">
                    <div className="form-field-left">
                        <div className="form-field-left-items">
                            <div className="form-field-item">
                                {facilityList && <>
                                    <Autocomplete
                                        PaperComponent={({children}) => <Paper
                                            style={{color: "#1e1e1e"}}>{children}</Paper>}
                                        value={selectedFacility}
                                        options={facilityList}
                                        getOptionLabel={(option: any) => option.name}
                                        placeholder={"Select Facility"}
                                        id="input_select_status"
                                        className={classes.autocomplete}
                                        onChange={($event, value) => {
                                            setSelectedFacility(value);
                                        }}
                                        renderInput={(params) => <TextField {...params} id="select_status"
                                                                            variant="outlined"
                                                                            placeholder={"Facility"}/>}
                                    />
                                </>}
                            </div>
                        </div>
                    </div>
                    {selectedFacility && (dateRange[0] || dateRange[1]) && !isPreviewingTheTimesheet &&<>
                        <div className="form-field-item">
                            <Autocomplete
                                disableClearable
                                className={classes.autocomplete}
                                PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                                multiple
                                id="input_select_time_types"
                                renderTags={() => null}
                                renderInput={(params) => <TextField {...params} variant="outlined"
                                                                    placeholder="Shift Types"/>}
                                disableCloseOnSelect
                                value={selectedTimeTypes}
                                options={formatFilterMenuList(timeTypesForFilters, isAllTimeTypesSelected)}
                                onChange={(e, newValue) =>
                                    handleFilterValueSelect(newValue, timeTypesForFilters, setSelectedTimeTypes, setisAllTimeTypesSelected, e)
                                }
                            />
                        </div>
                        {!isPreviewingTheTimesheet &&
                            <div className="form-field-item">
                                <Autocomplete
                                    disableClearable
                                    className={classes.autocomplete}
                                    PaperComponent={({children}) => <Paper
                                        style={{color: "#1e1e1e"}}>{children}</Paper>}
                                    multiple
                                    id="input_select_status"
                                    value={selectedStatusTypes}
                                    getOptionLabel={(option) => option?.name}
                                    getOptionSelected={(option, value) => option.name === value?.name}
                                    options={formatFilterMenuListToHandleObj(AllShiftStatusList, isAllStatusTypesSelected)}
                                    onChange={(e, newValue) => {
                                        if (newValue) {
                                            handleFilterValueSelectToHandleObj(
                                                newValue,
                                                AllShiftStatusList,
                                                setSelectedStatusTypes,
                                                setIsAllStatusTypesSelected
                                            );
                                        }
                                    }}
                                    renderTags={() => null}
                                    renderInput={(params) => <TextField {...params} variant="outlined"
                                                                        placeholder="Status"/>}
                                    disableCloseOnSelect
                                />
                            </div>}
                        <div className="form-field-item">
                            <Autocomplete
                                disableClearable
                                className={classes.autocomplete}
                                PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                                multiple
                                id="input_select_hcps"
                                value={selectedHcps}
                                options={formatFilterMenuList(hcpTypes, isAllHcpTypeSelected)}
                                onChange={(e, newValue) =>
                                    handleFilterValueSelect(newValue, hcpTypes, setSelectedHcps, setIsAllHcpTypeSelected, e)
                                }
                                renderTags={() => null}
                                renderInput={(params) => <TextField {...params} variant="outlined"
                                                                    placeholder="Staff Types"/>}
                                disableCloseOnSelect
                            />
                        </div>
                    </>}
                    <div className="form-field-right">
                        <DatePicker
                            dateFormat="MM/dd/yyyy"
                            placeholderText="Select Date"
                            className="custom-input"
                            selectsRange={true}
                            startDate={startDate && new Date(startDate)}
                            endDate={endDate && new Date(endDate)}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            isClearable={true}
                        />
                        {!dateRange[0] && !dateRange[1] &&
                            <DateRangeOutlined className="date-icon" fontSize="medium" color="action"/>}
                    </div>
                </div>
            </div>
            <div className="custom-chips-wrapper">
                {selectedHcps && selectedHcps.length > 0 && (
                    <p className="custom-chips">
                        {selectedHcps.map((data: any) => (
                            <Chip key={data} label={data} onDelete={() => handleHcpDelete(data)}/>
                        ))}
                    </p>
                )}
                {selectedTimeTypes && selectedTimeTypes.length > 0 && (
                    <p className="custom-chips">
                        {selectedTimeTypes.map((data: any) => (
                            <Chip key={data} label={data} onDelete={() => handleTimeTypeDelete(data)}/>
                        ))}
                    </p>
                )}
                {selectedStatusTypes && selectedStatusTypes.length > 0 && (
                    <p className="custom-chips">
                        {selectedStatusTypes.map((data: any, index: any) => (<Chip key={index} label={data?.name}
                                                                                   onDelete={() => handleStatusDelete(data?.code)}/>)
                        )}
                    </p>
                )}
                <div className="facility-chips-wrapper">
                        <span
                            onClick={() => {
                                resetFilters();
                            }}
                            color="secondary"
                            id="btn_reset_filter"
                            className={`clear-all-filters mrg-top-10  ${handleDisableReset() ? " hide-filter" : "show-filter"}`}
                        >
                        Clear All Filters
                        </span>
                </div>
            </div>
        </>
    );
};

export default GenerateFilters;

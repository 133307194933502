import "./HcpNoShowConfirmationBox.scss"
import DialogActions from "@material-ui/core/DialogActions";
import {Button} from "@material-ui/core";
import React from "react";

interface HcpNoShowConfirmationProps{
    cancelMarkingPendingConcern:any;
    confirmMarkingPendingConcern:any;
    selectedShifts:any;
    isHcpNoShowApiInProgress:any;
}

const HcpNoShowConfirmationBox=(props:HcpNoShowConfirmationProps)=>{
    const {cancelMarkingPendingConcern,confirmMarkingPendingConcern,isHcpNoShowApiInProgress}=props
    return(
        <>
            <div className={'hcp-no-show-component'}>
                <div className={'hcp-no-show-text'}>
                    Do you want to mark this shift as "no show"?
                </div>
                <DialogActions className={'pdd-20'}>
                    <Button onClick={cancelMarkingPendingConcern} variant={"contained"} color={'primary'}>
                        {'No, Cancel'}
                    </Button>
                    <Button className={isHcpNoShowApiInProgress ? "has-loading-spinner" : ""}  onClick={confirmMarkingPendingConcern} disabled={isHcpNoShowApiInProgress} variant={"contained"} color={'default'}>
                        {'Yes, Confirm'}
                    </Button>
                </DialogActions>
            </div>
        </>
    )
}

export default HcpNoShowConfirmationBox
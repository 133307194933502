import React, {useCallback, useEffect, useState} from 'react';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

export interface DatePickerComponentProps {
    value?: string;
    onChange?: (text: string) => void;
    placeholder?: string;
}

const DatePickerComponent = (props: DatePickerComponentProps) => {

    const {value, onChange,placeholder} = props;
    const [tmpValue, setTmpValue] = useState(value);
    const [err,setErr]=useState<any>(null)

    useEffect(() => {
        setTmpValue(value || '');
    }, [value]);

    const onDateChange = useCallback((value) => {
       setErr(value)
        setTmpValue(moment(value).format('YYYY-MM-DD'));
        if (onChange) {
            onChange(moment(value).format('YYYY-MM-DD'));
        }
    }, [onChange]);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                fullWidth
                id="date-picker-check-in-date"
                label={placeholder}
                inputVariant="outlined"
                format="MM/dd/yyyy"
                value={tmpValue}
                onChange={(e: any) => onDateChange(e)}
                KeyboardButtonProps={{
                    "aria-label": "change date"
                }}
                InputLabelProps={{
                            shrink: true,
                      }}
                invalidDateMessage={err?'Invalid Date Format':''}
            />
        </MuiPickersUtilsProvider>
    );
};

export default DatePickerComponent;

import {ENV} from "../../constants";
import {call, put, takeLatest} from "redux-saga/effects";
import {
    FETCH_NURSE_CHAMPION_LIST_REQUEST, FETCH_USER_BASIC_DETAILS_REQUEST,
    fetchNurseChampionListFailure,
    fetchNurseChampionListSuccess, fetchUserBasicDetailsFailure, fetchUserBasicDetailsSuccess
} from "../actions/employee-management.action";
import CommonService from "../../helpers/common-service";

function fetchNcList(searchNc: any): any {
    const url = ENV.API_URL + "user/lite?role=nurse_champion&is_active=true";
    const searchNcWord: any = searchNc || "";
    console.log(searchNcWord);
    return searchNcWord!=="" ? CommonService._api.get(`${url}&search=${searchNcWord}`) : CommonService._api.get(url);
}

function* getNcList({searchNc}: any) {
    try {
        //@ts-ignore
        const response = yield call(fetchNcList, searchNc);
        yield put(fetchNurseChampionListSuccess(response.data || []));
    } catch (error) {
        yield put(fetchNurseChampionListFailure(error));
    }
}

function fetchUserBasicDetails(userId:any): any {
    return CommonService._api.get(ENV.API_URL+`user/${userId}`)
}

function* getUserBasicDetails({userId}:any){
    try {
        //@ts-ignore
        const response=yield call(fetchUserBasicDetails,userId)
        yield put(fetchUserBasicDetailsSuccess(response?.data||null))
    }catch (error){
        yield put(fetchUserBasicDetailsFailure(error))
    }
}

export default function* employeeManagementSaga() {
    yield takeLatest(FETCH_NURSE_CHAMPION_LIST_REQUEST, getNcList);
    yield takeLatest(FETCH_USER_BASIC_DETAILS_REQUEST,getUserBasicDetails)
}

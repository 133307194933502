import {Button, Checkbox, MenuItem, Tooltip, TextField as MaterialTextField} from "@material-ui/core";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import {Rating} from "@material-ui/lab";
import {Field, Form, Formik, FormikHelpers} from "formik";
import {TextField} from "formik-material-ui";
import moment from "moment";
import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {TsFileUploadConfig, TsFileUploadWrapperClass} from "../../../../classes/ts-file-upload-wrapper.class";
import FileDropZoneComponent from "../../../../components/core/FileDropZoneComponent";
import DialogComponent from "../../../../components/DialogComponent";
import LoaderComponent from "../../../../components/LoaderComponent";
import CustomPreviewFile from "../../../../components/shared/CustomPreviewFile";
import {ENV} from "../../../../constants";
import {isHCPPaid as hcpPaidOptions} from "../../../../constants/data";
import {ApiService, CommonService, Communications} from "../../../../helpers";
import ShiftTimeline from "../../timeline/ShiftTimeline";
import "./ShiftMasterViewScreen.scss";

import EditShiftDetailsDialog from "../../editShiftDetailsDialog/EditShiftDetailsDialog";

import EditTimelineComponent from "../editTimelineComponent/EditTimelineComponent";

import {shiftClosedFieldsValidation, showDropDownBelowField} from "./ShiftMasterValidations";
import ApprovedCancelledComponentDetails
    from "../../approved-cancelled-shift-details/ShiftDetailsApprovedCancelledComponent";

// const SHOW_SENT_HOME_FIELD_FOR_STATUS = ["complete", "cancelled", "closed"];
// const SHOW_SALARY_CREDIT_DAY_FIELD_FOR_STATUS = ["pending", "in_progress", "complete", "cancelled", "closed"];
const SHOW_SHIFT_RATING_FOR_COMPLETE_AND_CLOSED = ["complete", "closed"];


const ShiftMasterViewScreen = () => {
    const param = useParams<any>();
    const {id} = param;
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isAttachmentsLoading] = useState<boolean>(false);
    const [isAttachhmentsDownloadLoading] = useState<boolean>(false);
    const [basicDetails, setBasicDetails] = useState<any>(null);
    const [attachmentsList, setAttachmentsList] = useState<any>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [previewFileData, setPreviewFile] = useState<any | null>(null);
    const [editTimelineOpen, setEditTimelineOpen] = useState<boolean>(false);
    const [fileUpload, setFileUpload] = useState<{ wrapper: any } | null>(null);
    const [required_attachments, setRequiredAttachments] = useState<any>([{name: "CDPH 530 A Form", index: -1}]);
    //const [downloadAttachmentsList, setDownloadAttachmentsList] = useState<any | null>(null);
    const [isTimeSheetBeingUpdated, setIsTimeSheetBeingUpdated] = useState<boolean>(false);
    const [hcpRating, setHcpRating] = useState<number | null>(null);
    const [isDataSubmitting, setIsDataSubmitting] = useState<boolean>(false);
    const [isRemarksSubmitting, setIsRemarksSubmitting] = useState<boolean>(false);
    const [isFacilityConfirm, setIsFacilityConfirm] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isPaymentSubmitting, setIsPaymentSubmitting] = useState<boolean>(false);
    const [isHCPPaid, setIsHCPPaid] = useState<boolean | null>(null);
    const [isHCPBilled, setIsHCPBilled] = useState<boolean | null>(null);
    const [isEditShiftOpen, setIsEditShiftOpen] = useState<boolean>(false);
    const [isFacilityBeingConfirmed, setIsFacilityBeingConfirmed] = useState<boolean>(false);
    const [remarks, setRemarks] = useState('');
    const [billableHour, setBillableHour] = useState<any>(null);
    const [facilityConfirmationFileUpload, setFacilityConfirmationFileUpload] = useState<{ wrapper: any } | null>(null);
    const [facilityConfirmationAttachmentList, setFacilityConfirmationAttachmentList] = useState<any>(null);
    const [facilityConfirmationDocument, setFacilityConfirmationDocument] = useState<any>([{
        name: "Facility Confirmation Document",
        index: -1
    }]);
    const [facilityConfirmationPreviewFileData, setFacilityConfirmationPreviewFileData] = useState<any | null>(null);
    const [facilityDocumentOpen, setFacilityDocumentOpen] = useState<boolean>(false);
    const timeSheetIsAllowed =
        basicDetails?.status === "complete" ||
        basicDetails?.status === "closed" ||
        basicDetails?.status === "in_progress" ||
        basicDetails?.status === "cancelled" ||
        basicDetails?.status === "pending";

    const billableHourCalculation = useCallback(() => {
        const differenceInMs = new Date(basicDetails?.expected?.shift_end_time).getTime() - new Date(basicDetails?.expected?.shift_start_time).getTime() - (30 * 60 * 1000);
        const differenceInHours = differenceInMs / (1000 * 60 * 60); // convert milliseconds to hours
        const roundedHours = Math.ceil(differenceInHours * 100) / 100;
        setBillableHour(roundedHours);
    }, [basicDetails]);


    useEffect(() => {
        billableHourCalculation();
    }, [billableHourCalculation]);


    const handleDeleteFacilityConfirmation = useCallback(() => {
        if (facilityConfirmationAttachmentList.length > 0) {
            const file_key = facilityConfirmationAttachmentList[0].file_key;
            ApiService.delete(ENV.API_URL + `deleteAttachment`, {
                file_key,
            })
                .then((res) => {
                    CommonService.showToast(res?.msg || `Attachment deleted successfully,`, "success");
                    setFacilityConfirmationAttachmentList([]);
                })
                .catch((err) => {
                    CommonService.showToast(err?.msg || `Error,`, "error");
                    console.log(err);
                });
        }
    }, [facilityConfirmationAttachmentList]);

    const deleteFacilityConfirmationFile = (temp: any) => {
        let data = facilityConfirmationFileUpload?.wrapper.filter((_: any, index: any) => index !== temp);
        if (facilityConfirmationDocument[temp]) {
            facilityConfirmationDocument[temp].index = -1;
            setFacilityConfirmationDocument([...facilityConfirmationDocument]);
        }
        setFacilityConfirmationFileUpload((prevState) => {
            return {wrapper: [...data]};
        });
    };

    const OnFacilityConfirmationFileSelected = (files: File[], index: any) => {
        if (facilityConfirmationDocument[index]) {
            facilityConfirmationDocument[index].index = facilityConfirmationFileUpload?.wrapper?.length || 0;
            setFacilityConfirmationDocument([...facilityConfirmationDocument]);
        }
        for (let file of files) {
            const uploadConfig: TsFileUploadConfig = {
                file: file,
                fileFieldName: "Data",
                uploadUrl: ENV.API_URL + "facility/add",
                allowed_types: ["jpg", "png", "csv", "pdf", "jpeg"],
                extraPayload: {file_type: facilityConfirmationDocument[index]?.name},
            };
            const uploadWrapper = new TsFileUploadWrapperClass(
                uploadConfig,
                CommonService._api,
                (state: { wrapper: TsFileUploadWrapperClass }) => {
                    // console.log(state);
                    setFacilityConfirmationFileUpload((prevState) => {
                        if (prevState) {
                            const index = prevState?.wrapper.findIndex((value: any) => value.uploadId === state.wrapper.uploadId);
                            prevState.wrapper[index] = state.wrapper;
                            return {wrapper: prevState.wrapper};
                        }
                        return prevState;
                    });
                }
            );
            uploadWrapper.onError = (err, heading) => {
                // console.error(err, heading);
                if (heading) {
                    CommonService.showToast(err, "error");
                }
            };
            uploadWrapper.onSuccess = (resp) => {
                console.log(resp);
                if (resp && resp.success) {
                    CommonService.showToast(resp.msg || resp.error, "success");
                }
            };
            uploadWrapper.onProgress = (progress) => {
            };
            setFacilityConfirmationFileUpload((prevState) => {
                let state: TsFileUploadWrapperClass[] = [];
                if (prevState) {
                    state = prevState?.wrapper;
                }
                const newState = [...state, uploadWrapper];
                return {wrapper: newState};
            });
        }
    };

    const handleShiftCloseFieldsUpdate = (payload: any) => {
        const {is_hcp_paid, hcp_pay_date, comments, facility_billed_details} = payload;
        const {invoice_date, is_billed, comments: facility_comments} = facility_billed_details;
        setIsSubmitting(true);
        return new Promise((resolve, reject) => {
            ApiService.put(ENV.API_URL + "shift/" + id, {
                is_hcp_paid,
                comments,
                is_billed,
                facility_comments,
                invoice_date: invoice_date ? moment(invoice_date).format("YYYY-MM-DD") : null,
                hcp_pay_date: hcp_pay_date ? moment(hcp_pay_date).format("YYYY-MM-DD") : null,
            })
                .then((res: any) => {
                    setIsSubmitting(false);
                    resolve(res);
                })
                .catch((err) => {
                    console.log(err);
                    setIsSubmitting(false);
                    reject(err);
                });
        });
    };

    const previewFacilityConfirmationDocument = useCallback((index: any, type: any) => {
        if (type === "local") {
            setFacilityConfirmationPreviewFileData(facilityConfirmationFileUpload?.wrapper[0]);
        } else {
            setFacilityConfirmationPreviewFileData(facilityConfirmationAttachmentList[index]);
        }
        setFacilityDocumentOpen(true);
    }, [facilityConfirmationAttachmentList, facilityConfirmationFileUpload?.wrapper]);

    const handleGetUrlForFacilityConfirmationDocumentUpload = useCallback(() => {
        return new Promise((resolve, reject) => {
            const file = facilityConfirmationFileUpload?.wrapper[0]?.file;
            const formData = new FormData();
            if (file) {
                formData.append("attachment", file);
            }
            if (facilityConfirmationFileUpload?.wrapper.length > 0) {
                CommonService._api
                    .upload(ENV.API_URL + "shift/" + id + "/facilityConfAttachment", formData)
                    .then((resp) => {
                        CommonService.showToast(resp?.msg || "attachment uploaded", "success");
                        resolve(resp);
                    })
                    .catch((err) => {
                        console.log(err);
                        resolve(err);
                        CommonService.showToast(err?.error || "Error", "error");
                    });
            } else {
                resolve(null);
            }
        });
    }, [facilityConfirmationFileUpload?.wrapper, id]);

    const onAdd = (payload: any, {setSubmitting, setErrors, resetForm}: FormikHelpers<any>) => {
        setIsSubmitting(true);
        setIsPaymentSubmitting(true);
        Promise.all([handleShiftCloseFieldsUpdate(payload), handleGetUrlForUpload(), handleConfirmationFromFacility(), handleRemarksEdit(), handleGetUrlForFacilityConfirmationDocumentUpload()])
            .then((res: any) => {
                CommonService.showToast(res?.msg || "Success", "success");
                setSubmitting(false);
                setIsPaymentSubmitting(false);
                history.goBack();
            })
            .catch((err) => {
                CommonService.showToast(err?.error || "Error", "error");
                setSubmitting(false);
                setIsPaymentSubmitting(false);
                setSubmitting(false);
            });
    };

    const history = useHistory();

    const previewFile = useCallback(
        (index: any, type: any) => {
            if (type === "local") {
                setPreviewFile(fileUpload?.wrapper[0]);
            } else {
                setPreviewFile(attachmentsList[index]);
            }
            setOpen(true);
        },
        [attachmentsList, fileUpload?.wrapper]
    );

    // const getShiftAttachmentsDownload = useCallback(() => {
    //     setIsAttachhmentsDownloadLoading(true);
    //     CommonService._api
    //         .get(ENV.API_URL + "shift/" + id + "/attachments")
    //         .then((resp) => {
    //             setDownloadAttachmentsList(resp?.data);
    //             setIsAttachhmentsDownloadLoading(false);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //             setIsAttachhmentsDownloadLoading(false);
    //         });
    // }, [id]);

    const cancelPreviewFile = useCallback(() => {
        setOpen(false);
    }, []);
    const confirmPreviewFile = useCallback(() => {
        setOpen(false);
    }, []);

    // const getShiftAttachments = useCallback(() => {
    //     setIsAttachmentsLoading(true);
    //     CommonService._api
    //         .get(ENV.API_URL + "shift/" + id + "/attachments?is_preview=true")
    //         .then((resp) => {
    //             seAttachmentsList(resp?.data || []);
    //             setIsAttachmentsLoading(false);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //             setIsAttachmentsLoading(false);
    //         });
    // }, [id]);

    const handleFacilityConfirmation = (e: any) => {
        setIsFacilityConfirm(e.target.checked);
    };

    const OnFileSelected = (files: File[], index: any) => {
        if (required_attachments[index]) {
            required_attachments[index].index = fileUpload?.wrapper?.length || 0;
            setRequiredAttachments([...required_attachments]);
        }
        for (let file of files) {
            // console.log(file)
            const uploadConfig: TsFileUploadConfig = {
                file: file,
                fileFieldName: "Data",
                uploadUrl: ENV.API_URL + "facility/add",
                allowed_types: ["jpg", "png", "csv", "pdf", "jpeg"],
                extraPayload: {file_type: required_attachments[index]?.name},
            };
            const uploadWrapper = new TsFileUploadWrapperClass(
                uploadConfig,
                CommonService._api,
                (state: { wrapper: TsFileUploadWrapperClass }) => {
                    // console.log(state);
                    setFileUpload((prevState) => {
                        if (prevState) {
                            const index = prevState?.wrapper.findIndex((value: any) => value.uploadId === state.wrapper.uploadId);
                            prevState.wrapper[index] = state.wrapper;
                            return {wrapper: prevState.wrapper};
                        }
                        return prevState;
                    });
                }
            );
            uploadWrapper.onError = (err, heading) => {
                // console.error(err, heading);
                if (heading) {
                    CommonService.showToast(err, "error");
                }
            };
            uploadWrapper.onSuccess = (resp) => {
                // console.log(resp);
                if (resp && resp.success) {
                    CommonService.showToast(resp.msg || resp.error, "success");
                }
            };
            uploadWrapper.onProgress = (progress) => {
            };
            setFileUpload((prevState) => {
                let state: TsFileUploadWrapperClass[] = [];
                if (prevState) {
                    state = prevState?.wrapper;
                }
                const newState = [...state, uploadWrapper];
                return {wrapper: newState};
            });
        }
    };

    const getShiftDetails = useCallback(() => {
        setIsLoading(true);
        CommonService._api
            .get(ENV.API_URL + "shift/" + id)
            .then((resp) => {
                setBasicDetails(resp.data);
                setIsFacilityConfirm(resp?.data?.is_facility_approved);
                setHcpRating(resp.data?.hcp_rating);
                setIsHCPPaid(resp.data?.is_hcp_paid);
                setIsHCPBilled(resp.data?.facility_billed_details?.is_billed);
                setRemarks(resp?.data?.remarks);
                setIsLoading(false);
                if (resp?.data?.timesheet_details?.url !== undefined) {
                    setAttachmentsList([resp?.data?.timesheet_details]);
                }
                if (resp?.data?.facility_confirmtion_details?.url !== undefined) {
                    setFacilityConfirmationAttachmentList([resp?.data?.facility_confirmtion_details]);
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }, [id]);

    const openEditTimeline = useCallback(() => {
        setEditTimelineOpen(true);
    }, []);

    const cancelEditTimelineOpen = useCallback(() => {
        setEditTimelineOpen(false);
    }, []);
    const cancelFacilityConfirmationPreviewFile = useCallback(() => {
        setFacilityDocumentOpen(false);
    }, []);

    const confirmFacilityConfirmationPreviewFile = useCallback(() => {
        setFacilityDocumentOpen(false);
    }, []);
    const deleteFile = (temp: any) => {
        let data = fileUpload?.wrapper.filter((_: any, index: any) => index !== temp);
        if (required_attachments[temp]) {
            required_attachments[temp].index = -1;
            setRequiredAttachments([...required_attachments]);
        }
        setFileUpload((prevState) => {
            return {wrapper: [...data]};
        });
    };

    const handleGetUrlForUpload = useCallback(() => {
        return new Promise((resolve, reject) => {
            setIsTimeSheetBeingUpdated(true);
            const file = fileUpload?.wrapper[0]?.file;
            const formData = new FormData();
            if (file) {
                formData.append("attachment", file);
                formData.append("attachment_type", fileUpload?.wrapper[0]?.extraPayload?.file_type);
            }
            if (fileUpload?.wrapper.length > 0) {
                CommonService._api
                    .upload(ENV.API_URL + "shift/" + id + "/timesheet", formData)
                    .then((resp) => {
                        setIsTimeSheetBeingUpdated(false);
                        resolve(resp);
                        CommonService.showToast(resp?.msg || "attachment uploaded", "success");
                    })
                    .catch((err) => {
                        console.log(err);
                        resolve(err);
                        CommonService.showToast(err?.error || "Error", "error");
                        setIsTimeSheetBeingUpdated(false);
                    });
            } else {
                resolve(null);
            }
        });
    }, [fileUpload?.wrapper, id]);

    const handleConfirmationFromFacility = useCallback(() => {
        setIsFacilityBeingConfirmed(true);
        if ((basicDetails?.status === "complete" || basicDetails?.status === "closed")) {
            return new Promise((resolve, reject) => {
                ApiService.put(ENV.API_URL + "shift/" + id, {
                    is_facility_approved: isFacilityConfirm,
                })
                    .then((res: any) => {
                        resolve(null);
                        setIsFacilityBeingConfirmed(false);
                    })
                    .catch((err) => {
                        console.log(err);
                        setIsFacilityBeingConfirmed(false);
                        reject(err);
                    });
            });
        }
    }, [id, isFacilityConfirm, basicDetails]);

    const handleConfirmationFromFacilityForClosedAndCompletedFields = useCallback(() => {
        setIsDataSubmitting(true);
        return new Promise((resolve, reject) => {
            ApiService.put(ENV.API_URL + "shift/" + id, {
                is_facility_approved: isFacilityConfirm,
                hcp_rating: hcpRating
            })
                .then((res: any) => {
                    setIsDataSubmitting(false);
                    CommonService.showToast(res?.msg, "success");
                    resolve(null);
                })
                .catch((err) => {
                    console.log(err);
                    setIsDataSubmitting(false);
                    reject(err);
                    CommonService.showToast(err?.msg, "error");
                });
        });
    }, [id, isFacilityConfirm, hcpRating]);

    const getUpdatedShiftData = useCallback(() => {
        return new Promise((resolve, reject) => {
            CommonService._api
                .get(ENV.API_URL + "shift/" + id)
                .then((resp) => {
                    setBasicDetails(resp?.data);
                    resolve(null);
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                    setIsLoading(false);
                });
        });
    }, [id]);

    const handleRemarksEdit = useCallback(() => {
        setIsRemarksSubmitting(true);
        return new Promise((resolve, reject) => {
            ApiService.put(ENV.API_URL + "shift/" + id, {
                remarks
            })
                .then((res: any) => {
                    setIsRemarksSubmitting(false);
                    resolve(res);
                })
                .catch((err) => {
                    console.log(err);
                    setIsRemarksSubmitting(false);
                    reject(err);
                });
        });
    }, [id, remarks]);

    const openEditShift = useCallback(() => {
        setIsEditShiftOpen(true);
    }, []);

    const cancelopenEditShift = useCallback(() => {
        setIsEditShiftOpen(false);
    }, []);

    const confirmopenEditShift = useCallback(() => {
        getUpdatedShiftData().then((res) => setIsEditShiftOpen(false));
    }, [getUpdatedShiftData]);

    const confirmOpenEditTimeline = useCallback(() => {
        getUpdatedShiftData().then((res) => setEditTimelineOpen(false));
    }, [getUpdatedShiftData]);


    const handleDeleteTimesheet = useCallback(() => {
        if (attachmentsList.length > 0) {
            const shiftId = basicDetails?._id;
            const file_key = attachmentsList[0].file_key;
            ApiService.delete(ENV.API_URL + `shift/${shiftId}/timesheet`, {
                file_key,
            })
                .then((res) => {
                    CommonService.showToast(res?.msg || `Attachment deleted successfully,`, "success");
                    setAttachmentsList([]);
                })
                .catch((err) => {
                    CommonService.showToast(err?.msg || `Error,`, "error");
                    console.log(err);
                });
        }
    }, [attachmentsList, basicDetails?._id]);


    const handleSubmitForShiftComplete = async () => {
        try {
            await handleConfirmationFromFacilityForClosedAndCompletedFields();
            await handleGetUrlForUpload();
            await handleGetUrlForFacilityConfirmationDocumentUpload();
            await handleRemarksEdit();
            history.goBack();
        } catch (error: any) {
            console.log(error);
            CommonService.showToast(error?.message || "Error", "error");
        }
    };

    const handleSubmitForInProgressPendingAndCancelledShifts = useCallback(() => {
        Promise.all([handleGetUrlForUpload(), handleConfirmationFromFacility(), handleRemarksEdit()])
            .then((res: any) => {
                CommonService.showToast(res?.msg || `Success`, "success");
                history.goBack();
            })
            .catch((err) => console.log(err));
    }, [handleConfirmationFromFacility, handleGetUrlForUpload, handleRemarksEdit, history]);


    useEffect(() => {
        getShiftDetails();
        Communications.pageTitleSubject.next("Shifts Master");
        Communications.pageBackButtonSubject.next("/shiftMaster/list");
    }, [getShiftDetails]);


    const shiftClosedFieldsInitialState = {
        is_hcp_paid: basicDetails?.hasOwnProperty("is_hcp_paid") ? basicDetails?.is_hcp_paid : null,
        hcp_pay_date: basicDetails?.hcp_pay_date ?? null,
        comments: basicDetails?.hasOwnProperty("comments") ? basicDetails?.comments : "",

        facility_billed_details: {
            is_billed: basicDetails?.facility_billed_details?.hasOwnProperty("is_billed")
                ? basicDetails?.facility_billed_details?.is_billed
                : null,
            invoice_date: basicDetails?.facility_billed_details?.invoice_date ?? null,
            comments: basicDetails?.facility_billed_details?.hasOwnProperty("comments")
                ? basicDetails?.facility_billed_details?.comments
                : "",
        },
    };


    if (isLoading || isAttachhmentsDownloadLoading || isAttachmentsLoading) {
        return <LoaderComponent/>;
    }

    return (
        <div className="shift-completed-view screen crud-layout pdd-30">
            <DialogComponent open={open} cancel={cancelPreviewFile} class="preview-content">
                <CustomPreviewFile cancel={cancelPreviewFile} confirm={confirmPreviewFile}
                                   previewData={previewFileData}/>
            </DialogComponent>
            <DialogComponent isEditableDialog={true} open={editTimelineOpen} cancel={cancelEditTimelineOpen}
                             maxWidth="md">
                <EditTimelineComponent
                    cancel={cancelEditTimelineOpen}
                    confirm={confirmOpenEditTimeline}
                    shiftDetails={basicDetails}
                />
            </DialogComponent>

            <DialogComponent open={facilityDocumentOpen} cancel={cancelFacilityConfirmationPreviewFile}
                             class="preview-content">
                <CustomPreviewFile cancel={cancelFacilityConfirmationPreviewFile}
                                   confirm={confirmFacilityConfirmationPreviewFile}
                                   previewData={facilityConfirmationPreviewFileData}/>
            </DialogComponent>

            <DialogComponent open={isEditShiftOpen} cancel={cancelopenEditShift} maxWidth="sm">
                <EditShiftDetailsDialog
                    shiftDetails={basicDetails}
                    cancel={cancelopenEditShift}
                    confirm={confirmopenEditShift}
                    shiftId={id}
                />
            </DialogComponent>

            {!isLoading && (
                <>
                    <div className="d-flex custom-border facility-details">
                        <div className="mrg-right-20">
                            <h2>{basicDetails?.facility?.name}</h2>
                            <p>
                                {basicDetails?.facility?.address?.street},&nbsp;{basicDetails?.facility?.address?.region_name},&nbsp;
                                {basicDetails?.facility?.address?.city},&nbsp;{basicDetails?.facility?.address?.country},&nbsp;
                                {basicDetails?.facility?.address?.zip_code}.
                            </p>
                        </div>
                        <div className="status-wrapper">
                            <div className="d-flex">
                                <p className="status-details mrg-right-0">Status</p>{" "}
                                <p className="status-details">
                                    &nbsp;:&nbsp;
                                    {basicDetails?.status === "in_progress" ? "In Progress" : basicDetails?.status}
                                </p>
                            </div>
                        </div>
                    </div>
                    <ApprovedCancelledComponentDetails basicDetails={basicDetails} openEditShift={openEditShift}/>
                    {(basicDetails?.status === "complete" || basicDetails?.status === "closed") && (
                        <div className="mrg-top-10   custom-border pdd-top-10">
                            <div className={"d-flex"}>
                                <h2>Facility Confirmation</h2>
                                <Checkbox checked={isFacilityConfirm} onChange={handleFacilityConfirmation}/>
                            </div>
                            {isFacilityConfirm && isFacilityConfirm && <>
                                <div className="mrg-top-10 custom-border pdd-top-10">
                                    <div className="mrg-top-20">
                                        {facilityConfirmationAttachmentList && facilityConfirmationAttachmentList?.length > 0 ? (
                                            <>
                                                <h3 className="mrg-top-20 mrg-bottom-0 file_name file_attachment_title">
                                                    {"Facility Confirmation Document"}
                                                </h3>
                                                <div className="d-flex" style={{gap: "50px"}}>
                                                    {facilityConfirmationAttachmentList?.map((item: any, index: any) => {
                                                        return (
                                                            <div className="attachments">
                                                                <div>
                                                                    <p className="">{item?.attachment_type}</p>
                                                                    <Tooltip
                                                                        title="Preview Facility Confirmation Document">
                                                                        {
                                                                            <InsertDriveFileIcon
                                                                                color={"primary"}
                                                                                className="file-icon"
                                                                                onClick={() => previewFacilityConfirmationDocument(index, "api")}
                                                                                style={{cursor: "pointer"}}
                                                                            />
                                                                        }
                                                                    </Tooltip>
                                                                </div>
                                                                <div className="d-flex">
                                                                    <Tooltip
                                                                        title="Preview Facility Confirmation Document">
                                                                        <p onClick={() => previewFacilityConfirmationDocument(index, "api")}
                                                                           className="file-actions">
                                                                            Preview
                                                                        </p>
                                                                    </Tooltip>
                                                                    <Tooltip
                                                                        title="Download Facility Confirmation Document">
                                                                        <div onClick={() => {
                                                                            CommonService.handleDownloadAnyAttachment(item?.file_key);
                                                                        }}
                                                                             className="file-actions mrg-left-10">
                                                                            Download
                                                                        </div>
                                                                    </Tooltip>
                                                                    {(basicDetails?.status === "complete" && basicDetails?.status !== "closed") &&
                                                                        <Tooltip
                                                                            title="Delete  Facility Confirmation Document">
                                                                            <div className="file-actions mrg-left-10"
                                                                                 onClick={handleDeleteFacilityConfirmation}>
                                                                                Delete
                                                                            </div>
                                                                        </Tooltip>
                                                                    }
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="d-flex" style={{gap: "50px"}}>
                                                    {facilityConfirmationDocument?.map((item: any, index: any) => {
                                                        if (item.index !== -1) {
                                                            return (
                                                                <>
                                                                    <div>
                                                                        <div className="custom_file mrg-top-0">
                                                                            <h3 className="mrg-top-20 mrg-bottom-0 file_name file_attachment_title">
                                                                                {" "}
                                                                                {facilityConfirmationFileUpload?.wrapper[facilityConfirmationDocument[index]?.index]?.extraPayload?.file_type}
                                                                            </h3>
                                                                            <div className="mrg-top-15">
                                                                                <InsertDriveFileIcon color={"primary"}
                                                                                                     className="file-icon"/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex file_actions">
                                                                            <Tooltip
                                                                                title={`View ${
                                                                                    facilityConfirmationFileUpload?.wrapper[facilityConfirmationDocument[index]?.index]?.extraPayload?.file_type
                                                                                }`}
                                                                            >
                                                                                <p
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        width: "50px"
                                                                                    }}
                                                                                    className={"delete-cdhp mrg-top-0"}
                                                                                    onClick={() => previewFacilityConfirmationDocument(index, "local")}
                                                                                >
                                                                                    View
                                                                                </p>
                                                                            </Tooltip>
                                                                            <Tooltip
                                                                                title={`Delete ${
                                                                                    facilityConfirmationFileUpload?.wrapper[facilityConfirmationDocument[index]?.index]?.extraPayload?.file_type
                                                                                }`}
                                                                            >
                                                                                <p
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        width: "50px"
                                                                                    }}
                                                                                    className={"delete-cdhp mrg-top-0"}
                                                                                    onClick={() => deleteFacilityConfirmationFile(index)}
                                                                                >
                                                                                    Delete
                                                                                </p>
                                                                            </Tooltip>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            );
                                                        } else {
                                                            return (
                                                                <div className="attachments">
                                                                    <div className="">
                                                                        <h3 className="attachement_name file_attachment_title">{item?.name}</h3>
                                                                        <Tooltip title={`Upload ${item?.name}`}>
                                                                            <div>
                                                                                <FileDropZoneComponent
                                                                                    OnFileSelected={(item) => OnFacilityConfirmationFileSelected(item, index)}
                                                                                    allowedTypes={".pdf"}
                                                                                />
                                                                            </div>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    })}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </>}

                        </div>


                    )}
                    {basicDetails?.status === "cancelled" && (
                        <div className="mrg-top-10 custom-border pdd-top-10">
                            <div className="">
                                <h2>Reason for Cancellation</h2>
                                <p>{basicDetails?.cancelled_details?.reason}</p>
                            </div>
                            <div className="reject-by-wrapper d-flex">
                                <div>
                                    <h3>Cancelled By:</h3>
                                    <p>
                                        {basicDetails?.cancelled_details?.cancelled_by?.first_name} &nbsp;{" "}
                                        {basicDetails?.cancelled_details?.cancelled_by?.last_name}
                                    </p>
                                </div>
                                <div className="mrg-left-50">
                                    <h3>Role:</h3>
                                    <p>{basicDetails?.cancelled_details?.cancelled_by?.role}</p>
                                </div>
                                <div className="mrg-left-50">
                                    <h3>Cancelled Date:</h3>
                                    <p>
                                        {basicDetails?.cancelled_details?.cancelled_date
                                            ? moment(basicDetails?.cancelled_details?.cancelled_date).format("MM-DD-YYYY")
                                            : "N/A"}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                    {basicDetails?.status === "closed" && (
                        <Formik
                            initialValues={shiftClosedFieldsInitialState}
                            validateOnChange={true}
                            validationSchema={shiftClosedFieldsValidation}
                            onSubmit={onAdd}
                        >
                            {({isSubmitting, handleSubmit, isValid, resetForm, setFieldValue, values}) => (
                                <Form id="add-payment-form" className={"payment-name form-holder"}>
                                    <div className="mrg-top-10 custom-border pdd-top-10">
                                        <h2>Payment Details</h2>
                                        <div className="input-container">
                                            <Field
                                                onChange={(e: any) => {
                                                    setFieldValue("is_hcp_paid", e.target.value);
                                                    setIsHCPPaid(e.target.value);
                                                }}
                                                SelectProps={showDropDownBelowField}
                                                select
                                                variant="outlined"
                                                fullWidth
                                                component={TextField}
                                                name="is_hcp_paid"
                                                id="input_is_hcp_paid"
                                                label="Payment Completed"
                                            >
                                                <MenuItem value="">Select </MenuItem>
                                                {hcpPaidOptions.map((item: any, index) => {
                                                    return (
                                                        <MenuItem value={item.value} key={index}
                                                                  id={"is_hcp_paid" + item.value}>
                                                            {item.label}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Field>
                                            <Field
                                                disabled={!isHCPPaid}
                                                required
                                                id="input_hcp_pay_date"
                                                variant="outlined"
                                                format="MM/dd/yyyy"
                                                inputVariant="outlined"
                                                component={TextField}
                                                type="date"
                                                onChange={(e: any) => {
                                                    setFieldValue("hcp_pay_date", e.target.value);
                                                }}
                                                placeholder="MM/DD/YYYY"
                                                fullWidth
                                                autoComplete="off"
                                                InputLabelProps={{shrink: true}}
                                                label="Staff Pay Date"
                                                name="hcp_pay_date"
                                            />
                                        </div>
                                        <Field
                                            multiline
                                            variant="outlined"
                                            fullWidth
                                            component={TextField}
                                            name="comments"
                                            label="Comments"
                                            id="input_comments"
                                        />
                                    </div>

                                    <div className="mrg-top-10 custom-border pdd-top-10">
                                        <h2>Facility Billed Details</h2>
                                        <div className="input-container">
                                            <Field
                                                onChange={(e: any) => {
                                                    if (e.target.value === false) {
                                                        setFieldValue("facility_billed_details.invoice_date", null);
                                                    }
                                                    setIsHCPBilled(e.target.value);
                                                    setFieldValue("facility_billed_details.is_billed", e.target.value);
                                                }}
                                                SelectProps={showDropDownBelowField}
                                                select
                                                variant="outlined"
                                                fullWidth
                                                component={TextField}
                                                name="facility_billed_details.is_billed"
                                                id="input_is_billed"
                                                label="Billed/Invoice"
                                            >
                                                <MenuItem value="">Select </MenuItem>
                                                {hcpPaidOptions.map((item: any, index) => {
                                                    return (
                                                        <MenuItem value={item.value} key={index}
                                                                  id={"is_billed" + item.value}>
                                                            {item.label}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Field>
                                            {isHCPBilled && (
                                                <Field
                                                    required
                                                    value={values.facility_billed_details.invoice_date}
                                                    id="input_invoice_date"
                                                    variant="outlined"
                                                    format="MM/dd/yyyy"
                                                    component={TextField}
                                                    placeholder="MM/DD/YYYY"
                                                    type="date"
                                                    fullWidth
                                                    autoComplete="off"
                                                    InputLabelProps={{shrink: true}}
                                                    label="Invoice Date"
                                                    name="facility_billed_details.invoice_date"
                                                    onChange={(e: any) => setFieldValue("facility_billed_details.invoice_date", e.target.value)}
                                                />
                                            )}
                                        </div>
                                        <Field
                                            multiline
                                            variant="outlined"
                                            fullWidth
                                            component={TextField}
                                            name="facility_billed_details.comments"
                                            label="Comments"
                                            id="input_facility_comments"
                                            minRows={3}
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}

                    <div className="custom-border mrg-top-40 pdd-top-10">
                        <h2>Remarks</h2>
                        <MaterialTextField
                            value={remarks}
                            onChange={(e: any) => {
                                setRemarks(e.target.value);
                            }}
                            disabled={isRemarksSubmitting}
                            multiline
                            variant="outlined"
                            fullWidth
                            name="remarks"
                            id="input_facility_remarks"
                            minRows={3}
                        />
                    </div>
                    <div className="header mrg-top-20 mrg-bottom-10">
                        <div className="filter"></div>
                        <div className="actions">
                            <Tooltip title={`Edit Timeline`}>
                                <Button
                                    onClick={openEditTimeline}
                                    disabled={basicDetails?.status === "cancelled"}
                                    variant={"contained"}
                                    color={"primary"}
                                    className="edit-timeline-btn"
                                >
                                    Edit Timeline
                                </Button>
                            </Tooltip>
                        </div>
                    </div>

                    <div className="mrg-top-10 custom-border pdd-top-10">
                        <div className="shift-name-requested">
                            <div className="d-flex">
                                <h2 className="flex-1">Shift Timings</h2>
                                <h4 className="hcp-rate">
                                    Staff Rate:
                                    <span className="mrg-left-10 ">
										{basicDetails?.hcp_details?.contract_details?.rate_per_hour ? basicDetails?.hcp_details?.contract_details?.rate_per_hour + " $" : "N/A"}
									</span>
                                </h4>
                            </div>
                            <div className="d-flex shift-date-time ">
                                <div className="d-flex flex-1 flex-baseline">
                                    <h3>Attended On:</h3>
                                    <p className="attended-date mrg-left-20">
                                        {basicDetails?.actuals?.shift_start_time
                                            ? moment(basicDetails?.actuals?.shift_start_time).utcOffset(0, false).format("MM-DD-YYYY")
                                            : "--"}
                                    </p>
                                </div>

                                <div className="d-flex flex-1 flex-baseline">
                                    <h3>Actual Hours:</h3>
                                    <p className="shift-duration mrg-left-20">
                                        {basicDetails?.actual_hours ? Math.round(basicDetails?.actual_hours * 100) / 100 + " Hrs" : "N/A"}
                                    </p>
                                </div>

                                <div className="d-flex flex-1 flex-baseline">
                                    <h3>Billable Hours:</h3>
                                    <p className="shift-duration mrg-left-20">
                                        {billableHour ? billableHour + " Hrs" : "NA"}
                                    </p>
                                </div>

                                {SHOW_SHIFT_RATING_FOR_COMPLETE_AND_CLOSED.includes(basicDetails?.status) && (
                                    <div className="d-flex flex-1 flex-center">
                                        <h3 className="hcp-rating mrg-left-15">Staff Rating &nbsp;</h3>
                                        <Rating
                                            disabled={basicDetails?.status === 'closed'}
                                            color="primary"
                                            name="hcp-rating"
                                            value={hcpRating}
                                            onChange={(event, newValue) => {
                                                setHcpRating(newValue);
                                            }}
                                        />
                                    </div>
                                )}
                            </div>

                            <div className="flex-container"></div>

                            <div className="pdd-bottom-55">
                                <ShiftTimeline basicDetails={basicDetails} timeBreakup={basicDetails?.time_breakup}/>
                            </div>
                        </div>
                    </div>
                    {timeSheetIsAllowed ? (
                        <div className="mrg-top-10 custom-border pdd-top-10">
                            <div className="mrg-top-20">
                                {attachmentsList?.length > 0 ? (
                                    <>
                                        <h3>Attachment:</h3>
                                        <div className="d-flex" style={{gap: "50px"}}>
                                            {attachmentsList?.map((item: any, index: any) => {
                                                return (
                                                    <div className="attachments" key={index}>
                                                        <div>
                                                            <p className="">{item?.attachment_type}</p>
                                                            <Tooltip title="Preview CDPH 530 A Form">
                                                                {
                                                                    <InsertDriveFileIcon
                                                                        color={"primary"}
                                                                        className="file-icon"
                                                                        onClick={() => previewFile(index, "api")}
                                                                        style={{cursor: "pointer"}}
                                                                    />
                                                                }
                                                            </Tooltip>
                                                        </div>
                                                        <div className="d-flex">
                                                            <Tooltip title="Preview CDPH 530 A Form">
                                                                <p onClick={() => previewFile(index, "api")}
                                                                   className="file-actions">
                                                                    Preview
                                                                </p>
                                                            </Tooltip>
                                                            <Tooltip title="Download CDPH 530 A Form">
                                                                <div className="file-actions mrg-left-10"
                                                                     onClick={() => {
                                                                         CommonService.handleDownloadAnyAttachment(item?.file_key);
                                                                     }}>
                                                                    Download
                                                                </div>
                                                            </Tooltip>
                                                            <Tooltip title="Delete CDPH 530 A Form">
																<span className="file-actions mrg-left-10"
                                                                      onClick={handleDeleteTimesheet}>
																	Delete
																</span>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {timeSheetIsAllowed ? (
                                            <>
                                                <h3 className="mrg-top-0">Attachment:</h3>
                                                <div className="d-flex" style={{gap: "50px"}}>
                                                    {required_attachments?.map((item: any, index: any) => {
                                                        if (item.index !== -1) {
                                                            return (

                                                                <div className="attachments" key={index}>
                                                                    <div className="custom_file mrg-top-0">
                                                                        <h3 className="mrg-top-20 mrg-bottom-0 file_name file_attachment_title">
                                                                            {
                                                                                fileUpload?.wrapper[required_attachments[index]?.index]?.extraPayload
                                                                                    ?.file_type
                                                                            }
                                                                        </h3>
                                                                        <div className="mrg-top-15">
                                                                            <InsertDriveFileIcon color={"primary"}
                                                                                                 className="file-icon"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex file_actions">
                                                                        <Tooltip
                                                                            title={`View ${
                                                                                fileUpload?.wrapper[required_attachments[index]?.index]?.extraPayload
                                                                                    ?.file_type
                                                                            }`}
                                                                        >
                                                                            <p
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    width: "50px"
                                                                                }}
                                                                                className={"delete-cdhp mrg-top-0"}
                                                                                onClick={() => previewFile(index, "local")}
                                                                            >
                                                                                View
                                                                            </p>
                                                                        </Tooltip>
                                                                        <Tooltip
                                                                            title={`Delete ${
                                                                                fileUpload?.wrapper[required_attachments[index]?.index]?.extraPayload
                                                                                    ?.file_type
                                                                            }`}
                                                                        >
                                                                            <p
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    width: "50px"
                                                                                }}
                                                                                className={"delete-cdhp mrg-top-0"}
                                                                                onClick={() => deleteFile(index)}
                                                                            >
                                                                                Delete
                                                                            </p>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>

                                                            );
                                                        } else {
                                                            return (
                                                                <div className="attachments" key={index}>
                                                                    <div className="">
                                                                        <h3 className="attachement_name file_attachment_title">{item?.name}</h3>
                                                                        <Tooltip title={`Upload ${item?.name}`}>
                                                                            <div>
                                                                                <FileDropZoneComponent
                                                                                    OnFileSelected={(item) => OnFileSelected(item, index)}
                                                                                    allowedTypes={".pdf"}
                                                                                />
                                                                            </div>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    })}
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    {basicDetails?.status === "complete" && (
                        <div className="shift-view-actions mrg-top-20">
                            <Tooltip title={"Cancel"}>
                                <Button
                                    size="large"
                                    onClick={() => history.goBack()}
                                    variant={"outlined"}
                                    color="primary"
                                    id="btn_cancel"
                                >
                                    {"Cancel"}
                                </Button>
                            </Tooltip>
                            <Tooltip title={"Save Changes"}>
                                <Button
                                    disabled={isDataSubmitting || isTimeSheetBeingUpdated}
                                    type="submit"
                                    id="btn_save"
                                    size="large"
                                    variant={"contained"}
                                    color={"primary"}
                                    className={isDataSubmitting || isTimeSheetBeingUpdated ? "has-loading-spinner" : ""}
                                    onClick={handleSubmitForShiftComplete}
                                >
                                    {isDataSubmitting || isTimeSheetBeingUpdated ? "Saving" : "Save"}
                                </Button>
                            </Tooltip>
                        </div>
                    )}

                    {basicDetails?.status === "closed" && (
                        <div className="shift-view-actions mrg-top-20">
                            <Tooltip title={"Cancel"}>
                                <Button
                                    size="large"
                                    onClick={() => history.goBack()}
                                    variant={"outlined"}
                                    color="primary"
                                    id="btn_cancel"
                                >
                                    {"Cancel"}
                                </Button>
                            </Tooltip>
                            <Tooltip title={"Save Changes"}>
                                <Button
                                    className={isPaymentSubmitting ? "has-loading-spinner" : ""}
                                    disabled={isSubmitting}
                                    form="add-payment-form"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    size="large"
                                    id="btn_submit"
                                >
                                    {isPaymentSubmitting ? "Saving" : "Save"}
                                </Button>
                            </Tooltip>
                        </div>
                    )}

                    {(basicDetails?.status === "in_progress" ||
                        basicDetails?.status === "pending" ||
                        basicDetails?.status === "cancelled") && (
                        <div className="shift-view-actions mrg-top-20">
                            <Tooltip title={"Cancel"}>
                                <Button
                                    size="large"
                                    onClick={() => history.goBack()}
                                    variant={"outlined"}
                                    color="primary"
                                    id="btn_cancel"
                                >
                                    {"Cancel"}
                                </Button>
                            </Tooltip>
                            <Tooltip title={"Save Changes"}>
                                <Button
                                    size="large"
                                    className={isFacilityBeingConfirmed ? "has-loading-spinner" : ""}
                                    disabled={isFacilityBeingConfirmed}
                                    onClick={handleSubmitForInProgressPendingAndCancelledShifts}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    id="btn_submit"
                                >
                                    {isFacilityBeingConfirmed ? "Saving" : "Save"}
                                </Button>
                            </Tooltip>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ShiftMasterViewScreen;

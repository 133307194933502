import { IconButton, TableRow, TextField, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import React from "react";

interface readOnlyRowProps {
  education: any;
  handleDeleteClick: any;
  index?: number
}

const ReadOnlyRow = ({ education, handleDeleteClick }: readOnlyRowProps) => {
  return (
     <TableRow className={"mat-tr"}>
      <td>
      
      <Tooltip title={education.institute_name}>
        <TextField
          InputProps={{
            disableUnderline: true,
          }}
          value={education.institute_name}
          disabled
        />
      </Tooltip>
 
      </td>
      <td>
      <Tooltip title={education.degree}>
        <TextField
          InputProps={{
            disableUnderline: true,
          }}
          value={education.degree}
          disabled
        />
      </Tooltip>

      </td>
      <td>
      <Tooltip title={education.location}>
        <TextField
          InputProps={{
            disableUnderline: true,
          }}
          value={education.location}
          disabled
          />
         </Tooltip>
      </td>

      <td>
   <Tooltip title={education.start_date ? moment(education.start_date).format('MM-YYYY') : "NA"}>
   <TextField
          InputProps={{
            disableUnderline: true,
          }}
          value={education.start_date ? moment(education.start_date).format('MM-YYYY') : "NA"}
          disabled
        />
   </Tooltip>
      </td>

      <td>
       <Tooltip title={education.graduation_date ? moment(education.graduation_date).format('MM-YYYY') : "NA"}>
       <TextField
          InputProps={{
            disableUnderline: true,
          }}
          value={education.graduation_date ? moment(education.graduation_date).format('MM-YYYY') : "NA"}
          disabled
        />
       </Tooltip>
      </td>

      <td>
        <Tooltip title={`Delete Education`}>
          <IconButton onClick={() => handleDeleteClick(education.tempId)}>
            <DeleteIcon className="delete-icon" />
          </IconButton>
        </Tooltip>
      </td>
    </TableRow>
  );
};

export default ReadOnlyRow;

import * as Yup from "yup";




export const showDropDownBelowField = {
  MenuProps: {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  },
};

export const shiftClosedFieldsValidation = Yup.object({
  is_hcp_paid: Yup.string().nullable(),
  comments: Yup.string().nullable(),
  hcp_pay_date: Yup.date().nullable(),
  facility_billed_details: Yup.object({
    is_billed: Yup.string().nullable(),
    invoice_date: Yup.date().nullable(),
    comments: Yup.string().nullable(),
  }),
});


export const facilityBillDetailsFieldsValidation =  Yup.object({
  is_hcp_paid: Yup.string().nullable(),
  comments: Yup.string().nullable(),
  hcp_pay_date: Yup.date().nullable(),
  facility_billed_details: Yup.object({
    is_billed: Yup.string().nullable(),
    invoice_date: Yup.date().nullable(),
    comments: Yup.string().nullable(),
  }),
});
 
import React, {PropsWithChildren, useCallback, useEffect, useState} from "react";
import {Button} from "@material-ui/core";
import {ENV} from "../../../../../constants";
import {CommonService} from "../../../../../helpers";
//import { useParams } from "react-router";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
//import { useSelector } from "react-redux";
//import { StateParams } from "../../../../../store/reducers";
import Checkbox from "@material-ui/core/Checkbox";
import animationData from "../../../../../assets/animations/NoData.json";
import "./AddHcpToShiftScreen.scss";
import Lottie from "react-lottie";
import {SearchRounded} from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import LoaderComponent from "../../../../../components/LoaderComponent";
import {CssTextField, useStyles} from "../../../../../constants/data/styles";
import DialogComponent from "../../../../../components/DialogComponent";
import AddShiftsComponentForOpenShift from "../../addShiftsComponentForOpenShift/AddShiftsComponentForOpenShift";


export interface AddHcpToShiftComponentProps {
    cancel: () => void;
    confirm: () => void;
    hcp_type: any;
    basicDetails: any;
}


const AddHcpToShiftScreen = (props: PropsWithChildren<AddHcpToShiftComponentProps>) => {
    const afterCancel = props?.cancel;
    const hcp_type = props?.hcp_type;
    const afterConfirm = props?.confirm;
    const basicDetails = props?.basicDetails;
    const classes = useStyles();
    const [hcpList, sethcpList] = React.useState<any>(null);
    const [searchHcp, setSearchHcp] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSelectedCount, setIsSelectedCount] = useState<any>(-1)
    const [isShiftAddDetailsOpen, setIsShiftAddDetailsOpen] = useState<boolean>(false);
    const [selectedHcps, setSelectedHcps] = useState<any>([]);

    const defaultOptions = {
        animationData,
    };

    const cancelShiftAddDetails = useCallback(() => {
        setIsShiftAddDetailsOpen(false);
    }, []);

    const confirmShiftAddDetails = useCallback(() => {
        setIsShiftAddDetailsOpen(false);
    }, []);

    const init = useCallback(() => {

        setIsLoading(true);
        let payload: any = {
            is_approved: true,
            facility_id: basicDetails?.facility?._id,
        };

        if (hcp_type === "CNA/CHHA") {
            payload.hcp_types = [
                "CNA", "CNA/CHHA", "CHHA"
            ];
        } else if (hcp_type === "CHHA") {
            payload.hcp_types = [
                "CHHA", "CNA/CHHA"
            ];
        } else if (hcp_type === "CNA") {
            payload.hcp_types = [
                "CNA", "CNA/CHHA"
            ];
        } else {
            payload.hcp_type = hcp_type;
        }

        if (hcp_type === "LVN" || hcp_type === "CNA" || hcp_type === "RN") {
            payload.requirement_date = CommonService.getUtcDate(basicDetails?.shift_date);
        }
        if (searchHcp !== "") {
            if (searchHcp !== "") {
                payload.search = searchHcp
            }
            if (hcp_type === "LVN" || hcp_type === "CNA" || hcp_type === "RN") {
                payload.requirement_date = CommonService.getUtcDate(basicDetails?.shift_date);
            }
        }
        // config
        CommonService._api.post(ENV.API_URL + "hcp/lite", payload).then((resp) => {
            sethcpList(resp?.data);
            setIsLoading(false);
        })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }, [hcp_type, searchHcp, basicDetails]);


    const addAllHcpToshift = useCallback(() => {
        setIsShiftAddDetailsOpen(true)
    }, []);

    const handleChange = (event: any) => {
        let index = selectedHcps.indexOf(event.target.value);
        let tempHcps = [];
        if (index > -1) {
            setIsSelectedCount(selectedHcps?.length - 1 === 0 ? -1 : selectedHcps?.length - 1)
            tempHcps = selectedHcps.filter((item: any) => item !== event.target.value);
        } else {
            setIsSelectedCount(selectedHcps?.length + 1)
            tempHcps = [...selectedHcps, event.target.value];
        }
        setSelectedHcps(tempHcps);
    };

    const cancel = (resetForm: any) => {
        if (afterCancel) {
            afterCancel();
        }
    };

    useEffect(() => {
        init();
    }, [init]);

    const isSelected = useCallback((_id: any) => {
        if (selectedHcps?.indexOf(_id) !== -1) {
            return true;
        } else {
            return false;
        }
    }, [selectedHcps]);

    return (
        <div className="add-hcp-requirment">
            <DialogComponent maxWidth={'md'} open={isShiftAddDetailsOpen} cancel={cancelShiftAddDetails}>
                <AddShiftsComponentForOpenShift hcpList={hcpList} afterConfirm={afterConfirm}
                                                setSelectedHcps={setSelectedHcps} selectedHcps={selectedHcps}
                                                cancel={cancelShiftAddDetails} confirm={confirmShiftAddDetails}
                                                basicDetails={basicDetails}/>
            </DialogComponent>
            <div className={classes.paper}>
                <h2 className={classes.title}>Add Staff to this Shift</h2>
                <FormLabel component="legend" className="mrg-left-0">
                    List Of Staff
                </FormLabel>
                <div id="alert-dialog-title">
                    <div className="mrg-top-20">
                        <div>
                            <div className="d-flex">
                                <div className="d-flex position-relative">
                                    <CssTextField
                                        defaultValue={""}
                                        onChange={(event) => {
                                            setSearchHcp(event?.target?.value);
                                        }}
                                        className="searchField"
                                        variant={"outlined"}
                                        size={"small"}
                                        type={"text"}
                                        placeholder={"Search Staff"}
                                        value={searchHcp}
                                    />
                                    {searchHcp === "" ? (
                                        <div className={"search_icon"}>
                                            <SearchRounded/>
                                        </div>
                                    ) : (
                                        <div className={"search_icon"}>
                                            <ClearIcon onClick={(event) => setSearchHcp("")} id="clear_hcp_search"/>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mrg-top-20">
                        {isLoading && <LoaderComponent position="block"/>}
                        {hcpList?.map((item: any) => {
                            const isItemSelected = isSelected(item?.user_id);
                            return (
                                <div>
                                    <FormControlLabel value={item?.user_id} checked={isItemSelected}
                                                      control={<Checkbox/>} onChange={(event) => handleChange(event)}
                                                      label={item?.first_name + " " + item?.last_name}/>
                                </div>
                            );
                        })}
                    </div>
                </div>

                {!isLoading && hcpList?.length === 0 && (
                    <div className={"display-flex flex-one mrg-top-20 mrg-bottom-20"}>
                        <Lottie width={400} height={400} speed={1} options={defaultOptions}/>
                    </div>
                )}

                {hcpList && hcpList.length > 0 ? (
                    <div className={classes.assignNcActions}>
                        <Button type={"submit"} size="large" variant={"outlined"} className={"normal"} onClick={cancel}>
                            Cancel
                        </Button>
                        <Button type={"submit"} color={"primary"} size="large" disabled={isSelectedCount === -1}
                                className={'mrg-left-20'} variant={"contained"} onClick={() => addAllHcpToshift()}>
                            Add Staff
                        </Button>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default AddHcpToShiftScreen;

import "./AccountingModuleComponent.scss";
import React, {useEffect} from "react";
import {Communications} from "../../../helpers";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import GeneratedInvoiceComponent from "./invoice-generated/GeneratedInvoiceComponent";
import GenerateInvoiceComponent from "./generate-invoice/GenerateInvoiceComponent";
import {useLocalStorage} from "../../../components/useLocalStorage";
// import PDFFile from "./invoice-generated/PdfViewer";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AccountingModuleComponent = () => {
    const [value, setValue] = useLocalStorage<any>("tab-initial-value-generate", 0);
    const handleChanges = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    useEffect(() => {
        Communications.pageTitleSubject.next("Accounting");
        Communications.pageBackButtonSubject.next(null);
    }, []);
    return (
        <>
            <div className={"accounting-module-component screen"}>
                <Box sx={{width: '100%'}}>
                    <Box style={{borderBottom: "1px solid #e4e9ea"}}>
                        <Tabs value={value} onChange={(e: any, value: any) => {
                            handleChanges(e, value);
                        }
                        } aria-label="tab"
                              TabIndicatorProps={{
                                  style: {
                                      backgroundColor: "#56ebff",
                                  }
                              }}
                              style={{
                                  color: "black",
                              }}>
                            <Tab label="Invoice Generated" {...a11yProps(0)} />
                            <Tab label="Generate Invoice" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <>
                            <GeneratedInvoiceComponent value={value}/>
                        </>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <>
                            <GenerateInvoiceComponent/>
                        </>
                    </TabPanel>
                </Box>
            </div>
        </>
    );
};
export default AccountingModuleComponent;
import React from 'react';

const EmployeeBasicDetailsComponent = (props: any) => {
    const basicDetails = props?.basicDetails;

    return <div>
        <div className='mrg-top-10 custom-border pdd-40'>
            <h3 className='mrg-top-0'>Basic Details</h3>
            <div className="d-flex">
                <div className="flex-1">
                    <h4>First Name</h4>
                    <p>{basicDetails?.first_name} </p>
                </div>
                <div className="flex-1">
                    <h4>Last Name</h4>
                    <p>{basicDetails?.last_name} </p>
                </div>
            </div>
            <div className="d-flex">
                <div className="flex-1">
                    <h4>Contact Number</h4>
                    <p>{basicDetails?.contact_number}</p>
                </div>
                <div className="flex-1">
                    <h4>Email</h4>
                    <p>{basicDetails?.email}</p>
                </div>
            </div>
            <div className="d-flex">
                <div className="flex-1">
                    <h4>Region Name</h4>
                    <p>{basicDetails?.address?.region}</p>
                </div>
                <div className="flex-1">
                    <h4>Street</h4>
                    <p>{basicDetails?.address?.street}</p>
                </div>
            </div>
            <div className='d-flex'>
                <div className="flex-1">
                    <h4>City</h4>
                    <p>{basicDetails?.address?.city}</p>
                </div>
                <div className="flex-1">
                    <h4>State</h4>
                    <p>{basicDetails?.address?.state}</p>
                </div>
            </div>
            <div className="d-flex">

                <div className="flex-1">
                    <h4>Country</h4>
                    <p>{basicDetails?.address?.country}</p>
                </div>
                <div className="flex-1">
                    <h4>Zip Code</h4>
                    <p>{basicDetails?.address?.zip_code}</p>
                </div>
            </div>
            <div className="mrg-top-40">
                <h4>Gender</h4>
                <p className="summary">{basicDetails?.gender}</p>
            </div>
        </div>
    </div>;
}


export default EmployeeBasicDetailsComponent;
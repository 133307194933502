import { IconButton, TableRow, TextField, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";

interface readOnlyRowProps {
  reference: any;
  handleDeleteClick: any;
  index?: number;
}

const ReadOnlyRow = ({ reference, handleDeleteClick }: readOnlyRowProps) => {
  return (
     <TableRow className={"mat-tr"}>
      <td>
        <Tooltip title={reference.reference_name}>
        <TextField
          InputProps={{
            disableUnderline: true,
          }}
          value={reference.reference_name}
          disabled
        />
        </Tooltip>
      </td>

      <td>
      <Tooltip title={reference.job_title}>
        <TextField
          InputProps={{
            disableUnderline: true,
          }}
          value={reference.job_title}
          disabled
          />
        </Tooltip>
      </td>
      <td>
      <Tooltip title={reference.contact_number}>
        <TextField
          InputProps={{
            disableUnderline: true,
          }}
          value={reference.contact_number}
          disabled
        />
        </Tooltip>
      </td>

      <td>
      <Tooltip title={reference.email ? reference.email : "NA"}>
        <TextField
          InputProps={{
            disableUnderline: true,
          }}
          value={reference.email ? reference.email : "NA"}
          disabled
        />
        </Tooltip>
      </td>
      
      <td>
        <Tooltip title={`Delete References`}>
          <IconButton onClick={() => handleDeleteClick(reference.tempId)}>
            <DeleteIcon className="delete-icon" />
          </IconButton>
        </Tooltip>
      </td>
    </TableRow>
  );
};

export default ReadOnlyRow;

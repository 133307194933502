import "./HcpNoteComponent.scss";
import {AddRounded} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import {TextField} from "formik-material-ui";
import {Tooltip} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {ApiService, CommonService} from "../../../../helpers";
import {ENV} from "../../../../constants";
// import {useSelector} from "react-redux";
// import {StateParams} from "../../../../store/reducers";
// import {useParams} from "react-router-dom";
import LoaderComponent from "../../../../components/LoaderComponent";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from "moment";
import {useSelector} from "react-redux";
import {StateParams} from "../../../../store/reducers";

const notesValidation = Yup.object({
    notes: Yup.string().min(10, "minimum 10 characters").trim().typeError("Text and Numbers Only").required("Required"),
});

interface notesProps {
    notes: string;
}

interface hcpNotesProps {
    hcpBasicDetails: any;
    id:any
}

const HcpNoteComponent = (props: hcpNotesProps) => {
    // const params = useParams<{ id: string }>();
    const {hcpBasicDetails,id} = props;
    const [isNotesFormOpen, setIsNotesFormOpen] = useState<any>(false);
    const [notesList, setNotesList] = useState<any>([]);
    const [isNotesListLoading, setIsNotesListLoading] = useState<any>(false);
    const [editableNotes, setEditableNotes] = useState<any>("");
    const [addedById, setAddedById] = useState<any>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const {user} = useSelector((state: StateParams) => state.auth);
    const [userRole, setUserRole] = useState<any>(null);
    const [notesOwner, setNotesOwner] = useState<Boolean>(false);

    // const {id} = params;


    const handleClose = useCallback((note: any) => {
        setAnchorEl(null);
    }, []);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const getNotesList = useCallback(() => {
        setIsNotesListLoading(true);
        CommonService._api.get(ENV.API_URL + 'hcp/' + hcpBasicDetails?._id + '/notes').then((resp) => {
            setNotesList(resp?.data.reverse());
            setIsNotesListLoading(false);
        }).catch((err) => {
        });
    }, [hcpBasicDetails]);

    useEffect(() => {
        getNotesList();
    }, [getNotesList]);

    useEffect(() => {
        console.log(notesList);
    }, [notesList]);

    const onAdd = (payload: any, {setSubmitting, setErrors, resetForm}: FormikHelpers<notesProps>) => {
        let createNotes = {
            notes: payload.notes,
        };
        if (editableNotes.length === 0) {
            ApiService.post(ENV.API_URL + `hcp/${hcpBasicDetails?._id}/notes`, createNotes)
                .then((resp: any) => {
                    if (resp) {
                        CommonService.showToast(resp?.msg || "Success", "success");
                        setIsNotesFormOpen(false);
                        getNotesList();
                    } else {
                        setSubmitting(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    //  CommonService.handleErrors(setErrors, err?.errors);
                    setSubmitting(false);
                    CommonService.showToast(err.msg || "Error", "error");
                });
        } else {
            ApiService.put(ENV.API_URL + `hcp/${id}/note/${addedById}`, createNotes)
                .then((resp: any) => {
                    if (resp) {
                        CommonService.showToast(resp?.msg || "Success", "success");
                        setIsNotesFormOpen(false);
                        getNotesList();
                    } else {
                        setSubmitting(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    //  CommonService.handleErrors(setErrors, err?.errors);
                    setSubmitting(false);
                    CommonService.showToast(err.msg || "Error", "error");
                });
        }
    };

    const onDeleteNotes = useCallback(() => {
        setAnchorEl(null);
        setIsNotesListLoading(true);
        ApiService.delete(ENV.API_URL + `hcp/${id}/note/${addedById}`)
            .then((resp: any) => {
                if (resp) {
                    CommonService.showToast(resp?.msg || "Success", "success");
                    setIsNotesFormOpen(false);
                    setIsNotesListLoading(false);
                    getNotesList();
                } else {
                }
            })
            .catch((err) => {
                console.log(err);
                //  CommonService.handleErrors(setErrors, err?.errors);
                CommonService.showToast(err.msg || "Error", "error");
            });
    }, [addedById, getNotesList,id]);

    useEffect(() => {
        if (user?.role === "super_admin") {
            setUserRole(user?.role);
        } else {
            setUserRole(null);
        }
    }, [user, notesOwner]);


    return (
        <>
            <div className={"hcp-notes-component"}>
                <div>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div className={'notes-heading'}>Notes</div>
                        <div>
                            <Button variant={"contained"} color={"primary"} disabled={isNotesFormOpen} onClick={() => {
                                setIsNotesFormOpen(true);
                                setEditableNotes("");
                            }}>
                                <AddRounded/>
                                &nbsp;&nbsp;Add Notes&nbsp;&nbsp;
                            </Button>
                        </div>
                    </div>

                    {
                        isNotesFormOpen && <>
                            <div>
                                <div className="sms-blast-message pdd-right-20">
                                    <Formik
                                        initialValues={{
                                            notes: `${editableNotes}`,
                                        }}
                                        validateOnChange={true}
                                        validationSchema={notesValidation}
                                        onSubmit={onAdd}
                                    >
                                        {({isSubmitting, isValid}) => (
                                            <Form className="">
                                                <Field autoComplete="off" className="mrg-top-20" component={TextField}
                                                       fullWidth
                                                       multiline name="notes" rows={6} variant="outlined"
                                                       placeholder="Type in your notes*"/>
                                                <div className="sms-blast-btn mrg-top-20">
                                                    <div style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                    }}>
                                                        <div className={"mrg-right-20"}>
                                                            <Button
                                                                color={"primary"}
                                                                variant={"outlined"} id="sms_blast_button"
                                                                size={"large"} fullWidth={true} onClick={() => {
                                                                setIsNotesFormOpen(false);
                                                            }}>
                                                                Cancel
                                                            </Button>
                                                        </div>
                                                        <div>
                                                            <Tooltip title={isSubmitting ? "Saving Notes" : "Save"}>
                                                                <Button disabled={isSubmitting || !isValid}
                                                                        color={"primary"}
                                                                        variant={"contained"} id="sms_blast_button"
                                                                        className={isSubmitting ? "has-loading-spinner" : ""}
                                                                        type="submit" size={"large"} fullWidth={true}>
                                                                    {isSubmitting ? "Saving Notes" : "Save"}
                                                                </Button>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </>
                    }

                    <div className={"notes-panel"}>
                        <div className={'notes-box'}>
                            {!isNotesListLoading && notesList && <>
                                {
                                    notesList?.map((note: any) => {
                                        const m = moment(note?.created_at);
                                        return (
                                            <div className={'custom-border pdd-10 mrg-bottom-10'}>
                                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                                    <div
                                                        style={{maxWidth: "900px"}}>{note?.notes}
                                                    </div>
                                                    <Button disabled={isNotesFormOpen} style={{cursor: "pointer"}}
                                                            onClick={(event: any) => {
                                                                handleClick(event);
                                                                setEditableNotes(note?.notes);
                                                                setAddedById(note?._id);
                                                                if (note?.user_details?._id === user?._id) {
                                                                    setNotesOwner(true);
                                                                } else {
                                                                    setNotesOwner(false);
                                                                    if (!userRole) {
                                                                        CommonService.showToast("Your are not the notes writer", 'error');
                                                                    }
                                                                }
                                                            }}>
                                                        <MoreVertIcon/>
                                                    </Button>
                                                    {(notesOwner || userRole?.length > 0) && <>
                                                        <Menu
                                                            id="basic-menu"
                                                            anchorEl={anchorEl}
                                                            open={open}
                                                            onClose={handleClose}
                                                            MenuListProps={{
                                                                'aria-labelledby': 'basic-button',
                                                            }}
                                                        >
                                                            {notesOwner && <>
                                                                <MenuItem onClick={() => {
                                                                    setIsNotesFormOpen(true);
                                                                    setAnchorEl(null);
                                                                    window.scrollTo({top: 0, behavior: "smooth"});
                                                                }}>
                                                                    <div>
                                                                        Edit
                                                                    </div>
                                                                </MenuItem>
                                                            </>}
                                                            {userRole === 'super_admin' && <>
                                                                <MenuItem onClick={() => {
                                                                    onDeleteNotes();
                                                                }}>Delete</MenuItem>
                                                            </>}
                                                        </Menu>
                                                    </>}
                                                </div>
                                                <div className={'notes-author'}>
                                                    <div>Added
                                                        By &nbsp;:&nbsp; {note?.user_details?.first_name} {note?.user_details?.last_name} &nbsp;|&nbsp; {m.format("dddd")} &nbsp;|&nbsp; {m.format("MMMM Do YYYY")} &nbsp;|&nbsp; {m.format("h:mm:ss a")}</div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </>}
                            {
                                isNotesListLoading && <>
                                    <div style={{padding: "30px"}}>
                                        <LoaderComponent/>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HcpNoteComponent;
export const FETCH_HCP_BASIC_DETAILS_REQUEST = "FETCH_HCP_BASIC_DETAILS_REQUEST";
export const FETCH_HCP_BASIC_DETAILS_SUCCESS = "FETCH_HCP_BASIC_DETAILS_SUCCESS";
export const FETCH_HCP_BASIC_DETAILS_FAILURE = "FETCH_HCP_BASIC_DETAILS_FAILURE";


export const fetchHcpBasicDetailsRequest = (hcpId:any) => ({
    type: FETCH_HCP_BASIC_DETAILS_REQUEST,
    payload: hcpId,
});

export const fetchHcpBasicDetailsSuccess = (hcpBasicDetails:any) => ({
    type: FETCH_HCP_BASIC_DETAILS_SUCCESS,
    payload: hcpBasicDetails,
});

export const fetchHcpBasicDetailsFailure = (hcpBasicDetailsError:any) => ({
    type: FETCH_HCP_BASIC_DETAILS_FAILURE,
    payload: hcpBasicDetailsError,
});
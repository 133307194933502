import "./FilterComponentForMap.scss";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Paper} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";


interface FilterPropsForHcpOnMap {
    hcpTypes: any;
    selectedHcpTypes: any;
    setSelectedHcpTypes: any;
    hcpList: any;
    setSelectedHcp: any;
    selectedHcp: any;
    setHcpDetails: any;
    setIsHcpDetailsCardOpen: any;
    regionList: any;
    selectedRegion: any;
    setSelectedRegion: any;
    setSelectedHcpTypeForNearbyHcp: any;
    setSelectedHcpRegionForNearbyHcp: any;
    setIsFacilityDetailsCardOpen: any;
    setSelectedFacility: any;
    facilityList: any;
    selectedFacility: any;
    setCheckedFacility: any;
    setMapNavigationOnFeatureSelection:any;
    setFacilityNavigation:any;
    selectedLegend:any;
    facilityWithOpenShift:any;
    facilityWithoutOpenShift:any;
}

const FilterComponentForMap = (props: FilterPropsForHcpOnMap) => {
    const {hcpTypes,selectedLegend,facilityWithOpenShift,facilityWithoutOpenShift} = props;
    const {selectedHcpTypes} = props;
    const {setSelectedHcpTypes} = props;
    const {hcpList, facilityList} = props;
    const {selectedHcp, selectedFacility} = props;
    const {setSelectedHcp} = props;
    const {setHcpDetails} = props;
    const {setIsHcpDetailsCardOpen} = props;
    const {regionList} = props;
    const {setSelectedRegion, setSelectedFacility, setCheckedFacility,setMapNavigationOnFeatureSelection} = props;
    const [facilityFilterList,setFacilityFilterList]=useState([])
    const [hcpFilterList,setHcpFilterList]=useState([])
    const {
        selectedRegion,
        setSelectedHcpTypeForNearbyHcp,
        setSelectedHcpRegionForNearbyHcp,
        setIsFacilityDetailsCardOpen,
        setFacilityNavigation
    } = props;

    useEffect(() => {
        console.log(regionList);
    }, [regionList]);

    useEffect(()=>{
        console.log(facilityList);
        const facilityFilteredList=facilityList?.filter((facility:any)=>facility?.is_active&&facility?.location?.coordinates[0]&&facility?.location?.coordinates[1])
        const hcpFilteredList=hcpList?.filter((hcp:any)=>hcp?.is_active&&hcp?.location?.coordinates[0]&&hcp?.location?.coordinates[1])
        if(selectedLegend.includes("Open Shift")&&selectedLegend.includes("Active Facility")) {
            setFacilityFilterList(facilityFilteredList)
        }
        setHcpFilterList(hcpFilteredList)
        console.log(facilityFilteredList,hcpFilteredList);
        // eslint-disable-next-line
    },[selectedLegend,facilityList,hcpList])



    useEffect(()=>{
        if(selectedLegend.includes("Open Shift")&&!selectedLegend.includes("Active Facility")) {
            const facilityFilteredList=facilityWithOpenShift?.filter((facility:any)=>facility?.is_active)
            setFacilityFilterList(facilityFilteredList)
        }
        if(!selectedLegend.includes("Open Shift")&&selectedLegend.includes("Active Facility")) {
            const facilityFilteredList=facilityWithoutOpenShift?.filter((facility:any)=>facility?.is_active)
            setFacilityFilterList(facilityFilteredList)
        }
    },[selectedLegend,facilityWithOpenShift,facilityWithoutOpenShift])

    return (
        <>
            <div className={"filter-component-for-map"}>
                <div className="form-field-wrapper">
                    <div className={`form-field-left`}>
                        <div className="form-field-left-items">
                            <div className="form-field-item">
                                <Autocomplete
                                    PaperComponent={({children}) => <Paper
                                        style={{color: "#1e1e1e"}}>{children}</Paper>}
                                    value={selectedRegion}
                                    options={regionList}
                                    getOptionLabel={(option: any) => option?.title}
                                    placeholder={"Select Status"}
                                    id="input_select_status"
                                    // className="mrg-top-10"
                                    style={{backgroundColor: "white", borderRadius: "3px"}}
                                    onChange={($event, value) => {
                                        console.log(value);
                                        setSelectedHcpTypeForNearbyHcp(null);
                                        setSelectedHcpRegionForNearbyHcp(null);
                                        setSelectedRegion(value);
                                        setIsHcpDetailsCardOpen(false);
                                        setIsFacilityDetailsCardOpen(false);
                                        setSelectedHcp(null);
                                        setSelectedFacility(null);
                                    }}
                                    renderInput={(params) => <TextField {...params} size={"small"} id="select_status"
                                                                        variant="outlined" value={[]}
                                                                        placeholder={"Region"} fullWidth/>}
                                />
                            </div>


                            <div className="form-field-item">
                                {facilityList && <>
                                    <Autocomplete
                                        PaperComponent={({children}) => <Paper
                                            style={{color: "#1e1e1e"}}>{children}</Paper>}
                                        value={selectedFacility}
                                        options={facilityFilterList}
                                        disabled={!selectedLegend?.includes("Open Shift")&&!selectedLegend?.includes("Active Facility")}
                                        getOptionLabel={(option: any) => (option?.name)}
                                        placeholder={"Select Status"}
                                        id="input_select_status"
                                        style={{backgroundColor: "white", borderRadius: "3px"}}
                                        onChange={($event, value) => {
                                            if (value) {
                                                setIsHcpDetailsCardOpen(false);
                                                setIsFacilityDetailsCardOpen(true);
                                                setSelectedFacility(value);
                                                setSelectedHcp(null);
                                                setMapNavigationOnFeatureSelection(value?.location?.coordinates[0],value?.location?.coordinates[1])
                                                setFacilityNavigation("facility-details")
                                            } else {
                                                setIsFacilityDetailsCardOpen(false);
                                                setSelectedFacility(null);
                                            }

                                        }}
                                        renderInput={(params) => <TextField {...params} size={"small"}
                                                                            id="select_status" variant="outlined"
                                                                            value={[]} placeholder={"Facility"}
                                                                            fullWidth/>}
                                    />
                                </>}
                            </div>

                            <div className="form-field-item">
                                {hcpTypes && <>
                                    <Autocomplete
                                        PaperComponent={({children}) => <Paper
                                            style={{color: "#1e1e1e"}}>{children}</Paper>}
                                        value={selectedHcpTypes}
                                        disabled={selectedHcp}
                                        options={hcpTypes}
                                        getOptionLabel={(option: any) => option?.title}
                                        placeholder={"Select Status"}
                                        id="input_select_status"
                                        style={{backgroundColor: "white", borderRadius: "3px"}}
                                        onChange={($event, value) => {
                                            setSelectedHcpTypes(value);
                                            setSelectedHcpTypeForNearbyHcp(null);
                                            setSelectedHcpRegionForNearbyHcp(null);
                                            setIsHcpDetailsCardOpen(false);
                                        }}
                                        renderInput={(params) => <TextField {...params} size={"small"}
                                                                            id="select_status" variant="outlined"
                                                                            value={[]} placeholder={"Staff Types"}
                                                                            fullWidth/>}
                                    />
                                </>}
                            </div>


                            <div className="form-field-item">
                                {hcpList && <>
                                    <Autocomplete
                                        PaperComponent={({children}) => <Paper
                                            style={{color: "#1e1e1e"}}>{children}</Paper>}
                                        value={selectedHcp}
                                        options={hcpFilterList}
                                        disabled={!selectedLegend?.includes("Active Hcp")}
                                        getOptionLabel={(option: any) => (option?.first_name ? option?.first_name + " " + option?.last_name : "")}
                                        placeholder={"Select Status"}
                                        id="input_select_status"
                                        style={{backgroundColor: "white", borderRadius: "3px"}}
                                        onChange={($event, value) => {
                                            if (value) {
                                                setHcpDetails(value);
                                                setIsHcpDetailsCardOpen(true);
                                                setIsFacilityDetailsCardOpen(false);
                                                setSelectedFacility(null);
                                                setSelectedHcp(value);
                                                setCheckedFacility(false);
                                                setMapNavigationOnFeatureSelection(value?.location?.coordinates[0],value?.location?.coordinates[1])

                                            } else {
                                                setIsHcpDetailsCardOpen(false);
                                                setSelectedHcp(null);
                                            }

                                        }}
                                        renderInput={(params) => <TextField  {...params} size={"small"}
                                                                             id="select_status" variant="outlined"
                                                                             value={[]} placeholder={"Staff"}
                                                                             fullWidth/>}
                                    />
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FilterComponentForMap;

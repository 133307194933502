import {
    FETCH_HCP_BASIC_DETAILS_REQUEST,
    FETCH_HCP_BASIC_DETAILS_SUCCESS,
    FETCH_HCP_BASIC_DETAILS_FAILURE,
} from "../actions/hcp-management.action";

export interface  HcpManagementParams{
    hcpBasicDetails: any;
    isHcpBasicDetailsLoading: boolean;
    hcpBasicDetailsError: string;
}
const initialState:HcpManagementParams= {
    hcpBasicDetails: [],
    isHcpBasicDetailsLoading: false,
    hcpBasicDetailsError: "",
};

const HcpManagementReducer = (state = initialState, action: any): HcpManagementParams => {
    switch (action.type) {
        case FETCH_HCP_BASIC_DETAILS_REQUEST:
            return { ...state, isHcpBasicDetailsLoading: true };
        case FETCH_HCP_BASIC_DETAILS_SUCCESS:
            return {
                ...state,
                hcpBasicDetails: action.payload,
                isHcpBasicDetailsLoading: false,
                hcpBasicDetailsError: "",
            };
        case FETCH_HCP_BASIC_DETAILS_FAILURE:
            return {
                ...state,
                isHcpBasicDetailsLoading: false,
                hcpBasicDetailsError: action.payload,
            };
        default:
            return state;
    }
};
export default HcpManagementReducer
import React, {useCallback, useEffect, useState} from "react";
import {ApiService, CommonService, Communications} from "../../../../helpers";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import {ENV} from "../../../../constants";
import "./BulkUploadComponent.scss"
import {
    TsDataListOptions,
    TsDataListState,
    TsDataListWrapperClass
} from "../../../../classes/ts-data-list-wrapper.class";
import {useLocalStorage} from "../../../../components/useLocalStorage";

import {
    Button,
    FormControlLabel,
    InputAdornment, Radio,
    RadioGroup as NormalRadioGroup,
    TablePagination,
} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import NoDataCardComponent from "../../../../components/NoDataCardComponent";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import DialogComponent from "../../../../components/DialogComponent";
import FilePickerComponent from "../../../../components/file-picker/FilePickerComponent";
import DeleteIcon from "@material-ui/icons/Delete";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const BulkUploadComponent = () => {
    const [facilityList, setFacilityList] = useState<any | null>(null);
    const [selectedFacility, setSelectedFacility] = useState<any | null>(null);
    const [selectedFacilityForUpload, setSelectedFacilityForUpload] = useState<any | null>(null);
    const [isFacilityListLoading, setIsFacilityListLoading] = useState<boolean>(false);
    const [value, setValue] = useState<any>(0);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [isBulkUploadDialogOpen, setIsBulkUploadDialogOpen] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<string>("facility");
    const [shiftUploadFile, setShiftUploadFile] = React.useState<any>([]);

    const handleChanges = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const getFacilityData = useCallback(() => {
        setIsFacilityListLoading(true);
        let payload: any = {};
        ApiService.post(ENV.API_URL + "facility/lite", payload)
            .then((res) => {
                setFacilityList(res?.data || []);
                setIsFacilityListLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsFacilityListLoading(false);
            });
    }, []);

    useEffect(() => {
        getFacilityData()
    }, [getFacilityData]);

    useEffect(() => {
        Communications.pageTitleSubject.next("Bulk Upload");
        Communications.pageBackButtonSubject.next("/shiftrequirementMaster/list");
    }, []);

    const [list, setList] = React.useState<TsDataListState | null>(null);
    const [pageSizeIndex, setPageSizeIndex] = useLocalStorage<any>("shiftReqPageSizeIndex", 10);

    const init = useCallback(() => {
        console.log(selectedFacility)
        let url = "requirement/uploadLogslist";
        let payload: any = {};

        if (value === 0) {
            payload.type = "facility";
        }
        if (selectedFacility) {
            payload.facility_id = selectedFacility?._id;
        }
        if (value === 1) {
            payload.type = "home";
        }
        if (value === 2) {
            payload.type = "contract";
        }

        //
        // if (shiftRequirementType?.value !== "") {
        //     payload.type = shiftRequirementType?.value;
        // }


        const options = new TsDataListOptions(
            {
                // @ts-ignore
                pagination: {
                    ...list?.table?.pagination,
                    pageSize: pageSizeIndex,
                },
                extraPayload: payload,
                webMatColumns: ["Facility Name", "Uploaded On", "Uploaded By", " "],
                mobileMatColumns: ["Facility Name", "Uploaded On", "Uploaded By", " "]
                ,
            },
            ENV.API_URL + url,
            setList,
            ApiService,
            "post"
        );

        let tableWrapperObj = new TsDataListWrapperClass(options);
        setList({table: tableWrapperObj});
        // eslint-disable-next-line
    }, [value, selectedFacility]);

    const getList = useCallback(() => {
        console.log("list")
        if (!list) {
            init();
            return;
        }
        let payload: any = {};

        if (value === 0) {
            payload.type = "facility";
        }

        if (value === 1) {
            payload.type = "home";
        }
        if (value === 2) {
            payload.type = "contract";
        }

        if (selectedFacility) {
            payload.facility_id = selectedFacility?._id;
        }

        list?.table?.setExtraPayload(payload);
        list?.table?.getList(1);
        // eslint-disable-next-line
    }, [value, selectedFacility]);

    const handleDownload = useCallback(() => {
        let payload: any = {};

        if (value === 0) {
            payload.type = "facility";
        }

        if (value === 1) {
            payload.type = "home";
        }
        if (value === 2) {
            payload.type = "contract";
        }

        setIsDownloading(true);
        ApiService.post(ENV.API_URL + "downloadTemplates", payload)
            .then((res) => {
                if (res?.data) {
                    const link = document.createElement("a");
                    link?.setAttribute("href", res?.data);
                    document.body.appendChild(link);
                    link.click();
                    setIsDownloading(false);
                } else {
                    CommonService.showToast(res?.error || "No Data to Download", "info");
                    setIsDownloading(false);
                }
            })
            .catch((err) => {
                setIsDownloading(false);
                CommonService.showToast(err?.error || "Error", "error");
            });
    }, [value]);


    const handleParticularDataDownload = useCallback((key: any) => {
        let payload: any = {};
        payload.file_key = key;
        ApiService.post(ENV.API_URL + "downloadAttachment", payload)
            .then((res) => {
                if (res?.data) {
                    const link = document.createElement("a");
                    link?.setAttribute("href", res?.data);
                    document.body.appendChild(link);
                    link.click();
                } else {
                    CommonService.showToast(res?.error || "No Data to Download", "info");
                }
            })
            .catch((err) => {
                CommonService.showToast(err?.error || "Error", "error");
            });
    }, [])


    useEffect(() => {
        getList();
    }, [getList]);

    const handleChange = (event: any) => {
        console.log(event.target.value);
        setSelectedValue(event.target.value);
    };

    const removeFile = (index: number) => {
        // Create a new array excluding the file at the given index
        const newTimesheetFile = [...shiftUploadFile];
        newTimesheetFile.splice(index, 1);
        // Update the timesheetFile state with the new array
        setShiftUploadFile([]);
    };


    const onAddBulkFileUpload = useCallback(() => {
        let url: any = ""
        const formData = new FormData();

        if (shiftUploadFile?.length > 0) {
            shiftUploadFile.forEach((file: File) => {
                // Ensure each file is appended correctly
                formData.append("attachment", file);
            });
        } else {
            console.error("No files to upload");
            return;  // Early return if no files are uploaded
        }

        // Append other fields
        formData.append("facility_id", selectedFacilityForUpload?._id || "");
        formData.append("type", selectedValue || "");
        formData.append("type", selectedValue);
        url = selectedValue === "facility" ? "requirement/bulkUploadPerDiem" : selectedValue === "home" ? "requirement/bulkUploadHome" : "requirement/bulkUploadContract"


        ApiService.upload(ENV.API_URL + url, formData,)
            .then((res) => {
                CommonService.showToast(res?.message, "info");
                setIsBulkUploadDialogOpen(false)
                getList();
                setValue(selectedValue === "facility" ? 0 : selectedValue === "home" ? 1 : 2);
                setSelectedFacilityForUpload(null);
                setShiftUploadFile([])
            })
            .catch((err) => {
                //  setSelectedFacilityForUpload(null);
                setShiftUploadFile([])
                CommonService.showToast(err?.error || "Error", "error");
            });

    }, [selectedFacilityForUpload, shiftUploadFile, selectedValue, getList])


    useEffect(() => {
        console.log(selectedFacilityForUpload);
    }, [selectedFacilityForUpload]);
    return (
        <>
            <div className={'bulk-upload-container-component'}>
                <DialogComponent isEditableDialog={true} open={isBulkUploadDialogOpen} cancel={() => {
                    setIsBulkUploadDialogOpen(false)
                }}>
                    <div className={'mrg-20 bulk-upload-dialog'}>
                        <div className={'form-header-text mrg-bottom-20'}>Bulk Upload</div>
                        <div>
                            {(!isFacilityListLoading && facilityList) &&
                                <Autocomplete
                                    disableClearable
                                    PaperComponent={({children}) => <Paper
                                        style={{
                                            color: "#1e1e1e",
                                        }}>{children}</Paper>}
                                    value={selectedFacilityForUpload}
                                    options={facilityList}
                                    getOptionLabel={(option: any) => option.name}
                                    placeholder={"Time Sheet"}
                                    id="input_select_status"
                                    onChange={($event, value) => {
                                        setSelectedFacilityForUpload(value);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            style={{
                                                minWidth: "200px",
                                                padding: "0px !important"
                                            }} {...params}
                                            id="select_requested_by"
                                            variant="outlined"
                                            placeholder={"Facility"}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {params.InputProps.endAdornment}
                                                        {selectedFacilityForUpload ? (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={() => setSelectedFacilityForUpload(null)}
                                                                    edge="end"
                                                                >
                                                                    <ClearIcon/>
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ) : null}
                                                    </>
                                                ),
                                            }}

                                        />
                                    )}
                                />}
                        </div>
                        <div>
                            <NormalRadioGroup aria-label="gender" defaultValue="open_shift" name="radio-buttons-group"
                                              className="mrg-top-10">
                                <div className={'d-flex mrg-bottom-20'}>
                                    {[{
                                        name: "Per Diem",
                                        code: "open_shift"
                                    }, {
                                        name: "Per Job / Visit",
                                        code: "home"
                                    }, {
                                        name: "Contract / Travel",
                                        code: "contract"
                                    }]?.map((item: any) => {
                                        return (
                                            <FormControlLabel value={item?.code}
                                                              disabled={shiftUploadFile?.length > 0}
                                                              style={{color: "red !important"}}
                                                              control={<Radio style={{color: "#0DB9D8"}}/>}
                                                              onChange={(event) => handleChange(event)}
                                                              label={item?.name}/>
                                        );
                                    })}
                                </div>
                            </NormalRadioGroup>
                        </div>
                        {shiftUploadFile?.length === 0 &&
                            <FilePickerComponent
                                height={'470px'}
                                id={" sc_upload_btn"}
                                btnText={"Upload"}
                                // disabled={timesheetFile?.length === 3}
                                multiple={false}
                                showDropZone={false}
                                onFilesDrop={(acceptedFiles: any, rejectedFiles: any) => {
                                    if (acceptedFiles && acceptedFiles.length > 0) {
                                        const file = acceptedFiles[0];
                                        //   setTimesheetFile(file);
                                        setShiftUploadFile([...shiftUploadFile, file]);
                                    }
                                }}
                                acceptedFileTypes={["xlsx"]}
                                acceptedFilesText={"Only .xlsx files are supported"}
                            />
                        }
                        <div className={'d-flex mrg-top-10 mrg-bottom-20'}>
                            {shiftUploadFile && shiftUploadFile?.map((file: any, index: number) => {
                                return (
                                    <>
                                        <div
                                            className={'d-flex justify-content-space-between file-holder-wrapper mrg-right-10 align-items-center '}>
                                            <div>  {file?.name}</div>
                                            <div className={'cursor-pointer'}
                                                 onClick={() => {
                                                     removeFile(index);
                                                 }}>
                                                <DeleteIcon/>
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                        <div className={'d-flex justify-content-center'}>
                            <div className="actions">
                                <Button variant={"outlined"} color="primary"
                                        onClick={() => {
                                            setIsBulkUploadDialogOpen(false)
                                        }}
                                >
                                    &nbsp;&nbsp;Cancel&nbsp;&nbsp;
                                </Button>
                            </div>
                            <div className="mrg-left-10">
                                <Button
                                    onClick={onAddBulkFileUpload}
                                    disabled={shiftUploadFile?.length === 0 || !selectedFacilityForUpload}
                                    variant={"contained"}
                                    color={"primary"}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;Save&nbsp;&nbsp;&nbsp;&nbsp;
                                </Button>
                            </div>
                        </div>
                    </div>
                </DialogComponent>
                <div>

                    <Box sx={{width: '100%'}}>
                        <Box style={{borderBottom: "1px solid #e4e9ea"}}>
                            <Tabs value={value} onChange={(e: any, value: any) => {
                                handleChanges(e, value);
                                setList(null)
                            }
                            } aria-label="tab"
                                  TabIndicatorProps={{
                                      style: {
                                          backgroundColor: "#56ebff",
                                      }
                                  }}
                                  style={{
                                      color: "black",
                                  }}>
                                <Tab label="Per Diem" {...a11yProps(0)} />
                                <Tab
                                    label="Per Job / Visit" {...a11yProps(1)} />
                                <Tab
                                    label="Contract / Travel" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <div className="hcp_view_details screen crud-layout pdd-30">
                            <TabPanel value={value} index={value}>
                                <div className="custom-border pdd-10  pdd-bottom-0">
                                    <div className={'d-flex '}>
                                        <div className="header d-flex justify-content-end" style={{width: '100%'}}>
                                            <div className="d-flex bulk-facility-filter">
                                                <div>
                                                    {(!isFacilityListLoading && facilityList) &&
                                                        <Autocomplete
                                                            disableClearable
                                                            PaperComponent={({children}) => <Paper
                                                                style={{
                                                                    color: "#1e1e1e",
                                                                }}>{children}</Paper>}
                                                            value={selectedFacility}
                                                            options={facilityList}
                                                            getOptionLabel={(option: any) => option.name}
                                                            placeholder={"Time Sheet"}
                                                            id="input_select_status"
                                                            onChange={($event, value) => {
                                                                setSelectedFacility(value);
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    style={{
                                                                        minWidth: "200px",
                                                                        padding: "0px !important"
                                                                    }} {...params}
                                                                    id="select_requested_by"
                                                                    variant="outlined"
                                                                    placeholder={"Facility"}
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        endAdornment: (
                                                                            <>
                                                                                {params.InputProps.endAdornment}
                                                                                {selectedFacility ? (
                                                                                    <InputAdornment position="end">
                                                                                        <IconButton
                                                                                            onClick={() => setSelectedFacility(null)}
                                                                                            edge="end"
                                                                                        >
                                                                                            <ClearIcon/>
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                ) : null}
                                                                            </>
                                                                        ),
                                                                    }}

                                                                />
                                                            )}
                                                            disableCloseOnSelect
                                                        />}
                                                </div>
                                                <div className="actions">
                                                    <Button variant={"outlined"} color="primary"
                                                            onClick={handleDownload}
                                                            className={!isDownloading ? "" : "has-loading-spinner"}
                                                            disabled={isDownloading}>
                                                        &nbsp;Download {value === 0 ? "Per Diem" : value === 1 ? "Per Job / Visit" : "Contract / Travel"} Template
                                                    </Button>
                                                </div>
                                                <div className="mrg-left-10">
                                                    <Button
                                                        onClick={() => {
                                                            setIsBulkUploadDialogOpen(true);
                                                        }}
                                                        variant={"contained"}
                                                        color={"primary"}>
                                                        &nbsp;&nbsp;Upload File&nbsp;&nbsp;
                                                    </Button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    {list && list.table && (
                                        <>
                                            <TableContainer component={Paper} className={"table-responsive"}>
                                                <Table stickyHeader className="mat-table table open-shifts-list-table">
                                                    <TableHead className={"mat-thead"}>
                                                        <TableRow className={"mat-tr"}>
                                                            {list?.table.matColumns.map((column: any, columnIndex: any) => (
                                                                <TableCell
                                                                    className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}
                                                                    key={"header-col-" + columnIndex}>
                                                                    {column}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody className={"mat-tbody"}>
                                                        {!list.table._isDataLoading && list.table?.data.length === 0 &&
                                                            <NoDataCardComponent
                                                                tableCellCount={list.table.matColumns.length}/>}
                                                        {list.table?.data.length > 0 &&
                                                            list?.table?.data?.map((row: any, rowIndex: any) => {
                                                                ;
                                                                return (
                                                                    <TableRow role="checkbox" tabIndex={-1}
                                                                              key={"row-" + rowIndex}
                                                                              className={"mat-tr"}>
                                                                        <TableCell
                                                                            className="mat-td mat-td-facility-name">{row["facility"]?.name}</TableCell>

                                                                        <TableCell
                                                                            className="mat-td mat-td-hcp-type">{CommonService.formatDateTime(row["updated_at"])}</TableCell>
                                                                        <TableCell
                                                                            className="mat-td mat-td-hcp-count">{row["uploaded_by"]?.first_name + " " + row["uploaded_by"]?.last_name}</TableCell>

                                                                        <TableCell
                                                                            className="mat-td mat-td-shift-type">
                                                                            <div className="actions pdd-right-5">
                                                                                <Button variant={"outlined"}
                                                                                        color="primary"
                                                                                        onClick={() => {
                                                                                            handleParticularDataDownload(row["s3_path"]);
                                                                                        }}
                                                                                    //  className={!isDownloading ? "" : "has-loading-spinner"}
                                                                                    //   disabled={isDownloading}
                                                                                >
                                                                                    &nbsp;Download
                                                                                </Button>
                                                                            </div>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={list.table.pagination.pageSizeOptions}
                                                component="div"
                                                count={list?.table.pagination.totalItems}
                                                rowsPerPage={list?.table.pagination.pageSize}
                                                page={list?.table.pagination.pageIndex}
                                                onPageChange={(event, page) => list.table.pageEvent(page)}
                                                onRowsPerPageChange={(event) => {
                                                    setPageSizeIndex(event.target.value);
                                                    list.table?.pageEvent(0, +event.target.value);
                                                }}
                                            />
                                        </>
                                    )}
                                </div>
                            </TabPanel>
                            {/*<TabPanel value={value} index={value}>*/}
                            {/*    <div className="custom-border pdd-10  pdd-bottom-0">*/}
                            {/*        <div className={'d-flex '}>*/}
                            {/*            <div className="header d-flex justify-content-end" style={{width: '100%'}}>*/}
                            {/*                <div className="d-flex bulk-facility-filter">*/}
                            {/*                    <div>*/}
                            {/*                        {(!isFacilityListLoading && facilityList) &&*/}
                            {/*                            <Autocomplete*/}
                            {/*                                disableClearable*/}
                            {/*                                PaperComponent={({children}) => <Paper*/}
                            {/*                                    style={{*/}
                            {/*                                        color: "#1e1e1e",*/}
                            {/*                                    }}>{children}</Paper>}*/}
                            {/*                                value={selectedFacility}*/}
                            {/*                                options={facilityList}*/}
                            {/*                                getOptionLabel={(option: any) => option.name}*/}
                            {/*                                placeholder={"Time Sheet"}*/}
                            {/*                                id="input_select_status"*/}
                            {/*                                onChange={($event, value) => {*/}
                            {/*                                    setSelectedFacility(value);*/}
                            {/*                                }}*/}
                            {/*                                renderInput={(params) => (*/}
                            {/*                                    <TextField*/}
                            {/*                                        style={{*/}
                            {/*                                            minWidth: "200px",*/}
                            {/*                                            padding: "0px !important"*/}
                            {/*                                        }} {...params}*/}
                            {/*                                        id="select_requested_by"*/}
                            {/*                                        variant="outlined"*/}
                            {/*                                        placeholder={"Facility"}*/}
                            {/*                                        InputProps={{*/}
                            {/*                                            ...params.InputProps,*/}
                            {/*                                            endAdornment: (*/}
                            {/*                                                <>*/}
                            {/*                                                    {params.InputProps.endAdornment}*/}
                            {/*                                                    {selectedFacility ? (*/}
                            {/*                                                        <InputAdornment position="end">*/}
                            {/*                                                            <IconButton*/}
                            {/*                                                                onClick={() => setSelectedFacility(null)}*/}
                            {/*                                                                edge="end"*/}
                            {/*                                                            >*/}
                            {/*                                                                <ClearIcon/>*/}
                            {/*                                                            </IconButton>*/}
                            {/*                                                        </InputAdornment>*/}
                            {/*                                                    ) : null}*/}
                            {/*                                                </>*/}
                            {/*                                            ),*/}
                            {/*                                        }}*/}

                            {/*                                    />*/}
                            {/*                                )}*/}
                            {/*                                disableCloseOnSelect*/}
                            {/*                            />}*/}
                            {/*                    </div>*/}
                            {/*                    <div className="actions pdd-right-5">*/}
                            {/*                        <Button variant={"outlined"} color="primary"*/}
                            {/*                                onClick={handleDownload}*/}
                            {/*                                className={!isDownloading ? "" : "has-loading-spinner"}*/}
                            {/*                                disabled={isDownloading}>*/}
                            {/*                            &nbsp;Download Per Visit Template*/}
                            {/*                        </Button>*/}
                            {/*                    </div>*/}
                            {/*                    <div className="mrg-left-10">*/}
                            {/*                        <Button*/}
                            {/*                            variant={"contained"}*/}
                            {/*                            color={"primary"}>*/}
                            {/*                            &nbsp;&nbsp;Bulk Upload&nbsp;&nbsp;*/}
                            {/*                        </Button>*/}
                            {/*                    </div>*/}

                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        {list && list.table && (*/}
                            {/*            <>*/}
                            {/*                <TableContainer component={Paper} className={"table-responsive"}>*/}
                            {/*                    <Table stickyHeader className="mat-table table open-shifts-list-table">*/}
                            {/*                        <TableHead className={"mat-thead"}>*/}
                            {/*                            <TableRow className={"mat-tr"}>*/}
                            {/*                                {list?.table.matColumns.map((column: any, columnIndex: any) => (*/}
                            {/*                                    <TableCell*/}
                            {/*                                        className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}*/}
                            {/*                                        key={"header-col-" + columnIndex}>*/}
                            {/*                                        {column}*/}
                            {/*                                    </TableCell>*/}
                            {/*                                ))}*/}
                            {/*                            </TableRow>*/}
                            {/*                        </TableHead>*/}
                            {/*                        <TableBody className={"mat-tbody"}>*/}
                            {/*                            {!list.table._isDataLoading && list.table?.data.length === 0 &&*/}
                            {/*                                <NoDataCardComponent*/}
                            {/*                                    tableCellCount={list.table.matColumns.length}/>}*/}
                            {/*                            {list.table?.data.length > 0 &&*/}
                            {/*                                list?.table?.data?.map((row: any, rowIndex: any) => {*/}
                            {/*                                    ;*/}
                            {/*                                    return (*/}
                            {/*                                        <TableRow role="checkbox" tabIndex={-1}*/}
                            {/*                                                  key={"row-" + rowIndex}*/}
                            {/*                                                  className={"mat-tr"}>*/}
                            {/*                                            <TableCell*/}
                            {/*                                                className="mat-td mat-td-facility-name">{row["facility"]?.name}</TableCell>*/}

                            {/*                                            <TableCell*/}
                            {/*                                                className="mat-td mat-td-hcp-type">{CommonService.formatDateTime(row["updated_at"])}</TableCell>*/}
                            {/*                                            <TableCell*/}
                            {/*                                                className="mat-td mat-td-hcp-count">{row["uploaded_by"]?.first_name + " " + row["uploaded_by"]?.last_name}</TableCell>*/}

                            {/*                                            <TableCell*/}
                            {/*                                                className="mat-td mat-td-shift-type">*/}
                            {/*                                                <div className="actions pdd-right-5">*/}
                            {/*                                                    <Button variant={"outlined"} color="primary"*/}
                            {/*                                                            onClick={() => {*/}
                            {/*                                                                handleParticularDataDownload(row["s3_path"]);*/}
                            {/*                                                            }}*/}
                            {/*                                                            className={!isDownloading ? "" : "has-loading-spinner"}*/}
                            {/*                                                            disabled={isDownloading}>*/}
                            {/*                                                        &nbsp;Download*/}
                            {/*                                                    </Button>*/}
                            {/*                                                </div>*/}
                            {/*                                            </TableCell>*/}
                            {/*                                        </TableRow>*/}
                            {/*                                    );*/}
                            {/*                                })}*/}
                            {/*                        </TableBody>*/}
                            {/*                    </Table>*/}
                            {/*                </TableContainer>*/}
                            {/*                <TablePagination*/}
                            {/*                    rowsPerPageOptions={list.table.pagination.pageSizeOptions}*/}
                            {/*                    component="div"*/}
                            {/*                    count={list?.table.pagination.totalItems}*/}
                            {/*                    rowsPerPage={list?.table.pagination.pageSize}*/}
                            {/*                    page={list?.table.pagination.pageIndex}*/}
                            {/*                    onPageChange={(event, page) => list.table.pageEvent(page)}*/}
                            {/*                    onRowsPerPageChange={(event) => {*/}
                            {/*                        setPageSizeIndex(event.target.value);*/}
                            {/*                        list.table?.pageEvent(0, +event.target.value);*/}
                            {/*                    }}*/}
                            {/*                />*/}
                            {/*            </>*/}
                            {/*        )}*/}
                            {/*    </div>*/}
                            {/*</TabPanel>*/}
                            {/*<TabPanel value={value} index={value}>*/}
                            {/*    <div className="custom-border pdd-10  pdd-bottom-0">*/}
                            {/*        <div className={'d-flex '}>*/}
                            {/*            <div className="header d-flex justify-content-end" style={{width: '100%'}}>*/}
                            {/*                <div className="d-flex bulk-facility-filter">*/}
                            {/*                    <div>*/}
                            {/*                        {(!isFacilityListLoading && facilityList) &&*/}
                            {/*                            <Autocomplete*/}
                            {/*                                disableClearable*/}
                            {/*                                PaperComponent={({children}) => <Paper*/}
                            {/*                                    style={{*/}
                            {/*                                        color: "#1e1e1e",*/}
                            {/*                                    }}>{children}</Paper>}*/}
                            {/*                                value={selectedFacility}*/}
                            {/*                                options={facilityList}*/}
                            {/*                                getOptionLabel={(option: any) => option.name}*/}
                            {/*                                placeholder={"Time Sheet"}*/}
                            {/*                                id="input_select_status"*/}
                            {/*                                onChange={($event, value) => {*/}
                            {/*                                    setSelectedFacility(value);*/}
                            {/*                                }}*/}
                            {/*                                renderInput={(params) => (*/}
                            {/*                                    <TextField*/}
                            {/*                                        style={{*/}
                            {/*                                            minWidth: "200px",*/}
                            {/*                                            padding: "0px !important"*/}
                            {/*                                        }} {...params}*/}
                            {/*                                        id="select_requested_by"*/}
                            {/*                                        variant="outlined"*/}
                            {/*                                        placeholder={"Facility"}*/}
                            {/*                                        InputProps={{*/}
                            {/*                                            ...params.InputProps,*/}
                            {/*                                            endAdornment: (*/}
                            {/*                                                <>*/}
                            {/*                                                    {params.InputProps.endAdornment}*/}
                            {/*                                                    {selectedFacility ? (*/}
                            {/*                                                        <InputAdornment position="end">*/}
                            {/*                                                            <IconButton*/}
                            {/*                                                                onClick={() => setSelectedFacility(null)}*/}
                            {/*                                                                edge="end"*/}
                            {/*                                                            >*/}
                            {/*                                                                <ClearIcon/>*/}
                            {/*                                                            </IconButton>*/}
                            {/*                                                        </InputAdornment>*/}
                            {/*                                                    ) : null}*/}
                            {/*                                                </>*/}
                            {/*                                            ),*/}
                            {/*                                        }}*/}

                            {/*                                    />*/}
                            {/*                                )}*/}
                            {/*                                disableCloseOnSelect*/}
                            {/*                            />}*/}
                            {/*                    </div>*/}
                            {/*                    <div className="actions pdd-right-5">*/}
                            {/*                        <Button variant={"outlined"} color="primary"*/}
                            {/*                                onClick={handleDownload}*/}
                            {/*                                className={!isDownloading ? "" : "has-loading-spinner"}*/}
                            {/*                                disabled={isDownloading}>*/}
                            {/*                            &nbsp;Download Contract / Travel Template*/}
                            {/*                        </Button>*/}
                            {/*                    </div>*/}
                            {/*                    <div className="mrg-left-10">*/}
                            {/*                        <Button*/}
                            {/*                            variant={"contained"}*/}
                            {/*                            color={"primary"}>*/}
                            {/*                            &nbsp;&nbsp;Bulk Upload&nbsp;&nbsp;*/}
                            {/*                        </Button>*/}
                            {/*                    </div>*/}

                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        {list && list.table && (*/}
                            {/*            <>*/}
                            {/*                <TableContainer component={Paper} className={"table-responsive"}>*/}
                            {/*                    <Table stickyHeader className="mat-table table open-shifts-list-table">*/}
                            {/*                        <TableHead className={"mat-thead"}>*/}
                            {/*                            <TableRow className={"mat-tr"}>*/}
                            {/*                                {list?.table.matColumns.map((column: any, columnIndex: any) => (*/}
                            {/*                                    <TableCell*/}
                            {/*                                        className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}*/}
                            {/*                                        key={"header-col-" + columnIndex}>*/}
                            {/*                                        {column}*/}
                            {/*                                    </TableCell>*/}
                            {/*                                ))}*/}
                            {/*                            </TableRow>*/}
                            {/*                        </TableHead>*/}
                            {/*                        <TableBody className={"mat-tbody"}>*/}
                            {/*                            {!list.table._isDataLoading && list.table?.data.length === 0 &&*/}
                            {/*                                <NoDataCardComponent*/}
                            {/*                                    tableCellCount={list.table.matColumns.length}/>}*/}
                            {/*                            {list.table?.data.length > 0 &&*/}
                            {/*                                list?.table?.data?.map((row: any, rowIndex: any) => {*/}
                            {/*                                    ;*/}
                            {/*                                    return (*/}
                            {/*                                        <TableRow role="checkbox" tabIndex={-1}*/}
                            {/*                                                  key={"row-" + rowIndex}*/}
                            {/*                                                  className={"mat-tr"}>*/}
                            {/*                                            <TableCell*/}
                            {/*                                                className="mat-td mat-td-facility-name">{row["facility"]?.name}</TableCell>*/}

                            {/*                                            <TableCell*/}
                            {/*                                                className="mat-td mat-td-hcp-type">{CommonService.formatDateTime(row["updated_at"])}</TableCell>*/}
                            {/*                                            <TableCell*/}
                            {/*                                                className="mat-td mat-td-hcp-count">{row["uploaded_by"]?.first_name + " " + row["uploaded_by"]?.last_name}</TableCell>*/}

                            {/*                                            <TableCell*/}
                            {/*                                                className="mat-td mat-td-shift-type">*/}
                            {/*                                                <div className="actions pdd-right-5">*/}
                            {/*                                                    <Button variant={"outlined"} color="primary"*/}
                            {/*                                                            onClick={() => {*/}
                            {/*                                                                handleParticularDataDownload(row["s3_path"]);*/}
                            {/*                                                            }}*/}
                            {/*                                                            className={!isDownloading ? "" : "has-loading-spinner"}*/}
                            {/*                                                            disabled={isDownloading}>*/}
                            {/*                                                        &nbsp;Download*/}
                            {/*                                                    </Button>*/}
                            {/*                                                </div>*/}
                            {/*                                            </TableCell>*/}
                            {/*                                        </TableRow>*/}
                            {/*                                    );*/}
                            {/*                                })}*/}
                            {/*                        </TableBody>*/}
                            {/*                    </Table>*/}
                            {/*                </TableContainer>*/}
                            {/*                <TablePagination*/}
                            {/*                    rowsPerPageOptions={list.table.pagination.pageSizeOptions}*/}
                            {/*                    component="div"*/}
                            {/*                    count={list?.table.pagination.totalItems}*/}
                            {/*                    rowsPerPage={list?.table.pagination.pageSize}*/}
                            {/*                    page={list?.table.pagination.pageIndex}*/}
                            {/*                    onPageChange={(event, page) => list.table.pageEvent(page)}*/}
                            {/*                    onRowsPerPageChange={(event) => {*/}
                            {/*                        setPageSizeIndex(event.target.value);*/}
                            {/*                        list.table?.pageEvent(0, +event.target.value);*/}
                            {/*                    }}*/}
                            {/*                />*/}
                            {/*            </>*/}
                            {/*        )}*/}
                            {/*    </div>*/}
                            {/*</TabPanel>*/}
                        </div>
                    </Box>
                </div>
            </div>
        </>
    )
}

export default BulkUploadComponent;

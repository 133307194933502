import React, {useCallback, useEffect, useState} from "react";
import "./HcpAttendanceComponent.scss";
import {ApiService, CommonService} from "../../../../helpers";
import {ENV} from "../../../../constants";
import {
    TsDataListOptions,
    TsDataListState,
    TsDataListWrapperClass
} from "../../../../classes/ts-data-list-wrapper.class";
import {useLocalStorage} from "../../../../components/useLocalStorage";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import NoDataCardComponent from "../../../../components/NoDataCardComponent";
import moment from "moment/moment";
import TablePagination from "@material-ui/core/TablePagination";
import EditScoreComponent from "./EditHcpScoreComponent";
import DialogComponent from "../../../../components/DialogComponent";

interface HcpAttendanceProps {
    hcpBasicDetails?: any;
}

const HcpAttendanceComponent = (props: HcpAttendanceProps) => {
    const [list, setList] = useState<TsDataListState | null>(null);
    const {hcpBasicDetails} = props;
    const [hcpStats, setHcpStats] = useState<any>(null);
    const [pageSizeIndex, setPageSizeIndex] = useLocalStorage<any>("hcpAttendancePageSizeIndex", 10);
    const [isHcpScoreEditOpen, setIsHcpScoreEditOpen] = useState(false);
    const handleHcpStatsApiCall = useCallback(() => {
        console.log(hcpBasicDetails);
        // config
        CommonService._api
            .get(ENV.API_URL + "hcp/" + hcpBasicDetails?.user_id + "/attendanceStats")
            .then((resp) => {
                setHcpStats(resp.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [hcpBasicDetails]);

    useEffect(() => {
        handleHcpStatsApiCall();
    }, [handleHcpStatsApiCall, hcpBasicDetails]);


    const init = useCallback(() => {
        let url = `hcp/${hcpBasicDetails?.user_id}/attendance`;
        let payload: any = {};
        const options = new TsDataListOptions(
            {
                // @ts-ignore
                pagination: {
                    ...list?.table?.pagination,
                    pageSize: pageSizeIndex,
                },
                extraPayload: payload,
                webMatColumns: ["Attendance Score", "Title", "Description", "Created Date"],
                mobileMatColumns: ["Attendance Score", "Title", "Description", "Created Date"],
            },
            ENV.API_URL + url,
            setList,
            ApiService,
            "get"
        );

        let tableWrapperObj = new TsDataListWrapperClass(options);
        setList({table: tableWrapperObj});
        // eslint-disable-next-line
    }, [hcpBasicDetails]);

    const getList = useCallback(() => {
        if (!list) {
            init();
            return;
        }
        let payload: any = {};
        list?.table?.setExtraPayload(payload);
        list?.table?.getList(1);
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        getList();
    }, [getList]);

    const getClassName = (score: any) => {
        const scores = score?.toString();
        if (scores && scores?.length > 0) {
            if (scores?.includes('+')) {
                return "green";
            } else if (scores?.includes('-')) {
                return "red";
            } else {
                return "yellow";
            }
        } else {
            return "";
        }
    };


    return (
        <>
            <DialogComponent open={isHcpScoreEditOpen} cancel={() => {
                setIsHcpScoreEditOpen(false);
            }}>
                <EditScoreComponent
                    hcpId={hcpBasicDetails?.user_id}
                    hcpStats={hcpStats}
                    setIsHcpScoreEditOpen={setIsHcpScoreEditOpen}
                    handleHcpStatsApiCall={handleHcpStatsApiCall}
                    getList={getList}
                />
            </DialogComponent>
            <div className="hcp-attendance-component d-flex  justify-content-space-between custom-border">
                <div className="flex-1">
                    <h4 className={'header-text'}>Total Score<span onClick={() => {
                        setIsHcpScoreEditOpen(true);
                    }} className={'edit-button-score mrg-left-10 cursor-pointer'}>
(Edit)
                    </span></h4>
                    <p className={
                        hcpStats?.total_score === undefined
                            ? "gray"
                            : hcpStats.total_score >= 75
                                ? "green"
                                : hcpStats.total_score > 25
                                    ? "yellow"
                                    : "red"
                    }>
                        {hcpStats && hcpStats?.total_score}
                    </p>
                </div>
                <div className="flex-1">
                    <h4 className={'header-text'}>Total Shifts</h4>
                    <p className={'header-text'}>{hcpStats && hcpStats?.total_shifts}</p>
                </div>
                <div className="flex-1">
                    <h4 className={'header-text'}>Upcoming Shifts</h4>
                    <p className={'header-text'}>
                        {hcpStats && hcpStats?.upcoming_shifts}
                    </p>
                </div>
                <div className="flex-1">
                    <h4 className={'header-text'}>NCNS</h4>
                    <p className={'header-text'}>{hcpStats && hcpStats?.total_ncns}</p>
                </div>
            </div>
            <div className={'mrg-top-30'}>
                {list && list.table && (
                    <>
                        <TableContainer component={Paper} className={"table-responsive min-width-100"}>
                            <Table stickyHeader className="mat-table table hcp-list-table">
                                <TableHead className={"mat-thead"}>
                                    <TableRow className={"mat-tr"}>
                                        {list?.table.matColumns.map((column: any, columnIndex: any) => (
                                            <TableCell
                                                className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}
                                                key={"header-col-" + columnIndex}>
                                                {column}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody className={"mat-tbody"}>
                                    {!list.table._isDataLoading && list.table?.data.length === 0 &&
                                        <NoDataCardComponent tableCellCount={list.table.matColumns.length}/>}
                                    {list?.table.data.map((row: any, rowIndex: any) => {
                                        const className = getClassName(row["score"]);
                                        return (
                                            <>
                                                {row["score"] && <>
                                            <TableRow role="checkbox" tabIndex={-1} key={"row-" + rowIndex}
                                                      className={"mat-tr"}>
                                                <TableCell className="mat-td mat-td-hcp-name">
                                                    <div className={className}>
                                                        {row["score"]}
                                                    </div>
                                                </TableCell>

                                                <TableCell
                                                    className="mat-td mat-td-created-at min-width-300">
                                                    {row["shift_title"] ? row["shift_title"] : row["shift_titles"]}
                                                </TableCell>
                                                <TableCell
                                                    className="mat-td mat-td-created-at">
                                                    {row["description"]}
                                                </TableCell>
                                                <TableCell
                                                    className="mat-td mat-td-created-at">
                                                    {row["created_at"] &&
                                                        <div>
                                                            {moment(row["created_at"]).format("MM-DD-YYYY")}
                                                        </div>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                                </>
                                                }
                                            </>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={list.table.pagination.pageSizeOptions}
                            component="div"
                            count={list?.table.pagination.totalItems}
                            rowsPerPage={list?.table.pagination.pageSize}
                            page={list?.table.pagination.pageIndex}
                            onPageChange={(event, page) => list.table.pageEvent(page)}
                            onRowsPerPageChange={(event) => {
                                setPageSizeIndex(event.target.value);
                                list.table?.pageEvent(0, +event.target.value);
                            }}
                        />
                    </>
                )}
            </div>
        </>
    );
};
export default HcpAttendanceComponent;
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import React, {useCallback, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import LoaderComponent from '../../../components/LoaderComponent';
import NoDataToShowCardComponent from '../../../components/NoDataToShowCardComponent';
import {ENV} from '../../../constants';
import {CommonService, Communications} from '../../../helpers';
import FacilityBasicDetailsComponent from './basicDetails/FacilityBasicDetailsComponent';
import './FacilityManagementViewScreen.scss';
import {useHistory} from "react-router-dom";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import FacilityManagementNotesComponent from "./note-tab/FacilityManagementNotesComponent";
import {useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const FacilityManagementViewScreen = (props: any) => {
    const classes = useStyles();
    let history = useHistory();
    const params = useParams<{ id: string }>();
    const {id} = params;
    const [facilityDetails, setFacilityDetails] = useState<any | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [expanded, setExpanded] = useState<string | false>(false);
    const [value, setValue] = useState<any>(0);
    const {user} = useSelector((state: StateParams) => state.auth);
    const handleChanges = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const init = useCallback(() => {
        // config
        CommonService._api.get(ENV.API_URL + 'facility/' + id).then((resp) => {
            setFacilityDetails(resp.data);
            setIsLoading(false);
        }).catch((err) => {
            //   console.log(err)
        });
    }, [id]);

    useEffect(() => {
        let prevLocation: any = "/facility/list";
        if (props?.location.state) {
            prevLocation = props?.location.state?.prevPath;
        }
        init();
        Communications.pageTitleSubject.next('Facility Details');
        Communications.pageBackButtonSubject.next(prevLocation);
    }, [init, id, history, props?.location.state]);


    if (isLoading) {
        return <LoaderComponent/>;
    }

    return <>
        <Box sx={{width: '100%'}}>
            <Box style={{borderBottom:"1px solid #e4e9ea"}}>
                <Tabs value={value} onChange={(e: any, value: any) => {
                    handleChanges(e, value);
                }
                } aria-label="tab"
                      TabIndicatorProps={{
                    style: {
                        backgroundColor: "#56ebff",
                    }
                }}
                style={{
                    color:"black",
                }}>
                    <Tab   label="About" {...a11yProps(0)} />
                    <Tab  disabled={user?.role === "operational_manager"} label="Notes" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <div>
                    {!isLoading && (<div className="facility_view_details">
                        <FacilityBasicDetailsComponent init={init} facilityDetails={facilityDetails}/>
                        <div className="basic_details custom-border mrg-top-10">
                            <Accordion expanded={expanded === 'Facility_Members'} onChange={handleChange('Facility_Members')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="panel1bh-content"
                                    id="panel1bhs-header"
                                >
                                    <Typography className={classes.heading}>Facility Members</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className={classes.root}>
                                        {
                                            facilityDetails?.members?.length > 0 ? facilityDetails?.members?.map((item: any, index: any) => {
                                                return (<div className={index !== 0 ? "mrg-top-30" : "mrg-top-10"}>
                                                    <h4 className="title-count mrg-top-0">Facility Member {index + 1}</h4>
                                                    <div>
                                                        <div className="d-flex">
                                                            <div className="flex-1">
                                                                <h4>Facility Manager Name</h4>
                                                                <p>{item.name}</p>
                                                            </div>
                                                            <div className="flex-1">
                                                                <h4>Email</h4>
                                                                <p>{item.email}</p>
                                                            </div>
                                                            <div className="flex-1">
                                                                <h4>Contact Number</h4>
                                                                <p>{item.contact_number}</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="flex-1">
                                                                <h4>Extension Number</h4>
                                                                <p>{item.extension_number ? item.extension_number : "N/A"}</p>
                                                            </div>
                                                            <div className="flex-1">
                                                                <h4>Designation</h4>
                                                                <p>{item.designation}</p>
                                                            </div>
                                                            <div className="flex-1"></div>
                                                        </div>
                                                    </div>
                                                </div>);
                                            }) : <NoDataToShowCardComponent/>
                                        }
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className="basic_details custom-border mrg-top-10">
                            <Accordion expanded={expanded === 'Shift_Details'} onChange={handleChange('Shift_Details')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography className={classes.heading}>Shift Details</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className={classes.root}>
                                        {
                                            facilityDetails?.shifts?.length > 0 ? facilityDetails?.shifts?.map((item: any, index: any) => {
                                                const start_time_to_show = moment(CommonService.convertMinsToHrsMins(item?.shift_start_time), 'hh:mm').format('LT');
                                                const end_time_to_show = moment(CommonService.convertMinsToHrsMins(item?.shift_end_time), 'hh:mm').format('LT');

                                                let start_time_to_cal = CommonService.convertMinsToHrsMins(item.shift_start_time);
                                                let end_time_to_cal = CommonService.convertMinsToHrsMins(item.shift_end_time);

                                                const start_time_to_get_duration = moment().startOf('day').add(item.shift_start_time, 'minutes');
                                                let end_time_to_get_duration = moment().startOf('day').add(item.shift_end_time, 'minutes');
                                                if (start_time_to_cal > end_time_to_cal) {
                                                    end_time_to_get_duration = moment(end_time_to_get_duration).add(1, 'day');
                                                }
                                                return (<div className={index !== 0 ? "mrg-top-30" : "mrg-top-10"}>
                                                    <h4 className="title-count mrg-top-0">Shift Details {index + 1}</h4>
                                                    <div className="">
                                                        <div className="d-flex">
                                                            <div className="flex-1">
                                                                <h4>Shift Start Time</h4>
                                                                <p>{start_time_to_show}</p>

                                                            </div>
                                                            <div className="flex-1">
                                                                <h4>Shift End Time</h4>
                                                                <p>{end_time_to_show}</p>
                                                            </div>
                                                            <div className="flex-1">
                                                                <h4>Shift Duration</h4>
                                                                <p>{CommonService.durationBetweenTimeStamps(
                                                                    start_time_to_get_duration,
                                                                    end_time_to_get_duration
                                                                )}</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="flex-1">
                                                                <h4>Shift Type</h4>
                                                                <p>{item.shift_type}</p>
                                                            </div>
                                                            <div className="flex-1"></div>
                                                        </div>
                                                    </div>
                                                </div>);
                                            }) : <NoDataToShowCardComponent/>
                                        }
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>)}
                    <ScrollToTop smooth color="white"/>
                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <FacilityManagementNotesComponent/>
            </TabPanel>
        </Box>
    </>;
};

export default FacilityManagementViewScreen;
import React, {useCallback, useState} from 'react';
import DialogComponent from '../DialogComponent';
import CustomPreviewFile from './CustomPreviewFile';
import moment from 'moment';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { Tooltip } from '@material-ui/core';

// interface CustomFileProps {
//     data?: any,
//     download?: boolean
//     handleDelete?: any,
// }

const CustomFile = (props: any) => {
    const [open, setOpen] = useState(false);
    const handleDownload = props?.handleDownload;
    const isDownload = props?.isDownload;

    const previewFile = useCallback(() => {
        setOpen(true)
    }, [])
    const cancelPreviewFile = useCallback(() => {
        setOpen(false)
    }, [])
    const confirmPreviewFile = useCallback(() => {
        setOpen(false)
    }, [])


    return <div className="custom_file">
        <DialogComponent open={open} cancel={cancelPreviewFile} class="preview-content">
            <CustomPreviewFile cancel={cancelPreviewFile} confirm={confirmPreviewFile} previewData={props?.data} />
        </DialogComponent>
        <h3 className="mrg-top-10 mrg-bottom-0 file_name">{props?.data?.attachment_type}</h3>
        <div className="d-flex">
            <div className="mrg-left-0 mrg-top-15"><InsertDriveFileIcon color={"primary"} className="file-icon" /></div>
            <div className="file_details mrg-left-20 mrg-top-15">
                {props?.data?.expiry_date ? <>
                    <p className="mrg-top-10 mrg-bottom-0">Date:</p>
                    <p className="mrg-top-5 mrg-bottom-0">{props?.data?.expiry_date ? moment(props?.data?.expiry_date).format("MM-DD-YYYY") : "N/A"}</p>
                </> : <></>}<div className={props?.data?.expiry_date ? "d-flex mrg-top-0 file_actions" : "mrg-top-20 file_actions"}>
                    <Tooltip title={`Preview ${props?.data?.attachment_type}`}>
                        <button onClick={previewFile} style={{ cursor: 'pointer' }} className={"mrg-right-20 download-link"}>Preview</button>
                    </Tooltip>
                        <button disabled={isDownload} onClick={()=>handleDownload(props?.data?.file_key)}  className={"download-link"} style={{ cursor: 'pointer' }}>Download</button>
                </div>
            </div>
        </div>
    </div>;
}

export default CustomFile;
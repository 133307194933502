import * as Yup from "yup";
import {CommonService} from "../../../../helpers";
import "./AddShiftsComponentForOpenShift.scss";

export const shiftsFormValidation = Yup.object({
    requirements: Yup.array().of(
        Yup.object().shape({
            facility_name: Yup.string().nullable(),
            start_date: Yup.string().required("Required"),
            end_date: Yup.string().required("Required"),
            start_time: Yup.string().test("end_time", "Start Time can not be same as End Time", function (item) {
                if (CommonService.convertHoursToMinutes(this?.parent.start_time) === CommonService.convertHoursToMinutes(this?.parent.end_time)) {
                    return false;
                } else {
                    return true;
                }
            }),
            end_time: Yup.string().test("start_time", "End Time can not be same as Start Time", function (item) {
                if (CommonService.convertHoursToMinutes(this?.parent.start_time) === CommonService.convertHoursToMinutes(this?.parent.end_time)) {
                    return false;
                } else {
                    return true;
                }
            }),
            //differential_amount: Yup.number().typeError("must be a number").max(9999, "max limit 9999.").required("Required"),
        })
    ),
});

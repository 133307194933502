import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import LoaderComponent from "../../../../components/LoaderComponent";
import NoDataToShowCardComponent from "../../../../components/NoDataToShowCardComponent";
// import CustomFile from "../../../../components/shared/CustomFile";
import {ENV} from "../../../../constants";
import {ApiService, CommonService} from "../../../../helpers";
import HcpContractComponent from "../contract/HcpContractComponent";
import HcpEducationComponent from "../education/HcpEducationComponent";
import HcpExperienceComponent from "../experience/HcpExperienceComponent";
import HcpReferenceComponent from "../reference/HcpReferenceComponent";
import HcpVolunteerExperienceComponent from "../volunteerExperience/HcpVolunteerExperienceComponent";
import "./HcpDetailsComponent.scss";
import DialogComponent from "../../../../components/DialogComponent";
import CustomPreviewFile from "../../../../components/shared/CustomPreviewFile";
import {Avatar, Button, IconButton, Paper} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CancelIcon from "@material-ui/icons/Cancel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import {useSelector} from "react-redux";
import {StateParams} from "../../../../store/reducers";
import TableViewCustomFile from "../../view/details/table-view-for-uploaded-file/TableViewCustomFile";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    tableBody: {
        height: 'auto',
        overflowY: 'auto',
    },
}));

const webMatColumns = ["Facility Name", "Date", "Added By", "Role", "Delete"];
const webMatColumnsForNcns = ["Facility Name", "Date", "Added By", "Role"];
const webMatColumnsForDoc = ["Document Name", "Expires On", "Download"];

const HcpDetailsComponent = (props: any) => {
    const classes = useStyles();
    const params = useParams<{ id: string }>();
    const {id} = params;
    const {setIsAttachmentsLoading} = props;
    const [hcpBasicDetails, setBasicDetails] = useState<any | null>(null);
    //  const [educationDetails, setEducationDetails] = useState<any | null>(null);
    //  const [volunteerExperience, setVolunteerExperience] = useState<any | null>(null);
    //  const [referenceDetails, setReferenceDetails] = useState<any | null>(null);
    //  const [experience, setExperienceDetails] = useState<any | null>(null);
    const [attachmentsDetails, setAttachmentsDetails] = useState<any | null>(null);
    const [sortedAttachments, setSortedAttachments] = useState<any | null>(null);
    const [expanded, setExpanded] = useState<string | false>(false);
    const [attachmentLoading, setAttachmentLoading] = useState<boolean>(false);
    const [isDownload, setIsDownloading] = useState<boolean>(false);
    const [HcpDetails, setHcpDetails] = useState<any>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [openFacilityAdd, setOpenFacilityAdd] = useState<boolean>(false);
    const [NcnsList, setNcnsList] = useState<any | null>(null);
    const [isNcnsListLoading, setIsNcnsListLoading] = useState<boolean>(false);
    const [dnrList, setDnrList] = useState<any | null>(null);
    const [isDnrListLoading, setIsDnrListLoading] = useState<boolean>(false);
    const [facilityList, setFacilityList] = useState<any>(null);
    const [selectedFacility, setSelectedFacility] = useState<any>(null);
    const [hcpRating, setHcpRating] = useState<any>(null);
    const {user} = useSelector((state: StateParams) => state.auth);


    const hcpRatingCalculation = useCallback(() => {
        if (dnrList?.length > 0) {
            //  {hcpBasicDetails?.rating ? `${Math.round(hcpBasicDetails?.rating * 100) / 100}/5` : "N/A"}
            console.log(typeof (hcpBasicDetails?.rating));
            console.log(hcpBasicDetails?.rating, "jjkhk");
            const dnr = dnrList?.length / 3;
            const ncns = NcnsList?.length / 3;
            setHcpRating(Math.round(hcpBasicDetails?.rating * 100) / 100 - Math.min(Math.floor(dnr), Math.floor(ncns)));
            console.log();
        } else {
            setHcpRating(Math.round(hcpBasicDetails?.rating * 100) / 100);
        }
        console.log(isDnrListLoading);
    }, [dnrList, NcnsList, isDnrListLoading, hcpBasicDetails]);

    useEffect(() => {
        hcpRatingCalculation();
    }, [hcpRatingCalculation]);

    const facilityListInit = useCallback(() => {
        let facilityUrl = "facility/lite";
        let facilityPayload: any = {};
        CommonService._api.post(ENV.API_URL + facilityUrl, facilityPayload).then((resp) => {
            setFacilityList(resp?.data);
        }).catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const previewFile = useCallback((index: any) => {
        setOpen(true);
    }, []);

    const cancelPreviewFile = useCallback(() => {
        setOpen(false);
    }, []);

    const confirmPreviewFile = useCallback(() => {
        setOpen(false);
    }, []);

    const cancelFacilityAdd = useCallback(() => {
        setOpenFacilityAdd(false);
        setSelectedFacility(null);
    }, []);

    const openFacilityAddDialog = useCallback(() => {
        setOpenFacilityAdd(true);
    }, []);

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const getAttachmentsDetails = useCallback(
        (hcp_id) => {
            setAttachmentLoading(true);
            CommonService._api
                .get(ENV.API_URL + "hcp/" + hcp_id + "/attachments")
                .then((resp) => {
                    setAttachmentsDetails(resp?.data);
                    setIsAttachmentsLoading(false);
                    setAttachmentLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setIsAttachmentsLoading(false);
                    setAttachmentLoading(false);
                });
        },
        [setIsAttachmentsLoading, setAttachmentLoading]
    );

    const getNcnsList = useCallback(
        (hcp_id) => {
            setIsNcnsListLoading(true);
            CommonService._api
                .get(ENV.API_URL + "hcp/" + hcp_id + "/ncns")
                .then((resp) => {
                    setNcnsList(resp?.data);
                    setIsNcnsListLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setIsNcnsListLoading(false);
                });
        },
        [setIsNcnsListLoading]
    );


    const getDnrList = useCallback(
        (hcp_id) => {
            setIsDnrListLoading(true);
            CommonService._api
                .get(ENV.API_URL + "hcp/" + hcp_id + "/dnr")
                .then((resp) => {
                    setDnrList(resp?.data);
                    setIsDnrListLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setIsDnrListLoading(false);
                });
        },
        [setIsDnrListLoading]
    );


    const getHcpImageDetails = useCallback((hcpId: any) => {
        CommonService._api.get(ENV.API_URL + "hcp/" + hcpId).then((resp) => {
            setHcpDetails(resp.data);
            console.log(resp);
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    const init = useCallback(() => {
        // config
        CommonService._api
            .get(ENV.API_URL + "hcp/" + id)
            .then((resp) => {
                setBasicDetails(resp.data);
                console.log(resp.data?._id);
                console.log(id);
                getAttachmentsDetails(resp.data?._id);
                getNcnsList(resp.data?.user_id);
                getHcpImageDetails(resp.data?._id);
                getDnrList(resp.data?._id);
                if (user?.role !== "hr" && user?.role !== "operational_manager") {
                    facilityListInit();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [id, getAttachmentsDetails, getHcpImageDetails, getNcnsList, getDnrList, facilityListInit, user]);

    const confirmMarkingDnr = useCallback(() => {
        let payload: any = {};
        payload.facility_id = selectedFacility?._id;
        CommonService._api.post(ENV.API_URL + `hcp/${hcpBasicDetails?._id}/dnr`, payload).then((resp) => {
            CommonService.showToast("success", "success");
            getDnrList(hcpBasicDetails?._id);
            setOpenFacilityAdd(false);
            setSelectedFacility(null);
        }).catch((err) => {
            console.log(err);
            CommonService.showToast(err?.error || "Already Added", "error");
            setOpenFacilityAdd(false);
            setSelectedFacility(null);
        });
    }, [selectedFacility, hcpBasicDetails, getDnrList]);


    useEffect(() => {
        init();
    }, [init]);

    const handleDownload = useCallback(
        (file_key: any) => {
            setIsDownloading(true);
            console.log(file_key);
            let payload = {
                file_key: file_key,
            };
            CommonService._api
                .post(ENV.API_URL + "downloadAttachment", payload)
                .then((resp) => {
                    const link = document.createElement("a");
                    link?.setAttribute("href", resp?.data);
                    document.body.appendChild(link);
                    link.click();
                    setIsDownloading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setIsDownloading(false);
                });
        },
        []
    );

    useEffect(() => {
        const required_attachments = [
            {name: "Resume", index: -1},
            {name: "Physical Test", index: -1},
            {name: "TB Test", index: -1},
            {name: "Chest X-ray", index: -1},
            {name: "CPR/BLS Card", index: -1},
            {name: "Driver License", index: -1},
            {name: "SSN Card", index: -1},
            {name: "License", index: -1},
            {name: "Covid Certificate", index: -1},
            {name: "Covid Vaccine Card", index: -1},
            {name: "Covid Test Result", index: -1},
            {name: "Livescan", index: -1},
            {name: "Vaccine Exemption Letter", index: -1},
        ];
        let tempAttachemnts: any = [];
        required_attachments?.forEach((item: any) => {
            attachmentsDetails?.forEach((attachment: any, index: any) => {
                if (item.name === attachment?.attachment_type) {
                    attachmentsDetails.splice(index, 1);
                    tempAttachemnts.push(attachment);
                }
            });
        });

        attachmentsDetails?.forEach((attachment: any) => {
            tempAttachemnts.push(attachment);
        });
        setSortedAttachments([...tempAttachemnts]);
    }, [attachmentsDetails]);

    const deleteDnr = useCallback((dnrListId: any) => {
        ApiService.delete(ENV.API_URL + `hcp/${hcpBasicDetails?._id}/dnr/${dnrListId}`)
            .then((resp: any) => {
                if (resp) {
                    CommonService.showToast(resp?.msg || "Success", "success");
                    getDnrList(hcpBasicDetails?._id);
                } else {
                }
            })
            .catch((err) => {
                console.log(err);
                //  CommonService.handleErrors(setErrors, err?.errors);
                CommonService.showToast(err.msg || "Error", "error");
            });
    }, [hcpBasicDetails, getDnrList]);

    const StyledLoader = () => {
        return (
            <div className="pdd-20" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <LoaderComponent position="block"/>
            </div>
        );
    };


    return (
        <>
            <DialogComponent open={open} cancel={cancelPreviewFile} class="preview-content">
                <CustomPreviewFile cancel={cancelPreviewFile} confirm={confirmPreviewFile}
                                   previewData={HcpDetails?.profile_url}/>
            </DialogComponent>
            <DialogComponent cancel={cancelFacilityAdd} open={openFacilityAdd}>
                <div className={'pdd-50'}>
                    <div className={'header-title'}
                         style={{display: "flex", alignItems: "center", justifyContent: "center",}}>
                        Mark As DNR
                    </div>
                    <div className={'sub-header'}>
                        Select Facility for Marking DNR
                    </div>
                    <div>
                        <div className="form-field-item">
                            {facilityList && <>
                                <Autocomplete
                                    PaperComponent={({children}) => <Paper
                                        style={{color: "#1e1e1e"}}>{children}</Paper>}
                                    value={selectedFacility}
                                    options={facilityList}
                                    getOptionLabel={(option: any) => (option?.name)}
                                    placeholder={"Select Status"}
                                    id="input_select_status"
                                    style={{backgroundColor: "white", borderRadius: "3px"}}
                                    onChange={($event, value) => {
                                        if (value) {
                                            setSelectedFacility(value);
                                            console.log(value);
                                        } else {
                                            setSelectedFacility(null);
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} size={"small"}
                                                                        id="select_status" variant="outlined"
                                                                        value={[]} placeholder={"Facility"}
                                                                        fullWidth/>}
                                />
                            </>}
                        </div>
                    </div>
                    <DialogActions className={'pdd-20'}>
                        <Button onClick={cancelFacilityAdd} variant={"contained"} color={'primary'}>
                            {'No, Cancel'}
                        </Button>
                        <Button
                            // className={isHcpNoShowApiInProgress ? "has-loading-spinner" : ""}
                            onClick={confirmMarkingDnr}
                            variant={"contained"} color={'default'}>
                            {'Yes, Confirm'}
                        </Button>
                    </DialogActions>
                </div>
            </DialogComponent>
            <div className="hcp_details">
                <div className="pdd-0 custom-border">
                    <div className={'d-flex pdd-20 hcp-photo-details-wrapper'} style={{display: "flex"}}>
                        <div>
                            <div style={{display: "flex"}}>
                                <Avatar style={{height: "80px", width: "80px"}}>
                                    <img style={{width: "80px", height: "80px"}} src={HcpDetails?.image_url}
                                         alt=""/>
                                </Avatar>
                                <div className="hcp-name-type">
                                    <h2 style={{color: "black"}}>
                                        {hcpBasicDetails?.first_name}&nbsp;{hcpBasicDetails?.last_name}
                                    </h2>
                                    <p>{hcpBasicDetails?.hcp_type}</p>
                                </div>
                            </div>
                        </div>
                        {/*<div style={{marginLeft: "auto"}} onClick={()=>{*/}
                        {/*    console.log(typeof (hcpBasicDetails?.rating));*/}
                        {/*    console.log(hcpBasicDetails?.rating,"jjkhk");*/}
                        {/*}}>*/}
                        {/*    <h5 style={{color: "gray"}}>*/}
                        {/*        Average Rating*/}
                        {/*    </h5>*/}
                        {/*    <div style={{color:"black"}}>*/}
                        {/*        {hcpRating}/5*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="rating-container">
                            <p className="rating-title">Average Rating</p>
                            <p className="rating-content"> {hcpRating}/5</p>
                        </div>
                    </div>
                    <div className="d-flex hcp-details pdd-bottom-20 custom-border" style={{gap: "20px"}}>
                        <div className="flex-1">
                            <h4>Years Of Experience</h4>
                            <p>
                                {hcpBasicDetails?.professional_details?.experience
                                    ? hcpBasicDetails?.professional_details?.experience + " Years"
                                    : hcpBasicDetails?.professional_details?.experience === 0
                                        ? 0 + " Years"
                                        : "N/A"}
                            </p>
                        </div>
                        <div className="flex-1">
                            <h4>Contact Number</h4>
                            <p>{hcpBasicDetails?.contact_number}</p>
                        </div>
                        <div className="flex-1">
                            <h4>Address</h4>
                            <p>
                                {hcpBasicDetails?.address?.region},&nbsp;{hcpBasicDetails?.address?.city},&nbsp;
                                {hcpBasicDetails?.address?.state},&nbsp;{hcpBasicDetails?.address?.country},&nbsp;
                                {hcpBasicDetails?.address?.zip_code}&nbsp;&nbsp;
                            </p>
                        </div>
                        <div className="flex-1">
                            <h4>Email</h4>
                            <p>{hcpBasicDetails?.email}</p>
                        </div>
                        <div className="flex-1">
                            <h4>Staff Rate (hr)</h4>
                            <p>{hcpBasicDetails?.contract_details?.rate_per_hour ?? 0} $</p>
                        </div>
                    </div>
                </div>
                <>
                    <div className={"pdd-20 custom-border mrg-top-10 mrg-bottom-10"}>
                        <div style={{display: "flex", marginBottom: "20px"}}>
                            <div className={'header-title'}>
                                DNR (Count:{dnrList?.length})
                            </div>
                            <div style={{marginLeft: "auto"}}>
                                {user?.role !== "hr" && user?.role !== "operational_manager" &&
                                    <Button onClick={openFacilityAddDialog} size="large"
                                            disabled={!facilityList}
                                            variant={"contained"}
                                            color={"primary"}>
                                        + Add More
                                    </Button>
                                }
                            </div>
                        </div>
                        {dnrList?.length !== 0 &&
                            <TableContainer component={Paper} className={`doc-table-responsive`}>
                                <Table stickyHeader
                                       className="mat-table table">
                                    <TableHead className={"mat-thead"}>
                                        <TableRow className={"mat-tr"}>
                                            {webMatColumns.map((column: any, columnIndex: any) => (
                                                <TableCell
                                                    className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}
                                                    key={"header-col-" + columnIndex}>
                                                    {column}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={classes.tableBody}>
                                        {dnrList?.map((item: any, index: any) => {
                                            return (<>
                                                    <TableRow className="mat-tr" role="checkbox" tabIndex={-1}
                                                              key={`row-${index}`}>
                                                        <TableCell
                                                            className="mat-td mat-td-hcp-name">
                                                            {item?.facility_details?.name}
                                                        </TableCell>
                                                        <TableCell>
                                                            {new Date(`${item?.created_at}`).toLocaleDateString()}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.user_details?.first_name}&nbsp;{item?.user_details?.last_name}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.user_details?.role}
                                                        </TableCell>
                                                        <TableCell className="mat-td mat-td-sticky mat-td-actions">
                                                            <div>
                                                                {/*<IconButton onClick={() => openRejectApplication(row["_id"])} disabled={status === "cancelled"}>*/}
                                                                {/*    <CancelIcon className="delete-icon" />*/}
                                                                {/*</IconButton>*/}
                                                                <IconButton
                                                                    disabled={user?.role === "hr" || user?.role === "operational_manager"}
                                                                    onClick={() => {
                                                                        deleteDnr(item?._id);
                                                                    }}>
                                                                    <CancelIcon className="delete-icon"/>
                                                                </IconButton>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                                ;
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                    </div>
                </>
                {!isNcnsListLoading && NcnsList?.length > 0 && <>
                    <div className={"pdd-20 custom-border mrg-top-10 mrg-bottom-10"}>
                        <div className={'header-title'}>
                            NCNS (Count:{NcnsList?.length})
                        </div>
                        <TableContainer component={Paper} className={"doc-table-responsive"}>
                            <Table size={"small"} stickyHeader
                                   className="mat-table table">
                                <TableHead className={"mat-thead"}>
                                    <TableRow className={"mat-tr"}>
                                        {webMatColumnsForNcns.map((column: any, columnIndex: any) => (
                                            <TableCell
                                                className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}
                                                key={"header-col-" + columnIndex}>
                                                {column}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody className={"mat-tbody"}>
                                    {NcnsList?.map((item: any, index: any) => {
                                        return (<>
                                                <TableRow className="mat-tr" role="checkbox" tabIndex={-1}
                                                          key={`row-${index}`}>
                                                    <TableCell
                                                        className="mat-td mat-td-hcp-name">
                                                        {item?.facility_details?.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {new Date(`${item?.created_at}`).toLocaleDateString()}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item?.user_details?.first_name}&nbsp;{item?.user_details?.last_name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item?.user_details?.role}
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        )
                                            ;
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </>}

                <div className="custom-border pdd-20 pdd-left-40 pdd-right-40">
                    <div className="d-flex title-rating-container edit-facility-wrapper">
                        <h3>Basic Details</h3>
                        {/*<div className="rating-container">*/}
                        {/*    <p className="rating-title">Average Rating</p>*/}
                        {/*    <p className="rating-content">{hcpBasicDetails?.rating ? `${Math.round(hcpBasicDetails?.rating * 100) / 100}/5` : "N/A"}</p>*/}
                        {/*</div>*/}
                    </div>
                    <div className="d-flex">
                        <div className="flex-1">
                            <h4>First Name</h4>
                            <p>{hcpBasicDetails?.first_name} </p>
                        </div>
                        <div className="flex-1">
                            <h4>Last Name</h4>
                            <p>{hcpBasicDetails?.last_name} </p>
                        </div>
                        <div className="flex-1">
                            <h4>Contact Number</h4>
                            <p>{hcpBasicDetails?.contact_number}</p>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="flex-1">
                            <h4>Email</h4>
                            <p>{hcpBasicDetails?.email}</p>
                        </div>
                        <div className="flex-1">
                            <h4>Gender</h4>
                            <p>{hcpBasicDetails?.gender}</p>
                        </div>
                        <div className="flex-1">
                            <h4>Role</h4>
                            <p>{hcpBasicDetails?.hcp_type}</p>
                        </div>
                    </div>

                    <div className="d-flex">
                        <div className="flex-1">
                            <h4>Region Name</h4>
                            <p>{hcpBasicDetails?.address?.region}</p>
                        </div>
                        <div className="flex-1">
                            <h4>Street</h4>
                            <p>{hcpBasicDetails?.address?.street}</p>
                        </div>
                        <div className="flex-1">
                            <h4>City</h4>
                            <p>{hcpBasicDetails?.address?.city}</p>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="flex-1">
                            <h4>State</h4>
                            <p>{hcpBasicDetails?.address?.state}</p>
                        </div>
                        <div className="flex-1">
                            <h4>Country</h4>
                            <p>{hcpBasicDetails?.address?.country}</p>
                        </div>
                        <div className="flex-1">
                            <h4>Zip Code</h4>
                            <p>{hcpBasicDetails?.address?.zip_code}</p>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="flex-1">
                            <h4>Latitude</h4>
                            <p>{hcpBasicDetails?.location?.coordinates?.[1]}</p>
                        </div>
                        <div className="flex-1">
                            <h4>Longitude</h4>
                            <p>{hcpBasicDetails?.location?.coordinates?.[0]}</p>
                        </div>
                        <div className="flex-1">
                            <h4>DOB</h4>
                            <p>{hcpBasicDetails?.dob?.split("-").reverse().join("-")}</p>
                        </div>
                    </div>
                    <div className="mrg-top-10">
                        <h4>About the Staff</h4>
                        <p className="summary">{hcpBasicDetails?.about ? hcpBasicDetails?.about : "N/A"}</p>
                    </div>
                </div>
                <div className="custom-border mrg-top-10 pdd-20 pdd-left-40 pdd-right-40">
                    <h3>Professional Details</h3>
                    <div className="d-flex">
                        <div className="flex-1">
                            <h4>Years of Experience</h4>
                            <p>{hcpBasicDetails?.professional_details?.hasOwnProperty('experience') ? hcpBasicDetails?.professional_details?.experience : "N/A"}</p>
                        </div>
                        <div className="flex-1">
                            <h4>Speciality</h4>
                            <p>{hcpBasicDetails?.professional_details?.speciality ? hcpBasicDetails?.professional_details?.speciality : "N/A"}</p>
                        </div>
                    </div>
                    <div>
                        <h4>Professional Summary</h4>
                        <p className="summary">{hcpBasicDetails?.professional_details?.summary ? hcpBasicDetails?.professional_details?.summary : "N/A"}</p>
                    </div>
                </div>

                {!attachmentLoading && sortedAttachments ? (
                    // <div className="custom-border mrg-top-10 pdd-20 pdd-left-40 pdd-right-40">
                    //     <h3>Attachments/Documents</h3>
                    //     {sortedAttachments?.length === 0 && (
                    //         <p>
                    //             <NoDataToShowCardComponent/>
                    //         </p>
                    //     )}
                    //     <div className="attachments_wrapper">
                    //         {sortedAttachments?.map((item: any) => {
                    //             return (
                    //                 <div className="attachments">
                    //                     <CustomFile data={item} handleDownload={handleDownload}
                    //                                 isDownload={isDownload}/>
                    //                 </div>
                    //             );
                    //         })}
                    //     </div>
                    // </div>
                    <div className="custom-border mrg-top-20 pdd-20">
                        <h3>Attachments/Documents</h3>
                        {sortedAttachments?.length === 0 && (
                            <p>
                                <NoDataToShowCardComponent/>
                            </p>
                        )}
                        {sortedAttachments?.length !== 0 && (
                            <TableContainer component={Paper} className={"doc-table-responsive"}>
                                <Table size={"small"} stickyHeader
                                       className="mat-table table shifts-requirment-pending-list-table">
                                    <TableHead className={"mat-thead"}>
                                        <TableRow className={"mat-tr"}>
                                            {webMatColumnsForDoc.map((column: any, columnIndex: any) => (
                                                <TableCell
                                                    className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}
                                                    key={"header-col-" + columnIndex}>
                                                    {column}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={"mat-tbody"}>
                                        {sortedAttachments?.map((item: any, index: any) => {
                                            return (<>
                                                    <TableViewCustomFile data={item} handleDownload={handleDownload}
                                                                         isDownload={isDownload}/>
                                                </>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </div>
                ) : (
                    <div className="custom-border mrg-top-10 pdd-20 pdd-left-40 pdd-right-40 ">
                        <StyledLoader/>
                    </div>
                )}

                <div>
                    <HcpContractComponent id={hcpBasicDetails?._id} hcpDetails={hcpBasicDetails}/>
                </div>

                <div>
                    <div className="hcp_contract_details mrg-top-10">
                        <div className="custom-border pdd-20 pdd-left-40 pdd-right-40">
                            {HcpDetails?.profile_url ? (
                                <div>
                                    <h3 className="contract-heading">Profile Image</h3>
                                    <img src={HcpDetails?.profile_url} alt=""
                                         style={{
                                             height: "100px",
                                             width: "100px",
                                             cursor: "pointer",
                                         }}
                                         onClick={previewFile}
                                    />
                                </div>
                            ) : (
                                <>
                                    <h3 className="contract-heading">Profile Image</h3>
                                    <NoDataToShowCardComponent/>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className="custom-border mrg-top-10 pdd-20 pdd-left-40 pdd-right-40">
                    <h3>Travel Preference</h3>
                    <div className="d-flex mrg-top-20">
                        {hcpBasicDetails?.nc_details?.travel_preferences && Array.isArray(hcpBasicDetails?.nc_details?.travel_preferences) && hcpBasicDetails?.nc_details?.travel_preferences?.length > 0 ? (
                            hcpBasicDetails?.nc_details?.travel_preferences?.map((item: any) => <p
                                className="flex-1">{item} Miles</p>)
                        ) : (
                            <p>N/A</p>
                        )}
                    </div>
                </div>

                <div className="custom-border mrg-top-10 pdd-20 pdd-left-40 pdd-right-40">
                    <h3>Notification Preference</h3>
                    <div className="d-flex mrg-top-20">
                        {hcpBasicDetails?.hasOwnProperty("notification_preferences") ? (
                            <>
                                {hcpBasicDetails?.notification_preferences?.sms &&
                                    <p className="flex-1">{hcpBasicDetails?.notification_preferences?.sms ? "SMS" : null}</p>}
                                {hcpBasicDetails?.notification_preferences?.email &&
                                    <p className="flex-1">{hcpBasicDetails?.notification_preferences?.email ? "Email" : null}</p>}
                                {hcpBasicDetails?.notification_preferences?.push_notification &&
                                    <p className="flex-1">{hcpBasicDetails?.notification_preferences?.push_notification ? "In-application" : null}</p>}
                            </>
                        ) : (
                            <p className="flex-1">N/A</p>
                        )}
                        {
                            hcpBasicDetails?.hasOwnProperty('notification_preference') && JSON.stringify(Object.values(hcpBasicDetails?.notification_preference))
                        }

                    </div>
                </div>

                <div className="custom-border mrg-top-10 pdd-20 pdd-left-40 pdd-right-40 pdd-bottom-35">
                    <h3>NC Section</h3>

                    <div className="d-flex">
                        <div className="flex-1">
                            <h4>DNR</h4>
                            <p>{hcpBasicDetails?.nc_details?.dnr ? hcpBasicDetails?.nc_details?.dnr : "N/A"}</p>
                        </div>

                        <div className="flex-1">
                            <h4>Contact Type</h4>
                            <p>{hcpBasicDetails?.nc_details?.contact_type ? CommonService.nameCoverterToNormal(hcpBasicDetails?.nc_details?.contact_type) : "N/A"}</p>
                        </div>

                        <div className="flex-1">
                            <h4>Preferred Location to Work</h4>
                            <p>{hcpBasicDetails?.nc_details?.location_preference ? hcpBasicDetails?.nc_details?.location_preference : "N/A"}</p>
                        </div>
                    </div>

                    <div className="d-flex">
                        <div className="flex-1">
                            <h4>Preference Shift Type</h4>
                            <p>{hcpBasicDetails?.nc_details?.shift_type_preference ? hcpBasicDetails?.nc_details?.shift_type_preference : "N/A"}</p>
                        </div>
                        <div className="flex-1">
                            <h4>Zone Assignment</h4>
                            <p>{hcpBasicDetails?.nc_details?.zone_assignment !== "" ? hcpBasicDetails?.nc_details?.zone_assignment : "N/A"}</p>
                        </div>
                        <div className="flex-1">
                            <h4>Last Call Date</h4>
                            <p>{hcpBasicDetails?.nc_details?.last_call_date ? moment(hcpBasicDetails?.nc_details?.last_call_date).format("MMMM Do YYYY") : "N/A"}</p>
                        </div>
                    </div>

                    <div className="d-flex">
                        <div className="flex-1">
                            <h4>Vaccine</h4>
                            <p>{!hcpBasicDetails?.nc_details?.vaccine ? "N/A" : hcpBasicDetails?.nc_details?.vaccine === "half" ? "1st Dose" : hcpBasicDetails?.nc_details?.vaccine}</p>
                        </div>

                        <div className="flex-1">
                            <h4>First Shot Date</h4>
                            <p>{hcpBasicDetails?.nc_details?.vaccination_dates?.first_shot ? hcpBasicDetails?.nc_details?.vaccination_dates?.first_shot : "N/A"}</p>
                        </div>

                        <div className="flex-1">
                            <h4>Latest Shot Date</h4>
                            <p>{hcpBasicDetails?.nc_details?.vaccination_dates?.latest_shot ? hcpBasicDetails?.nc_details?.vaccination_dates?.latest_shot : "N/A"}</p>
                        </div>
                    </div>

                    <div className="d-flex">
                        <div className="flex-1">
                            <h4>Do you have a Full-time Job ?</h4>
                            <p>{hcpBasicDetails?.nc_details?.is_fulltime_job !== "" ? (hcpBasicDetails?.nc_details?.is_fulltime_job === "true" ? "Yes" : "No") : "N/A"}</p>
                        </div>
                        <div className="flex-1">
                            <h4>Covid (or) Non Covid Facility ?</h4>
                            <p>{hcpBasicDetails?.nc_details?.covid_facility_preference ? hcpBasicDetails?.nc_details?.covid_facility_preference : "N/A"}</p>
                        </div>
                        <div className="flex-1">
                            <h4>What Is More Important for You ?</h4>
                            <p>{hcpBasicDetails?.nc_details?.more_important_preference !== "" ? hcpBasicDetails?.nc_details?.more_important_preference : "N/A"}</p>
                        </div>
                    </div>

                    <div className="d-flex">
                        <div className="flex-1">
                            <h4>Is this a Supplement to your Income ?</h4>
                            <p>{hcpBasicDetails?.nc_details?.is_supplement_to_income !== "" ? (hcpBasicDetails?.nc_details?.is_supplement_to_income === "true" ? "Yes" : "No") : "N/A"}</p>
                        </div>
                        <div className="flex-1">
                            <h4>Are you Studying ?</h4>
                            <p>{hcpBasicDetails?.nc_details?.is_studying !== "" ? (hcpBasicDetails?.nc_details?.is_studying === "true" ? "Yes" : "No") : "N/A"}</p>
                        </div>
                        {/*<div className="flex-1">*/}
                        {/*    <h4>Gusto</h4>*/}
                        {/*    <p>{hcpBasicDetails?.nc_details?.gusto_type !== "" ? CommonService.nameCoverterToNormal(hcpBasicDetails?.nc_details?.gusto_type) : "N/A"}</p>*/}
                        {/*</div>*/}
                    </div>

                    <div className="d-flex">
                        <div className="flex-1">
                            <h4>Is Gusto Invited ?</h4>
                            <p>{hcpBasicDetails?.nc_details?.is_gusto_invited !== "" ? (hcpBasicDetails?.nc_details?.is_gusto_invited === "true" ? "Yes" : "No") : "N/A"}</p>
                        </div>
                        <div className="flex-1">
                            <h4>Is Gusto Onboarded ?</h4>
                            <p>{hcpBasicDetails?.nc_details?.is_gusto_onboarded !== "" ? (hcpBasicDetails?.nc_details?.is_gusto_onboarded === "true" ? "Yes" : "No") : "N/A"}</p>
                        </div>
                        <div className="flex-1">
                            <h4>Require Sponsorship for Employment in United States?</h4>
                            <p>{hcpBasicDetails?.nc_details?.is_require_employment_sponsorship !== "" ? (hcpBasicDetails?.nc_details?.is_require_employment_sponsorship === "true" ? "Yes" : "No") : "N/A"}</p>
                        </div>
                    </div>

                    <div className="d-flex">
                        <div className="flex-1">
                            <h4>Legally Authorized to work in United States ?</h4>
                            <p>{hcpBasicDetails?.nc_details?.is_authorized_to_work !== "" ? (hcpBasicDetails?.nc_details?.is_authorized_to_work === "true" ? "Yes" : "No") : "N/A"}</p>
                        </div>
                    </div>

                    <div className="d-flex">
                        <div className="flex-1">
                            <h4>Family Considerations</h4>
                            <p className="summary">{hcpBasicDetails?.nc_details?.family_consideration ? hcpBasicDetails?.nc_details?.family_consideration : "N/A"}</p>
                        </div>
                    </div>

                    <div className="d-flex">
                        <div className="flex-1">
                            <h4>Other Information Gathered</h4>
                            <p>{hcpBasicDetails?.nc_details?.other_information ? hcpBasicDetails?.nc_details?.other_information : "N/A"}</p>
                        </div>
                    </div>
                </div>

                <div className="custom-border mrg-top-10 pdd-20 pdd-left-40 pdd-right-40">
                    <Accordion expanded={expanded === "Education"} onChange={handleChange("Education")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1bh-content"
                                          id="panel1bh-header">
                            <Typography className={classes.heading}>Education</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.root}>
                                <HcpEducationComponent educationDetails={hcpBasicDetails?.education}/>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="custom-border mrg-top-10 pdd-20 pdd-left-40 pdd-right-40">
                    <Accordion expanded={expanded === "Experience"} onChange={handleChange("Experience")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1bh-content"
                                          id="panel1bh-header">
                            <Typography className={classes.heading}>Work Experience</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.root}>
                                <HcpExperienceComponent experience={hcpBasicDetails?.experience}/>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="custom-border mrg-top-10 pdd-20 pdd-left-40 pdd-right-40">
                    <Accordion expanded={expanded === "Volunteer_Experience"}
                               onChange={handleChange("Volunteer_Experience")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1bh-content"
                                          id="panel1bh-header">
                            <Typography className={classes.heading}>Volunteer Experience</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.root}>
                                <HcpVolunteerExperienceComponent volunteerExperience={hcpBasicDetails?.experience}/>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="custom-border mrg-top-10 pdd-20 pdd-left-40 pdd-right-40">
                    <Accordion expanded={expanded === "Reference"} onChange={handleChange("Reference")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1bh-content"
                                          id="panel1bh-header">
                            <Typography className={classes.heading}>Reference</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.root}>
                                <HcpReferenceComponent referenceDetails={hcpBasicDetails?.reference}/>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </>
    );
};

export default HcpDetailsComponent;

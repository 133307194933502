import React, {PropsWithChildren} from 'react';

interface DynamicDivProps {
    show: boolean;
    backgroundColor: string;
    textColor: string;
}

const StatusViewComponent = (props: PropsWithChildren<DynamicDivProps>) => {
    if (!props?.show) {
        return null; // If 'show' is false, render nothing
    }
    const divStyle = {
        backgroundColor: props?.backgroundColor,
        color: props?.textColor,
        padding: "4px 4px",
        borderRadius: "100px",
        width: "90px",
        fontSize: "12px",
        display: "flex",
        justifyContent: "center",
        // height: "24px",
    };

    return (
        <div style={divStyle}>
            {props?.children}
        </div>
    );
};

export default StatusViewComponent;
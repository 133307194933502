import { TextField } from "@material-ui/core";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";


export const CssTextField = withStyles({
	root: {
		"& .MuiOutlinedInput-root": {
			"&:hover fieldset": {
				borderColor: "#10c4d3",
			},
		},
	},
})(TextField);




export const useStyles = makeStyles((theme: Theme) =>
createStyles({
  formControl: {
    margin: theme.spacing(3),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "30px 50px",
    margin: "auto",
  },
  title: {
    fontWeight: 450,
  },
  assignNcActions: {
    margin: "auto",
    marginTop: "100px",
    textAlign: "center",
    justifyContent: "center",
  },
})
);


export const showDropDownBelowField = {
  MenuProps: {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  },
};
import Communications from './communications-service';
import queryString from "querystring";
import {FormikErrors} from "formik";
import {toast, ToastOptions, TypeOptions} from "react-toastify";
import {TsConfirmationConfig} from "../constants/CommonTypes";
import axios, {CancelTokenSource} from "axios";
import ApiService from './api-service';
import moment, {Moment} from "moment";
import {ENV} from "../constants";

export const ADMIN = 'super_admin';
export const HUMANRESOURCE = 'hr';
export const ACCOUNTMANAGER = 'account_manager';
export const NURSECHAMPION = 'nurse_champion';
export const FINANCE = 'finance';
export const OPERATIONALMANAGER = 'operational_manager';


const parseQueryString = (q: string): any => {
    return queryString.parse(q.replace('?', ''));
};
const getBytesInMB = (bytes: number) => {
    return bytes / (1024 * 1024);
};
const formatSizeUnits = (bytes: number, decimals = 2) => {
    if (bytes === 0) {
        return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
const getRandomID = (length: number) => {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
};
const showToast = (msg: string, type: TypeOptions = "info", options: ToastOptions = {}) => {
    switch (type) {
        case 'info':
            toast.info(msg, options);
            break;
        case 'success':
            toast.success(msg, options);
            break;
        case 'error':
            toast.error(msg, options);
            break;
        case 'warning':
            toast.warning(msg, options);
            break;
        default:
            toast.dark(msg, options);
            break;
    }
};
const handleErrors = (setErrors: (errors: FormikErrors<any>) => void, err: any) => {
    if (err.errors) {
        const errors: any = {};
        for (let field in err.errors) {
            if (err.errors.hasOwnProperty(field)) {
                errors[field] = err.errors[field][0];
            }
        }
        setErrors(errors);
    } else if (err.error) {
        showToast(err.error, "error");
    }
};
const onConfirm = (config: TsConfirmationConfig = {}) => {
    const defaultConfig: TsConfirmationConfig = {
        confirmationText: 'Are you sure ?',
        yes: {text: 'Yes, Confirm', color: 'default'},
        no: {text: 'No, Cancel', color: 'primary'}
    };
    config = {...defaultConfig, ...config};
    return new Promise((resolve, reject) => {
        Communications.ConfirmStateSubject.next({config, promise: {resolve, reject}});
    });
};
const openDialog = (component: any) => {
    return new Promise((resolve, reject) => {
        Communications.DialogStateSubject.next({component, promise: {resolve, reject}});
    });
};
const getCancelToken = (): CancelTokenSource => {
    return axios.CancelToken.source();
};

const getPayloadFilterDates = (mode: 'day' | 'week' | 'month'): { start_date: string, end_date: string } => {
    const payload = {start_date: '', end_date: moment().format('YYYY-MM-DD')};
    switch (mode) {
        case "day":
            payload.start_date = moment().format('YYYY-MM-DD');
            break;
        case "week":
            payload.start_date = moment().subtract(1, 'week').format('YYYY-MM-DD');
            break;
        case "month":
            payload.start_date = moment().subtract(1, "month").format('YYYY-MM-DD');
            break;
    }
    return payload;
};

const getExtraPayloadFilterDates = (mode: 'day' | 'week' | 'month'): { start_date: string, end_date: string } => {
    const payload = {start_date: '', end_date: ''};
    switch (mode) {
        case "day":
            payload.start_date = moment().subtract(1, 'day').format('YYYY-MM-DD');
            payload.end_date = moment().subtract(1, 'day').format('YYYY-MM-DD');
            break;
        case "week":
            payload.start_date = moment().subtract(2, 'week').format('YYYY-MM-DD');
            payload.end_date = moment().subtract(1, 'week').format('YYYY-MM-DD');
            break;
        case "month":
            payload.start_date = moment().subtract(2, "month").format('YYYY-MM-DD');
            payload.end_date = moment().subtract(1, "month").format('YYYY-MM-DD');
            break;
    }
    return payload;
};

const duration = (start: Moment, end: Moment) => {
    return moment.duration(end.diff(start)).humanize();
};

const durationFromHHMM = (start: string, end: string) => {
    let duration = moment(end, 'hh:mm').diff(moment(start, 'hh:mm'), "minutes");

    if (duration > 0) {
        return convertMinsToHrsMins(duration);
    } else if (duration === 0) {
        return '0';
    } else {
        return '-';
    }
};

const getFormDataFromJSON = (json: any): FormData => {
    const payload = new FormData();
    for (const key in json) {
        if (json.hasOwnProperty(key)) {
            payload.append(key, json[key]);
        }
    }
    return payload;
};

const durationBetweenTimeStamps = (start: any, end: any) => {
    let duration = moment(end).diff(moment(start), "minutes");

    // console.log(duration, 'duration');
    // console.log(duration, 'duration');
    // console.log(start, 'start');
    // console.log(end, 'end');

    return convertMinsToHrsMins(duration);

};

const convertMinsToHrsMins = (minutes: number) => {
    let h: string | number = Math.floor(minutes / 60);
    let m: string | number = minutes % 60;
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    return h + ':' + m;
};

const convertHoursToMinutes = (timeInHour: string | any) => {
    const timeParts = timeInHour?.split(":");
    return Number(timeParts[0]) * 60 + Number(timeParts[1]);
};

const convertRelativeTimeToLocalTime = (timezone: number, startTimeInMins: number, endTimeInMins: number) => {
    let facilityTimezoneOffset = timezone;
    let startTime = CommonService.convertMinsToHrsMins(startTimeInMins);
    let endTime = CommonService.convertMinsToHrsMins(endTimeInMins);

    let startTimeAndDate = moment(moment().format('YYYY-MM-DD') + ' ' + startTime);
    let endTimeAndDate = moment(moment().format('YYYY-MM-DD') + ' ' + endTime);

    let start, end;
    if (facilityTimezoneOffset > 1) {
        start = moment(startTimeAndDate).subtract(facilityTimezoneOffset, 'minutes').format('LT');
        end = moment(endTimeAndDate).subtract(facilityTimezoneOffset, 'minutes').format('LT');
    } else if (facilityTimezoneOffset < 1) {
        start = moment(startTimeAndDate).add(facilityTimezoneOffset, 'minutes').format('LT');
        end = moment(endTimeAndDate).add(facilityTimezoneOffset, 'minutes').format('LT');
    }

    return {start, end};
};

const getUtcTimeInAMPM = (raw_utc_start_time: any, raw_utc_end_time: any) => {
    function z(n: any) {
        return ('0' + n).slice(-2);
    }

    let raw_start_utc = new Date(raw_utc_start_time);
    let raw_end_utc = new Date(raw_utc_end_time);

    let utcStartTime = z(raw_start_utc.getUTCHours()) + ':' + z(raw_start_utc.getUTCMinutes());
    let utcEndTime = z(raw_end_utc.getUTCHours()) + ':' + z(raw_end_utc.getUTCMinutes());

    let start_time = moment((utcStartTime), 'hh:mm').format('LT');
    let end_time = moment((utcEndTime), 'hh:mm').format('LT');

    return {start_time, end_time};
};

const startTime = (raw_utc_start_time: any) => {
    function z(n: any) {
        return ('0' + n).slice(-2);
    }

    let raw_start_utc = new Date(raw_utc_start_time);

    let utcStartTime = z(raw_start_utc.getUTCHours()) + ':' + z(raw_start_utc.getUTCMinutes());

    let start_time = moment((utcStartTime), 'hh:mm').format('LT');

    return start_time;
};

const endTime = (raw_utc_end_time: any) => {
    function z(n: any) {
        return ('0' + n).slice(-2);
    }

    let raw_end_utc = new Date(raw_utc_end_time);

    let utcEndTime = z(raw_end_utc.getUTCHours()) + ':' + z(raw_end_utc.getUTCMinutes());

    let end_time = moment((utcEndTime), 'hh:mm').format('LT');

    return end_time;
};


const getUtcTimeinHours = (raw_utc_start_time: any, raw_utc_end_time: any) => {

    function z(n: any) {
        return ('0' + n).slice(-2);
    }

    let raw_start_utc = new Date(raw_utc_start_time);
    let raw_end_utc = new Date(raw_utc_end_time);

    let start_time: any = z(raw_start_utc.getUTCHours()) + ':' + z(raw_start_utc.getUTCMinutes());
    let end_time: any = z(raw_end_utc.getUTCHours()) + ':' + z(raw_end_utc.getUTCMinutes());

    if (start_time === 'aN:aN') {
        start_time = null;
    }

    if (end_time === 'aN:aN') {
        end_time = null;
    }

    return {start_time, end_time};
};


// const getUtcDate = (raw_utc_date: any) => {
//     if (raw_utc_date) {
//         let raw_date_utc = moment(raw_utc_date);
//         let utcDate = raw_date_utc.toISOString()?.split("T")[0];
//         let shift_date = moment(utcDate).format('MM-DD-YYYY');
//         return shift_date;
//     } else {
//         return '';
//     }
//
// };

const getUtcDate = (raw_utc_date: any) => {
    if (raw_utc_date) {
        // Parse the input date as UTC
        let raw_date_utc = moment.utc(raw_utc_date);

        // Format the UTC date directly without shifting to local timezone
        let shift_date = raw_date_utc.format('MM-DD-YYYY');

        return shift_date;
    } else {
        return '';
    }
};

const getUtcDateForForm = (raw_utc_date: any) => {
    console.log(raw_utc_date);
    if (raw_utc_date) {
        // Parse the raw date as UTC and format it directly to 'YYYY-MM-DD'
        let shift_date = moment.utc(raw_utc_date);
        return shift_date;
    } else {
        return '';
    }
};

const getYearsDiff = (start_date: any, end_date: any) => {

    if (end_date) {
        return Math.round(moment(end_date).diff(start_date, 'years', true) * 10) / 10;
    } else {
        let end_date = moment().format('YYYY-MM');
        return Math.round(moment(end_date).diff(start_date, 'years', true) * 10) / 10;
    }
};


const sortDatesByLatest = (arr: any[], fieldName: string) => {
    const newarr = arr.sort((a, b) => {
        return moment(b[fieldName]).diff(a[fieldName]);
    });

    return newarr;
};

const str2bool = (value: string) => {
    if (value && typeof value === "string") {
        if (value.toLowerCase() === "true") return true;
        if (value.toLowerCase() === "false") return false;
    }
    return value;
};


const getCurrentMonthFirstAndLastDay = () => {
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');

    return [startOfMonth, endOfMonth];
};

const getFirstDayAndLastDayUsingDate = () => {
    const date = new Date(), y = date.getFullYear(), m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    return [firstDay, lastDay, date];

};

const nameCoverterToNormal = (name: any) => {
    const OriginalTextMode = name;
    return OriginalTextMode?.split("_")?.map((word: any) => word?.charAt(0)?.toUpperCase() + word?.slice(1))?.join(" ");
};

const handleDownloadAnyAttachment = (file_key: any) => {
    const payload = {
        file_key: file_key
    };
    ApiService.post(ENV.API_URL + "downloadAttachment", payload)
        .then((res) => {
            if (res?.data) {
                const link = document.createElement("a");
                link?.setAttribute("href", res?.data);
                document.body.appendChild(link);
                link.click();
            } else {
                CommonService.showToast(res?.msg || "No Data to Download", "info");
            }
        })
        .catch((err) => {
            CommonService.showToast(err?.msg || "Error", "error");
        });
};

const convertTimestampInUSFormat = (timestamp: any) => {
    const dateString = new Date(timestamp);
    const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: 'numeric'
    };
    return dateString?.toLocaleDateString('en-US', options);
};


const convertToDateFromTimestamp = (timestamp: any) => {
    const dates = new Date(timestamp);
    const month = (dates.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const day = dates.getDate().toString().padStart(2, '0');
    const year = dates.getFullYear();
    // Format the date into "MM-DD-YYYY" format
    return `${month}-${day}-${year}`;
};

const calculateAge = (dob: string): number => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    // Adjust age if the birth month/day hasn't occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
    }

    return age;
};

const capitalizeFirstLetter = (string: string | undefined) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
};


const calculateSum = (perDiems: any) => {
    return perDiems.reduce((sum: any, item: any) => sum + parseFloat(item?.rate), 0);
};

const generateDateRange = (startDate: string, endDate: string): string[] => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const dateArray: string[] = [];

    let currentDate = start;
    while (currentDate <= end) {
        // Add the current date to the array
        dateArray.push(currentDate.toISOString().split('T')[0]);
        // Move to the next date
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateArray;
};

const formatDate = (dateString: string): string => {
    const [year, month, day] = dateString.split("-");
    return `${month}-${day}-${year}`;
};

const formatDateTimeStamp = (dateString: string): string => {
    const date = new Date(dateString);

    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are 0-based, so we add 1
    const day = date.getUTCDate().toString().padStart(2, '0');
    const year = date.getUTCFullYear().toString();

    return `${month}-${day}-${year}`;
};

function formatDateTime(isoString: string): string {
    const date = new Date(isoString);

    // Extracting components of the date
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const year = date.getFullYear();

    // Extracting components of the time
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12; // Convert 24-hour format to 12-hour format

    return `${month}-${day}-${year} | ${hours}:${minutes}${ampm}`;
}

const CommonService = {
    parseQueryString,
    handleErrors,
    onConfirm,
    openDialog,
    showToast,
    formatSizeUnits,
    getRandomID,
    getBytesInMB,
    getCancelToken,
    getPayloadFilterDates,
    getExtraPayloadFilterDates,
    duration,
    durationFromHHMM,
    convertMinsToHrsMins,
    convertHoursToMinutes,
    getUtcTimeInAMPM,
    getUtcTimeinHours,
    getUtcDate,
    getYearsDiff,
    getCurrentMonthFirstAndLastDay,
    sortDatesByLatest,
    str2bool,
    calculateAge,
    convertRelativeTimeToLocalTime,
    durationBetweenTimeStamps,
    getFormDataFromJSON,
    capitalizeFirstLetter,
    _api: ApiService,
    _communications: Communications,
    getFirstDayAndLastDayUsingDate,
    nameCoverterToNormal,
    handleDownloadAnyAttachment,
    startTime,
    endTime,
    convertTimestampInUSFormat,
    convertToDateFromTimestamp,
    calculateSum,
    generateDateRange,
    formatDate,
    formatDateTimeStamp,
    formatDateTime,
    getUtcDateForForm

};
export default CommonService;

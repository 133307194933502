import React from "react";
import AddHcpComponent from "./addHcpComponent/AddHcpComponent";
import './ChangeShiftHCPDialog.scss'

interface ChangeShiftHCPDialogProps {
  confirm: () => void;
  cancel: () => void;
  selectedHcpType: string;
  shiftId:string

}

const ChangeShiftHCPDialog = (props: ChangeShiftHCPDialogProps) => {
  const { cancel, confirm, selectedHcpType,shiftId } = props;

  return (
    <div className="dialog-container">
        <AddHcpComponent
          cancel={cancel}
          confirm={confirm}
          hcp_type={selectedHcpType}
          shiftId={shiftId}
        />
    </div>
  );
};
export default ChangeShiftHCPDialog;

import {
    Button,
    createStyles,
    DialogActions,
    DialogContent,
    FormControlLabel, makeStyles,
    Radio,
    Theme
} from "@material-ui/core";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { RadioGroup } from "formik-material-ui";
import React from "react";
import * as Yup from "yup";
import { ENV } from "../../../../constants";
import { ApiService, CommonService } from "../../../../helpers";
import "./RecoverShiftsComponent.scss";

interface RecoverShiftsComponentProps {
	cancel: () => void;
	confirm: () => void;
	selectedShifts: any[];
}

const formValidation = Yup.object({
	reason: Yup.string().required("Required"),
});

const reasonsToRecoverShifts: any[] = [
	{
		label: "Recover to the previous state",
		value: "previous",
	},
	{
		label: "Recover to shifts approved",
		value: "approved",
	},
];

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			margin: theme.spacing(3),
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[5],
			padding: "30px 50px",
			margin: "auto",
		},
		assignNcActions: {
			margin: "auto",
			marginTop: "100px",
			textAlign: "center",
			justifyContent: "center",
		},
		selectReason: {
			color: "#10c4d3 !important",
		},
	})
);

const RecoverShiftsComponent = (props: RecoverShiftsComponentProps) => {
	const classes = useStyles();
	const { selectedShifts, cancel, confirm } = props;

	const onAdd = (data: any, { setSubmitting, setErrors, resetForm, setFieldValue }: FormikHelpers<any>) => {
		const payload = {
			shift_ids: selectedShifts,
			recover_type: data.reason,
		};

		ApiService.patch(ENV.API_URL + "shift/recover", payload)
			.then((res: any) => {
				CommonService.showToast(res?.msg || "Success", "success");
				setSubmitting(false);
				confirm();
			})
			.catch((err) => {
				CommonService.showToast(err?.msg || err?.error || "Error", "error");
				setSubmitting(false);
			});
	};
	return (
		<div className="recover-shifts-screen">
			<div className={classes.paper}>
				<h1 className='recover-shifts-title'>{selectedShifts && selectedShifts?.length > 1 ? "Recover Shifts" : "Recover Shift"}</h1>
				<Formik
					initialValues={{ reason: "" }}
					validateOnChange={true}
					validationSchema={formValidation}
					onSubmit={onAdd}
				>
					{({ isSubmitting, isValid,values }) => (
						<Form className={"form-holder"}>
							<DialogContent>
								<>
									<h4 className={"recover-shifts-subtitle"}>Select an Option</h4>
									<Field required component={RadioGroup}  name="reason" >
										<div className="d-flex">
											{reasonsToRecoverShifts.map((item: any, index) => {
												return (
														<FormControlLabel
															key={"input_hcp_add_more_important_preference" + index}
															value={item.value}
															control={<Radio disabled={isSubmitting} />}
															disabled={isSubmitting}
															name="reason"
															label={item.label}
														/>
													
												);
											})}
										</div>
									</Field>
								</>
							</DialogContent>
							<DialogActions className="mrg-top-50">
								<Button
									variant="outlined"
									onClick={() => cancel()}
									color="primary"
									id="btn_cancel"
									className="pdd-left-30 pdd-right-30"
								>
									Cancel
								</Button>

								<Button
									type={"submit"}
									id="btn_reject_application"
									className={isSubmitting ? "submit has-loading-spinner" : "submit"}
									disabled={isSubmitting || values.reason === ""}
									variant={"contained"}
									color="primary"
									autoFocus
								>
									{isSubmitting
										? "Recovering Shift"
										: selectedShifts && selectedShifts?.length > 1
										? "Recover Shifts"
										: "Recover Shift"}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default RecoverShiftsComponent;

// import CheckIcon from "@material-ui/icons/Check";
import { IconButton, Tooltip } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CancelIcon from "@material-ui/icons/Cancel";
import moment from "moment";
import React, { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TsDataListOptions, TsDataListState, TsDataListWrapperClass } from "../../../../../classes/ts-data-list-wrapper.class";
import DialogComponent from "../../../../../components/DialogComponent";
import LoaderComponent from "../../../../../components/LoaderComponent";
import NoDataCardComponent from "../../../../../components/NoDataCardComponent";
import { ENV } from "../../../../../constants";
import { ApiService } from "../../../../../helpers";
import RejectHcpApplicationComponent from "../../../pending/rejectHcp/RejectHcpApplicationComponent";
import "./PendingHcpApplicationComponent.scss";

export interface PendingHcpApplicationComponentProps {
  isAddOpen: Boolean;
  status: string;
}

const PendingHcpApplicationComponent = (props: PropsWithChildren<PendingHcpApplicationComponentProps>) => {
  const status = props?.status;
  const isAddOpen = props?.isAddOpen;
  const param = useParams<any>();
  const { id } = param;
  const [list, setList] = useState<TsDataListState | null>(null);
  const [isRejectOpen, setIsRejectOpen] = useState<boolean>(false);
  const [applicationId, setApplicationId] = useState<string>("");

  // eslint-disable-next-line
  const [hcpId, setHcpId] = useState<string>("");

  const init = useCallback(() => {
    const options = new TsDataListOptions(
      {
        webMatColumns: ["Staff Name", "Email", "Applied On", "Staff Type", "Staff Rate", "Actions"],
        mobileMatColumns: ["Staff Name", "Email", "Applied On", "Staff Type", "Staff Rate", "Actions"],
      },
      ENV.API_URL + "requirement/" + id + "/application?status=pending",
      setList,
      ApiService,
      "get"
    );
    let tableWrapperObj = new TsDataListWrapperClass(options);
    setList({ table: tableWrapperObj });
  }, [id]);


  useEffect(() => {
    init();
  }, [init, isAddOpen]);

  const openRejectApplication = useCallback((applicationId: any) => {
    setApplicationId(applicationId);
    setIsRejectOpen(true);
  }, []);

  const cancelRejectApplication = useCallback(() => {
    setIsRejectOpen(false);
  }, []);

  const confirmRejectApplication = useCallback(() => {
    setIsRejectOpen(false);
    init();
  }, [init]);

  return (
    <div className="pending-shift-applications-hcps-list">
      <DialogComponent open={isRejectOpen} cancel={cancelRejectApplication}>
        <RejectHcpApplicationComponent cancel={cancelRejectApplication} confirm={confirmRejectApplication} requirementId={id} applicationId={applicationId} />
      </DialogComponent>


      {list && list.table && (
        <>
          <TableContainer component={Paper} className={"table-responsive"}>
            {list && list.table?._isDataLoading && (
              <div className="">
                <LoaderComponent position="block" />
              </div>
            )}
            <Table stickyHeader className="mat-table table shifts-requirment-pending-list-table">
              <TableHead className={"mat-thead"}>
                <TableRow className={"mat-tr"}>
                  {list?.table.matColumns.map((column: any, columnIndex: any) => (
                    <TableCell className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"} key={"header-col-" + columnIndex}>
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody className={"mat-tbody"}>
                {list.table.canShowNoData() && <NoDataCardComponent tableCellCount={list.table.matColumns.length} />}
                {list?.table.data.map((row: any, rowIndex: any) => {

                const hcpRate = row["hcp_details"]["contract_details"]?.rate_per_hour
                  return (
                    <TableRow className="mat-tr" role="checkbox" tabIndex={-1} key={"row-"}>
                      <TableCell className="mat-td mat-td-hcp-name">
                        {row["hcp_details"]?.first_name}&nbsp;{row["hcp_details"]?.last_name}
                      </TableCell>
                      <TableCell className="mat-td mat-td-hcp-email">{row["hcp_details"]?.email}</TableCell>
                      <TableCell className="mat-td mat-td-created-at">{moment(row["created_at"]).format("MM-DD-YYYY")}</TableCell>
                      <TableCell className="mat-td mat-td-hcp-type">{row["hcp_details"]?.hcp_type}</TableCell>
                      <TableCell className="mat-td mat-td-hcp-rate">{hcpRate ? hcpRate : (hcpRate === "" ? "NA" : hcpRate)  }</TableCell>
                      <TableCell className="mat-td mat-td-sticky mat-td-actions">
                        <div>
                          <Tooltip title={`Reject ${row["hcp_details"]?.first_name} ${row["hcp_details"]?.last_name} Application`}>
                            <IconButton onClick={() => openRejectApplication(row["_id"])} disabled={status === "cancelled"}>
                              <CancelIcon className="delete-icon" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

export default PendingHcpApplicationComponent;

import moment from "moment";
import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import LoaderComponent from "../../../../components/LoaderComponent";
import NoDataToShowCardComponent from "../../../../components/NoDataToShowCardComponent";
import {ENV} from "../../../../constants";
import {CommonService} from "../../../../helpers";
import HcpContractComponent from "../contract/HcpContractComponent";
import "./HcpDetailsComponent.scss";
import TableViewCustomFile from "./table-view-for-uploaded-file/TableViewCustomFile";
import {Paper} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";

const webMatColumns = ["Document Name", "Expires On", "Download"];
const HcpDetailsComponent = (props: any) => {
    const params = useParams<{ id: string }>();
    const {id} = params;
    const hcpBasicDetails = props?.hcpBasicDetails;
    const [attachmentsDetails, setAttachmentsDetails] = useState<any | null>(null);
    const [sortedAttachments, setSortedAttachments] = useState<any | null>(null);
    const [attachmentLoading, setAttachmentLoading] = useState<boolean>(false);
    const [isDownload, setIsDownloading] = useState<boolean>(false);


    const getAttachmentsDetails = useCallback(() => {
        setAttachmentLoading(true);
        CommonService._api.get(ENV.API_URL + "hcp/" + id + "/attachments").then((resp) => {
            setAttachmentsDetails(resp?.data);
            setAttachmentLoading(false);
        }).catch((err) => {
            console.log(err);
            setAttachmentLoading(false);
        });
    }, [id]);

    useEffect(() => {
        getAttachmentsDetails();
    }, [getAttachmentsDetails]);

    useEffect(() => {
        const required_attachments = [
            {name: "Resume", index: -1},
            {name: "Physical Test", index: -1},
            {name: "TB Test", index: -1},
            {name: "Chest X-ray", index: -1},
            {name: "CPR/BLS Card", index: -1},
            {name: "Driver License", index: -1},
            {name: "SSN Card", index: -1},
            {name: "License", index: -1},
            {name: "Covid Certificate", index: -1},
            {name: "Covid Vaccine Card", index: -1},
            {name: "Covid Test Result", index: -1},
            {name: "Livescan", index: -1},
            {name: "Vaccine Exemption Letter", index: -1},
        ];
        let tempAttachemnts: any = [];
        required_attachments?.forEach((item: any) => {
            attachmentsDetails?.forEach((attachment: any, index: any) => {
                if (item.name === attachment?.attachment_type) {
                    attachmentsDetails.splice(index, 1);
                    tempAttachemnts.push(attachment);
                }
            });
        });

        attachmentsDetails?.forEach((attachment: any) => {
            tempAttachemnts.push(attachment);
        });
        setSortedAttachments([...tempAttachemnts]);
    }, [attachmentsDetails]);

    const handleDownload = useCallback((file_key: any) => {
        setIsDownloading(true);
        let payload = {
            file_key: file_key
        };
        CommonService._api.post(ENV.API_URL + "downloadAttachment", payload).then((resp) => {
            const link = document.createElement('a');
            link?.setAttribute('href', resp?.data);
            document.body.appendChild(link);
            link.click();
            setIsDownloading(false);
        }).catch((err) => {
            console.log(err);
            setIsDownloading(false);
        });
    }, []);


    const StyledLoader = () => {
        return (
            <div className="pdd-20" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <LoaderComponent position="block"/>
            </div>
        );
    };

    return (
        <div className="hcp_details">
            <div className="custom-border pdd-20 pdd-left-40 pdd-right-40 mrg-top-15">
                <div className="d-flex profile-status-wrapper">
                    <h3>Basic Details</h3>
                    <div className="d-flex">
                        <h2 className="status mrg-right-0">Status</h2> <h2
                        className="status">&nbsp;:&nbsp;{hcpBasicDetails?.status}</h2>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="flex-1">
                        <h4>First Name</h4>
                        <p>{hcpBasicDetails?.first_name} </p>
                    </div>
                    <div className="flex-1">
                        <h4>Last Name</h4>
                        <p>{hcpBasicDetails?.last_name} </p>
                    </div>
                    <div className="flex-1">
                        <h4>Contact Number</h4>
                        <p>{hcpBasicDetails?.contact_number}</p>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="flex-1">
                        <h4>Email</h4>
                        <p>{hcpBasicDetails?.email}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Gender</h4>
                        <p>{hcpBasicDetails?.gender}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Role</h4>
                        <p>{hcpBasicDetails?.hcp_type}</p>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="flex-1">
                        <h4>Region Name</h4>
                        <p>{hcpBasicDetails?.address?.region}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Street</h4>
                        <p>{hcpBasicDetails?.address?.street}</p>
                    </div>
                    <div className="flex-1">
                        <h4>City</h4>
                        <p>{hcpBasicDetails?.address?.city}</p>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="flex-1">
                        <h4>State</h4>
                        <p>{hcpBasicDetails?.address?.state}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Country</h4>
                        <p>{hcpBasicDetails?.address?.country}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Zip Code</h4>
                        <p>{hcpBasicDetails?.address?.zip_code}</p>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="flex-1">
                        <h4>Latitude</h4>
                        <p>{hcpBasicDetails?.location?.coordinates?.[1]}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Longitude</h4>
                        <p>{hcpBasicDetails?.location?.coordinates?.[0]}</p>
                    </div>
                    <div className="flex-1">
                        <h4>DOB</h4>
                        <p>{hcpBasicDetails?.dob?.split("-").reverse().join("-")}</p>
                    </div>
                </div>
                <div className="mrg-top-10">
                    <h4>About the Staff</h4>
                    <p className="summary">{hcpBasicDetails?.about ? hcpBasicDetails?.about : "N/A"}</p>
                </div>
            </div>
            {hcpBasicDetails?.status === "rejected" ?
                <div className="mrg-top-15 custom-border pdd-20 pdd-left-40 pdd-right-40">
                    <div className="">
                        <h3>Reason for Rejection</h3>
                        <p>{hcpBasicDetails?.rejected_details?.reason}</p>
                    </div>
                    <div className="reject-by-wrapper d-flex">
                        <div>
                            <h4>Rejected By:</h4>
                            <p>
                                {hcpBasicDetails?.rejected_details?.rejected_by?.first_name} &nbsp; {hcpBasicDetails?.rejected_details?.rejected_by?.last_name}
                            </p>
                        </div>
                        <div className="mrg-left-50">
                            <h4>Role:</h4>
                            <p>{hcpBasicDetails?.rejected_details?.rejected_by?.role}</p>
                        </div>
                        <div className="mrg-left-50">
                            <h4>Rejected Date:</h4>
                            <p>{hcpBasicDetails?.rejected_details?.rejected_date ? moment(hcpBasicDetails?.rejected_details?.rejected_date).format("MM-DD-YYYY") : "N/A"}</p>
                        </div>
                    </div>
                </div> : <></>}
            <div className="custom-border mrg-top-10 pdd-20 pdd-left-40 pdd-right-40">
                <h3>Professional Details</h3>
                <div className="d-flex">
                    <div className="flex-1">
                        <h4>Years of Experience</h4>
                        <p>{hcpBasicDetails?.professional_details?.hasOwnProperty('experience') ? hcpBasicDetails?.professional_details?.experience : "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Speciality</h4>
                        <p>{hcpBasicDetails?.professional_details?.speciality ? hcpBasicDetails?.professional_details?.speciality : "N/A"}</p>
                    </div>
                </div>
                <div>
                    <h4>Professional Summary</h4>
                    <p className="summary">{hcpBasicDetails?.professional_details?.summary ? hcpBasicDetails?.professional_details?.summary : "N/A"}</p>
                </div>
            </div>

            {!attachmentLoading && sortedAttachments ? (
                <div className="custom-border mrg-top-20 pdd-20">
                    <h3>Attachments/Documents</h3>
                    {sortedAttachments?.length === 0 && (
                        <p>
                            <NoDataToShowCardComponent/>
                        </p>
                    )}
                    {sortedAttachments?.length !== 0 &&
                        <TableContainer component={Paper} className={"doc-table-responsive"}>
                            <Table size={"small"} stickyHeader
                                   className="mat-table table shifts-requirment-pending-list-table">
                                <TableHead className={"mat-thead"}>
                                    <TableRow className={"mat-tr"}>
                                        {webMatColumns.map((column: any, columnIndex: any) => (
                                            <TableCell
                                                className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}
                                                key={"header-col-" + columnIndex}>
                                                {column}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody className={"mat-tbody"}>
                                    {sortedAttachments?.map((item: any, index: any) => {
                                        return (<>
                                                <TableViewCustomFile data={item} handleDownload={handleDownload}
                                                                     isDownload={isDownload}/>
                                            </>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                </div>
            ) : (
                <div className="custom-border mrg-top-10 pdd-20 pdd-left-40 pdd-right-40">
                    <StyledLoader/>
                </div>
            )}
            <div>
                <HcpContractComponent hcpDetails={hcpBasicDetails}/>
            </div>


            <div className="custom-border mrg-top-10 pdd-20 pdd-left-40 pdd-right-40">
                <h3>Travel Preference</h3>
                <div className="d-flex mrg-top-20">
                    {hcpBasicDetails?.nc_details?.travel_preferences && Array.isArray(hcpBasicDetails?.nc_details?.travel_preferences) && hcpBasicDetails?.nc_details?.travel_preferences?.length > 0 ? hcpBasicDetails?.nc_details?.travel_preferences?.map((item: any) =>
                        <p className="flex-1">{item} Miles</p>) : <p>N/A</p>}
                </div>
            </div>

            <div className="custom-border mrg-top-10 pdd-20 pdd-left-40 pdd-right-40">
                <h3>Notification Preference</h3>
                <div className="mrg-top-20" style={{display: "flex"}}>
                    {hcpBasicDetails?.hasOwnProperty("notification_preferences") ? (
                        <>
                            {hcpBasicDetails?.notification_preferences?.sms &&
                                <p className="flex-1">{hcpBasicDetails?.notification_preferences?.sms ? "SMS" : null}</p>}
                            {hcpBasicDetails?.notification_preferences?.email &&
                                <p className="flex-1">{hcpBasicDetails?.notification_preferences?.email ? "Email" : null}</p>}
                            {hcpBasicDetails?.notification_preferences?.push_notification &&
                                <p className="flex-1">{hcpBasicDetails?.notification_preferences?.push_notification ? "In-application" : null}</p>}
                        </>
                    ) : (
                        <p className="flex-1">N/A</p>
                    )}
                </div>
            </div>

            <div className="custom-border mrg-top-10 pdd-20 pdd-left-40 pdd-right-40 pdd-bottom-35">
                <h3>NC Section</h3>

                <div className="d-flex">
                    <div className="flex-1">
                        <h4>DNR</h4>
                        <p>{hcpBasicDetails?.nc_details?.dnr ? hcpBasicDetails?.nc_details?.dnr : 'N/A'}</p>
                    </div>

                    <div className="flex-1">
                        <h4>Contact Type</h4>
                        <p>{hcpBasicDetails?.nc_details?.contact_type !== "" ? CommonService.nameCoverterToNormal(hcpBasicDetails?.nc_details?.contact_type) : "NA"}</p>
                    </div>

                    <div className="flex-1">
                        <h4>Preferred Location to Work</h4>
                        <p>{hcpBasicDetails?.nc_details?.location_preference ? hcpBasicDetails?.nc_details?.location_preference : "N/A"}</p>
                    </div>
                </div>

                <div className="d-flex">
                    <div className="flex-1">
                        <h4>Preference Shift Type</h4>
                        <p>{hcpBasicDetails?.nc_details?.shift_type_preference ? hcpBasicDetails?.nc_details?.shift_type_preference : "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Zone Assignment</h4>
                        <p>{hcpBasicDetails?.nc_details?.zone_assignment !== "" ? hcpBasicDetails?.nc_details?.zone_assignment : "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Last Call Date</h4>
                        <p>{hcpBasicDetails?.nc_details?.last_call_date ? moment(hcpBasicDetails?.nc_details?.last_call_date).format("MMMM Do YYYY") : "N/A"}</p>
                    </div>
                </div>

                <div className="d-flex">
                    <div className="flex-1">
                        <h4>Vaccine</h4>
                        <p>{!hcpBasicDetails?.nc_details?.vaccine ? "N/A" : hcpBasicDetails?.nc_details?.vaccine === "half" ? "1st Dose" : hcpBasicDetails?.nc_details?.vaccine}</p>
                    </div>

                    <div className="flex-1">
                        <h4>First Shot Date</h4>
                        <p>{hcpBasicDetails?.nc_details?.vaccination_dates?.first_shot ? hcpBasicDetails?.nc_details?.vaccination_dates?.first_shot : "N/A"}</p>
                    </div>

                    <div className="flex-1">
                        <h4>Latest Shot Date</h4>
                        <p>{hcpBasicDetails?.nc_details?.vaccination_dates?.latest_shot ? hcpBasicDetails?.nc_details?.vaccination_dates?.latest_shot : "N/A"}</p>
                    </div>
                </div>

                <div className="d-flex">
                    <div className="flex-1">
                        <h4>Do you have a Full-time Job ?</h4>
                        <p>{hcpBasicDetails?.nc_details?.is_fulltime_job !== "" ? (hcpBasicDetails?.nc_details?.is_fulltime_job === "true" ? "Yes" : "No") : "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Covid (or) Non Covid Facility ?</h4>
                        <p>{hcpBasicDetails?.nc_details?.covid_facility_preference ? hcpBasicDetails?.nc_details?.covid_facility_preference : "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>What Is More Important for You ?</h4>
                        <p>{hcpBasicDetails?.nc_details?.more_important_preference !== "" ? hcpBasicDetails?.nc_details?.more_important_preference : "N/A"}</p>
                    </div>
                </div>

                <div className="d-flex">
                    <div className="flex-1">
                        <h4>Is this a Supplement to your Income ?</h4>
                        <p>{hcpBasicDetails?.nc_details?.is_supplement_to_income !== "" ? (hcpBasicDetails?.nc_details?.is_supplement_to_income === "true" ? "Yes" : "No") : "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Are you Studying ?</h4>
                        <p>{hcpBasicDetails?.nc_details?.is_studying !== "" ? (hcpBasicDetails?.nc_details?.is_studying === "true" ? "Yes" : "No") : "N/A"}</p>
                    </div>
                    {/*<div className="flex-1">*/}
                    {/*    <h4>Gusto</h4>*/}
                    {/*    <p>{hcpBasicDetails?.nc_details?.gusto_type !== "" ? CommonService.nameCoverterToNormal(hcpBasicDetails?.nc_details?.gusto_type) : "N/A"}</p>*/}
                    {/*</div>*/}
                </div>

                <div className="d-flex">
                    <div className="flex-1">
                        <h4>Is Gusto Invited ?</h4>
                        <p>{hcpBasicDetails?.nc_details?.is_gusto_invited !== "" ? (hcpBasicDetails?.nc_details?.is_gusto_invited === "true" ? "Yes" : "No") : "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Is Gusto Onboarded ?</h4>
                        <p>{hcpBasicDetails?.nc_details?.is_gusto_onboarded !== "" ? (hcpBasicDetails?.nc_details?.is_gusto_onboarded === "true" ? "Yes" : "No") : "N/A"}</p>
                    </div>
                    <div className="flex-1">
                        <h4>Require Sponsorship for Employment in United States?</h4>
                        <p>{hcpBasicDetails?.nc_details?.is_require_employment_sponsorship !== "" ? (hcpBasicDetails?.nc_details?.is_require_employment_sponsorship === "true" ? "Yes" : "No") : "N/A"}</p>
                    </div>
                </div>

                <div className="d-flex">
                    <div className="flex-1">
                        <h4>Legally Authorized to work in United States ?</h4>
                        <p>{hcpBasicDetails?.nc_details?.is_authorized_to_work !== "" ? (hcpBasicDetails?.nc_details?.is_authorized_to_work === "true" ? "Yes" : "No") : "N/A"}</p>
                    </div>
                </div>

                <div className="d-flex">
                    <div className="flex-1">
                        <h4>Family Considerations</h4>
                        <p className="summary">{hcpBasicDetails?.nc_details?.family_consideration ? hcpBasicDetails?.nc_details?.family_consideration : "N/A"}</p>
                    </div>
                </div>

                <div className="d-flex">
                    <div className="flex-1">
                        <h4>Other Information Gathered</h4>
                        <p>{hcpBasicDetails?.nc_details?.other_information ? hcpBasicDetails?.nc_details?.other_information : "N/A"}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HcpDetailsComponent;

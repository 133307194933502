import React from "react";
import {Box, IconButton, MenuItem, Paper} from "@material-ui/core";
import {Field, FieldProps, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";
import {facilityFormValidation, facilityInitialState} from "../FacilityInitialAndValidationsComponent";
import {ScrollToError} from "../../../../components/ScrollToError";
import PhoneInputComponent from "../../../../components/phoneInput/PhoneInputComponent";
import {PdfIcon} from "../../../../constants/ImageConfig";
import FileDropZoneComponent from "../../../../components/core/FileDropZoneComponent";
import Tooltip from "@material-ui/core/Tooltip";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CancelIcon from "@material-ui/icons/Cancel";

export interface FacilityAddDetailsComponentProps {
    onAdd: any;
    regions: any;
    americanTimeZone: any;
    fileUpload: any;
    previewFile: any;
    deleteFile: any;
    OnFileSelected: any;
    otHours: any;
    contractFileUpload: any;
    previewContractFile: any;
    deleteContractFile: any;
    OnContractFileSelected: any;
    setOtherDocuments?: any;
    otherDocuments?: any;
    otherFileUpload?: any;
    setOtherFileUpload?: any;
    OnOtherFileSelected?: any;
    previewOtherFile?: any;
}


const webMatColumns = ["Document Name", "Delete"];
const FacilityAddDetailsComponent = (props: any) => {
    const previewOtherFile = props?.previewOtherFile;
    const onAdd = props?.onAdd;
    const regions = props?.regions;
    const americanTimeZone = props?.americanTimeZone;
    const fileUpload = props?.fileUpload;
    const previewFile = props?.previewFile;
    const deleteFile = props?.deleteFile;
    const OnFileSelected = props?.OnFileSelected;
    const contractFileUpload = props?.contractFileUpload;
    const previewContractFile = props?.previewContractFile;
    const deleteContractFile = props?.deleteContractFile;
    const OnContractFileSelected = props?.OnContractFileSelected;
    const otHours = props?.otHours;
    const otherDocuments = props?.otherDocuments;
    const setOtherDocuments = props?.setOtherDocuments;
    const OnOtherFileSelected = props?.OnOtherFileSelected;


    const deleteOtherFile = (temp: any, itemIndex: any) => {
        const updatedOthersDocument = [...otherDocuments];
        updatedOthersDocument.splice(temp, 1);
        setOtherDocuments(updatedOthersDocument);
    };

    const showDropDownBelowField = {
        MenuProps: {
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
            },
            getContentAnchorEl: null,
        },
    };
    const onlyNums = /^\d{1,3}(\.$|\.\d{1,3}$|$)/;
    return (
        <div>
            <Formik initialValues={facilityInitialState} validateOnChange={true} validateOnBlur={false}
                    validationSchema={facilityFormValidation} onSubmit={onAdd}>
                {({isSubmitting, isValid, resetForm, setFieldValue}) => (
                    <Form id="facility-add-form" className={"form-holder"}>
                        <ScrollToError/>
                        <div className="facility-basic-details custom-border">
                            <p className="card-header">Basic Details</p>
                            <div className="input-container">
                                <Field variant="outlined" name="name" type={"text"} component={TextField}
                                       label="Facility Name*" fullWidth autoComplete="off"
                                       id="input_facility_add_facility_name"/>
                                <Field variant="outlined" name="business_name" type={"text"} component={TextField}
                                       label="Business Name" fullWidth autoComplete="off"
                                       id="input_facility_add_business_name"/>
                            </div>

                            <div className="input-container">
                                <Field variant="outlined" name="uid" type={"text"} component={TextField}
                                       label="Facility Unique ID*" fullWidth autoComplete="off"
                                       id="input_facility_add_facility_uid"/>
                                <Field variant="outlined" name="short_name" type={"text"} component={TextField}
                                       label="Facility Short Name*" fullWidth autoComplete="off"
                                       id="input_facility_add_facility_short_name"/>
                            </div>

                            <div className="input-container ">
                                <Field
                                    SelectProps={showDropDownBelowField}
                                    variant="outlined"
                                    name="address.region"
                                    type={"text"}
                                    component={TextField}
                                    select
                                    label="Region*"
                                    fullWidth
                                    className="flex-1"
                                    autoComplete="off"
                                    id="input_facility_add_address_region_name"
                                >
                                    {regions &&
                                        regions.map((item: any, index: any) => (
                                            <MenuItem id={`${item}`} value={item.code} key={index}>
                                                {item.title}
                                            </MenuItem>
                                        ))}
                                </Field>

                                <div className="flex-1 d-flex">
                                    <div className="phone-number">
                                        <Field name={"contact_number"} variant="outlined" inputProps={{maxLength: 10}}
                                               id="phone_number_facility_add" style={{font: "inherit"}}>
                                            {(field: FieldProps) => {
                                                return <PhoneInputComponent field={field}
                                                                            placeholder={"Enter Phone number*"}/>;
                                            }}
                                        </Field>
                                    </div>
                                    <div className="extension-number">
                                        <Field variant="outlined" component={TextField}
                                               fullWidth autoComplete="off" label="Ext No" name="extension_number"
                                               id="input_facility_add_extension_number"/>
                                    </div>
                                </div>
                            </div>
                            <div className="input-container">
                                <Field variant="outlined" name="email" type={"text"} component={TextField} label="Email"
                                       fullWidth autoComplete="off" id="input_facility_add_email"/>
                                <Field variant="outlined" name="website_url" type={"text"} component={TextField}
                                       label="Website" fullWidth autoComplete="off"
                                       id="input_facility_add_website_url"/>
                            </div>

                            <div className="input-container ">
                                <Field variant="outlined" name="address.street" type={"text"} component={TextField}
                                       label="Street*" fullWidth autoComplete="off"
                                       id="input_facility_add_address_street"/>
                                <Field variant="outlined" name="address.city" type={"text"} component={TextField}
                                       label="City*" fullWidth autoComplete="off" id="input_facility_add_address_city"/>
                            </div>

                            <div className="input-container">
                                <Field variant="outlined" name="address.state" type={"text"} component={TextField}
                                       label="State*" fullWidth autoComplete="off"
                                       id="input_facility_add_address_state"/>
                                <Field variant="outlined" name="address.country" type={"text"} component={TextField}
                                       label="Country*" fullWidth autoComplete="off"
                                       id="input_facility_add_address_country"/>
                            </div>
                            <div className="input-container ">
                                <Field
                                    inputProps={{
                                        maxLength: 6,
                                    }}
                                    variant="outlined"
                                    name="address.zip_code"
                                    type={"text"}
                                    component={TextField}
                                    label="Zip Code*"
                                    autoComplete="off"
                                    id="input_facility_add_address_zip_code"
                                />
                                <Field SelectProps={showDropDownBelowField} variant="outlined" name="timezone"
                                       type={"text"} component={TextField} select label="Facility Timezone*" fullWidth
                                       autoComplete="off" id="input_facility_add_timezone">
                                    <MenuItem style={{fontWeight: 500}} value="">
                                        Select Timezone
                                    </MenuItem>
                                    {americanTimeZone &&
                                        americanTimeZone.map((item: any, index: any) => (
                                            <MenuItem style={{fontSize: "14px"}} value={item.value} key={index}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                </Field>
                            </div>

                            <div className="input-container">
                                <Field fullWidth variant="outlined" name="latitude" type={"text"} component={TextField}
                                       label="Latitude*" autoComplete="off" id="input_facility_add_latitude"/>
                                <Field fullWidth variant="outlined" name="longitude" type={"text"} component={TextField}
                                       label="Longitude*" autoComplete="off" id="input_facility_add_longitude"/>
                            </div>
                            <Field fullWidth variant="outlined" name="parent_company" type={"text"}
                                   component={TextField}
                                   label="SNF Parent Company" autoComplete="off"
                                   id="input_facility_add_SNF_Parent_company"/>
                            <div className="facility-about ">
                                <p className="card-header">About the Facility</p>
                                <Field variant="outlined" component={TextField} type={"text"} name="about" fullWidth
                                       multiline rows={2} id="input_facility_add_about"/>
                            </div>


                            <div style={{display: "flex"}}>
                                <div id={''}>
                                    <h3 className="card-header facility-image-header mrg-bottom-0">Upload Facility
                                        Image</h3>
                                    <div className="d-flex mrg-top-10" style={{gap: "50px"}}>
                                        {fileUpload?.wrapper &&
                                            fileUpload?.wrapper?.map((item: any, index: any) => {
                                                return (
                                                    <div className="attachments">
                                                        <Tooltip title="Preview Facility Icon">
                                                            {item?.file?.type === "image/jpg" || item?.file?.type === "image/png" || item?.file?.type === "image/jpeg" ? (
                                                                <img src={item?.file?.base64} alt="" style={{
                                                                    height: "100px",
                                                                    width: "100px",
                                                                    cursor: "pointer"
                                                                }} onClick={() => previewFile(index)}/>
                                                            ) : (
                                                                <img src={PdfIcon} alt="" style={{
                                                                    height: "100px",
                                                                    width: "100px",
                                                                    cursor: "pointer"
                                                                }} onClick={() => previewFile(index)}/>
                                                            )}
                                                        </Tooltip>
                                                        <div className="d-flex image_actions mrg-top-10">
                                                            <p style={{cursor: "pointer"}}
                                                               onClick={() => previewFile(index)}
                                                               className="delete-image"
                                                               id={'view_facility_image'}
                                                            >
                                                                View
                                                            </p>
                                                            <p style={{cursor: "pointer"}}
                                                               onClick={() => deleteFile(index)}
                                                               className="delete-image mrg-left-20"
                                                               id={'delete_facility_image'}
                                                            >
                                                                Delete
                                                            </p>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>

                                    {fileUpload?.wrapper.length >= 1 ? (
                                        <></>
                                    ) : (
                                        <div>
                                            <Box display="flex" gridGap="10px">
                                                <Tooltip title={"Upload Facility Image"}>
                                                    <Box width="250px" className="mrg-top-10">
                                                        <div>
                                                            <FileDropZoneComponent OnFileSelected={OnFileSelected}
                                                                                   allowedTypes={".jpg,.png,.jpeg"}/>
                                                        </div>
                                                    </Box>
                                                </Tooltip>
                                            </Box>
                                        </div>
                                    )}
                                </div>

                                <div id={''}>
                                    <h3 className="card-header facility-image-header mrg-bottom-0">Others</h3>
                                    <div>
                                        <Box display="flex" gridGap="10px">
                                            <Tooltip title={"Upload Facility Image"}>
                                                <Box width="250px" className="mrg-top-10">
                                                    <div>
                                                        <FileDropZoneComponent OnFileSelected={OnOtherFileSelected}
                                                                               allowedTypes={".pdf"}/>
                                                    </div>
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </div>
                                </div>

                                <div className={'mrg-left-100'}>
                                    <h3 className="card-header facility-image-header mrg-bottom-0">Upload Facility
                                        Contract</h3>
                                    <div className="d-flex mrg-top-10" style={{gap: "50px"}}>
                                        {contractFileUpload?.wrapper &&
                                            contractFileUpload?.wrapper?.map((item: any, index: any) => {
                                                return (
                                                    <div className="attachments">
                                                        <Tooltip title="Preview Facility Icon">
                                                            {item?.file?.type === "image/jpg" || item?.file?.type === "image/png" || item?.file?.type === "image/jpeg" || item?.file?.type === "image/pdf" ? (
                                                                <img src={item?.file?.base64} alt="" style={{
                                                                    height: "100px",
                                                                    width: "100px",
                                                                    cursor: "pointer"
                                                                }} onClick={() => previewContractFile(index)}/>
                                                            ) : (
                                                                <img src={PdfIcon} alt="" style={{
                                                                    height: "100px",
                                                                    width: "100px",
                                                                    cursor: "pointer"
                                                                }} onClick={() => previewContractFile(index)}/>
                                                            )}
                                                        </Tooltip>
                                                        <div className="d-flex image_actions mrg-top-10">
                                                            <p style={{cursor: "pointer"}}
                                                               onClick={() => previewContractFile(index)}
                                                               className="delete-image"
                                                               id={'view_facility_contract'}
                                                            >
                                                                View
                                                            </p>
                                                            <p style={{cursor: "pointer"}}
                                                               onClick={() => deleteContractFile(index)}
                                                               className="delete-image mrg-left-20"
                                                               id={"delete_facility_contract"}
                                                            >
                                                                Delete
                                                            </p>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>

                                    {contractFileUpload?.wrapper.length >= 1 ? (
                                        <></>
                                    ) : (
                                        <div>
                                            <Box display="flex" gridGap="10px">
                                                <Tooltip title={"Upload Facility Contract"}>
                                                    <Box width="250px" className="mrg-top-10">
                                                        <FileDropZoneComponent OnFileSelected={OnContractFileSelected}
                                                                               allowedTypes={".jpg,.png,.jpeg,.pdf"}/>
                                                    </Box>
                                                </Tooltip>
                                            </Box>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {otherDocuments?.length > 0 &&
                                <div className={"mrg-top-20"}>
                                    <TableContainer component={Paper} className={"table-responsive"}>
                                        <Table size={"small"} stickyHeader
                                               className="mat-table table shifts-requirment-pending-list-table">
                                            <TableHead className={"mat-thead"}>
                                                <TableRow className={"mat-tr"}>
                                                    {webMatColumns.map((column: any, columnIndex: any) => (
                                                        <TableCell
                                                            className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}
                                                            key={"header-col-" + columnIndex}>
                                                            {column}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className={"mat-tbody"}>
                                                {otherDocuments?.map((item: any, index: any) => {
                                                    return (<>
                                                            <TableRow className="mat-tr" role="checkbox" tabIndex={-1}
                                                                      key={`row-${index}`}>
                                                                <TableCell
                                                                    className="mat-td mat-td-hcp-name">
                                                                    <p style={{
                                                                        cursor: 'pointer',
                                                                        textUnderlinePosition: "under"
                                                                    }}
                                                                       onClick={() => previewOtherFile(item?.index, "attachment")}
                                                                       className="delete-image"> {otherDocuments[index].name}</p>
                                                                </TableCell>
                                                                {/*<TableCell*/}
                                                                {/*    className="mat-td mat-td-hcp-email">*/}
                                                                {/*    <div className="d-flex">*/}
                                                                {/*        <NormalTextField*/}
                                                                {/*            required*/}
                                                                {/*            label="Attachment Name"*/}
                                                                {/*            type={"text"}*/}
                                                                {/*            inputProps={{maxLength: 30}}*/}
                                                                {/*            InputLabelProps={{shrink: true}}*/}
                                                                {/*            onChange={(event) => handleOtherAttachmentName(event, otherDocuments[index]?.index)}*/}
                                                                {/*            value={fileUpload?.wrapper[otherDocuments[index]?.index]?.extraPayload?.expiry_date}*/}
                                                                {/*        />*/}
                                                                {/*        <div className="mrg-top-15 mrg-left-15"><Button*/}
                                                                {/*            color="primary"*/}
                                                                {/*            variant={"outlined"}*/}
                                                                {/*            onClick={() => handleOthersAttachmentName(otherDocuments[index]?.index, index)}>Save</Button>*/}
                                                                {/*        </div>*/}
                                                                {/*    </div>*/}
                                                                {/*</TableCell>*/}
                                                                <TableCell
                                                                    className="mat-td mat-td-sticky mat-td-actions">
                                                                    <div>
                                                                        {/*<IconButton onClick={() => openRejectApplication(row["_id"])} disabled={status === "cancelled"}>*/}
                                                                        {/*    <CancelIcon className="delete-icon" />*/}
                                                                        {/*</IconButton>*/}
                                                                        <IconButton
                                                                            onClick={() => deleteOtherFile(index, item?.index)}>
                                                                            <CancelIcon className="delete-icon"/>
                                                                        </IconButton>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        </>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>}
                        </div>


                        <div className="facility-other-details mrg-top-10 custom-border">
                            <p className="card-header">Other Details</p>

                            <div className="input-container ">
                                <Field variant="outlined" name="hourly_base_rates.slp" type={"text"}
                                       component={TextField} label="SLP Rate ($/hr)" fullWidth autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_slp" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.slp", e.target.value);
                                    }
                                }}/>
                                <Field variant="outlined" name="hourly_base_rates.scrub_tech" type={"text"}
                                       component={TextField} label="Scrub Tech Rate ($/hr)" fullWidth autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_scrub_tech" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.scrub_tech", e.target.value);
                                    }
                                }}/>
                            </div>

                            <div className="input-container ">
                                <Field variant="outlined" name="hourly_base_rates.medical_assistant" type={"text"}
                                       component={TextField} label="Medical Assistant Rate ($/hr)" fullWidth
                                       autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_medical_assistant"
                                       onChange={(e: any) => {
                                           if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                               setFieldValue("hourly_base_rates.medical_assistant", e.target.value);
                                           }
                                       }}/>
                                <Field variant="outlined" name="hourly_base_rates.marriage_family_therapist"
                                       type={"text"}
                                       component={TextField} label="Marriage Family Therapist Rate ($/hr)" fullWidth
                                       autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_marriage_family_therapist"
                                       onChange={(e: any) => {
                                           if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                               setFieldValue("hourly_base_rates.marriage_family_therapist", e.target.value);
                                           }
                                       }}/>
                            </div>


                            <div className="input-container ">
                                <Field variant="outlined" name="hourly_base_rates.clinical_social_worker" type={"text"}
                                       component={TextField} label="Clinical Social Worker Rate ($/hr)" fullWidth
                                       autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_clinical_social_worker"
                                       onChange={(e: any) => {
                                           if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                               setFieldValue("hourly_base_rates.clinical_social_worker", e.target.value);
                                           }
                                       }}/>
                                <Field variant="outlined" name="hourly_base_rates.psychologist" type={"text"}
                                       component={TextField} label="Psychologist Rate ($/hr)" fullWidth
                                       autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_scrub_tech" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.psychologist", e.target.value);
                                    }
                                }}/>
                            </div>

                            <div className="input-container ">
                                <Field variant="outlined" name="hourly_base_rates.pharmacist" type={"text"}
                                       component={TextField} label="Pharmacist Rate ($/hr)" fullWidth autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_pharmacist" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.pharmacist", e.target.value);
                                    }
                                }}/>
                                <Field variant="outlined" name="hourly_base_rates.pharmacy_technician" type={"text"}
                                       component={TextField} label="Pharmacy Technician Tech Rate ($/hr)" fullWidth
                                       autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_pharmacy_technician"
                                       onChange={(e: any) => {
                                           if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                               setFieldValue("hourly_base_rates.pharmacy_technician", e.target.value);
                                           }
                                       }}/>
                            </div>


                            <div className="input-container ">
                                <Field variant="outlined" name="hourly_base_rates.cna" type={"text"}
                                       component={TextField} label="CNA Rate ($/hr)*" fullWidth autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_cna" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.cna", e.target.value);
                                    }
                                }}/>
                                <Field variant="outlined" name="hourly_base_rates.lvn" type={"text"}
                                       component={TextField} label="LVN Rate ($/hr)*" fullWidth autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_lvn" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.lvn", e.target.value);
                                    }
                                }}/>
                            </div>

                            <div className="input-container">
                                <Field variant="outlined" name="hourly_base_rates.rn" type={"text"}
                                       component={TextField} label="RN Rate ($/hr)*" fullWidth autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_rn" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.rn", e.target.value);
                                    }
                                }}/>
                                <Field variant="outlined" name="hourly_base_rates.care_giver" type={"text"}
                                       component={TextField} label="Care Giver Rate ($/hr)" fullWidth autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_care_giver" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.care_giver", e.target.value);
                                    }
                                }}/>
                            </div>

                            <div className="input-container ">
                                <Field variant="outlined" name="hourly_base_rates.med_tech" type={"text"}
                                       component={TextField} label="Med Tech Rate($/hr)" fullWidth autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_med_tech" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.med_tech", e.target.value);
                                    }
                                }}/>
                                <Field variant="outlined" name="hourly_base_rates.spd_tech" type={"text"}
                                       component={TextField} label="SPD Tech Rate ($/hr)" fullWidth autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_spd_tech" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.spd_tech", e.target.value);
                                    }
                                }}/>
                            </div>
                            <div className="input-container ">
                                <Field variant="outlined" name="hourly_base_rates.dsp" type={"text"}
                                       component={TextField} label="DSP Rate ($/hr)" fullWidth autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_dsp" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.dsp", e.target.value);
                                    }
                                }}/>
                                <Field variant="outlined" name="hourly_base_rates.pss" type={"text"}
                                       component={TextField} label="PSS Rate ($/hr)" fullWidth autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_pss" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.pss", e.target.value);
                                    }
                                }}/>
                            </div>

                            <div className="input-container ">
                                <Field variant="outlined" name="hourly_base_rates.crma" type={"text"}
                                       component={TextField} label="CRMA Rate ($/hr)" fullWidth autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_crma" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.crma", e.target.value);
                                    }
                                }}/>
                                <Field variant="outlined" name="hourly_base_rates.rn_supervisor" type={"text"}
                                       component={TextField} label="RN Supervisor Rate ($/hr)" fullWidth
                                       autoComplete="off" id="input_facility_add_hourly_base_rates_rn_supervisor"
                                       onChange={(e: any) => {
                                           if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                               setFieldValue("hourly_base_rates.rn_supervisor", e.target.value);
                                           }
                                       }}/>
                            </div>
                            <div className="input-container ">
                                <Field variant="outlined" name="hourly_base_rates.caregiver_dsp" type={"text"}
                                       component={TextField} label="Caregiver DSP Rate ($/hr)" fullWidth
                                       autoComplete="off" id="input_facility_add_hourly_base_rates_caregiver_dsp"
                                       onChange={(e: any) => {
                                           if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                               setFieldValue("hourly_base_rates.caregiver_dsp", e.target.value);
                                           }
                                       }}/>
                                <Field variant="outlined" name="hourly_base_rates.caregiver_pss" type={"text"}
                                       component={TextField} label="Caregiver PSS Rate ($/hr)" fullWidth
                                       autoComplete="off" id="input_facility_add_hourly_base_rates_caregiver_pss"
                                       onChange={(e: any) => {
                                           if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                               setFieldValue("hourly_base_rates.caregiver_pss", e.target.value);
                                           }
                                       }}/>
                            </div>
                            <div className="input-container ">
                                <Field variant="outlined" name="hourly_base_rates.caregiver_crma" type={"text"}
                                       component={TextField} label="Caregiver CRMA Rate ($/hr)" fullWidth
                                       autoComplete="off" id="input_facility_add_hourly_base_rates_caregiver_crma"
                                       onChange={(e: any) => {
                                           if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                               setFieldValue("hourly_base_rates.caregiver_crma", e.target.value);
                                           }
                                       }}/>
                                <Field variant="outlined" name="hourly_base_rates.cls" type={"text"}
                                       component={TextField} label="CLS Rate ($/hr)" fullWidth
                                       autoComplete="off" id="input_facility_add_hourly_base_rates_cls"
                                       onChange={(e: any) => {
                                           if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                               setFieldValue("hourly_base_rates.cls", e.target.value);
                                           }
                                       }}/>
                            </div>


                            <div className="input-container ">
                                <Field variant="outlined" name="hourly_base_rates.rn_speciality1" type={"text"}
                                       component={TextField} label="RN Speciality 1 Rate ($/hr)" fullWidth
                                       autoComplete="off" id="input_facility_add_hourly_base_rates_rn_speciality1"
                                       onChange={(e: any) => {
                                           if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                               setFieldValue("hourly_base_rates.rn_speciality1", e.target.value);
                                           }
                                       }}/>
                                <Field variant="outlined" name="hourly_base_rates.rn_speciality2" type={"text"}
                                       component={TextField} label="RN Speciality 2 Rate ($/hr)" fullWidth
                                       autoComplete="off" id="input_facility_add_hourly_base_rates_rn_speciality2"
                                       onChange={(e: any) => {
                                           if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                               setFieldValue("hourly_base_rates.rn_speciality2", e.target.value);
                                           }
                                       }}/>
                            </div>

                            <div className="input-container ">
                                <Field variant="outlined" name="hourly_base_rates.rn_travel" type={"text"}
                                       component={TextField} label="RN Travel Rate ($/hr)" fullWidth autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_rn_travel" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.rn_travel", e.target.value);
                                    }
                                }}/>
                                <Field variant="outlined" name="hourly_base_rates.emt" type={"text"}
                                       component={TextField} label="EMT Rate ($/hr)" fullWidth autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_emt" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.emt", e.target.value);
                                    }
                                }}/>
                            </div>

                            <div className="input-container ">
                                <Field variant="outlined" name="hourly_base_rates.monitor_tech" type={"text"}
                                       component={TextField} label="Monitor Tech Rate ($/hr)" fullWidth
                                       autoComplete="off" id="input_facility_add_hourly_base_rates_monitor_tech"
                                       onChange={(e: any) => {
                                           if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                               setFieldValue("hourly_base_rates.monitor_tech", e.target.value);
                                           }
                                       }}/>
                                <Field variant="outlined" name="hourly_base_rates.orst" type={"text"}
                                       component={TextField} label="ORST Rate ($/hr)" fullWidth autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_orst" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.orst", e.target.value);
                                    }
                                }}/>
                            </div>

                            <div className="input-container ">
                                <Field variant="outlined" name="hourly_base_rates.radiology_tech" type={"text"}
                                       component={TextField} label="Radiology Tech Rate ($/hr)" fullWidth
                                       autoComplete="off" id="input_facility_add_hourly_base_rates_radiology_tech"
                                       onChange={(e: any) => {
                                           if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                               setFieldValue("hourly_base_rates.radiology_tech", e.target.value);
                                           }
                                       }}/>
                                <Field variant="outlined" name="hourly_base_rates.lpn" type={"text"}
                                       component={TextField} label="LPN Rate ($/hr)" fullWidth autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_lpn" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.lpn", e.target.value);
                                    }
                                }}/>
                            </div>

                            <div className="input-container ">
                                <Field variant="outlined" name="hourly_base_rates.physical_therapist" type={"text"}
                                       component={TextField} label="Physical Therapist Rate ($/hr)" fullWidth
                                       autoComplete="off" id="input_facility_add_hourly_base_rates_physical_therapist"
                                       onChange={(e: any) => {
                                           if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                               setFieldValue("hourly_base_rates.physical_therapist", e.target.value);
                                           }
                                       }}/>
                                <Field variant="outlined" name="hourly_base_rates.occupational_therapist" type={"text"}
                                       component={TextField} label="Occupational Therapist Rate ($/hr)" fullWidth
                                       autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_occupational_therapist"
                                       onChange={(e: any) => {
                                           if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                               setFieldValue("hourly_base_rates.occupational_therapist", e.target.value);
                                           }
                                       }}/>
                            </div>

                            <div className="input-container ">
                                <Field variant="outlined" name="hourly_base_rates.chha" type={"text"}
                                       component={TextField} label="CHHA Rate ($/hr)" fullWidth
                                       autoComplete="off" id="input_facility_add_hourly_base_rates_chha"
                                       onChange={(e: any) => {
                                           if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                               setFieldValue("hourly_base_rates.chha", e.target.value);
                                           }
                                       }}/>
                                <Field variant="outlined" name="hourly_base_rates.cna_chha" type={"text"}
                                       component={TextField} label="CNA/CHHA Rate ($/hr)" fullWidth autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_cna_chha" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.cna_chha", e.target.value);
                                    }
                                }}/>
                            </div>


                            <div className="input-container">
                                <Field variant="outlined" name="diff_rates.noc" type={"text"} component={TextField}
                                       label="NOC Diff Rate ($/hr)*" fullWidth autoComplete="off"
                                       id="input_facility_add_diff_rates_noc" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("diff_rates.noc", e.target.value);
                                    }
                                }}/>
                                <Field variant="outlined" name="hourly_base_rates.hazard" type={"text"}
                                       component={TextField} label="Hazard Rate ($/hr)*" fullWidth autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_hazard" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.hazard", e.target.value);
                                    }
                                }}/>
                            </div>

                            <div className="input-container ">
                                <Field variant="outlined" name="diff_rates.pm" type={"text"} component={TextField}
                                       label="PM Diff Rate ($/hr)*" fullWidth autoComplete="off"
                                       id="input_facility_add_diff_rates_pm" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("diff_rates.pm", e.target.value);
                                    }
                                }}/>
                                <Field variant="outlined" name="diff_rates.weekend" type={"text"} component={TextField}
                                       label="Weekend Rate ($/hr)*" fullWidth autoComplete="off"
                                       id="input_facility_add_diff_rates_weekend" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("diff_rates.weekend", e.target.value);
                                    }
                                }}/>
                            </div>


                            <div className="input-container ">
                                <Field variant="outlined" name="hourly_base_rates.evaluation" type={"text"}
                                       component={TextField}
                                       label="Evaluation (RN/OT/PT/ST)" fullWidth autoComplete="off"
                                       id="input_facility_add_diff_rates_evaluation" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.evaluation", e.target.value);
                                    }
                                }}/>
                                <Field variant="outlined" name="hourly_base_rates.start_of_care" type={"text"}
                                       component={TextField}
                                       label="Start of Care (RN/OT/PT/ST)" fullWidth autoComplete="off"
                                       id="input_facility_add_diff_rates_start_of_care" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.start_of_care", e.target.value);
                                    }
                                }}/>
                            </div>


                            <div className="input-container ">
                                <Field variant="outlined" name="hourly_base_rates.rn_routine_vist" type={"text"}
                                       component={TextField}
                                       label="Routine visit (RN)" fullWidth autoComplete="off"
                                       id="input_facility_add_diff_rates_pm" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.rn_routine_vist", e.target.value);
                                    }
                                }}/>
                                <Field variant="outlined" name="hourly_base_rates.lvn_routine_vist" type={"text"}
                                       component={TextField}
                                       label="Routine visit (LVN)" fullWidth autoComplete="off"
                                       id="input_facility_add_diff_rates_lvn_routine_vist" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.lvn_routine_vist", e.target.value);
                                    }
                                }}/>
                            </div>

                            <div className="input-container ">
                                <Field variant="outlined" name="hourly_base_rates.ot_pt_st_routine_vist" type={"text"}
                                       component={TextField}
                                       label="Routine visit (OT/PT/ST)" fullWidth autoComplete="off"
                                       id="input_facility_add_diff_rates_ot_pt_st_routine_vist" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.ot_pt_st_routine_vist", e.target.value);
                                    }
                                }}/>
                                <Field variant="outlined" name="hourly_base_rates.ota_pta_sta_routine_vist"
                                       type={"text"}
                                       component={TextField}
                                       label="Routine visit (OTA/PTA/STA)" fullWidth autoComplete="off"
                                       id="input_facility_add_diff_rates_ota_pta_sta_routine_vist"
                                       onChange={(e: any) => {
                                           if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                               setFieldValue("hourly_base_rates.ota_pta_sta_routine_vist", e.target.value);
                                           }
                                       }}/>
                            </div>

                            <div className="input-container ">
                                <Field variant="outlined" name="hourly_base_rates.cna_routine_vist" type={"text"}
                                       component={TextField}
                                       label="Routine visit (CNA)" fullWidth autoComplete="off"
                                       id="input_facility_add_diff_rates_cna_routine_vist" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.cna_routine_vist", e.target.value);
                                    }
                                }}/>
                                <Field variant="outlined" name="hourly_base_rates.caregiver_chha_routine_vist"
                                       type={"text"}
                                       component={TextField}
                                       label="Routine visit (CareGiver/CHHA)" fullWidth autoComplete="off"
                                       id="input_facility_add_diff_rates_caregiver_chha_routine_vist"
                                       onChange={(e: any) => {
                                           if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                               setFieldValue("hourly_base_rates.caregiver_chha_routine_vist", e.target.value);
                                           }
                                       }}/>
                            </div>

                            <div className="input-container ">
                                <Field variant="outlined" name="hourly_base_rates.discharge" type={"text"}
                                       component={TextField}
                                       label="Discharge" fullWidth autoComplete="off"
                                       id="input_facility_add_diff_rates_discharge" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.discharge", e.target.value);
                                    }
                                }}/>
                                <Field variant="outlined" name="hourly_base_rates.recertification" type={"text"}
                                       component={TextField}
                                       label="Recertification" fullWidth autoComplete="off"
                                       id="input_facility_add_diff_rates_recertification" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.recertification", e.target.value);
                                    }
                                }}/>
                            </div>

                            <div className="input-container ">
                                <Field variant="outlined" name="hourly_base_rates.resumption_of_care" type={"text"}
                                       component={TextField}
                                       label="Resumption of Care" fullWidth autoComplete="off"
                                       id="input_facility_add_diff_rates_pm" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.resumption_of_care", e.target.value);
                                    }
                                }}/>
                                <Field variant="outlined" name="hourly_base_rates.holiday" type={"text"}
                                       component={TextField} label="Holiday Rate ($/hr)*" fullWidth autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_holiday" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.holiday", e.target.value);
                                    }
                                }}/>
                            </div>


                            <div className="input-container">
                                <Field
                                    SelectProps={showDropDownBelowField}
                                    variant="outlined"
                                    name="conditional_rates.overtime.hours"
                                    type={"text"}
                                    component={TextField}
                                    select
                                    label="OT Hours (hr/day)"
                                    fullWidth
                                    autoComplete="off"
                                    id="input_facility_add_conditional_rates.overtime.hours"
                                >
                                    <MenuItem>Select</MenuItem>
                                    {otHours &&
                                        otHours.map((item: any, index: any) => (
                                            <MenuItem value={item.value} key={index}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                </Field>
                                <Field variant="outlined" name="conditional_rates.overtime.rate" type={"text"}
                                       component={TextField} label="OT Rate($/hr)" fullWidth autoComplete="off"
                                       id="input_facility_add_conditional_rates_overtime_rate" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("conditional_rates.overtime.rate", e.target.value);
                                    }
                                }}/>
                            </div>

                            <div className="input-container mrg-top-10">
                                <Field variant="outlined" name="conditional_rates.rush.hours" type={"text"}
                                       component={TextField} label="Rush Hours" fullWidth autoComplete="off"
                                       id="input_facility_add_conditional_rates_rush_hours"/>
                                <Field variant="outlined" name="conditional_rates.rush.rate" type={"text"}
                                       component={TextField} label="Rush Rate($/hr)" fullWidth autoComplete="off"
                                       id="input_facility_add_conditional_rates_rush_rate" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("conditional_rates.rush.rate", e.target.value);
                                    }
                                }}/>
                            </div>

                            <div className="input-container">
                                <Field
                                    variant="outlined"
                                    name="conditional_rates.cancellation_before.hours"
                                    type={"text"}
                                    component={TextField}
                                    label="Cancellation Before Hours"
                                    fullWidth
                                    autoComplete="off"
                                    id="input_facility_add_conditional_rates_cancellation_before_hours"
                                />
                                <Field
                                    variant="outlined"
                                    name="conditional_rates.cancellation_before.rate"
                                    type={"text"}
                                    component={TextField}
                                    label="Cancellation Before Rate($/hr)"
                                    fullWidth
                                    autoComplete="off"
                                    id="input_facility_add_conditional_rates_cancellation_before_rate"
                                    onChange={(e: any) => {
                                        if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                            setFieldValue("conditional_rates.cancellation_before.rate", e.target.value);
                                        }
                                    }}
                                />
                            </div>

                            <div className="input-container mrg-top-10">
                                <Field
                                    variant="outlined"
                                    name="conditional_rates.shift_early_completion.hours"
                                    type={"text"}
                                    component={TextField}
                                    label="Shift Early Completion Hours"
                                    fullWidth
                                    autoComplete="off"
                                    id="input_facility_add_conditional_rates_shift_early_completion_hours"
                                />
                                <Field
                                    variant="outlined"
                                    name="conditional_rates.shift_early_completion.rate"
                                    type={"text"}
                                    component={TextField}
                                    label="Shift Early Completion Rate($/hr)"
                                    fullWidth
                                    autoComplete="off"
                                    id="input_facility_add_conditional_rates_shift_early_completion_rate"
                                    onChange={(e: any) => {
                                        if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                            setFieldValue("conditional_rates.shift_early_completion.rate", e.target.value);
                                        }
                                    }}
                                />
                            </div>

                            <div className="input-container ">
                                <Field variant="outlined" name="hourly_base_rates.bsw" type={"text"}
                                       component={TextField}
                                       label="BSW Rate ($/hr)" fullWidth autoComplete="off"
                                       id="input_facility_add_diff_rates_bsw" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.bsw", e.target.value);
                                    }
                                }}/>
                            </div>

                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default FacilityAddDetailsComponent;
